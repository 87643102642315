import {
  Button,
  Skeleton,
  Stack,
  styled,
  Typography,
  Box,
  SxProps,
  Theme,
  Chip,
  ChipProps,
  Tooltip,
  IconButton,
  CircularProgress,
} from '@mui/material';
import Arrow from '@mui/icons-material/East';
import { FC, ReactNode } from 'react';
import { FlagIcon } from '@src/shared/flag-icon';
import { StopPropagationWrapper } from '../StopPropagationWrapper';
import { CheckboxIcon, checkboxType } from '../../shared/checkbox/checkbox';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import DuplicateIcon from '@mui/icons-material/ContentCopy';
import { Protected } from '@src/authentication/Protected';
import { ProductLineSlug, UserRole } from '@flashpack/graphql';

type PropType = Record<string, unknown> & {
  title: ReactNode;
  padTitle?: boolean;
  subtitle?: string;
  chips?: ChipProps[];
  children?: ReactNode;
  href?: string;
  onSelect?: () => unknown;
  onArchiveClick?: () => unknown;
  productLineSlug?: ProductLineSlug;
  onDuplicateClick?: () => unknown;
  duplicateLoading?: boolean;
  selected?: boolean;
  flagged?: boolean;
  greyedOut?: boolean;
  sx?: SxProps<Theme>;
  checkbox?: checkboxType;
  archived?: boolean;
  disabled?: boolean;
};

export const Bulletin = ({
  title,
  padTitle,
  subtitle,
  children,
  href,
  selected,
  flagged,
  greyedOut,
  onSelect,
  onArchiveClick,
  productLineSlug,
  onDuplicateClick,
  duplicateLoading,
  chips,
  sx,
  checkbox,
  archived,
  disabled,
  ...htmlAttrs
}: PropType) => {
  return (
    <Container sx={{ flexGrow: 1 }}>
      <ButtonContainer
        sx={sx}
        greyedOut={greyedOut ? true : undefined}
        selected={selected ? true : undefined}
        flagged={flagged ? true : undefined}
        {...htmlAttrs}
        href={href}
      >
        <>
          <FlagIcon flagged={flagged} />
          <Stack direction="row" alignItems="center" gap={2} width={'100%'}>
            {onSelect && (
              <StopPropagationWrapper allowDefault={true}>
                <CheckboxIcon
                  checkboxTypeProp={checkbox}
                  onSelect={onSelect}
                  selected={selected}
                ></CheckboxIcon>
              </StopPropagationWrapper>
            )}
            {!onSelect && padTitle && <Box sx={{ width: 42 }} />}
            <Stack flexGrow="1" direction="row" alignItems="center" gap={2}>
              {isString(title) ? (
                <Typography variant="subHeader">{title}</Typography>
              ) : (
                title
              )}
              {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </Stack>
            {chips?.map((chip, index) => (
              <Chip
                size={'small'}
                key={index}
                sx={{ backgroundColor: 'transparent', color: 'principal.grey70' }}
                {...chip}
              />
            ))}
            {onDuplicateClick && (
              <Protected roles={[UserRole.Superuser]}>
                <StopPropagationWrapper>
                  {productLineSlug === ProductLineSlug.FlashPack ? (
                    <Tooltip title="Duplicate for 45-59">
                      <IconButton onClick={onDuplicateClick} disabled={duplicateLoading}>
                        {duplicateLoading ? (
                          <CircularProgress size="24px" />
                        ) : (
                          <DuplicateIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Box sx={{ width: '40px', height: '40px' }} /> // Placeholder to maintain alignment
                  )}
                </StopPropagationWrapper>
              </Protected>
            )}
          </Stack>
          {archived != null && (
            <StopPropagationWrapper>
              <Tooltip title={archived ? 'Unarchive' : 'Archive'}>
                <IconButton
                  onClick={onArchiveClick}
                  data-testid="archive-adventure-button"
                  disabled={disabled}
                >
                  {disabled ? (
                    <CircularProgress sx={{ color: 'white' }} size="24px" />
                  ) : archived ? (
                    <UnarchiveOutlinedIcon />
                  ) : (
                    <ArchiveOutlinedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </StopPropagationWrapper>
          )}

          {href && <NextIcon />}
        </>
      </ButtonContainer>
      <Extra>{children}</Extra>
    </Container>
  );
};

export const BulletinList = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1),
}));

export const SkeletonBulletin = styled(Skeleton)(({ theme }) => ({
  display: 'flex',
  border: `1px solid ${theme.palette.principal.grey50}`,
  alignItems: 'center',
  borderRadius: '2px',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  minHeight: 72,
}));

export const SkeletonBulletinList: FC<{ count: number }> = ({ count }) => {
  return (
    <BulletinList>
      {[...Array(count).keys()].map((key) => (
        <SkeletonBulletin
          sx={{ animationDelay: `${key / 12}s`, animationDuration: '1s' }}
          variant="rectangular"
          key={key}
        />
      ))}
    </BulletinList>
  );
};

const Container = styled('div')(() => ({
  position: 'relative',
  background: 'white',
}));

export const ButtonContainer = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'greyedOut' && prop !== 'flagged',
})<{ selected?: boolean; greyedOut?: boolean; flagged?: boolean }>(
  ({ selected, greyedOut, flagged, theme }) => ({
    display: 'flex',
    width: '100%',
    border: `1px solid ${theme.palette.principal.grey50}`,
    justifyContent: 'space-between',
    borderRadius: '2px',
    padding: 0,
    color: theme.palette.principal.black,
    background: greyedOut
      ? theme.palette.principal.grey30
      : selected
        ? theme.palette.system.green30
        : flagged
          ? theme.palette.system.red10
          : 'white',
    borderColor: selected
      ? theme.palette.brand.jungle
      : flagged
        ? theme.palette.system.red100
        : undefined,
    '&:hover': {
      boxShadow: '0px 10px 20px -5px #20202033',
      backgroundColor: selected
        ? theme.palette.system.green10
        : greyedOut
          ? theme.palette.principal.grey30
          : flagged
            ? theme.palette.system.red30
            : theme.palette.principal.white,
    },

    // add a small white overlay on top of checkbox for better visibility
    // when the bulletin row is colored
    '& .MuiCheckbox-root': {
      position: 'relative',
      zIndex: 0,
    },
    '& .MuiCheckbox-root:not(.Mui-checked):after': {
      content: '""',
      left: 14,
      top: 14,
      height: 15,
      width: 15,
      position: 'absolute',
      backgroundColor: theme.palette.background.default,
      zIndex: -1,
    },
  }),
);

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.principal.grey70,
  flexGrow: 1,
  textAlign: 'left',
}));
Subtitle.defaultProps = {
  variant: 'bodyPara',
};

const Extra = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: theme.spacing(9),
}));

const NextIcon = styled(Arrow)(({ theme }) => ({
  color: theme.palette.principal.black,
  marginLeft: 20,
  height: 18,
  width: 18,
}));

const isString = (element: ReactNode) => typeof element === 'string';
