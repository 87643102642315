import { Capacitor } from '@capacitor/core';
import { FirebaseApp, initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  connectAuthEmulator,
  getAuth,
  initializeAuth,
} from 'firebase/auth';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_AUTH_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_AUTH_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_AUTH_STORAGE_BUCKET,
  appId: import.meta.env.VITE_FIREBASE_AUTH_APP_ID,
};

const shouldUseEmulator =
  import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST ||
  import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST_MOBILE;

export let firebaseApp: FirebaseApp;

export const initializeFirebase = () => {
  firebaseApp = initializeApp(firebaseConfig);
  if (Capacitor.isNativePlatform()) {
    initializeAuth(firebaseApp, {
      persistence: browserLocalPersistence,
    });
  }
  if (shouldUseEmulator) {
    const emulatorHost = Capacitor.isNativePlatform()
      ? import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST_MOBILE
      : import.meta.env.VITE_FIREBASE_AUTH_EMULATOR_HOST;
    connectAuthEmulator(getAuth(), emulatorHost, {
      disableWarnings: true,
    });
    const url = new URL(emulatorHost);
    connectStorageEmulator(
      getStorage(),
      url.hostname,
      import.meta.env.VITE_FIREBASE_STORAGE_EMULATOR_PORT as number,
    );
  }
};
