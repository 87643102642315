import { Rating, RatingProps } from '@mui/material';
import { FC } from 'react';
import { styled } from '@mui/material/styles';
import { StarRounded } from '@mui/icons-material';

const StyledRating = styled(Rating)(({ theme }) => ({
  color: theme.palette.principal.grey70,
  fontSize: theme.typography.micro.fontSize,
  lineHeight: theme.typography.micro.fontSize,
  '&.MuiRating-root.Mui-disabled': {
    opacity: '1',
  },
}));

export const HotelRating: FC<RatingProps> = ({ value, ...forwardProps }) => {
  return value ? (
    <StyledRating
      icon={<StarRounded sx={{ fontSize: 'inherit' }} />}
      value={value}
      emptyIcon={<span />}
      disabled
      {...forwardProps}
    />
  ) : (
    <span></span>
  );
};
