import { FC } from 'react';
import { InlineConfirmation } from 'src/design-system/dialogs/InlineConfirmation';
import {
  TimelineDocument,
  DeleteAccommodationsDocument,
  AdventureWithItineraryAndDepartureTimelinesDocument,
} from '@flashpack/graphql';
import { useToast } from 'src/shared/toast/useToast';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { DeleteTrashCanIcon } from 'design-system';
import { Stack } from '@mui/system';
import { Typography } from '@mui/material';
import { useMutation } from '@apollo/client';

interface PropTypes {
  accommodationIds: string[];
  actionOnDelete?: () => void;
}

export const DeleteAccommodations: FC<PropTypes> = ({
  accommodationIds,
  actionOnDelete,
}) => {
  const [deleteAccommodations] = useMutation(DeleteAccommodationsDocument, {
    refetchQueries: [
      TimelineDocument,
      AdventureWithItineraryAndDepartureTimelinesDocument,
    ],
  });

  const { success, error: errorToast } = useToast('bottom-right');
  const { safeMutation } = useSafeMutation();

  const onDelete = async () => {
    await safeMutation(deleteAccommodations({ variables: { accommodationIds } }), {
      onSuccess: () => {
        if (actionOnDelete) {
          actionOnDelete();
        }
        success('Accommodation successfully deleted');
      },
      onServerValidationError: (e) => errorToast(e.message),
      onUnexpectedError: () =>
        errorToast('Unexpected error occurred while deleting accommodation'),
    });
  };

  return (
    <InlineConfirmation
      confirmBtnLabel="Confirm"
      btnVariant="text"
      btnColor="error"
      btnSize="large"
      onConfirm={onDelete}
      alignment="start"
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
        <DeleteTrashCanIcon sx={{ height: '18px' }} />
        <Typography>Delete</Typography>
      </Stack>
    </InlineConfirmation>
  );
};
