import { useAuthorization } from '@src/authentication/AuthorizationProvider';
import { UserRole } from '@flashpack/graphql';

const usePermissions = () => {
  const { currentUser } = useAuthorization();
  const isFlashpack =
    currentUser?.role === UserRole.Flashpack || currentUser?.role === UserRole.Superuser;

  const canUserSubmitForm = (locked: boolean) => {
    return isFlashpack || !locked;
  };

  return { canUserSubmitForm };
};

export default usePermissions;
