import { Box, Button, Dialog, Stack, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { Departure } from '@flashpack/graphql';
import { Form, FormSpy } from 'react-final-form';
import { TextField } from 'mui-rff';
import { FormParser } from '@src/design-system/forms/parsers';
import { UserRole } from '@flashpack/graphql';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';
import { Validator } from 'design-system';

interface PropTypes {
  open: boolean;
  departure?: Departure;
  onUpdate: ({
    roomReleaseDays,
    capacity,
    toGuarantee,
    earlyBirds,
    manifestUrl,
  }: {
    roomReleaseDays: number;
    capacity: number;
    toGuarantee: number;
    earlyBirds: number;
    manifestUrl: string;
  }) => Promise<void>;
  onClose: () => void;
}

export const UpdateDepartureSettingsDialog: FC<PropTypes> = (props) => {
  const { open, onUpdate, onClose, departure } = props;
  const theme = useTheme();
  const [isDisabled, setIsDisabled] = useState(!departure);
  const { currentUser } = useAuthorization();
  const isUserFlashpack =
    currentUser?.role === UserRole.Flashpack || currentUser?.role === UserRole.Superuser;

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={onClose}>
      <Box padding={5} data-testid="departure-settings-dialog">
        <Typography variant="pageHeader">Settings</Typography>
        {!departure && (
          <Typography
            variant="label_Figma"
            color={theme.palette.principal.grey70}
            textAlign="right"
            mt={1}
          >
            {isUserFlashpack && 'leave blank for no change'}
          </Typography>
        )}
        <Form<{
          roomReleaseDays: number;
          capacity: number;
          earlyBirds: number;
          toGuarantee: number;
          manifestUrl: string;
        }>
          initialValues={{
            roomReleaseDays: departure?.roomReleaseDays,
            capacity: departure?.capacity ?? undefined,
            earlyBirds: departure?.maxEarlyBirds ?? undefined,
            toGuarantee: departure?.minToGuarantee ?? undefined,
            manifestUrl: departure?.manifestUrl ?? undefined,
          }}
          onSubmit={onUpdate}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={(e) => void handleSubmit(e)}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={departure ? 2 : 1}
                  mb={isUserFlashpack ? 0 : 2}
                >
                  <Typography variant="captionSingle">
                    {'Room release'}

                    <Typography
                      variant="label_Figma"
                      color={theme.palette.principal.grey70}
                    >
                      days before departure
                    </Typography>
                  </Typography>

                  <TextField
                    fullWidth={false}
                    placeholder="e.g. 30"
                    InputProps={{ sx: { width: '80px' } }}
                    size="small"
                    name="roomReleaseDays"
                    data-testid="update-departure-dialog-room-release-days"
                    fieldProps={{
                      parse: FormParser.parseNumber,
                    }}
                  />
                </Stack>
                {isUserFlashpack && (
                  <>
                    <Stack
                      direction="row"
                      alignItems="center"
                      my={2}
                      justifyContent="space-between"
                    >
                      <Typography variant="captionSingle">{'Capacity '}</Typography>
                      <TextField
                        fullWidth={false}
                        placeholder="e.g. 14"
                        InputProps={{ sx: { width: '80px' } }}
                        size="small"
                        name="capacity"
                        data-testid="update-departure-dialog-capacity"
                        fieldProps={{
                          parse: FormParser.parseNumber,
                        }}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      my={2}
                      justifyContent="space-between"
                    >
                      <Typography variant="captionSingle">{'Early Birds '}</Typography>
                      <TextField
                        fullWidth={false}
                        placeholder="e.g. 4"
                        InputProps={{ sx: { width: '80px' } }}
                        size="small"
                        name="earlyBirds"
                        data-testid="update-departure-dialog-early-birds"
                        fieldProps={{
                          parse: FormParser.parseNumber,
                        }}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="center"
                      my={2}
                      justifyContent="space-between"
                    >
                      <Typography variant="captionSingle">{'To Guarantee '}</Typography>
                      <TextField
                        fullWidth={false}
                        placeholder="e.g. 4"
                        InputProps={{ sx: { width: '80px' } }}
                        size="small"
                        name="toGuarantee"
                        data-testid="update-departure-dialog-min-to-guarantee"
                        fieldProps={{
                          parse: FormParser.parseNumber,
                        }}
                      />
                    </Stack>
                    {departure && (
                      <Box my={3}>
                        <Typography variant="captionSingle">Manifest link</Typography>
                        <Typography
                          variant="label_Figma"
                          color={theme.palette.principal.grey70}
                          my={1}
                        >
                          Paste the google sheets link here
                        </Typography>
                        <TextField
                          fullWidth
                          placeholder="e.g. https://docs.google.com/spreadsheets/d/1P7Zz9v8J0vXy1Q/edit#gid=0"
                          size="small"
                          name="manifestUrl"
                          data-testid="update-departure-dialog-manifest-url"
                          fieldProps={{
                            validate: Validator.validUrl,
                          }}
                        />
                      </Box>
                    )}
                  </>
                )}
                <Stack direction="row" justifyContent="space-between">
                  <Button onClick={() => onClose()} variant="outlined">
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit" disabled={isDisabled}>
                    Update
                  </Button>
                </Stack>
                <FormSpy
                  subscription={{ values: true }}
                  onChange={({ values }) => {
                    const allFieldsEmpty = Object.values(values).every(
                      (value) => value === undefined,
                    );
                    setIsDisabled(allFieldsEmpty);
                  }}
                />
              </form>
            );
          }}
        />
      </Box>
    </Dialog>
  );
};
