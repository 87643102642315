import { GenericError } from 'design-system';
import { ExtrasCostTable } from './ExtrasCostTable';
import { PassengerCostTable } from './PassengerCostTable';
import { MandatoryCostTable } from './MandatoryCostTable';
import { FC, useCallback, useEffect, useState } from 'react';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { SelectViewDropdown } from '@src/shared/table/SelectViewDropdown';
import { RoutePath } from '@src/shared/routePath';
import { CurrencySelectDropdown } from './CurrencyDropdown';
import { Stack, useTheme } from '@mui/system';
import { ExportCostsButton } from './ExportCostsButton';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Button, Tooltip } from '@mui/material';
import {
  ItineraryLockedCostsDocument,
  UpdateItineraryCostsLockedDocument,
  UserRole,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';
import { useApolloClient, useMutation } from '@apollo/client';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';
import { isVisibleTo } from '@src/authentication/Protected';
import { CostTypes, CostTypeLocked } from './utils';
import { Subscription } from 'zen-observable-ts';

const defaultNonTableScreenHeight = 465;

export interface CostsComponentProps {
  nonTableScreenHeight: number;
  renderCanton: (hasPendingChanges: boolean) => React.ReactNode;
}

type TableViewType = {
  path: CostTypes;
  label: string;
  lockedField: CostTypeLocked;
  component: FC<CostsComponentProps>;
};

const tableViews: TableViewType[] = [
  {
    path: 'passengersCost',
    label: 'Passengers',
    lockedField: 'passengerCostsLocked',
    component: PassengerCostTable,
  },
  {
    path: 'extrasCost',
    label: 'Extras',
    lockedField: 'extrasCostsLocked',
    component: ExtrasCostTable,
  },
  {
    path: 'mandatoryCost',
    label: 'Mandatory',
    lockedField: 'mandatoryCostsLocked',
    component: MandatoryCostTable,
  },
];

const viewOptions = tableViews.map((v) => {
  return {
    value: v.path,
    label: v.label,
  };
});

export const CostsPage = ({
  nonTableScreenHeight = defaultNonTableScreenHeight,
}: {
  nonTableScreenHeight?: number;
}) => {
  const { adventureId, itineraryId, view } = useRequiredParams([
    'adventureId',
    'itineraryId',
    'view',
  ]);
  const theme = useTheme();
  const [lockedItineraryCost, setLockedItineraryCost] = useState(false);
  const { safeMutation } = useSafeMutation();
  const { success, error: errorToast } = useToast();
  const [UpdateItineraryCostsLockedMutation] = useMutation(
    UpdateItineraryCostsLockedDocument,
  );

  const client = useApolloClient();
  const watchedQuery = client.watchQuery({
    query: ItineraryLockedCostsDocument,
    variables: {
      id: itineraryId,
    },
  });

  const { currentUser } = useAuthorization();
  const currentView = tableViews.find((x) => x.path === view);

  useEffect(() => {
    let subscription: Subscription;

    if (currentUser) {
      subscription = watchedQuery.subscribe(({ data }) => {
        if (!currentView) {
          throw 'No cost type selected';
        }
        const costField = currentView.lockedField;
        setLockedItineraryCost(data.itinerary[costField]);
      });
    }

    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [currentUser, watchedQuery, currentView]);

  const renderCanton = useCallback(
    (hasPendingChanges: boolean) => {
      return (
        <SelectViewDropdown
          hasPendingChanges={hasPendingChanges}
          adventureId={adventureId}
          itineraryId={itineraryId}
          view={view}
          viewOptions={viewOptions}
          routePath={RoutePath.ITINERARY_COSTS}
        />
      );
    },
    [adventureId, itineraryId, view],
  );

  if (!currentView) {
    return <GenericError error="The view does not exist" />;
  }

  const isFlashpackUser = isVisibleTo(currentUser, [UserRole.Flashpack]);

  const lockItineraryCost = async () => {
    await safeMutation(
      UpdateItineraryCostsLockedMutation({
        variables: {
          input: {
            id: itineraryId,
            passengerCostsLocked: !lockedItineraryCost,
            extrasCostsLocked: !lockedItineraryCost,
            mandatoryCostsLocked: !lockedItineraryCost,
          },
        },
      }),
      {
        onSuccess: () => {
          success(
            `All costs successfully ${lockedItineraryCost ? 'unlocked' : 'locked'}`,
          );
        },
        onError: () =>
          errorToast(`Failed to ${lockedItineraryCost ? 'unlock' : 'lock'} costs`),
      },
    );
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={theme.spacing(2)}
      >
        <CurrencySelectDropdown itineraryId={itineraryId} />
        <Stack direction="row" sx={{ gap: 2 }}>
          <Tooltip title={`${lockedItineraryCost ? 'Unlock' : 'Lock'} costs`}>
            <Button
              variant="outlined"
              onClick={() => void lockItineraryCost()}
              disabled={!isFlashpackUser}
              data-testid="lock-costs-button"
            >
              {lockedItineraryCost ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />}
            </Button>
          </Tooltip>
          <ExportCostsButton itineraryId={itineraryId} />
        </Stack>
      </Stack>
      <currentView.component
        nonTableScreenHeight={nonTableScreenHeight}
        renderCanton={renderCanton}
      />
    </>
  );
};
