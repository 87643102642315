import { useMutation, useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';
import { WarningMessage } from '@src/design-system/warning-message/WarningMessage';
import { useFxRatesWithDecimalsQuery } from '@src/finance/useFxRatesWithDecimalsQuery';
import {
  FxRates,
  ItineraryWithTripPricesDocument,
  Scalars,
  UpdateDeparturePricesDocument,
  UserRole,
} from '@flashpack/graphql';
import { FinanceEntity } from '@src/shared/finance/utils';
import Table, {
  TableData,
  TableRow,
  TableRowContextOption,
} from '@src/shared/table/Table';
import {
  departuresToTableRows,
  pasteFromAbove,
  showTableOpenMenuButton,
  lockPricesAndDiscountsFactory,
  lockedMultiCurrencyHeaders,
} from '@src/shared/table/utils';
import { useToast } from '@src/shared/toast/useToast';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { ReactNode, useMemo, useState } from 'react';
import { BulkUpdatePricesModal } from './BulkUpdatePricesModal';
import { handleAmountUsdChangedForLockedMultiCurrencyRow } from './utils';

const lockedPricesMessage =
  'The pricing table is read-only because you do not have permission to edit.';

export const TripPricesTable = ({
  renderCanton,
}: {
  renderCanton: (hasPendingChanges: boolean) => ReactNode;
}) => {
  const [openBulkUpdateModal, setOpenBulkUpdateModal] = useState<boolean>(false);
  const [selectedDepartureIds, setSelectedDepartureIds] = useState<
    Array<Scalars['UUID']>
  >([]);
  const { error: errorToast, success: successToast } = useToast();
  const { safeMutation } = useSafeMutation();
  const { itineraryId } = useRequiredParams(['itineraryId']);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const fx = useFxRatesWithDecimalsQuery();
  const itinerary = useQuery(ItineraryWithTripPricesDocument, {
    variables: {
      id: itineraryId,
    },
  });

  const { currentUser } = useAuthorization();
  const userIsSuperuser = currentUser?.role === UserRole.Superuser;
  const [updateDeparturePrices] = useMutation(UpdateDeparturePricesDocument);

  // Fetch departure pricing.
  const pricingData: TableData = useMemo(() => {
    const departures = itinerary.data?.itinerary?.departures ?? [];

    return {
      headers: lockedMultiCurrencyHeaders,
      rows: departuresToTableRows(
        departures,
        lockPricesAndDiscountsFactory(currentUser?.role),
      ),
    };
  }, [itinerary, currentUser]);

  if (fx.error) {
    return <Typography variant="pageHeader">Error fetching FX rates</Typography>;
  }

  const rowOptions: TableRowContextOption[] = [pasteFromAbove];

  const handleSubmitPricingAmounts = async (formValues: TableData) => {
    setIsSubmitting(true);
    await safeMutation(
      updateDeparturePrices({
        variables: {
          input: formValues.rows
            .filter((row) => row.values.amountUSD != undefined && !row.locked)
            .map((row) => {
              return {
                departureId: row.name,
                amountUSD: row.values.amountUSD as number,
              };
            }),
        },
        refetchQueries: [ItineraryWithTripPricesDocument],
      }),
      {
        onSuccess: () => {
          setIsSubmitting(false);
          successToast('Trip prices updated successfully');
        },
        onError: () => {
          setIsSubmitting(false);
          errorToast('Could not update trip prices');
        },
      },
    );
  };

  // Handle query FX rates
  const handleRowChanged = (row: TableRow) => {
    const pricingRate = fx.data?.fxRates.pricing;
    return handleAmountUsdChangedForLockedMultiCurrencyRow(row, pricingRate as FxRates);
  };

  // Render table modelling.
  return (
    <>
      {!userIsSuperuser && (
        <WarningMessage message={lockedPricesMessage} iconType="LOCKED" />
      )}
      <BulkUpdatePricesModal
        open={openBulkUpdateModal}
        onClose={() => setOpenBulkUpdateModal(false)}
        selectedDepartureIds={selectedDepartureIds}
        setSelectedDepartureIds={setSelectedDepartureIds}
      />
      <Table
        initialData={pricingData}
        handleSubmit={handleSubmitPricingAmounts}
        handleRowChanged={handleRowChanged}
        rowOptions={rowOptions}
        editable={true}
        isLoading={isSubmitting || fx.loading || itinerary.loading}
        showOpenMenuButton={showTableOpenMenuButton}
        isRequired={false}
        renderCanton={renderCanton}
        submitLabel={'Save Trip Costs'}
        tableFormId="prices-table"
        entity={FinanceEntity.PRICE}
        selectedRowIds={selectedDepartureIds}
        setSelectedRowIds={setSelectedDepartureIds}
        setOpenBulkUpdateModal={setOpenBulkUpdateModal}
      />
    </>
  );
};
