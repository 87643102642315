import { ProposeDeparturesDrawer } from './ProposeDeparturesDrawer';
import { useState } from 'react';
import {
  ItineraryWithItineraryAndDepartureTimelinesDocument,
  AdventureDocument,
  ItineraryDocument,
} from '@flashpack/graphql';
import { SkeletonBulletinList } from '@src/design-system/bulletin';
import { DeparturesList } from './DeparturesList';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { useQuery } from '@apollo/client';
import { DepartureWithAdjustmentsAndFlaggedType } from '@src/adventure/adventure-overview/AdventureDeparturesTab';
import { getDepartureAdjustments } from './adjustments/utils';

export const ItineraryDeparturesPage = () => {
  const { adventureId, itineraryId } = useRequiredParams(['adventureId', 'itineraryId']);

  const { data: itineraryData } = useQuery(ItineraryDocument, {
    variables: { id: itineraryId },
  });

  const { data: adventureData, loading: adventureLoading } = useQuery(AdventureDocument, {
    variables: { id: adventureId },
    skip: !itineraryData?.itinerary.adventure?.id,
  });

  const { data, loading } = useQuery(
    ItineraryWithItineraryAndDepartureTimelinesDocument,
    {
      variables: {
        id: itineraryId,
      },
    },
  );

  const departuresWithAdjustments = data?.itinerary.departures.reduce(
    (departureAccumulator, departure) => {
      const adjustments = getDepartureAdjustments(
        data.itinerary.timeline,
        departure.timeline,
      );
      departureAccumulator.push({
        id: departure.id,
        flagged: departure.timeline.flagged,
        durationDays: departure.timeline.durationDays,
        adjustments,
      });
      return departureAccumulator;
    },
    [] as DepartureWithAdjustmentsAndFlaggedType[],
  );

  const [drawerOpen, setDrawerOpen] = useState(false);

  const addDeparture = () => setDrawerOpen(true);

  if (adventureLoading) {
    return <SkeletonBulletinList count={5} />;
  }

  return (
    <>
      {itineraryData && (
        <ProposeDeparturesDrawer
          itineraryId={itineraryId}
          onClose={() => setDrawerOpen(false)}
          open={drawerOpen}
          adventureCode={adventureData?.adventure.code || ''}
          adventureId={adventureId}
          itineraryDescription={itineraryData?.itinerary.description || ''}
        />
      )}

      <DeparturesList
        addDeparture={addDeparture}
        itineraryId={itineraryId}
        adventureId={adventureId}
        adjustedDeparturesData={departuresWithAdjustments}
        adjustedDeparturesLoading={loading}
      />
    </>
  );
};
