import { Dispatch, SetStateAction } from 'react';
import { Box, Stack } from '@mui/system';
import { Divider, flashPackTheme, Typography } from 'design-system';
import { DayBoxItem } from '@src/shared/DayBoxItem';

export const DaysFilter = ({
  daysArray,
  selectedDay,
  setSelectedDay,
}: {
  daysArray: number[];
  selectedDay: number | null;
  setSelectedDay: Dispatch<SetStateAction<number | null>>;
}) => {
  return (
    <Box textAlign="left">
      <Typography color={flashPackTheme.palette.principal.grey70} mb={1}>
        Filter results by day:
      </Typography>
      <Stack direction="row" spacing={1}>
        {daysArray.map((day) => (
          <DayBoxItem
            key={day}
            onClick={() => {
              if (day === selectedDay) {
                setSelectedDay(null);
              } else {
                setSelectedDay(day);
              }
            }}
            sx={{
              color: flashPackTheme.palette.principal.grey70,
              background:
                day === selectedDay
                  ? flashPackTheme.palette.principal.grey50
                  : flashPackTheme.palette.common.white,
              '&:hover': {
                cursor: 'pointer',
              },
            }}
          >
            {day}
          </DayBoxItem>
        ))}
      </Stack>
      <Divider />
    </Box>
  );
};
