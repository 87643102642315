import { useLazyQuery, useMutation } from '@apollo/client';
import {
  AdventureWithItineraryAndDepartureTimelinesDocument,
  DirectionOfOverlapOnBulkUpdate,
  EditEngagementsDocument,
  EditEngagementsInput,
  EngagementFieldsForComparisonFragment,
  OverlappingDirectionForBulkUpdateEngagementsAutoResolveDocument,
} from '@flashpack/graphql';
import { ItineraryDrawer } from '@src/itinerary/common/ItineraryDrawer';
import { useToast } from '@src/shared/toast/useToast';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { TransferIcon } from 'design-system';
import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { getFormValuesFromTransfers, getPayloadForEditTransfers } from './utils';
import {
  EditTransferFormValues,
  TransferForm,
} from '@src/itinerary/transfer/common/TransferForm';
import { SharedTabsContextType } from '@src/shared/context/SharedTabsContext';

type PropTypes = {
  transfers: EngagementFieldsForComparisonFragment[];
  setTransfers: Dispatch<SetStateAction<EngagementFieldsForComparisonFragment[]>>;
  setDirectionOfOverlapOnBulkUpdate: Dispatch<
    SetStateAction<DirectionOfOverlapOnBulkUpdate | null>
  >;
  setEditEngagementsInput: Dispatch<SetStateAction<EditEngagementsInput | null>>;
};

export const TransferContext = createContext<Pick<SharedTabsContextType, 'transferForm'>>(
  {},
);

export const BulkEditTransferDrawer: FC<PropTypes> = (props) => {
  const {
    transfers,
    setTransfers,
    setDirectionOfOverlapOnBulkUpdate,
    setEditEngagementsInput,
  } = props;
  const drawerTitle = `${transfers.length} Transfer${transfers.length > 1 ? 's' : ''} Selected`;
  const { safeMutation } = useSafeMutation();
  const [editEngagements, { error }] = useMutation(EditEngagementsDocument);
  const [overlappingDirectionForBulkUpdateEngagementsAutoResolve] = useLazyQuery(
    OverlappingDirectionForBulkUpdateEngagementsAutoResolveDocument,
  );
  const { success: successToast, error: errorToast } = useToast();

  const [formValues, setFormValues] = useState(getFormValuesFromTransfers(transfers));
  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  useEffect(() => {
    setFormValues(getFormValuesFromTransfers(transfers));
    setHasPendingChanges(false);
  }, [transfers]);

  const handleSubmit = useCallback(
    async (formData: EditTransferFormValues) => {
      const transfersPayload = getPayloadForEditTransfers(formData);
      const { data: overlapData } =
        await overlappingDirectionForBulkUpdateEngagementsAutoResolve({
          variables: {
            input: transfersPayload,
          },
        });
      if (
        overlapData?.overlappingDirectionForBulkUpdateEngagementsAutoResolve
          .willConflictingItemsAutoResolveToMoveEarlier ||
        overlapData?.overlappingDirectionForBulkUpdateEngagementsAutoResolve
          .willConflictingItemsAutoResolveToMoveLater
      ) {
        setDirectionOfOverlapOnBulkUpdate(
          overlapData?.overlappingDirectionForBulkUpdateEngagementsAutoResolve,
        );
        setEditEngagementsInput(transfersPayload);
        return;
      }
      await safeMutation(
        editEngagements({
          variables: {
            input: transfersPayload,
          },
          refetchQueries: [AdventureWithItineraryAndDepartureTimelinesDocument],
        }),
        {
          onSuccess: () => {
            setHasPendingChanges(false);
            successToast(`${transfers.length} transfers successfully updated`);
          },
          onUnexpectedError: () => {
            errorToast(
              `There was an issue while saving ${transfers.length > 1 ? 'these transfers' : 'this transfer'} - try again later`,
            );
          },
          onServerValidationError: (error) => {
            errorToast(error.message);
          },
        },
      );
    },
    [
      editEngagements,
      errorToast,
      safeMutation,
      successToast,
      transfers,
      setDirectionOfOverlapOnBulkUpdate,
      setEditEngagementsInput,
      overlappingDirectionForBulkUpdateEngagementsAutoResolve,
    ],
  );

  return (
    <ItineraryDrawer
      warnForChangesNotSaved={hasPendingChanges}
      title={drawerTitle}
      icon={<TransferIcon />}
      handleClose={() => {
        setTransfers([]);
      }}
    >
      <TransferContext.Provider
        value={{
          transferForm: {
            state: formValues,
            setState: setFormValues,
            hasPendingChanges,
            setHasPendingChanges,
          },
        }}
      >
        <TransferForm
          initialValues={formValues}
          onSubmit={handleSubmit}
          onCancel={() => setTransfers([])}
          error={error}
          isBulk
        />
      </TransferContext.Provider>
    </ItineraryDrawer>
  );
};
