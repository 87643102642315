import * as FullStory from '@fullstory/browser';
import { HotelRate, HotelOverviewFragment } from '@flashpack/graphql';

const orgId = 'o-1CJHA9-na1';

export const initFullStory = () => {
  try {
    FullStory.init({ orgId });
  } catch (e) {}
};

enum Events {
  HOTEL_SEARCH = 'HOTEL-SEARCH',
  HOTEL_RATES_QUERY = 'HOTEL_RATES_QUERY',
  ADVENTURE_LINK_CLICK = 'ADVENTURE_LINK_CLICK',
  ACTIVITY_FEED_ICON_CLICK = 'ACTIVITY_FEED_ICON_CLICK',
}

export const captureAdventureLinkClick = (linkName_str: string) => {
  FullStory.event(Events.ADVENTURE_LINK_CLICK, {
    linkName_str,
  });
};

export const captureHotelSearchResults = (
  hotelName: string,
  hotels?: HotelOverviewFragment[] | null,
) => {
  FullStory.event(Events.HOTEL_SEARCH, {
    hotelName,
    numberOfResults: hotels?.length ?? 0,
  });
};

export const captureHotelRatesQueryResults = (
  hotelId: string,
  hotelRates?: HotelRate[] | null,
) => {
  FullStory.event(Events.HOTEL_RATES_QUERY, {
    hotelId,
    numberOfResults: hotelRates?.length ?? 0,
  });
};

export const captureActivityFeedIconClick = () => {
  FullStory.event('ACTIVITY_FEED_ICON_CLICK', {});
};
