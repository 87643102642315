import { DeparturesList } from '@src/departures/DeparturesList';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { useQuery } from '@apollo/client';
import { AdventureWithItineraryAndDepartureTimelinesDocument } from '@flashpack/graphql';
import { useState } from 'react';
import { ProposeDeparturesOnAdventureDrawer } from '@src/departures/ProposeDeparturesOnAdventureDrawer';
import { Adjustment, getDepartureAdjustments } from '@src/departures/adjustments/utils';

export type DepartureWithAdjustmentsAndFlaggedType = {
  id: string;
  flagged: boolean;
  durationDays: number;
  adjustments: Adjustment[];
};

export const AdventureDeparturesTab = () => {
  const { adventureId } = useRequiredParams(['adventureId']);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const addDeparture = () => setDrawerOpen(true);

  const { data, loading } = useQuery(
    AdventureWithItineraryAndDepartureTimelinesDocument,
    {
      variables: {
        id: adventureId,
      },
    },
  );

  const departuresWithAdjustments = data?.adventure.itineraries.reduce(
    (departureAccumulator, itinerary) => {
      itinerary.departures.forEach((departure) => {
        const adjustments = getDepartureAdjustments(
          itinerary.timeline,
          departure.timeline,
        );
        departureAccumulator.push({
          id: departure.id,
          flagged: departure.timeline.flagged,
          durationDays: departure.timeline.durationDays,
          adjustments,
        });
      });
      return departureAccumulator;
    },
    [] as DepartureWithAdjustmentsAndFlaggedType[],
  );

  return (
    <>
      <ProposeDeparturesOnAdventureDrawer
        onClose={() => setDrawerOpen(false)}
        open={drawerOpen}
        adventureId={adventureId}
      />

      <DeparturesList
        addDeparture={addDeparture}
        adventureId={adventureId}
        adjustedDeparturesData={departuresWithAdjustments}
        adjustedDeparturesLoading={loading}
      />
    </>
  );
};
