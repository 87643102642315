import { FC } from 'react';
import { Stack, Typography } from 'design-system';
import { format, formatDistanceToNow, parseISO, subDays } from 'date-fns';
import { flashPackTheme } from '@src/design-system/theme';
import { Departure, ViewedDepartureSignOffRequestDocument } from '@flashpack/graphql';
import { Button, Chip } from '@mui/material';
import { TaskAlt } from '@mui/icons-material';
import { TripFillIndicator } from './TripFillIndicator';
import { RoutePath } from '@src/shared/routePath';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { useMutation } from '@apollo/client';
import { useRouting } from '@src/shared/useRouting';
import { statusLabel } from './departure-status/utils';

export const DepartureSignOffCard: FC<{
  departure: Departure;
}> = ({ departure }) => {
  const [viewedDepartureSignOffRequest] = useMutation(
    ViewedDepartureSignOffRequestDocument,
  );
  const { navigate } = useRouting();

  const { id, itinerary, signedOffAt, status, date, tripFill, capacity, minToGuarantee } =
    departure;

  const timeSinceAction = signedOffAt
    ? formatDistanceToNow(new Date(signedOffAt))
    : formatDistanceToNow(subDays(new Date(date), 40));

  const { adventureId } = useRequiredParams(['adventureId']);

  const onClickReview: () => Promise<void> = async () => {
    await viewedDepartureSignOffRequest({
      variables: {
        departureId: id,
      },
    });
    navigate(
      RoutePath.ITINERARY_DEPARTURE_SIGN_OFF.generatePath(adventureId, itinerary.id, id),
    );
  };

  const onClickHandler = () => {
    // The button click handler does not accept promises, so we need to catch the promise
    onClickReview().catch(() => {});
  };

  return (
    <Stack
      sx={{ backgroundColor: flashPackTheme.palette.principal.grey30, padding: 2 }}
      spacing={2}
    >
      <Stack direction={'row'} alignItems="center" justifyContent="space-between">
        <Typography variant="bodySingle" fontSize="large">
          Sign-Off Request
        </Typography>
        <Typography variant="bodyPara" color={flashPackTheme.palette.principal.grey70}>
          {timeSinceAction} ago
        </Typography>
      </Stack>
      <Stack direction={'row'} alignItems="center" justifyContent="space-between">
        <Stack direction={'row'} alignItems="center">
          <Typography variant="bodyPara" mr={4}>
            {format(parseISO(date), 'dd MMM yyyy')}
          </Typography>
          <Chip label={statusLabel(status)} />
          <TripFillIndicator
            tripFill={tripFill}
            tripCapacity={capacity}
            minToGuarantee={minToGuarantee}
          />
        </Stack>
        {!!signedOffAt ? (
          <Stack direction="row" spacing={1}>
            <TaskAlt style={{ color: flashPackTheme.palette.system.green100 }} />
            <Typography variant="bodyPara" color={flashPackTheme.palette.system.green100}>
              Signed Off
            </Typography>
          </Stack>
        ) : (
          <Button variant="outlined" onClick={onClickHandler}>
            Review
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
