import { useMutation } from '@apollo/client';
import { Stack } from '@mui/system';
import {
  AccommodationFieldsFragment,
  FlagAccommodationDocument,
} from '@flashpack/graphql';
import { CommentsSection } from '@src/itinerary/common/comments/CommentsSection';
import { FlagSwitch } from '@src/itinerary/common/FlagSwitch';
import { FC, useCallback } from 'react';

type PropTypes = {
  accommodation: AccommodationFieldsFragment;
};

export const AccommodationCommentsTab: FC<PropTypes> = ({ accommodation }) => {
  const [flagAccommodationMutation] = useMutation(FlagAccommodationDocument);
  const { flagged } = accommodation;
  const onChange = useCallback(
    async ({ flagged }: { flagged: boolean }) => {
      if (!accommodation.id) {
        return;
      }

      return flagAccommodationMutation({
        variables: { accommodation: { id: accommodation.id, flagged } },
      });
    },
    [accommodation?.id, flagAccommodationMutation],
  );
  const onChangeFlagged = (flagged: boolean) => onChange({ flagged });

  return (
    <Stack gap={2} sx={{ marginY: 5 }}>
      <CommentsSection threadId={accommodation.commentThread.id} />
      <FlagSwitch flagged={flagged} onChange={onChangeFlagged} />
    </Stack>
  );
};
