import { Dispatch, FC, SetStateAction } from 'react';
import { MenuItem, Select, Typography } from '@mui/material';
import { flashPackTheme } from '@src/design-system/theme';

type ItinerarySelectProps = {
  itineraryOptions: { value: string; label: string }[];
  itineraryId: string;
  setItineraryId: Dispatch<SetStateAction<string>>;
};

export const ItinerarySelect: FC<ItinerarySelectProps> = ({
  itineraryOptions,
  itineraryId,
  setItineraryId,
}) => {
  return (
    <Select
      value={itineraryId}
      displayEmpty
      onChange={(event) => {
        setItineraryId(event.target.value);
      }}
      sx={{
        height: flashPackTheme.spacing(5),
        width: flashPackTheme.spacing(40),
        backgroundColor: flashPackTheme.palette.principal.grey30,
        '&:hover': {
          backgroundColor: flashPackTheme.palette.principal.grey50,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      }}
    >
      <MenuItem value={''} disabled>
        <Typography variant="bodyPara" color={flashPackTheme.palette.principal.grey70}>
          Select
        </Typography>
      </MenuItem>
      {itineraryOptions.map((view) => (
        <MenuItem value={view.value} key={view.value}>
          {view.label}
        </MenuItem>
      ))}
    </Select>
  );
};
