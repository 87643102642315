import { Chip, Typography, useTheme } from '@mui/material';
import { DepartureVisibility } from '@flashpack/graphql';
import { FC } from 'react';

interface PropTypes {
  visibility?: DepartureVisibility | null;
  onClick?: (e: React.MouseEvent) => void;
}

export const DepartureVisibilityItem: FC<PropTypes> = (props) => {
  const { visibility, onClick } = props;
  const theme = useTheme();

  return (
    <Chip
      onClick={onClick}
      sx={{
        color:
          visibility === DepartureVisibility.Live
            ? theme.palette.principal.white
            : theme.palette.principal.grey70,
        border: `1px solid ${theme.palette.principal.grey50}`,
        backgroundColor:
          visibility === DepartureVisibility.Live
            ? theme.palette.system.green100
            : theme.palette.principal.grey30,
        '&:hover': {
          backgroundColor:
            visibility === DepartureVisibility.Live
              ? theme.palette.system.green70
              : theme.palette.principal.grey50,
        },
      }}
      label={
        <Typography variant="bodySingle">
          {visibility === DepartureVisibility.Live ? 'On Site' : 'Not On Site'}
        </Typography>
      }
    />
  );
};
