import { ButtonProps } from '@mui/material';
import { Button } from './Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { FC } from 'react';

export const BackButton: FC<ButtonProps> = ({ sx, ...props }) => {
  return (
    <Button
      sx={{
        fontWeight: 500,
        marginBottom: 2,
        marginTop: 2,
        fontSize: 16,
        ...sx,
      }}
      size="large"
      startIcon={<ArrowBackIcon />}
      {...props}
    >
      {props.children}
    </Button>
  );
};
