import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client';
import fetch from 'cross-fetch';
import { introspectionResult } from '@flashpack/graphql';
import { onError } from '@apollo/client/link/error';
import { captureMessage } from '@sentry/react';
import { setContext } from '@apollo/client/link/context';
import { getAuth } from 'firebase/auth';
import { relayStylePagination } from '@apollo/client/utilities';

const getServerUri = () => {
  return import.meta.env.VITE_APOLLO_URI;
};

const apolloUri = getServerUri();

const authContext = setContext(async () => {
  const token = (await getAuth().currentUser?.getIdToken()) || '';
  return { headers: { Authorization: `Bearer ${token}` } };
});

const errorLink = onError(({ networkError, operation }) => {
  if (networkError) {
    captureMessage('A request has failed due to network issues.', {
      level: 'warning',
      tags: {
        operationName: operation.operationName,
        errorName: networkError.name,
      },
    });
  }
});

const getHttpLink = () => {
  if (!apolloUri) {
    throw new Error('The Apollo server URI has not been defined');
  }
  return createHttpLink({ uri: apolloUri, fetch });
};

export const apolloClient = new ApolloClient({
  link: from([authContext, errorLink, getHttpLink()]),
  cache: new InMemoryCache({
    ...introspectionResult,
    typePolicies: {
      Query: {
        fields: {
          userActivities: relayStylePagination(),
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
    },
  },
});

export const openApolloSandbox = () => {
  void getAuth()
    .currentUser?.getIdToken()
    .then((token) => {
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      window.open(`${apolloUri}?headers=${JSON.stringify(headers)}`, '_blank');
    });
};
