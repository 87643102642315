import { FC, ReactNode } from 'react';

import {
  AuthenticationContext,
  useFirebaseAuth,
} from '@src/shared/authentication/useAuthentication';

export const AuthenticationProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const { authenticated, loadingAuthentication } = useFirebaseAuth();

  return (
    <AuthenticationContext.Provider value={{ authenticated, loadingAuthentication }}>
      {children}
    </AuthenticationContext.Provider>
  );
};
