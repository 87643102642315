import { styled, ButtonProps } from '@mui/material';
import { Button } from './Button';

import { FC } from 'react';

export const TertiaryButton: FC<ButtonProps> = ({ sx, ...props }) => {
  return (
    <StyledButton
      sx={{
        fontWeight: 500,
        fontSize: 16,
        ...sx,
      }}
      {...props}
    >
      {props.children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  '&:hover': {
    background: theme.palette.system.green10,
  },
}));
