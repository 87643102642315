import ReactDOM from 'react-dom/client';
import App from '@src/app/App';
import { initSentry } from 'src/sentry';
import { initFullStory } from 'src/fullStory';
import { StrictMode } from 'react';
import { initiateCountryFlagEmojis } from '@src/country-flag-emojis';
import { initializeFirebase } from '@src/firebase';

initSentry();
initializeFirebase();
initFullStory();
initiateCountryFlagEmojis();

const root = document.getElementById('root');

if (root) {
  ReactDOM.createRoot(root).render(
    <StrictMode>
      <App />
    </StrictMode>,
  );
}
