import { Box, Dialog, Stack, Typography } from '@mui/material';
import { Button, CircleCloseIcon, flashPackTheme } from 'design-system';
import { FC } from 'react';

interface PropTypes {
  open: boolean;
  onClose: () => void;
}

export const RescheduleFailureModal: FC<PropTypes> = (props) => {
  const { open, onClose } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { width: '504px', maxWidth: 'none' } }}
    >
      <Stack padding={4}>
        <Stack direction="row" gap={1.5} mb={3} alignItems="center">
          <CircleCloseIcon
            sx={{ color: flashPackTheme.palette.system.red100, width: 32, height: 32 }}
          />
          <Typography variant="pageHeader">Reschedule Failure</Typography>
        </Stack>
        <Box
          p={1}
          sx={{ background: flashPackTheme.palette.system.red30, borderRadius: 2 }}
          mb={3}
        >
          <Typography variant="bodyPara">
            Sorry, something went wrong while we were trying to reschedule departures. No
            changes have been made. Please try again.
          </Typography>
        </Box>
        <Typography variant="bodyPara">
          If the problem persists, please reach out to your Flash Pack Adventure
          Operations Manager for help.
        </Typography>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            width: '160px',
            alignSelf: 'center',
            mt: 5,
          }}
        >
          Close
        </Button>
      </Stack>
    </Dialog>
  );
};
