import { Dialog, Typography, Box, Button, Stack } from '@mui/material';
import { FC } from 'react';
import { flashPackTheme } from 'design-system';
import { WarningAmber } from '@mui/icons-material';
import { OverlappingTimelineItemPair } from '@flashpack/graphql';

interface PropTypes {
  open: boolean;
  onClose?: () => void;
  overlappingTimelineItemPair: OverlappingTimelineItemPair;
}

export const ConflictModal: FC<PropTypes> = (props) => {
  const { open, onClose, overlappingTimelineItemPair } = props;
  const movingItemType =
    overlappingTimelineItemPair.movingItem.type.charAt(0).toUpperCase() +
    overlappingTimelineItemPair.movingItem.type.slice(1).toLowerCase();
  const movingItemTitle = overlappingTimelineItemPair.movingItem.title;
  const existingItemType = overlappingTimelineItemPair.existingItem.type.toLowerCase();
  const existingItemTitle = overlappingTimelineItemPair.existingItem.title;

  return (
    <Dialog fullWidth open={open} onClose={onClose}>
      <Stack padding={5}>
        <Typography variant="pageHeader" mb={2}>
          Error moving items
        </Typography>
        <Stack direction="row" alignItems="center" gap={1}>
          <WarningAmber sx={{ color: flashPackTheme.palette.system.red100 }} />
          <Typography color={flashPackTheme.palette.system.red100} variant="bodyPara">
            Not all schedule items could be moved
          </Typography>
        </Stack>
        <Typography variant="bodyPara" my={3}>
          {movingItemType} (<b>{movingItemTitle}</b>) cannot move because it will conflict
          with {existingItemType} (<b>{existingItemTitle}</b>).
        </Typography>
        <Box p={1} sx={{ background: flashPackTheme.palette.system.red10 }} mb={3}>
          <Typography variant="captionPara">
            Schedule items cannot conflict with each other - make space in the schedule or
            contact your Flash Pack representative to discuss what can be done.
          </Typography>
        </Box>
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            width: '160px',
            alignSelf: 'center',
          }}
        >
          Done
        </Button>
      </Stack>
    </Dialog>
  );
};
