import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { AccommodationFieldsFragmentWithDepartureDate } from './utils';
import { AccommodationFieldsForComparisonFragment } from '@flashpack/graphql';
import { MemoizedTimelineEventBoxAccommodation } from './TimelineEventBoxAccommodation';
import { Box, Stack } from '@mui/system';
import { Checkbox, flashPackTheme, Typography } from 'design-system';
import { ReactComponent as EmptyAccommodationIcon } from './EmptyAccommodationIcon.svg';
import { DaysFilter } from './DaysFilter';
import { NoMatchesFound } from './NoMatchesFound';

type Props = {
  departureAccommodations: AccommodationFieldsFragmentWithDepartureDate[];
  searchString: string;
  selectedAccommodations: AccommodationFieldsForComparisonFragment[];
  setSelectedAccommodations: Dispatch<
    SetStateAction<AccommodationFieldsForComparisonFragment[]>
  >;
};

export const SearchedAccommodations: FC<Props> = ({
  departureAccommodations,
  searchString,
  selectedAccommodations,
  setSelectedAccommodations,
}) => {
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedDay, setSelectedDay] = useState<number | null>(null);
  const maxDay = departureAccommodations.reduce(
    (max, a) => Math.max(max, a.accommodation.checkOut.day),
    0,
  );
  const daysArray = Array.from({ length: maxDay }, (_, i) => i + 1);

  const filteredAccommodations = useMemo(
    () =>
      departureAccommodations.filter((departureAccommodation) => {
        const titleMatches = departureAccommodation.accommodation.title
          .toLowerCase()
          .includes(searchString.toLowerCase());
        const dayMatches =
          !selectedDay ||
          (selectedDay >= departureAccommodation.accommodation.checkIn.day &&
            selectedDay <= departureAccommodation.accommodation.checkOut.day);

        return titleMatches && dayMatches;
      }),
    [departureAccommodations, searchString, selectedDay],
  );

  const toggleSelect = useCallback(
    (accommodation: AccommodationFieldsForComparisonFragment) => {
      setSelectedAccommodations((selectedAccommodations) => {
        if (
          selectedAccommodations.find(
            (selectedAccommodation) => selectedAccommodation.id === accommodation.id,
          )
        ) {
          setSelectedAll(false);
          return selectedAccommodations.filter(
            (selectedAccommodation) => selectedAccommodation.id !== accommodation.id,
          );
        }
        if (selectedAccommodations.length === filteredAccommodations.length - 1) {
          setSelectedAll(true);
        }
        return [...selectedAccommodations, accommodation];
      });
    },
    [setSelectedAccommodations, filteredAccommodations.length],
  );

  const toggleSelectAll = () => {
    if (selectedAll) {
      setSelectedAccommodations([]);
    } else {
      setSelectedAccommodations(filteredAccommodations.map((a) => a.accommodation));
    }
    setSelectedAll(!selectedAll);
  };

  // Clear selectedAll when search string changes
  useEffect(() => {
    setSelectedAll(false);
  }, [searchString]);

  // When drawer is closed, selectedAccommodations are cleared, so select all must be false
  useEffect(() => {
    if (selectedAccommodations.length === 0) {
      setSelectedAll(false);
    }
  }, [selectedAccommodations.length]);

  if (departureAccommodations.length === 0) {
    return (
      <Box mt={4}>
        <Typography variant="h3" color={flashPackTheme.palette.principal.grey70} my={2}>
          Find the accommodation you want to change by name
        </Typography>
        <EmptyAccommodationIcon />
      </Box>
    );
  }

  if (filteredAccommodations.length === 0) {
    return (
      <NoMatchesFound
        daysArray={daysArray}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
      />
    );
  }

  return (
    <Stack spacing={1}>
      <DaysFilter
        selectedDay={selectedDay}
        daysArray={daysArray}
        setSelectedDay={setSelectedDay}
      />
      <Stack direction="row" alignItems="center">
        <Checkbox
          checked={selectedAll}
          onChange={toggleSelectAll}
          data-testid="select-all-accommodation-items"
        />
        <Typography color={flashPackTheme.palette.principal.grey70}>
          {`Select all ${filteredAccommodations.length} result${filteredAccommodations.length > 1 ? 's' : ''}`}
        </Typography>
      </Stack>
      {filteredAccommodations.map(({ accommodation, departureDate }, key) => (
        <MemoizedTimelineEventBoxAccommodation
          key={accommodation.id}
          selected={
            !!selectedAccommodations.find(
              (selectedAccommodation) => selectedAccommodation.id === accommodation.id,
            )
          }
          onSelect={() => {
            toggleSelect(accommodation);
          }}
          departureDate={departureDate}
          accommodation={accommodation}
          testid={`searched-accommodation-item-${key}`}
        />
      ))}
    </Stack>
  );
};
