import { ChevronRight } from '@mui/icons-material';
import { Button, TextField, Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { HotelPicker } from '@src/hotels/hotel-picker/HotelPicker';
import { adventureCodeToIndividualCountryCodes } from '@src/shared/adventure/util';
import { AccommodationType, AdventureDocument } from '@flashpack/graphql';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { ChangeEvent, FC, useState } from 'react';
import { useQuery } from '@apollo/client';

type PropTypes = {
  onPropertySelect: (property: DraftPropertyMulti) => void;
  cancelPropertySelect?: () => void;
};

export type DraftPropertyMulti = {
  type: AccommodationType;
  ratehawkProperty?: {
    id: string;
  };
  manualProperty?: {
    name: string;
  };
};

export const SelectPropertyTab: FC<PropTypes> = ({
  onPropertySelect,
  cancelPropertySelect,
}) => {
  const [hotelName, setHotelName] = useState<string>('');
  const [isManual, setIsManual] = useState<boolean>(false);

  const { adventureId } = useRequiredParams(['adventureId']);

  const { data: adventureCodeData } = useQuery(AdventureDocument, {
    variables: { id: adventureId },
  });

  const selectRateHawkProperty = (hotelId: string) => {
    onPropertySelect({
      type: AccommodationType.RateHawk,
      ratehawkProperty: {
        id: hotelId,
      },
    });
  };
  const selectManualProperty = () => {
    onPropertySelect({
      type: AccommodationType.Manual,
      manualProperty: {
        name: hotelName,
      },
    });
  };
  const countryCodes = adventureCodeToIndividualCountryCodes(
    adventureCodeData?.adventure.code ?? '',
  );

  return (
    <Box>
      {isManual && (
        <Stack gap={1}>
          <Typography variant="subHeader">Accommodation name</Typography>
          <TextField
            fullWidth
            name="description"
            placeholder="eg. Amazonian Treehouses"
            data-testid="select-property-tab__manual-accommodation-name"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setHotelName(e.target.value);
            }}
          />
          <Stack direction="row" justifyContent="end">
            <Button
              variant="contained"
              disabled={hotelName.length === 0}
              onClick={selectManualProperty}
              data-testid="manual-accommodation-confirm"
            >
              Confirm
            </Button>
          </Stack>
        </Stack>
      )}
      {!isManual && (
        <HotelPicker
          hotelName={hotelName}
          setHotelName={setHotelName}
          onHotelSelected={selectRateHawkProperty}
          showClosedHotels={false}
          countryFilter={countryCodes}
        >
          <Stack direction="row" justifyContent="end">
            <Button onClick={() => setIsManual(true)}>
              <Typography variant="bodySingle">Add accommodation manually</Typography>
              <ChevronRight />
            </Button>
          </Stack>
        </HotelPicker>
      )}
      {cancelPropertySelect && (
        <Stack mt={1} direction="row" justifyContent="flex-end">
          <Button onClick={cancelPropertySelect} variant="outlined">
            Cancel
          </Button>
        </Stack>
      )}
    </Box>
  );
};
