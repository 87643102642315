import { useMutation, useQuery } from '@apollo/client';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { SkeletonBulletinList } from '@src/design-system/bulletin';
import { ConfirmationDialog } from '@src/design-system/dialogs/ConfirmationDialog';
import {
  AdventureWithItinerariesAndDeparturesDocument,
  AssignPackLeaderDocument,
  PackLeadersForItineraryDocument,
  Scalars,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { FC } from 'react';
import { PackLeaderAvatar } from './DeparturePackLeader';

interface PropTypes {
  open: boolean;
  onClose: () => void;
  itineraryId: Scalars['UUID'];
  departureId: Scalars['UUID'];
}

export const AssignPackLeadersDialog: FC<PropTypes> = ({ open, onClose, ...props }) => {
  return (
    <ConfirmationDialog
      open={open}
      onCancel={onClose}
      title="Assign Pack Leader"
      hasCustomActions={true}
      maxWidth="sm"
    >
      <AssignPackLeaders onClose={onClose} {...props} />
    </ConfirmationDialog>
  );
};

export const AssignPackLeaders: FC<Omit<PropTypes, 'open'>> = ({
  onClose,
  itineraryId,
  departureId,
}) => {
  const theme = useTheme();

  const { safeMutation } = useSafeMutation();
  const { success, error } = useToast();

  const { data, loading } = useQuery(PackLeadersForItineraryDocument, {
    variables: { itineraryId },
  });

  const [assignPackLeaderMutation] = useMutation(AssignPackLeaderDocument);

  if (loading) {
    return <SkeletonBulletinList count={3} />;
  }

  const packLeaders = [...(data?.itinerary.packLeaders ?? [])].sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  if (packLeaders?.length == 0) {
    return (
      <>
        <Typography
          variant="bodySingle"
          sx={{
            lineHeight: '20px',
            color: theme.palette.principal.grey70,
          }}
        >
          There are no pack leaders for this itinerary. Add Pack Leaders in the Pack
          Leaders tab.
        </Typography>
      </>
    );
  }

  const assignPackLeader = async (packLeaderId: Scalars['UUID'] | null) => {
    const successMessage = packLeaderId
      ? 'Pack leader assigned'
      : 'Pack leader unassigned';

    const errorMessage = packLeaderId
      ? 'Failed to assign pack leader'
      : 'Failed to unassign pack leader';

    await safeMutation(
      assignPackLeaderMutation({
        variables: {
          departureId,
          packLeaderId,
        },
        refetchQueries: [AdventureWithItinerariesAndDeparturesDocument],
      }),
      {
        onSuccess: () => success(successMessage),
        onError: () => error(errorMessage),
      },
    );
    onClose();
  };

  return (
    <Box>
      {packLeaders.map((packLeader) => {
        return (
          <Button
            fullWidth
            sx={{
              justifyContent: 'flex-start',
              paddingX: theme.spacing(3),
              py: 2,
              color: theme.palette.principal.black,
              '&:hover': { backgroundColor: theme.palette.principal.grey30 },
            }}
            data-testid="assign-pack-leader"
            key={packLeader.id}
            onClick={() => void assignPackLeader(packLeader.id)}
          >
            <Stack gap={1} direction="row" alignItems="center">
              <PackLeaderAvatar size={30} packLeader={packLeader} fontSize={15} />
              <Typography>{packLeader.name}</Typography>
              <Typography>({packLeader.email})</Typography>
            </Stack>
          </Button>
        );
      })}

      <Box>
        <Button
          sx={{ mt: 2 }}
          fullWidth
          variant="outlined"
          key={'remove'}
          data-testid="remove-pack-leader"
          title={'Remove pack leader'}
          onClick={() => void assignPackLeader(null)}
        >
          Remove
        </Button>
      </Box>
    </Box>
  );
};
