import { Button, ButtonProps } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import LinkBehavior from '../LinkBehaviour';

export const CreateButton = ({
  title,
  href,
  ...forwardProps
}: {
  title: string;
  href?: string;
} & ButtonProps) => {
  return (
    <Button
      href={href}
      variant="contained"
      title={title}
      LinkComponent={LinkBehavior}
      sx={{ paddingX: 3 }}
      {...forwardProps}
    >
      <AddIcon fontSize="small" sx={{ marginRight: 1 }} />
      {title}
    </Button>
  );
};
