import { FC, ReactElement } from 'react';
import { AutocompleteField } from 'src/design-system/forms/autocomplete/AutocompleteField';
import { AutocompleteProps } from 'mui-rff';

import { Validator } from 'src/design-system/forms/validators';
import { Field } from 'react-final-form';

export type PropTypes = Omit<
  AutocompleteProps<string | number, false, false, false>,
  'options'
> & {
  label?: string;
  linkedStartDayField?: string;
  readonly?: boolean;
  isMixed?: boolean;
  isBulk?: boolean;
};

export const DaySelector: FC<PropTypes> = ({
  label,
  linkedStartDayField,
  readonly,
  isMixed,
  isBulk,
  ...props
}) => {
  return (
    <LinkedStartDayWrapper
      linkedStartDayField={linkedStartDayField}
      render={({ startDay }) => (
        <AutocompleteField
          options={isBulk ? [...getOptions(startDay), 'Multiple'] : getOptions(startDay)}
          getOptionLabel={(key) => (isMixed ? `${key}` : `Day ${key}`)}
          helperText=" "
          label={label}
          fieldProps={{ validate: Validator.required }}
          data-testid="day-selector"
          fullWidth
          readOnly={readonly}
          {...props}
        />
      )}
    />
  );
};
DaySelector.defaultProps = {
  label: 'Select a day',
};

const getOptions = (startDay = 1) =>
  [...Array(30 - startDay).keys()].map((num) => num + startDay);

type LinkedValueWrapperProps = {
  linkedStartDayField?: string;
  render: ({ startDay }: { startDay: number }) => ReactElement;
};
const LinkedStartDayWrapper: FC<LinkedValueWrapperProps> = ({
  linkedStartDayField,
  render,
}) => {
  if (linkedStartDayField) {
    return (
      <Field
        name={linkedStartDayField}
        subscription={{ value: true }}
        render={({ input: { value } }) => render({ startDay: Number(value) || 1 })}
      />
    );
  }

  return render({ startDay: 1 });
};
