import { AddAccommodationPage } from '@src/itinerary/add-accommodation/AddAccommodationPage';
import { ViewEditAccommodationPage } from '@src/itinerary/view-accommodation/ViewEditAccommodationPage';
import { RoutePath } from '@src/shared/routePath';
import { TimelineSchedule } from '@src/shared/timeline/TimelineSchedule';
import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ItineraryDetailsActions } from '@src/itinerary/itinerary-details/ItineraryDetailsActions';
import { AddTransferPage } from '@src/itinerary/transfer/add-transfer/AddTransferPage';
import { AddActivityPage } from '@src/itinerary/activity/add-activity/AddActivityPage';
import { AdventureDocument, TimelineDocument, UserRole } from '@flashpack/graphql';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { TimelineContext } from './TimelineContext';
import { SkeletonBulletinList } from '@src/design-system/bulletin';
import { Box, Skeleton } from '@mui/material';
import { ViewEditTransferPage } from '@src/itinerary/transfer/view-transfer/ViewEditTransferPage';
import { ViewEditActivityPage } from '@src/itinerary/activity/view-activity/ViewEditActivityPage';
import { useQuery } from '@apollo/client';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';
import { useDrawerContext } from '../context/DrawerContext';
interface PropTypes {
  locked?: boolean;
  timelineId: string;
  emptyTimelineMessage: string;
  lockedTimelineMessage: string;
  departureDate?: string;
  routeLocation: string;
}

export const TimelineSection: FC<PropTypes> = (props) => {
  const {
    timelineId,
    locked,
    emptyTimelineMessage,
    lockedTimelineMessage,
    departureDate,
    routeLocation,
  } = props;
  const { adventureId } = useRequiredParams(['adventureId']);
  const { timelineDrawerOpen } = useDrawerContext();

  const { data: adventureCodeData } = useQuery(AdventureDocument, {
    variables: { id: adventureId },
  });

  const { data: timelineData, loading: timelineLoading } = useQuery(TimelineDocument, {
    variables: { id: timelineId ?? '' },
    skip: !timelineId,
  });
  const { currentUser } = useAuthorization();

  const allowedRoles: string[] = [UserRole.Flashpack, UserRole.Superuser, UserRole.Dmc];
  const userHasEditAccess = !!currentUser && allowedRoles.includes(currentUser.role);

  const timeline = timelineData?.timeline;

  if (timelineLoading) {
    return (
      <Box mt={4}>
        <Skeleton width={130} height={40} />
        <Box mx={10} mt={1}>
          <SkeletonBulletinList count={10} />
        </Box>
      </Box>
    );
  }

  if (!timeline) {
    return null;
  }

  const getAccommodation = (id: string) => {
    const accommodation = timeline.accommodations.find((a) => a.id === id);
    if (!accommodation) {
      throw new Error('Cant find an accommodation with the provided id');
    }
    return accommodation;
  };

  const getEngagement = (id: string) => {
    const engagement = timeline.engagements.find((e) => e.id === id);
    if (!engagement) {
      throw new Error('Cant find an engagement with the provided id');
    }
    return engagement;
  };

  const hideAddTimelineComponentButton = locked || timelineDrawerOpen;

  return (
    <TimelineContext.Provider value={{ timelineId, routeLocation }}>
      <Routes>
        <Route
          path={RoutePath.ADD_ACCOMMODATION.relative}
          element={
            <AddAccommodationPage adventureCode={adventureCodeData?.adventure.code} />
          }
        />
        <Route path={RoutePath.ADD_TRANSFER.relative} element={<AddTransferPage />} />
        <Route path={RoutePath.ADD_ACTIVITY.relative} element={<AddActivityPage />} />
      </Routes>
      <Routes>
        <Route
          path={RoutePath.VIEW_ACCOMMODATION.relative}
          element={
            <ViewEditAccommodationPage
              getAccommodation={getAccommodation}
              isTimelineLocked={locked}
              isEditable={userHasEditAccess}
            />
          }
        />
        <Route
          path={RoutePath.VIEW_TRANSFER.relative}
          element={
            <ViewEditTransferPage
              getTransfer={getEngagement}
              isTimelineLocked={locked}
              isEditable={userHasEditAccess}
            />
          }
        />
        <Route
          path={RoutePath.VIEW_ACTIVITY.relative}
          element={
            <ViewEditActivityPage
              getActivity={getEngagement}
              isTimelineLocked={locked}
              isEditable={userHasEditAccess}
            />
          }
        />
      </Routes>
      <TimelineSchedule
        locked={locked}
        timeline={timeline}
        emptyTimelineMessage={emptyTimelineMessage}
        lockedTimelineMessage={lockedTimelineMessage}
        departureDate={departureDate}
      />
      {!hideAddTimelineComponentButton && <ItineraryDetailsActions />}
    </TimelineContext.Provider>
  );
};
