import { FC, useState } from 'react';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { Box, Link, PageHeading, Stack, Typography } from 'design-system';
import { useMutation, useQuery } from '@apollo/client';
import { format, parseISO } from 'date-fns';
import { flashPackTheme } from '@src/design-system/theme';
import {
  AccommodationFieldsForComparisonFragment,
  DepartureSignOffQueryDocument,
  Departure,
  SignOffDepartureDocument,
  DepartureSignOffSortAndFilter,
} from '@flashpack/graphql';
import { AccommodationDetails } from '@src/shared/accommodation/AccommodationDetails';
import { Button } from '@mui/material';
import { EditOutlined, OpenInNew } from '@mui/icons-material';
import { BulkEditAccommodationDrawer } from '@src/shared/timeline/bulk-timeline-items/BulkEditAccommodationDrawer';
import { DeparturePackLeader } from './DeparturePackLeader';
import { AssignPackLeadersDialog } from './AssignPackLeadersDialog';
import { styled } from '@mui/material/styles';
import { RoutePath } from '@src/shared/routePath';
import { useRouting } from '@src/shared/useRouting';

const BorderedBox = styled(Box)(({ theme }) => ({
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.shape.borderRadius,
  borderColor: flashPackTheme.palette.principal.grey50,
  textAlign: 'left',
  padding: theme.spacing(2),
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

const EditButton = ({ onClick }: { onClick: () => void }) => (
  <Button variant="outlined" startIcon={<EditOutlined />} onClick={onClick}>
    Edit
  </Button>
);

export const ItineraryDepartureSignOff: FC = () => {
  const { departureId, itineraryId, adventureId } = useRequiredParams([
    'departureId',
    'itineraryId',
    'adventureId',
  ]);
  const [selectedAccommodations, setSelectedAccommodations] = useState<
    AccommodationFieldsForComparisonFragment[]
  >([]);
  const [packLeaderDialogOpen, setPackLeaderDialogOpen] = useState(false);
  const { navigate } = useRouting();
  const [signOffDeparture] = useMutation(SignOffDepartureDocument, {
    variables: { departureId },
    onCompleted: () => {
      navigate(RoutePath.ADVENTURE_ACTIONS.generatePath(adventureId), {
        toAdd: {
          ordering: DepartureSignOffSortAndFilter.SignOffCompleted,
        },
      });
    },
  });

  const { data } = useQuery(DepartureSignOffQueryDocument, {
    variables: { departureId },
  });

  const renderAccommodationItem = (
    accommodation: AccommodationFieldsForComparisonFragment,
    departureDate: Departure['date'],
  ) => (
    <BorderedBox key={accommodation.id}>
      <Stack direction="row" justifyContent="space-between" alignItems="start">
        <Box>
          <Typography variant="bodySingle" pb={1}>
            {accommodation.title}
          </Typography>
          <AccommodationDetails
            accommodation={accommodation}
            departureDate={departureDate}
          />
        </Box>
        <EditButton onClick={() => setSelectedAccommodations([accommodation])} />
      </Stack>
    </BorderedBox>
  );

  const renderPackLeaderSection = () => (
    <Box mt={4}>
      <Typography variant="subHeader" mb={2}>
        Pack Leader
      </Typography>
      <BorderedBox>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" gap={1.5}>
            <DeparturePackLeader
              disabled
              packLeader={data?.departure.packLeader ?? undefined}
            />
            {data?.departure.packLeader ? (
              <Typography variant="bodySingle">
                {data.departure.packLeader.name} ({data.departure.packLeader.email})
              </Typography>
            ) : (
              <Typography variant="bodyPara" color={flashPackTheme.palette.system.red100}>
                Select Pack Leader to Sign-Off
              </Typography>
            )}
          </Stack>
          <EditButton onClick={() => setPackLeaderDialogOpen(true)} />
        </Stack>
      </BorderedBox>
    </Box>
  );

  const renderManifestSection = () => (
    <Box mt={4}>
      <Typography variant="subHeader" mb={1}>
        Manifest
      </Typography>
      <Typography variant="bodyPara">
        ✅ Confirm you have secured the Allocations
      </Typography>
      <Typography variant="bodyPara" mb={2}>
        ✅ Confirm you have arranged Arrival Transfers
      </Typography>
      <BorderedBox sx={{ width: 'fit-content' }}>
        {data?.departure.manifestUrl ? (
          <Stack direction="row" gap={0.5}>
            <Link
              href={data.departure.manifestUrl}
              target="_blank"
              rel="noreferrer"
              underline="none"
            >
              <Typography>Open Manifest</Typography>
            </Link>
            <OpenInNew sx={{ color: flashPackTheme.palette.brand.jungle }} />
          </Stack>
        ) : (
          <Typography color={flashPackTheme.palette.principal.grey70}>
            No Manifest Provided
          </Typography>
        )}
      </BorderedBox>
    </Box>
  );

  return (
    <>
      <Stack direction={'row'} spacing={2} alignItems="center" mt={4}>
        <PageHeading data-testid="adventure-code">Sign-Off Request</PageHeading>
        <Box
          sx={{
            backgroundColor: flashPackTheme.palette.principal.grey70,
            px: 1,
            borderRadius: 1,
          }}
        >
          <Typography color={flashPackTheme.palette.principal.white}>
            {data?.departure.date
              ? format(parseISO(data?.departure.date), 'dd MMM yyyy')
              : '...loading'}
          </Typography>
        </Box>
      </Stack>
      <Typography variant="bodyPara" my={2}>
        Please confirm all the information below is correct
      </Typography>
      <Box mt={4}>
        <Typography variant="subHeader">Accommodations</Typography>
      </Box>
      {data?.departure.timeline.accommodations.map((accommodation) =>
        renderAccommodationItem(accommodation, data.departure.date),
      )}
      {selectedAccommodations.length > 0 && (
        <BulkEditAccommodationDrawer
          accommodations={selectedAccommodations}
          setAccommodations={setSelectedAccommodations}
        />
      )}
      {renderPackLeaderSection()}
      <AssignPackLeadersDialog
        onClose={() => setPackLeaderDialogOpen(false)}
        open={packLeaderDialogOpen}
        departureId={departureId}
        itineraryId={itineraryId}
      />
      {renderManifestSection()}
      <Stack direction="row" justifyContent="end" gap={2} mt={4}>
        <Button
          variant="outlined"
          onClick={() => {
            navigate(-1);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!data?.departure.packLeader}
          onClick={() => void signOffDeparture()}
        >
          Sign-Off
        </Button>
      </Stack>
    </>
  );
};
