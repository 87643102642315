import { Decimal } from 'decimal.js';
import { TableRow } from '@src/shared/table/Table';
import { FxRates } from '@flashpack/graphql';

const roundUpToFive = (amount: Decimal): number =>
  amount.dividedBy(5).ceil().mul(5).toNumber();

export const handleAmountUsdChangedForLockedMultiCurrencyRow = (
  row: TableRow,
  pricingRate?: FxRates | null,
) => {
  const priceUSD = row.values.amountUSD;
  if (priceUSD === undefined || priceUSD === null || !pricingRate) {
    row.values = {};
    return row;
  }
  row.values.amountGBP = roundUpToFive(pricingRate.rateGBP.mul(priceUSD));
  row.values.amountEUR = roundUpToFive(pricingRate.rateEUR.mul(priceUSD));
  row.values.amountHKD = roundUpToFive(pricingRate.rateHKD.mul(priceUSD));
  row.values.amountAUD = roundUpToFive(pricingRate.rateAUD.mul(priceUSD));
  row.values.amountAED = roundUpToFive(pricingRate.rateAED.mul(priceUSD));
  row.values.amountNZD = roundUpToFive(pricingRate.rateNZD.mul(priceUSD));
  row.values.amountCAD = roundUpToFive(pricingRate.rateCAD.mul(priceUSD));
  return row;
};
