import {
  Container,
  Grid,
  Typography,
  Skeleton,
  OutlinedInput,
  Box,
  IconButton,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { FC, ReactNode, useCallback, useEffect } from 'react';
import { HotelsDocument } from '@flashpack/graphql';
import { HotelCard } from 'src/hotels/hotels-overview/HotelCard';
import { captureHotelSearchResults } from 'src/fullStory';
import { useTheme } from '@mui/material/styles';
import { extractInfoFromRateHawkProperty } from '@src/shared/accommodation/utils';
import { useLazyQuery } from '@apollo/client';
interface PropTypes {
  onHotelSelected: (hotelId: string) => void;
  showClosedHotels: boolean;
  hotelName: string;
  setHotelName: (name: string) => void;
  children?: ReactNode | ReactNode[];
  countryFilter: string[];
}

export const HotelPicker: FC<PropTypes> = ({
  onHotelSelected,
  showClosedHotels,
  children,
  hotelName,
  setHotelName,
  countryFilter,
}) => {
  const theme = useTheme();
  const [searchHotels, { data, loading }] = useLazyQuery(HotelsDocument);

  const onHotelSearch = useCallback(() => {
    void searchHotels({
      variables: {
        name: hotelName,
        countryFilter,
      },
    });
  }, [countryFilter, hotelName, searchHotels]);

  useEffect(() => {
    if (!loading) {
      captureHotelSearchResults(hotelName, data?.hotels);
    }
  }, [data, hotelName, loading]);

  useEffect(() => {
    if (hotelName) {
      onHotelSearch();
    }
  }, [hotelName, onHotelSearch]);

  const onHotelClick = (hotelId: string) => {
    onHotelSelected(hotelId);
  };

  const renderHotelResults = () => {
    if (loading) {
      return (
        <Grid container columns={6} spacing={2}>
          {[...Array(4).keys()].map((key) => (
            <Grid key={key} item xs={3}>
              <Skeleton variant="rectangular" width="100%" height={420} />
            </Grid>
          ))}
        </Grid>
      );
    }

    const hotels = data?.hotels;

    if (!hotels) {
      return;
    }

    const hotelResults = hotels?.filter((hotel) => showClosedHotels || !hotel?.is_closed);

    if (hotelResults?.length === 0) {
      return (
        <Box mt={4}>
          <Typography variant="bodySingle" color={theme.palette.grey[400]}>
            No hotels found.
          </Typography>
        </Box>
      );
    }

    const hotelCards = hotelResults?.map((hotel, index) => {
      const hotelInfo = extractInfoFromRateHawkProperty(hotel);
      return (
        <Grid key={index} item xs={12} sm={3} xl={3}>
          <HotelCard
            onClick={hotel?.is_closed ? () => {} : onHotelClick}
            hotelInfo={hotelInfo}
            view="search"
          />
        </Grid>
      );
    });

    return (
      <Box>
        <Box mt={4} sx={{ marginBottom: '10px' }}>
          <Typography variant="bodySingle" color={theme.palette.grey[400]}>
            Found {hotelResults?.length} hotels for &ldquo;{hotelName}&rdquo;.
          </Typography>
        </Box>
        <Grid container columns={6} spacing={2}>
          {hotelCards}
        </Grid>
      </Box>
    );
  };

  return (
    <>
      <Container maxWidth="xl" sx={{ padding: 3 }}>
        <OutlinedInput
          data-testid="hotel-name-input"
          fullWidth={true}
          sx={{ borderRadius: '4px', border: theme.palette.success.main }}
          placeholder="Search for a region or hotel..."
          endAdornment={
            <IconButton onClick={onHotelSearch}>
              <SearchIcon data-testid="hotel-search-icon" />
            </IconButton>
          }
          onChange={(e) => setHotelName(e.target.value)}
          onKeyDown={(e) => {
            if (e.code == 'Enter') {
              onHotelSearch();
            }
          }}
          value={hotelName}
          autoFocus
        ></OutlinedInput>
        {children}
        {renderHotelResults()}
      </Container>
    </>
  );
};
