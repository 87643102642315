import { DepartureSecuredStatus } from '@flashpack/graphql';

export const securedStatusLabel = (securedStatus: DepartureSecuredStatus) => {
  switch (securedStatus) {
    case DepartureSecuredStatus.Pending:
      return 'Secured?';
    case DepartureSecuredStatus.Secured:
      return 'Secured';
    case DepartureSecuredStatus.NotSecured:
      return 'Not secured';
    default:
      return securedStatus;
  }
};
