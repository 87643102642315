import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import {
  commentAnchor,
  currentCommentIdFromHash,
  hasCurrentCommentHash,
} from '@src/shared/timeline/util';
import { SingleMessage } from './SingleMessage';
import { Message } from '@flashpack/graphql';

interface PropTypes {
  messages: Message[] | null;
}

const defaultCommentBoxHeight = 430;

export const Messages: FC<PropTypes> = ({ messages = [] }) => {
  const { hash } = useLocation();

  const noMessagesPresent = !messages || messages.length === 0;

  const commentBox = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);

  const scrollToComment = useCallback(() => {
    if (commentBox.current) {
      const commentIdToScrollTo = hasCurrentCommentHash(hash)
        ? currentCommentIdFromHash(hash)
        : messages?.slice(-1)[0]?.id;
      commentIdToScrollTo &&
        document
          .getElementById(commentAnchor({ id: commentIdToScrollTo }))
          ?.scrollIntoView({ block: 'end', inline: 'nearest' });
    }
  }, [messages, hash]);

  // Once the messages are loaded in, we can figure out if the box is large
  // enough to be scrollable
  useEffect(() => {
    setIsScrollable(() => {
      return (
        !!commentBox.current && commentBox.current.scrollHeight > defaultCommentBoxHeight
      );
    });
    // We should still scroll to bottom whenever messages change (e.g. a new comment is added)
    scrollToComment();
  }, [messages, scrollToComment]);

  // If is scrollable is set, then we scroll to the bottom. This needs to happen
  // after isScrollable is set to true, because isScrollable will affect
  // the height of the element due to extra padding
  useEffect(() => {
    if (isScrollable) {
      scrollToComment();
    }
  }, [isScrollable, scrollToComment]);

  const currentCommentId = currentCommentIdFromHash(hash);
  return (
    <MessagesBox position="relative">
      {isScrollable && (
        <Box
          sx={{
            height: '47px',
            width: '100%',
            background:
              'linear-gradient(180deg, #FFFFFF 20.21%, rgba(246, 246, 246, 0) 100%)',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        ></Box>
      )}
      <MessagessStack
        // We add a larger padding when the box is scrollable so that
        // the gradient doesn't hide the top comment.
        sx={{
          paddingTop: isScrollable ? 4 : 2,
          paddingBottom: 2,
          '& > :first-of-type': {
            marginTop: 'auto',
          },
        }}
        gap={2}
        ref={commentBox}
      >
        {noMessagesPresent && (
          <Typography
            align="center"
            sx={{
              my: 0,
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            variant="bodySingle"
          >
            No messages to show
          </Typography>
        )}
        {messages?.map((message, index) => {
          return (
            <SingleMessage
              index={index}
              key={message.id}
              hilight={message.id == currentCommentId}
              {...message}
            />
          );
        })}
      </MessagessStack>
    </MessagesBox>
  );
};

const MessagesBox = styled(Box)(({ theme }) => ({
  background: theme.palette.principal.grey30,
}));

const MessagessStack = styled(Stack)(({ theme }) => ({
  height: `${defaultCommentBoxHeight}px`,
  overflowY: 'auto',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));
