import { Checkbox as MuiCheckbox, useTheme } from '@mui/material';
import type { CheckboxProps as MuiCheckboxProps } from '@mui/material';
import { FC, useMemo } from 'react';
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from 'design-system';

export type CheckboxProps = MuiCheckboxProps & {
  error?: boolean;
};

export const Checkbox: FC<CheckboxProps> = (props) => {
  const { error, ...rest } = props;

  const theme = useTheme();

  const color = useMemo(() => {
    if (props.disabled) {
      return theme.palette.principal.grey30;
    } else if (props.error) {
      return theme.palette.system.red100;
    }
    return theme.palette.principal.grey50;
  }, [theme, props.disabled, props.error]);

  return (
    <MuiCheckbox
      icon={<CheckboxUncheckedIcon sx={{ color }} />}
      checkedIcon={<CheckboxCheckedIcon />}
      {...rest}
    />
  );
};
