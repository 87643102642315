import { Stack, Typography, useTheme } from '@mui/material';
import { PeopleGroupIcon } from 'design-system';

interface PropTypes {
  large?: boolean;
  label?: string;
  colorVariant?: 'greyed' | 'colored';
}

export function DeparturePrivateGroup({
  label,
  colorVariant = 'greyed',
  large = false,
}: PropTypes) {
  const theme = useTheme();

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap={1}
      data-testid="departure-private-group"
      color={
        colorVariant === 'greyed'
          ? theme.palette.principal.grey70
          : theme.palette.brand.jungle
      }
    >
      <PeopleGroupIcon
        sx={{
          width: large ? 24 : 18,
          height: large ? 24 : 14,
        }}
      />
      {label && <Typography variant="bodySingle">{label}</Typography>}
    </Stack>
  );
}
