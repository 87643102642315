import { lazy, Suspense } from 'react';

const HotelsOverview = lazy(() => import('src/hotels/hotels-overview/HotelsOverview'));
const FinancePage = lazy(() => import('src/finance/FinancePage'));
const InnovationPage = lazy(() => import('src/innovation/InnovationPage'));

import { ItineraryDetailsPage } from 'src/itinerary/itinerary-details/ItineraryDetailsPage';
import {
  Route,
  Navigate,
  Outlet,
  useLocation,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import { LoginPage } from '@src/authentication/LoginPage';
import { LoginCallback } from '@src/authentication/LoginCallback';
import { RoutePath } from '../shared/routePath';
import { UserRole } from '@flashpack/graphql';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';
import { AddAdventurePage } from '@src/adventure/adventure-overview/AddAdventurePage';
import { AdventureListPage } from '@src/adventure/adventure-list/AdventureListPage';
import { ItineraryDeparturesPage } from '@src/departures/ItineraryDeparturesPage';
import { ItineraryPage } from '@src/itinerary/itinerary-page/ItineraryPage';

import { AppLayout } from '@src/app/AppLayout';
import {
  ITINERARY_TAB_HANDLE,
  ItineraryTab,
} from '@src/itinerary/itinerary-page/ItineraryHeader';
import { ItineraryDeparture } from '@src/departures/ItineraryDeparture';
import { PackLeaders } from '@src/packLeaders/PackLeaders';
import { PricesPage } from '@src/itinerary/prices-page/PricesPage';
import {
  AdventurePage,
  AdventureTab,
  ADVENTURE_TAB_HANDLE,
} from '@src/adventure/adventure-overview/AdventurePage';
import { AdventureSettingsTab } from '@src/adventure/adventure-overview/AdventureSettingsTab';
import { AdventureItinerariesTab } from '@src/adventure/adventure-overview/AdventureItinerariesTab';
import { AdventureDeparturesTab } from '@src/adventure/adventure-overview/AdventureDeparturesTab';
import { CostsPage } from '@src/itinerary/costs-page/CostsPage';
import { BulkTimelineItemsPage } from '@src/shared/timeline/bulk-timeline-items/BulkTimelineItemsPage';
import { ItineraryDepartureSignOff } from '@src/departures/ItineraryDepartureSignOff';
import { AdventureActionsTab } from '@src/adventure/adventure-overview/AdventureActionsTab';

const ProtectedRoute = ({
  children,
  roles,
}: {
  children?: JSX.Element;
  roles: UserRole[];
}) => {
  const { currentUser, loadingAuthorization } = useAuthorization();
  const location = useLocation();

  if (loadingAuthorization) {
    return null;
  }

  const allRoles = [...roles];

  if (allRoles.includes(UserRole.Flashpack)) {
    allRoles.push(UserRole.Superuser);
  }

  if (!currentUser) {
    return (
      <Navigate
        to={RoutePath.LOGIN.value}
        state={{ redirectBack: location.pathname }}
        replace
      />
    );
  }

  if (!allRoles.includes(currentUser.role)) {
    return <Navigate to={'/'} state={{ redirectBack: location.pathname }} replace />;
  }

  return children ? children : <Outlet />;
};

export const AppRouter = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<AppLayout />}>
        <Route element={<ProtectedRoute roles={[UserRole.Flashpack]} />}>
          <Route path={RoutePath.ADD_ADVENTURE.value} element={<AddAdventurePage />} />
          <Route
            path={RoutePath.FINANCE.value}
            element={
              <Suspense fallback={null}>
                <FinancePage />
              </Suspense>
            }
          ></Route>
          <Route
            path={RoutePath.INNOVATION.value}
            element={
              <Suspense fallback={null}>
                <InnovationPage />
              </Suspense>
            }
          ></Route>
        </Route>

        <Route element={<ProtectedRoute roles={[UserRole.Flashpack, UserRole.Dmc]} />}>
          <Route
            path={RoutePath.HOTELS.value}
            element={
              <Suspense fallback={null}>
                <HotelsOverview />
              </Suspense>
            }
          />
        </Route>

        <Route
          element={
            <ProtectedRoute
              roles={[UserRole.Flashpack, UserRole.Dmc, UserRole.Customer]}
            />
          }
        >
          <Route path="/" element={<Navigate to={RoutePath.ADVENTURES.value} />} />
          <Route path={RoutePath.ADVENTURES.value} element={<AdventureListPage />} />
          <Route element={<AdventurePage />}>
            <Route
              path={RoutePath.ADVENTURE_ACTIONS.value}
              element={<AdventureActionsTab />}
              handle={{ [ADVENTURE_TAB_HANDLE]: AdventureTab.ACTIONS }}
            />
            <Route
              path={RoutePath.ADVENTURE_ITINERARIES.value}
              element={<AdventureItinerariesTab />}
              handle={{ [ADVENTURE_TAB_HANDLE]: AdventureTab.ITINERARIES }}
            />
            <Route
              path={RoutePath.ADVENTURE_DEPARTURES.value}
              element={<AdventureDeparturesTab />}
              handle={{ [ADVENTURE_TAB_HANDLE]: AdventureTab.DEPARTURES }}
            />
            <Route
              path={RoutePath.ADVENTURE_SETTINGS.value}
              element={<AdventureSettingsTab />}
              handle={{ [ADVENTURE_TAB_HANDLE]: AdventureTab.SETTINGS }}
            />
          </Route>
          <Route element={<ItineraryPage />}>
            <Route
              path={RoutePath.ITINERARY_DEPARTURES.value}
              element={<ItineraryDeparturesPage />}
              handle={{ [ITINERARY_TAB_HANDLE]: ItineraryTab.DEPARTURES }}
            />
            <Route
              path={`${RoutePath.ITINERARY_TIMELINE.value}/*`}
              element={<ItineraryDetailsPage />}
              handle={{ [ITINERARY_TAB_HANDLE]: ItineraryTab.ITINERARY }}
            />
            <Route
              path={RoutePath.ITINERARY_PACK_LEADERS.value}
              element={<PackLeaders />}
              handle={{ [ITINERARY_TAB_HANDLE]: ItineraryTab.PACK_LEADERS }}
            />
            <Route
              path={RoutePath.ITINERARY_COSTS.value}
              element={<CostsPage />}
              handle={{ [ITINERARY_TAB_HANDLE]: ItineraryTab.COSTS }}
            />
            <Route element={<ProtectedRoute roles={[UserRole.Flashpack]} />}>
              <Route
                path={RoutePath.ITINERARY_PRICES.value}
                element={<PricesPage />}
                handle={{ [ITINERARY_TAB_HANDLE]: ItineraryTab.PRICES }}
              />
            </Route>
          </Route>
          <Route
            path={`${RoutePath.ITINERARY_DEPARTURE.value}/*`}
            element={<ItineraryDeparture />}
            handle={{ [ITINERARY_TAB_HANDLE]: ItineraryTab.DEPARTURES }}
          />
          <Route
            path={`${RoutePath.ITINERARY_DEPARTURE_SIGN_OFF.value}/*`}
            element={<ItineraryDepartureSignOff />}
          />
          <Route
            path={RoutePath.ADVENTURE_BULK_TIMELINE_ITEMS.value}
            element={<BulkTimelineItemsPage />}
          />
          <Route
            path={RoutePath.ITINERARY_BULK_TIMELINE_ITEMS.value}
            element={<BulkTimelineItemsPage />}
          />
        </Route>

        <Route path={RoutePath.LOGIN_CALLBACK.value} element={<LoginCallback />} />
        <Route path={RoutePath.LOGIN.value} element={<LoginPage />} />
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
};
