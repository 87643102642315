import { FC, useEffect, useMemo, useState } from 'react';
import { TransferIcon, GenericError } from 'design-system';
import { ItineraryDrawer } from 'src/itinerary/common/ItineraryDrawer';
import { EngagementFieldsFragment } from '@flashpack/graphql';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab } from '@mui/material';
import { CommentFormState } from '@src/shared/context/SharedTabsContext';
import { SharedTabsContextProvider } from '@src/shared/context/SharedTabsContextProvider';
import { EngagementCommentsTab } from '@src/itinerary/common/EngagementCommentsTab';
import { TransferInfoTab } from './TransferInfoTab';
import { EditTransferFormValues } from '../common/TransferForm';
import { TransferMapper } from '../common/transferMapper';
import { useCommentsTabOrDefault } from '@src/shared/timeline/util';

interface PropTypes {
  getTransfer: (id: string) => EngagementFieldsFragment;
  isTimelineLocked?: boolean;
  isEditable: boolean;
}

export const ViewEditTransferPage: FC<PropTypes> = ({
  getTransfer,
  isTimelineLocked,
  isEditable,
}) => {
  const { transferId } = useRequiredParams(['transferId']);
  const engagement = useMemo(() => getTransfer(transferId), [getTransfer, transferId]);

  const initialTransferFormState = TransferMapper.toEditTransferFormValues(engagement);

  const [transferFormState, setTransferFormState] = useState<EditTransferFormValues>(
    initialTransferFormState,
  );
  const [hasPendingChanges, setHasPendingChanges] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useCommentsTabOrDefault('information');
  const handleTabClick = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const [commentFormState, setCommentFormState] = useState<CommentFormState>({
    content: '',
  });

  useEffect(() => {
    setTransferFormState(TransferMapper.toEditTransferFormValues(engagement));
    setHasPendingChanges(false);
  }, [transferId, engagement]);

  if (!engagement) {
    return (
      <ItineraryDrawer title="Transfer" icon={<TransferIcon />}>
        <GenericError error={'This engagement does not seem to exist'} />
      </ItineraryDrawer>
    );
  }

  if (!engagement.transfer) {
    return (
      <ItineraryDrawer title="Transfer" icon={<TransferIcon />}>
        <GenericError error="Expected transfer but the provided engagement is not a Transfer" />
      </ItineraryDrawer>
    );
  }

  const shouldWarnForUnsavedChanges =
    hasPendingChanges || commentFormState.content !== '';

  return (
    <ItineraryDrawer
      title="Transfer"
      warnForChangesNotSaved={shouldWarnForUnsavedChanges}
      icon={<TransferIcon />}
    >
      <SharedTabsContextProvider
        value={{
          commentForm: {
            state: commentFormState,
            setState: setCommentFormState,
          },
          transferForm: {
            state: transferFormState,
            setState: setTransferFormState,
            hasPendingChanges: hasPendingChanges,
            setHasPendingChanges: setHasPendingChanges,
          },
        }}
      >
        <TabContext value={selectedTab}>
          <TabList onChange={handleTabClick}>
            <Tab label="Information" value="information" data-testid="information-tab" />
            <Tab label="Comments" value="comments" data-testid="comments-tab" />
          </TabList>
          <TabPanel value="information" sx={{ padding: 0 }}>
            <TransferInfoTab
              isTimelineLocked={isTimelineLocked}
              isEditable={isEditable}
            />
          </TabPanel>
          <TabPanel value="comments" sx={{ padding: 0 }}>
            <EngagementCommentsTab engagement={engagement} />
          </TabPanel>
        </TabContext>
      </SharedTabsContextProvider>
    </ItineraryDrawer>
  );
};
