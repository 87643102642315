import { Chip, SxProps, Typography, styled } from '@mui/material';
import { Theme } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { ExclamationIcon, SuccessIcon } from 'design-system';
import { HotelApprovalStatus } from '@flashpack/graphql';
import { FC } from 'react';

interface PropTypes {
  status: HotelApprovalStatus;
  sx?: SxProps<Theme> | undefined;
}

export const HotelApprovalStatusIndicator: FC<PropTypes> = ({ status, sx }) => {
  const theme = useTheme();

  switch (status) {
    case HotelApprovalStatus.Rejected:
      return (
        <HotelIndicatorChip
          clickable={false}
          icon={<ExclamationIcon style={{ color: theme.palette.principal.white }} />}
          size={'small'}
          sx={{
            ...sx,
            backgroundColor: 'system.red100',
          }}
          label={<Typography variant="captionSingle">Rejected</Typography>}
        />
      );
    case HotelApprovalStatus.Approved:
      return (
        <HotelIndicatorChip
          clickable={false}
          size={'small'}
          icon={<SuccessIcon style={{ color: theme.palette.principal.white }} />}
          sx={{
            ...sx,
            backgroundColor: 'system.green100',
          }}
          label={<Typography variant="captionSingle">Approved</Typography>}
        />
      );
    default:
      return null;
  }
};

const HotelIndicatorChip = styled(Chip)(({ theme }) => ({
  color: 'white',
  padding: theme.spacing(0.5),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  borderRadius: '4px',
  position: 'absolute',
}));
