import { useNavigate } from 'react-router-dom';
import { MenuItem, Select, styled } from '@mui/material';

export type SelectViewDropdownProps = {
  hasPendingChanges: boolean;
  adventureId: string;
  itineraryId: string;
  view: string;
  viewOptions: Array<{ value: string; label: string }>;
  routePath: {
    value: string;
    generatePath: (...args: string[]) => string;
  };
};

export const SelectViewDropdown = ({
  adventureId,
  itineraryId,
  view,
  viewOptions,
  routePath,
}: SelectViewDropdownProps) => {
  const navigate = useNavigate();

  return (
    <>
      <PriceTypeSelect
        id="select-table-mode"
        name="table.select.mode"
        value={view}
        fullWidth
        onChange={(event) => {
          navigate(
            routePath.generatePath(
              adventureId,
              itineraryId,
              event.target.value as string,
            ),
          );
        }}
        data-testid="table-select-view"
      >
        {viewOptions.map((view) => (
          <MenuItem value={view.value} key={view.value}>
            {view.label}
          </MenuItem>
        ))}
      </PriceTypeSelect>
    </>
  );
};

const PriceTypeSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.principal.white,
  color: theme.palette.brand.jungle,
  width: '175px',
  textOverflow: 'ellipsis',
  textAlign: 'left',
}));
