import { Close } from '@mui/icons-material';
import { Button, Drawer, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { FC, useState } from 'react';
import { ActivityFeedList } from './ActivityFeedList';
import { LoadingButton } from '@mui/lab';
import { useMutation } from '@apollo/client';
import {
  CurrentAdventureManagerUserDocument,
  MarkAllAsReadDocument,
  UserActivitiesDocument,
  UserActivityCategory,
  UserRole,
} from '@flashpack/graphql';
import { Protected } from '@src/authentication/Protected';
import { ActivityFeedFilters, FilterStates } from './ActivityFeedFilters';
import { flashPackTheme } from '@src/design-system/theme';

interface PropTypes {
  open: boolean;
  onClose: () => void;
}

export const ActivityFeedDrawer: FC<PropTypes> = ({ open, onClose }) => {
  const theme = useTheme();
  const [selectedAdventure, setSelectedAdventure] = useState<string>('');
  const [selectedUser, setSelectedUser] = useState<string>('');
  const [selectedType, setSelectedType] = useState<UserActivityCategory | ''>('');
  const [markAllAsRead, { loading }] = useMutation(MarkAllAsReadDocument);

  const onCloseDrawer = () => {
    clearFilters();
    onClose();
  };

  const filterStates: FilterStates = {
    adventure: {
      selectedAdventure,
      setSelectedAdventure,
    },
    user: {
      selectedUser,
      setSelectedUser,
    },
    type: {
      selectedType,
      setSelectedType,
    },
  };

  const clearFilters = () => {
    setSelectedAdventure('');
    setSelectedUser('');
    setSelectedType('');
  };

  const filtersActive = selectedAdventure || selectedUser || selectedType;

  const onMarkAsRead = async () => {
    await markAllAsRead({
      refetchQueries: [UserActivitiesDocument, CurrentAdventureManagerUserDocument],
    });
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onCloseDrawer}
      PaperProps={{
        sx: {
          width: 800,
          padding: theme.spacing(2),
        },
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" my={2}>
        <Typography variant="subHeader">Activity Feed</Typography>
        <IconButton
          onClick={() => {
            onCloseDrawer();
          }}
          data-testid="close-drawer"
        >
          <Close />
        </IconButton>
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        paddingBottom={flashPackTheme.spacing(1)}
      >
        <Protected roles={[UserRole.Flashpack]}>
          <ActivityFeedFilters {...filterStates} />
        </Protected>
        {filtersActive ? (
          <Button size="small" sx={{ marginLeft: 'auto' }} onClick={clearFilters}>
            Clear all filters
          </Button>
        ) : (
          <LoadingButton
            size="small"
            loading={loading}
            sx={{ marginLeft: 'auto' }}
            onClick={() => void onMarkAsRead()}
          >
            Mark all as read
          </LoadingButton>
        )}
      </Stack>
      <ActivityFeedList open={open} onClose={onCloseDrawer} filterStates={filterStates} />
    </Drawer>
  );
};
