import { ButtonProps, styled } from '@mui/material';
import { Button } from './Button';

import AddIcon from '@mui/icons-material/Add';
import { FC } from 'react';

export const TextAddButton: FC<ButtonProps> = ({ sx, ...props }) => {
  return <TextButton startIcon={<AddIcon fontSize="small" />} sx={sx} {...props} />;
};

export const TextButton: FC<ButtonProps> = ({ sx, ...props }) => {
  return (
    <StyledButton
      sx={{
        fontWeight: 500,
        fontSize: 16,
        ...sx,
      }}
      size="large"
      {...props}
    >
      {props.children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  '&:hover': {
    background: theme.palette.system.green10,
  },
}));
