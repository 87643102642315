import React, { FC, useMemo } from 'react';
import { DepartureStatus } from '@flashpack/graphql';
import { Stack, Typography, Box } from '@mui/material';
import { OpenMenu } from './icons/DepartureStatusIcon';
import { useTheme } from '@mui/material/styles';
import { statusLabel } from './utils';

interface PropTypes {
  onClick: (e: React.MouseEvent) => void;
  status: DepartureStatus;
  openIcon?: boolean;
}

export const DepartureStatusItem: FC<PropTypes> = (props) => {
  const { status, onClick, openIcon } = props;
  const theme = useTheme();

  const backgroundColor = useMemo(() => {
    switch (status) {
      case DepartureStatus.Cancelled:
        return theme.palette.system.red100;
      default:
        return theme.palette.principal.grey30;
    }
  }, [status, theme]);

  const textColor = useMemo(() => {
    switch (status) {
      case DepartureStatus.Cancelled:
        return theme.palette.principal.white;
      case DepartureStatus.OnSale:
      case DepartureStatus.Guaranteed:
      case DepartureStatus.OnRequest:
      case DepartureStatus.SoldOut:
        return theme.palette.system.green100;
      default:
        return theme.palette.principal.grey70;
    }
  }, [status, theme]);

  return (
    <Box
      onClick={onClick}
      sx={{
        px: 1,
        py: 0.5,
        backgroundColor,
        color: textColor,
        borderRadius: 1,
        maxWidth: 'fit-content',
        cursor: openIcon ? 'pointer' : 'default',
        border: openIcon ? `1px solid ${theme.palette.principal.grey50}` : null,
        '&:hover': {
          backgroundColor: openIcon ? theme.palette.principal.grey50 : null,
        },
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="115px"
      >
        <Typography variant="bodySingle">{statusLabel(status)}</Typography>
        {openIcon && <OpenMenu fill={theme.palette.principal.grey70} />}
      </Stack>
    </Box>
  );
};
