import { MuiPickersAdapterContext, TimePicker } from '@mui/x-date-pickers';
import { Field, FieldInputProps } from 'react-final-form';
import { TextField, showErrorOnBlur } from 'mui-rff';
import { TextField as MuiTextField } from '@mui/material';
import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { NaiveDayTime } from '@flashpack/graphql';
import { isValid } from 'date-fns';
import { useTheme } from '@mui/material/styles';

interface PropTypes {
  name: string;
  label?: string;
  validate?: (value: string, formValues: object) => string | undefined;
  readonly?: boolean;
}

export const TimeField: FC<PropTypes> = ({ name, label, validate, readonly }) => {
  const theme = useTheme();
  const localizationContext = useContext(MuiPickersAdapterContext);
  const timeFieldInputRef = useRef<HTMLInputElement>(null); // Ref to access the input element inside MuiTextField
  const [shouldFocusOnTimeField, setShouldFocusOnTimeField] = useState(false);

  const toDate = useCallback(
    (value: string): Date | string | null => {
      if (!localizationContext) {
        return '';
      }
      const date = localizationContext.utils.parse(value, 'HH:mm') as Date;
      return isValid(date) ? date : value ?? '';
    },
    [localizationContext],
  );

  const toString = useCallback(
    (value: Date | string | null): string => {
      if (value === 'Multiple') {
        return value;
      }
      if (!localizationContext) {
        return '';
      }
      if (!isValid(value)) {
        return (value ?? '').toString();
      }
      return localizationContext.utils.format(value, 'fullTime24h');
    },
    [localizationContext],
  );

  const handleValidate = (value: string, formValues: object | undefined) => {
    if (value === 'Multiple') {
      return;
    }
    if (!isValid(localizationContext?.utils.parse(value, 'HH:mm'))) {
      return 'Please provide a valid time';
    }
    if (validate) {
      return validate(value, formValues as object);
    }
  };

  const handleFocusOnMultipleField = (
    input: FieldInputProps<string | Date | null, HTMLElement>,
  ) => {
    input.onChange('');
    setShouldFocusOnTimeField(true);
  };

  useEffect(() => {
    if (shouldFocusOnTimeField && timeFieldInputRef.current) {
      timeFieldInputRef.current.focus();
      setShouldFocusOnTimeField(false);
    }
  }, [shouldFocusOnTimeField]);

  return (
    <Field<NaiveDayTime['time'], HTMLElement, Date | string | null>
      name={name}
      format={toDate}
      parse={toString}
      validate={handleValidate}
      render={({ input, meta }) => {
        return (
          <>
            {input.value === 'Multiple' ? (
              <TextField
                name={name}
                value={input.value}
                fullWidth
                disabled={readonly}
                label="Time"
                sx={{ height: '56px' }}
                onFocus={() => handleFocusOnMultipleField(input)}
              />
            ) : (
              <TimePicker
                value={input.value}
                onChange={input.onChange}
                // for disabling functionality
                disabled={readonly}
                renderInput={(props) => (
                  <MuiTextField
                    {...props}
                    onBlur={input.onBlur}
                    onFocus={input.onFocus}
                    fullWidth
                    error={showErrorOnBlur({ meta })}
                    helperText={(meta.touched && (meta.error as string)) || ' '}
                    // for "disabled" styles
                    disabled={readonly}
                    inputRef={timeFieldInputRef}
                  />
                )}
                ampm={false}
                inputFormat="HH:mm"
                OpenPickerButtonProps={{ sx: { stroke: theme.palette.primary.main } }}
                PopperProps={{
                  anchorEl: timeFieldInputRef.current,
                  disablePortal: true,
                  modifiers: [
                    {
                      name: 'flip',
                      enabled: false,
                    },
                  ],
                }}
                InputProps={{
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  'data-testid': 'time-picker',
                }}
                label={label}
              />
            )}
          </>
        );
      }}
    />
  );
};

TimeField.defaultProps = {
  label: 'Enter a time (24h)',
};
