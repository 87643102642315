import { replaceImageSizePlaceholder } from '@src/design-system/images/imageUtils';
import fallbackImage from '../../../src/static-assets/fallback-hotel.png';
import {
  AccommodationFieldsForComparisonFragment,
  AccommodationFieldsFragment,
  AccommodationType,
  HotelApprovalStatus,
  HotelOverviewFragment,
  ManualAccommodation,
} from '@flashpack/graphql';
import {
  HotelInfoForViewing,
  ManualPropertyFields,
  RateHawkPropertyFields,
  ViewAccommodation,
  ViewAccommodationMulti,
  ViewRateHawkAccommodation,
} from './types';
import { DraftPropertyMulti } from '@src/itinerary/view-accommodation/tabs/SelectPropertyTab';
import { rgExtToString } from '@src/hotels/hotel-details/room-explorer/roomUtils';
import { AccommodationFormState } from '../context/SharedTabsContext';

export const isRateHawkAccommodation = (
  accommodation: ViewAccommodationMulti,
): accommodation is ViewRateHawkAccommodation =>
  accommodation.__typename === 'RateHawkAccommodation';

type AccommodationNameArgument = {
  hotel?: {
    name?: string;
  } | null;
  description?: string;
};

export const getAccommodationName = (
  accommodation: AccommodationNameArgument,
): string => {
  const hotelName = accommodation?.description || accommodation?.hotel?.name;
  return hotelName ?? '';
};

export const getFallbackAccommodationImage = (): string => {
  return fallbackImage;
};

export const isValidHotel = (accommodation: ViewAccommodationMulti) => {
  if (isRateHawkAccommodation(accommodation) && !accommodation.hotel?.name) {
    return false;
  }

  return true;
};

export const getHotelId = (accommodation: AccommodationFieldsForComparisonFragment) => {
  if (isRateHawkAccommodation(accommodation)) {
    return accommodation.hotel?.id ?? null;
  }
  return null;
};

export const getDescription = (
  accommodation: AccommodationFieldsForComparisonFragment,
) => {
  if (accommodation.__typename === 'RateHawkAccommodation') {
    return null;
  }
  return accommodation.description;
};

// TODO: at some point we should clean this up, twinRoomId is not really id, but a stringified object
// also, to be consistent with manual accommodations, we could use room name for ratehawk
export const getRoomDescriptorTwin = (
  accommodation: AccommodationFieldsForComparisonFragment,
) => {
  if (accommodation.__typename === 'RateHawkAccommodation') {
    return rgExtToString(accommodation.twinRoom?.rg_ext);
  }
  return accommodation.twinRoom?.name ?? '';
};
export const getRoomDescriptorSingle = (
  accommodation: AccommodationFieldsForComparisonFragment,
) => {
  if (accommodation.__typename === 'RateHawkAccommodation') {
    return rgExtToString(accommodation.singleRoom?.rg_ext);
  }
  return accommodation.singleRoom?.name ?? '';
};

export const extractInfoFromManualProperty = (
  accommodation: ViewAccommodationMulti,
): HotelInfoForViewing => {
  const accommodationName = getAccommodationName(accommodation);
  const thumbnailImageUrl = getFallbackAccommodationImage();
  return {
    thumbnailImageUrl,
    accommodationName,
    isHotelClosed: false,
  };
};

export const extractInfoFromFormValues = (
  formValues: AccommodationFormState,
): HotelInfoForViewing => {
  const thumbnailImageUrl = getFallbackAccommodationImage();
  return {
    thumbnailImageUrl,
    accommodationName: formValues.description ?? '',
    isHotelClosed: false,
  };
};

export const extractInfoFromRateHawkProperty = (
  hotel: Omit<HotelOverviewFragment, '__typename'>,
): HotelInfoForViewing => {
  const storedImage: string | undefined = hotel.images
    ? replaceImageSizePlaceholder(hotel.images[0], '1024x768')
    : '';
  const isHotelClosed = Boolean(hotel ? hotel.is_closed : false);
  const hotelImageUrl: string =
    storedImage === '' || !storedImage ? getFallbackAccommodationImage() : storedImage;
  const kind = hotel.kind ?? undefined;
  const starRating = hotel.star_rating ?? undefined;
  const locationName = `${hotel.location.region}, ${hotel.location.country.name}`;

  return {
    thumbnailImageUrl: hotelImageUrl,
    accommodationName: hotel.name,
    kind,
    locationName,
    starRating,
    isHotelClosed,
    hotelId: hotel.id,
    status: hotel.status ?? HotelApprovalStatus.NotApplicable,
  };
};

export const isManualAccommodation = (
  accommodation: AccommodationFieldsFragment,
): accommodation is ManualAccommodation =>
  accommodation?.__typename === 'ManualAccommodation';

export const extractFieldsFromDraftProperty = (
  draftProperty: DraftPropertyMulti,
): RateHawkPropertyFields | ManualPropertyFields => {
  if (
    draftProperty.type === AccommodationType.RateHawk &&
    draftProperty.ratehawkProperty
  ) {
    return {
      __typename: 'RateHawkAccommodation',
      hotel: {
        id: draftProperty.ratehawkProperty.id,
        name: '',
      },
    };
  }

  if (draftProperty.type === AccommodationType.Manual && draftProperty.manualProperty) {
    return {
      __typename: 'ManualAccommodation',
      description: draftProperty.manualProperty.name,
    };
  }

  throw Error("Draft property doesn't have a valid type");
};

export const mergeAccommodationWithDraftProperty = (
  accommodation: ViewAccommodationMulti,
  draftProperty: DraftPropertyMulti,
): ViewAccommodationMulti => {
  const baseAccommodation: ViewAccommodation = {
    id: accommodation.id,
    notes: accommodation.notes,
    flagged: accommodation.flagged,
    checkIn: {
      ...accommodation.checkIn,
      time: '',
    },
    checkOut: {
      ...accommodation.checkOut,
      time: '',
    },
    links: accommodation.links,
    commentThread: accommodation.commentThread,
  };
  const mergeWith = extractFieldsFromDraftProperty(draftProperty);

  return { ...baseAccommodation, ...mergeWith };
};
