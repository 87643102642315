import { Box } from '@mui/material';
import { PadlockIcon } from 'design-system';
import { FC } from 'react';
import { GreenTooltip } from '../toolTip/GreenToolTip';

interface ToolTipWrapperPropTypes {
  hasToolTip: boolean;
  children: JSX.Element;
  toolTipText?: string;
}

const ToolTipWrapper: FC<ToolTipWrapperPropTypes> = ({
  hasToolTip,
  children,
  toolTipText,
}) => {
  if (!hasToolTip) {
    return <Box>{children}</Box>;
  }
  return (
    <GreenTooltip title={toolTipText} placement="right">
      {children}
    </GreenTooltip>
  );
};

export interface TableRowLockedIconPropTypes {
  hasToolTip: boolean;
  toolTipText?: string;
}

export const TableRowLockedIcon: FC<TableRowLockedIconPropTypes> = ({
  hasToolTip,
  toolTipText,
}) => {
  return (
    <ToolTipWrapper hasToolTip={hasToolTip} toolTipText={toolTipText}>
      <Box sx={{ padding: '6px' }}>
        <PadlockIcon
          sx={{
            height: '14px',
          }}
        />
      </Box>
    </ToolTipWrapper>
  );
};
