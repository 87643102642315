import { LoadingButton } from '@mui/lab';
import { Stack, FormHelperText, Button, Typography, useTheme } from '@mui/material';
import { Validator } from '@src/design-system/forms/validators';
import { showErrorOnBlur, TextField } from 'mui-rff';
import { Form, FormSpy } from 'react-final-form';
import { CreateMessageDocument, MessageThreadDocument } from '@flashpack/graphql';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { FormState, FORM_ERROR } from 'final-form';
import {
  SharedTabsContext,
  CommentFormState,
} from '@src/shared/context/SharedTabsContext';
import { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { ConfirmationDialog } from '@src/design-system/dialogs/ConfirmationDialog';
import { confirmationDialogLabels } from '../ItineraryDrawer';

type PropTypes = {
  messageThreadId: string;
  onCancel: () => void;
};

export const AddMessage = ({ messageThreadId, onCancel }: PropTypes) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isContentSet, setContent] = useState<boolean>(false);
  const [addMessageToThread] = useMutation(CreateMessageDocument, {
    refetchQueries: [MessageThreadDocument],
  });

  const { commentForm } = useContext(SharedTabsContext);
  const shouldSubscribeToFormChanges = !!commentForm;

  const { safeMutation } = useSafeMutation();
  const onSubmit = async (
    { content }: { content: string },
    { restart }: { restart: () => void },
  ) => {
    const { error } = await safeMutation(
      addMessageToThread({ variables: { input: { content, messageThreadId } } }),
      { onSuccess: restart },
    );
    if (error) {
      return { [FORM_ERROR]: 'Failed to save message' };
    }
    if (shouldSubscribeToFormChanges) {
      commentForm.setState({ content: '' });
    }
  };

  const theme = useTheme();
  const messageValue = commentForm?.state.content ?? '';

  const onFormStateChange = (
    formState: FormState<Record<string, string>, Partial<Record<string, string>>>,
  ) => {
    if (!shouldSubscribeToFormChanges) {
      return;
    }
    // We avoid calls to the contextual setState if the data is unchanged
    if (formState.values.content === messageValue) {
      return;
    }
    const formStateValues: CommentFormState = {
      content: formState.values.content ?? '',
    };
    commentForm.setState(formStateValues);
  };

  return (
    <Form<CommentFormState>
      initialValues={{ content: messageValue }}
      onSubmit={onSubmit}
      render={({ submitError, modifiedSinceLastSubmit, submitting, handleSubmit }) => (
        <form onSubmit={(event) => void handleSubmit(event)}>
          <Stack alignItems="flex-end" justifyContent="center">
            <TextField
              name="content"
              multiline
              helperText=" "
              rows={3}
              placeholder="Write comment here..."
              fieldProps={{ validate: Validator.required }}
              showError={showErrorOnBlur}
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '2px',
                  borderColor: 'principal.grey70',
                },
                '& .MuiFormHelperText-root': {
                  fontSize: 'micro.fontSize',
                  lineHeight: '13px',
                  marginTop: '3px',
                },
              }}
              data-testid="comment-field"
              inputProps={{
                'data-testid': 'comment-field-textarea',
              }}
              onInput={(event: React.ChangeEvent<HTMLInputElement>) => (
                setIsDialogOpen(false), setContent(event.target.value.length > 0)
              )}
            />
            {shouldSubscribeToFormChanges && (
              <FormSpy
                subscription={{ values: true }}
                onChange={(props) => onFormStateChange(props)}
              />
            )}
            {!modifiedSinceLastSubmit && submitError && (
              <FormHelperText sx={{ py: 0, height: '16px' }} error>
                Failed to save comment
              </FormHelperText>
            )}
            <Stack
              direction="row"
              gap={theme.spacing(1)}
              sx={{ width: '100%', justifyContent: 'space-between' }}
            >
              <Button
                onClick={() => (isContentSet ? setIsDialogOpen(true) : onCancel())}
                data-testid="cancel-create-thread-message-button"
              >
                Cancel
              </Button>
              <LoadingButton
                onClick={(e) => void handleSubmit(e)}
                loading={submitting}
                variant="contained"
                data-testid="create-thread-message-button"
              >
                Send
              </LoadingButton>
            </Stack>
            {isDialogOpen ? (
              <ConfirmationDialog
                open={isDialogOpen}
                onConfirm={() => setIsDialogOpen(false)}
                title={confirmationDialogLabels.title}
                cancelLabel={confirmationDialogLabels.cancelLabel}
                confirmLabel={confirmationDialogLabels.confirmLabel}
                maxWidth="sm"
                onCancel={() => {
                  setIsDialogOpen(false);
                  onCancel();
                }}
              >
                <Typography sx={{ mb: 5 }} variant="bodyPara">
                  {confirmationDialogLabels.content}
                </Typography>
              </ConfirmationDialog>
            ) : null}
          </Stack>
        </form>
      )}
    ></Form>
  );
};
