import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { Typography } from '@mui/material';
import { FC } from 'react';
import { SubtractIcon } from '..';

const LoadingButtonWithFont: FC<LoadingButtonProps> = (props) => (
  <LoadingButton {...props}>
    <Typography variant="bodySingle">{props.children}</Typography>
  </LoadingButton>
);

const LoadingButtonWithIcon: FC<LoadingButtonProps> = (props) => (
  <LoadingButton
    variant="outlined"
    sx={{ padding: '11px', justifyContent: 'center', ...props.sx }}
    {...props}
  >
    <SubtractIcon
      sx={{
        width: 18,
        height: 18,
        animation: 'spin 2s ease-in-out infinite',
        '@keyframes spin': {
          from: { transform: 'rotate(0deg)' },
          to: { transform: 'rotate(360deg)' },
        },
      }}
    />
  </LoadingButton>
);

export { LoadingButtonWithFont as LoadingButton, LoadingButtonWithIcon };
