import { PublicHolidayRecord } from './types';

export const us: PublicHolidayRecord[] = [
  {
    name: 'Labor Day',
    date: new Date('2023-09-04'),
  },
  {
    name: 'Thanksgiving Day',
    date: new Date('2023-11-23'),
  },
  {
    name: 'Christmas Day',
    date: new Date('2023-12-25'),
  },
  {
    name: "New Year's Day",
    date: new Date('2024-01-01'),
  },
  {
    name: 'Memorial Day',
    date: new Date('2024-05-27'),
  },
  {
    name: 'Independence Day',
    date: new Date('2024-07-04'),
  },
  {
    name: 'Labor Day',
    date: new Date('2024-09-02'),
  },
  {
    name: 'Thanksgiving Day',
    date: new Date('2024-11-28'),
  },
  {
    name: 'Christmas Day',
    date: new Date('2024-12-25'),
  },
];
