import { FC, useState } from 'react';
import { Alert, FormGroup, Switch } from '@mui/material';
import { useToast } from '../../shared/toast/useToast';
import { FormControlLabel } from '@src/shared/FormControlLabel';

interface PropTypes {
  flagged: boolean;
  onChange: (flagged: boolean) => Promise<unknown>;
}

export const FlagSwitch: FC<PropTypes> = (props) => {
  const { flagged, onChange } = props;
  const { success, error } = useToast();
  const [loading, setLoading] = useState(false);

  const toggleFlagged = async (flagged: boolean) => {
    try {
      setLoading(true);
      await onChange(flagged);
      success(flagged ? 'Flag saved' : 'Flag removed');
    } catch (e) {
      error('Flag not saved');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Alert icon={false} color="error" sx={{ mb: 3 }}>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              name="flagged"
              checked={flagged}
              onChange={(e) => void toggleFlagged(e.target.checked)}
              disabled={loading}
              inputProps={{ 'aria-label': 'controlled' }}
              color="error"
              data-testid="flag-switch"
            />
          }
          label="Attention required"
          sx={{
            color: 'principal.black',
          }}
        />
      </FormGroup>
    </Alert>
  );
};
