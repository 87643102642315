import { Box } from '@mui/material';
import { TimelineEventBoxDetail } from '../timeline/bulk-timeline-items/TimelineEventBoxDetail';
import { AccommodationFieldsForComparisonFragment, Departure } from '@flashpack/graphql';
import { FC } from 'react';
import { calculateDateFromDepartureAndDay } from '../timeline/bulk-timeline-items/utils';
import _ from 'lodash';

interface Props {
  departureDate: Departure['date'];
  accommodation: AccommodationFieldsForComparisonFragment;
}

export const AccommodationDetails: FC<Props> = ({ departureDate, accommodation }) => {
  const widthCheckInOut = 170;
  const widthAllocation = 30;

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap">
      <TimelineEventBoxDetail
        title="Check In"
        content={`${accommodation.checkIn.time} • ${calculateDateFromDepartureAndDay(
          departureDate,
          accommodation.checkIn.day,
        )} • `}
        subcontent={`Day ${accommodation.checkIn.day}`}
        width={widthCheckInOut}
      />
      <TimelineEventBoxDetail
        title="Check Out"
        content={`${accommodation.checkOut.time} • ${calculateDateFromDepartureAndDay(
          departureDate,
          accommodation.checkOut.day,
        )} • `}
        subcontent={`Day ${accommodation.checkOut.day}`}
        width={widthCheckInOut}
      />
      {!_.isNil(accommodation.allocationTwinRoom) && (
        <TimelineEventBoxDetail
          title="Twins"
          content={accommodation.allocationTwinRoom.toString()}
          width={widthAllocation}
        />
      )}
      {!_.isNil(accommodation.allocationSingleRoom) && (
        <TimelineEventBoxDetail
          title="Singles"
          content={accommodation.allocationSingleRoom.toString()}
          width={widthAllocation}
        />
      )}
    </Box>
  );
};
