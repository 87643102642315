import { HotelRate, RoomGroup, RoomGroupExt } from '@flashpack/graphql';

import isEqual from 'lodash.isequal';
import { createAmountFormatter } from '../../../shared/amountUtils';

const BeddingType = {
  SINGLE: 2,
  TWIN: 4,
};

export const findRoomIdWithTwoSingleBeds = (
  roomGroups?: Array<RoomGroup | null> | null,
): string | undefined | null => {
  const match = roomGroups?.find((roomGroup) => {
    const { bedding, capacity } = roomGroup?.rg_ext || {};
    const isSingleOrTwin = bedding === BeddingType.SINGLE || bedding === BeddingType.TWIN;
    const isCapacityTwo = capacity === 2;

    return isSingleOrTwin && isCapacityTwo;
  });

  return rgExtToString(match?.rg_ext);
};

export const findRoomPriceByRoomGroupExt = (
  hotelRates?: HotelRate[] | null,
  rgExt?: RoomGroupExt | null,
) => {
  const hotelRate = findHotelRateByRoomGroupExt(hotelRates, rgExt);
  return hotelRate?.payment_options?.payment_types?.[0]?.amount;
};

export const findHotelRateByRoomGroupExt = (
  hotelRates?: HotelRate[] | null,
  rgExt?: RoomGroupExt | null,
): HotelRate | undefined => {
  if (!rgExt || !hotelRates) {
    return undefined;
  }

  return hotelRates?.find((rate) => {
    if (!rate) {
      return undefined;
    }
    return isEqual(rate.rg_ext, rgExt);
  });
};

export interface RoomGroupOption {
  label?: string | null;
  roomId?: string | null;
}

export const createAutocompleteOptionsFromRoomGroups = (
  roomGroups: Array<RoomGroup | null> | null | undefined,
  hotelRates: HotelRate[] | null | undefined,
  currency: string,
): RoomGroupOption[] => {
  const formatAmount = createAmountFormatter(currency);
  const options =
    roomGroups?.map((roomGroup) => {
      const roomPrice = findRoomPriceByRoomGroupExt(hotelRates, roomGroup?.rg_ext);
      const label = roomPrice
        ? `${roomGroup?.name || ''} | ${formatAmount(roomPrice)}`
        : roomGroup?.name;

      return {
        label,
        roomId: rgExtToString(roomGroup?.rg_ext),
      };
    }) || [];

  return options;
};

export const createRoomAutocompleteOptions = (roomGroups: Array<RoomGroup>) => {
  return roomGroups.map((group) => ({
    label: group.name,
    value: rgExtToString(group.rg_ext),
  }));
};

type RoomGroupExtVarTypename = Omit<RoomGroupExt, '__typename'> & { __typename: string };

export const rgExtToString = (rgExt?: RoomGroupExtVarTypename | null) => {
  if (!rgExt) {
    return null;
  }

  const { __typename, ...rgExtProperties } = rgExt;

  const withOrderedKeys = (
    Object.keys(rgExtProperties) as Array<keyof typeof rgExtProperties>
  )
    .sort()
    .reduce(
      (obj, key) => {
        obj[key] = rgExtProperties[key];
        return obj;
      },
      {} as typeof rgExtProperties,
    );

  return JSON.stringify(withOrderedKeys);
};

export const areRgExtEqual = (
  rgExt1: RoomGroupExtVarTypename,
  rgExt2: RoomGroupExtVarTypename,
) => {
  return rgExtToString(rgExt1) === rgExtToString(rgExt2);
};
