import { parse, isBefore, isAfter } from 'date-fns';

type DayTime = {
  day?: number;
  time?: string;
};

export const startDayNotAfterEndDayValidator = (
  start: DayTime,
  end: DayTime,
  errorMessage = 'Start day cannot be after end day',
) => {
  if (end.day && start.day && start.day > end.day) {
    return errorMessage;
  }
};

export const startTimeNotAfterEndTimeValidator = (
  start: DayTime,
  end: DayTime,
  errorMessage = 'Start time cannot be after end time',
) => {
  if (start.day === end.day && start.time && end.time) {
    const startTimeDate = parse(start.time, 'HH:mm', 0);
    const endTimeDate = parse(end.time, 'HH:mm', 0);

    if (isAfter(startTimeDate, endTimeDate)) {
      return errorMessage;
    }
  }
};

export const endDayNotBeforeStartDayValidator = (
  start: DayTime,
  end: DayTime,
  errorMessage = 'End day cannot be before start day',
) => {
  if (start.day && end.day && end?.day < start.day) {
    return errorMessage;
  }
};

export const endTimeNotBeforeStartTimeValidator = (
  start: DayTime,
  end: DayTime,
  errorMessage = 'End time cannot be before start time',
) => {
  if (start.day === end.day && start.time && end.time) {
    const startTimeDate = parse(start.time, 'HH:mm', 0);
    const endTimeDate = parse(end.time, 'HH:mm', 0);

    if (isBefore(endTimeDate, startTimeDate)) {
      return errorMessage;
    }
  }
};
