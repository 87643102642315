import { useTheme } from '@mui/material/styles';
import { TextField, Typography, styled } from '@mui/material';
import {
  StaticDatePicker as MUIDatePicker,
  PickersDay,
  PickersDayProps,
} from '@mui/x-date-pickers';
import { Box } from '@mui/system';
import { FC } from 'react';
import { addDays, isWithinInterval } from 'date-fns';

export type HighlightInfo = {
  date: Date;
  softHighlightDuration: number;
  heavyHighlightDuration: number;
};

type HighlightDuration = {
  startDate: Date;
  softHighlightEndDate: Date;
  heavyHighlightEndDate: Date;
};

interface PropTypes {
  errorMessage?: string;
  onChange: (value: Date) => void;
  value: Date;
  highlights?: HighlightInfo[];
  specialDates?: SpecialDate[];
  isDaySelected: (day: Date) => boolean;
  onMonthChange?: (value: Date) => void;
  onYearChange?: (value: Date) => void;
  isDayDisabled?: (day: Date) => boolean;
}

export type SpecialDate = {
  date: Date;
  color: string;
  name?: string;
};

const weekdayFormatMap: { [key: string]: string } = {
  Mo: 'MON',
  Tu: 'TUE',
  We: 'WED',
  Th: 'THU',
  Fr: 'FRI',
  Sa: 'SAT',
  Su: 'SUN',
};

const getFormattedDayText = (input: string): string => {
  return weekdayFormatMap[input] ?? '?';
};

const isSameDay = (input: Date | undefined, target: Date): boolean =>
  Boolean(input) &&
  input?.getDate() == target.getDate() &&
  input?.getMonth() == target.getMonth() &&
  input?.getFullYear() == target.getFullYear();

const renderRangeBackground = (
  day: Date,
  highlightDurations: HighlightDuration[],
  color: string,
): JSX.Element | undefined => {
  const softHighlight = highlightDurations.find((duration) =>
    isWithinInterval(day, {
      start: duration.startDate,
      end: duration.softHighlightEndDate,
    }),
  );

  const heavyHighlight = highlightDurations.find((duration) =>
    isWithinInterval(day, {
      start: duration.startDate,
      end: duration.heavyHighlightEndDate,
    }),
  );

  if (!softHighlight) {
    return;
  }
  const isFirstDayOfWeek = day.getDay() == 0;
  const isLastDayOfWeek = day.getDay() == 6;
  const isHighlightStartDate = isSameDay(softHighlight.startDate, day);
  const isSoftHighlightFirstDay = isFirstDayOfWeek || isHighlightStartDate;
  const isSoftHighlightLastDay =
    isLastDayOfWeek || isSameDay(softHighlight.softHighlightEndDate, day);

  const isHeavyHighlightFirstDay =
    isFirstDayOfWeek || isSameDay(heavyHighlight?.startDate, day);
  const isHeavyHighlightLastDay =
    isLastDayOfWeek || isSameDay(heavyHighlight?.heavyHighlightEndDate, day);

  return (
    softHighlight && (
      <>
        <DatePickerSoftHighlightBackground
          color={color}
          roundedLeft={isSoftHighlightFirstDay}
          roundedRight={isSoftHighlightLastDay}
        />
        {heavyHighlight && (
          <DatePickerHeavyHighlightBackground
            roundedLeft={isHeavyHighlightFirstDay}
            roundedRight={isHeavyHighlightLastDay}
          />
        )}
        {isHighlightStartDate && <PickersDayBackground />}
      </>
    )
  );
};

const renderSpecialDates = (
  day: Date,
  specialDates: SpecialDate[],
): JSX.Element | undefined => {
  const match = specialDates.find(
    (x) =>
      x.date.getDate() == day.getDate() &&
      x.date.getMonth() == day.getMonth() &&
      x.date.getFullYear() == day.getFullYear(),
  );

  return (
    match && (
      <span
        style={{
          position: 'absolute',
          display: 'block',
          left: '50%',
          bottom: '3px',
          transform: 'translateX(-50%)',
          width: '6px',
          height: '6px',
          backgroundColor: match.color,
          borderRadius: '50%',
        }}
      />
    )
  );
};

export const DatePicker = ({
  isDaySelected,
  value,
  specialDates,
  highlights,
  onChange,
  onMonthChange,
  onYearChange,
  isDayDisabled,
}: PropTypes) => {
  const theme = useTheme();

  const highlightDurations: HighlightDuration[] = (highlights ?? []).map((highlight) => {
    const startDate = highlight.date;
    return {
      startDate,
      softHighlightEndDate: addDays(startDate, highlight.softHighlightDuration),
      heavyHighlightEndDate: addDays(startDate, highlight.heavyHighlightDuration),
    };
  });

  const renderPickerDay = (
    day: Date,
    _: Date[],
    pickersDayProps: PickersDayProps<Date>,
  ) => {
    const selected = isDaySelected(day);

    return (
      <Box position="relative">
        {highlightDurations &&
          !pickersDayProps.outsideCurrentMonth &&
          renderRangeBackground(day, highlightDurations, theme.palette.system.green10)}
        <CustomPickersDay
          {...pickersDayProps}
          selected={selected}
          data-testid={`datepicker-day-${day.getDate()}`}
          onClick={() => {
            onChange(day);
          }}
        >
          <Typography variant="bodySingle">{day.getDate()}</Typography>
        </CustomPickersDay>
        {specialDates &&
          !pickersDayProps.outsideCurrentMonth &&
          renderSpecialDates(day, specialDates)}
      </Box>
    );
  };

  return (
    <CustomMUIDatePicker<Date>
      onMonthChange={onMonthChange}
      onYearChange={onYearChange}
      views={['year', 'month', 'day']}
      displayStaticWrapperAs="desktop"
      onChange={() => {}}
      dayOfWeekFormatter={(day) => getFormattedDayText(day)}
      renderInput={(props) => <TextField {...props} fullWidth />}
      ampm={false}
      value={value}
      OpenPickerButtonProps={{ sx: { stroke: theme.palette.primary.main } }}
      renderDay={renderPickerDay}
      shouldDisableDate={isDayDisabled}
      showToolbar={false}
      InputProps={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        'data-testid': 'date-picker',
      }}
      componentsProps={{
        leftArrowButton: {
          color: 'primary',
        },
        rightArrowButton: {
          color: 'primary',
        },
        switchViewButton: {
          color: 'primary',
          className: 'switch-month-year-button',
        },
      }}
      sx={{
        '.MuiCalendarPicker-root': {
          borderRadius: '2px',
          border: 1,
          borderColor: theme.palette.grey[300],
        },
        '.MuiPickersCalendarHeader-label': {
          color: theme.palette.brand.jungle,
        },
      }}
    />
  );
};

const CustomPickersDay = styled(PickersDay<Date>, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme, selected }) => ({
  backgroundColor: 'transparent',
  '&.Mui-disabled': {
    color: theme.palette.common.white,
  },
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
    fontWeight: theme.typography.captionPara.fontWeight,
    borderRadius: '50%',
  }),
}));

interface DatePickerHeavyHighlightBackgroundPropTypes {
  roundedLeft: boolean;
  roundedRight: boolean;
}

const DatePickerHeavyHighlightBackground: FC<
  DatePickerHeavyHighlightBackgroundPropTypes
> = ({ roundedLeft, roundedRight }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        position: 'absolute',
        display: 'block',
        width: '100%',
        height: '100%',
        top: 0,
        borderColor: theme.palette.primary.main,
        borderWidth: 1,
        borderStyle: 'solid',
        backgroundColor: 'transparent',
        borderLeftWidth: roundedLeft ? 1 : 0,
        borderRightWidth: roundedRight ? 1 : 0,
        borderTopLeftRadius: roundedLeft ? '50%' : '0%',
        borderBottomLeftRadius: roundedLeft ? '50%' : '0%',
        borderTopRightRadius: roundedRight ? '50%' : '0%',
        borderBottomRightRadius: roundedRight ? '50%' : '0%',
      }}
    />
  );
};

interface DatePickerSoftHighlightBackgroundPropTypes {
  color: string;
  roundedLeft: boolean;
  roundedRight: boolean;
}

const DatePickerSoftHighlightBackground: FC<
  DatePickerSoftHighlightBackgroundPropTypes
> = ({ color, roundedLeft, roundedRight }) => (
  <div
    style={{
      position: 'absolute',
      display: 'block',
      width: '100%',
      height: '100%',
      top: 0,
      backgroundColor: color,
      borderTopLeftRadius: roundedLeft ? '50%' : '0%',
      borderBottomLeftRadius: roundedLeft ? '50%' : '0%',
      borderTopRightRadius: roundedRight ? '50%' : '0%',
      borderBottomRightRadius: roundedRight ? '50%' : '0%',
    }}
  />
);

const PickersDayBackground = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  position: 'absolute',
  display: 'block',
  width: '100%',
  height: '100%',
  top: 0,
  borderRadius: '50%',
}));

const CustomMUIDatePicker = styled(MUIDatePicker, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme }) => ({
  ...{
    backgroundColor: theme.palette.divider,
    color: theme.palette.common.black,
    fontSize: theme.typography.bodySingle.fontSize,
    fontWeight: theme.typography.bodySingle.fontWeight,
    textTransform: 'uppercase',
  },
  '.MuiDayPicker-weekDayLabel': {
    fontWeight: theme.typography.label.fontWeight,
    fontSize: theme.typography.label.fontSize,
  },
})) as typeof MUIDatePicker;
