import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { flashPackTheme } from '@src/design-system/theme';
import { FC } from 'react';
import { ChangeDisplay } from './AdjustmentChangeDisplay';
import { Divider } from 'design-system';
import { Adjustment } from './utils';

type Props = {
  adjustment: Adjustment;
};

export const DepartureDayAdjustmentsPopover: FC<Props> = (props) => {
  return (
    <Stack
      id="departure-day-adjustments-popover"
      direction="column"
      spacing={1}
      sx={{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: flashPackTheme.palette.principal.grey30,
        width: '300px',
        padding: flashPackTheme.spacing(1),
        borderRadius: flashPackTheme.spacing(0.5),
      }}
    >
      <Typography variant="bodySingle">Adjustments:</Typography>
      <Divider
        variant="middle"
        sx={{
          borderColor: flashPackTheme.palette.system.amber100,
          borderWidth: flashPackTheme.spacing(0.125),
        }}
      />
      <Stack direction="column" spacing={1}>
        {props.adjustment.changes.map((change) => (
          <ChangeDisplay key={change.title} {...change} />
        ))}
      </Stack>
    </Stack>
  );
};
