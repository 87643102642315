import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { Bulletin } from '@src/design-system/bulletin';
import {
  Scalars,
  UserRole,
  DepartureFieldsForListViewFragment,
} from '@flashpack/graphql';
import { format, parseISO } from 'date-fns';
import { RoutePath } from '@src/shared/routePath';
import { DepartureStatusChange } from '@src/departures/departure-status/DepartureStatusChange';
import { PackLeaderSelection } from '@src/departures/PackLeaderSelection';
import { useRouting } from '@src/shared/useRouting';
import { DeparturePrivateGroup } from '@src/departures/DeparturePrivateGroup';
import { DepartureSyncStatus } from './DepartureSyncStatus';
import { DownloadDepartureManifest } from '@src/departures/DownloadDepartureManifest';
import { DepartureSecuredStatusMenu } from '@src/departures/departure-secured-status/DepartureSecuredStatusMenu';
import { DepartureVisibilityMenu } from '@src/departures/departure-visibility/DepartureVisibilityMenu';
import { isSecuredStatusMenuDisabled } from './utils';
import { TripFillIndicator } from './TripFillIndicator';
import { TripGuaranteedIndicator } from './TripGuaranteedIndicator';
import { useTheme } from '@mui/material/styles';
import { DeparturesView } from './DeparturesList';
import { Protected } from '@src/authentication/Protected';
import { useCallback } from 'react';
import { PerDayAdjustments } from './adjustments/PerDayAdjustments';
import { Adjustment } from './adjustments/utils';
import { DepartureWithAdjustmentsAndFlaggedType } from '@src/adventure/adventure-overview/AdventureDeparturesTab';

export const DepartureRowItems = ({
  departure,
}: {
  departure: DepartureFieldsForListViewFragment;
}) => {
  return (
    <>
      <PackLeaderSelection departure={departure} />
      <DepartureStatusChange departure={departure} />
      <DepartureSecuredStatusMenu
        departureId={departure.id}
        securedStatus={departure.securedStatus}
        disabled={isSecuredStatusMenuDisabled(departure.status)}
      />
      {departure.bookingEngineId && (
        <>
          <Protected roles={[UserRole.Flashpack]}>
            <DepartureVisibilityMenu
              visibility={departure.visibility}
              departureIds={[departure.id]}
            />
          </Protected>
          <TripFillIndicator
            tripFill={departure.tripFill}
            tripCapacity={departure.capacity}
            minToGuarantee={departure.minToGuarantee}
          />
          <TripGuaranteedIndicator isGuaranteed={departure.isGuaranteed} />
        </>
      )}
      <Stack direction="row">
        {departure.privateGroup && (
          <Tooltip title="Private Group">
            <Box>
              <DeparturePrivateGroup colorVariant="greyed" large />
            </Box>
          </Tooltip>
        )}
      </Stack>
    </>
  );
};

const BulletinContent = ({
  departure,
  durationDays,
  adjustments,
  loadingAdjustments,
}: {
  departure: DepartureFieldsForListViewFragment;
  durationDays: number;
  adjustments: Adjustment[];
  loadingAdjustments: boolean;
}) => {
  const theme = useTheme();
  return (
    <Stack direction="column" width={'100%'} spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems={'center'} gap={2}>
          <Box width="110px" textAlign="left">
            <Typography variant="bodySingle" data-testid="departure-date">
              {format(parseISO(departure.date), 'dd MMM yyyy')}
            </Typography>
            <Typography
              variant="micro"
              color={theme.palette.principal.grey70}
              sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              {departure.itinerary.description}
            </Typography>
          </Box>

          <DepartureRowItems departure={departure} />
        </Stack>
        <Stack direction="row" gap={2} alignItems="center">
          <Protected roles={[UserRole.Flashpack]}>
            {departure.sync && (
              <DepartureSyncStatus
                sync={departure.sync}
                archivedDeparture={departure.archived}
              />
            )}
          </Protected>
          <DownloadDepartureManifest departureId={departure.id} />
        </Stack>
      </Stack>

      <PerDayAdjustments
        days={durationDays}
        adjustments={adjustments}
        loadingAdjustments={loadingAdjustments}
      />

      <Stack direction="row" spacing={3} alignItems="center" pt={1}>
        <Typography variant="micro" color={theme.palette.principal.grey70}>
          Room release: {departure.roomReleaseDays}
        </Typography>
        <Protected roles={[UserRole.Flashpack]}>
          <Typography variant="micro" color={theme.palette.principal.grey70}>
            Capacity: {departure.capacity}
          </Typography>
          <Typography variant="micro" color={theme.palette.principal.grey70}>
            Early birds: {departure.maxEarlyBirds}
          </Typography>
          <Typography variant="micro" color={theme.palette.principal.grey70}>
            To guarantee: {departure.minToGuarantee}
          </Typography>
        </Protected>
      </Stack>
    </Stack>
  );
};

export const DepartureBulletin = ({
  departureForListView,
  departureWithAdjustmentsAndFlagged,
  toggleSelectedDeparture,
  selected,
  adventureId,
  view,
  loadingAdjustments,
}: {
  departureForListView: DepartureFieldsForListViewFragment;
  departureWithAdjustmentsAndFlagged?: DepartureWithAdjustmentsAndFlaggedType;
  toggleSelectedDeparture: (id: Scalars['UUID']) => void;
  selected: boolean;
  adventureId: string;
  view: DeparturesView;
  loadingAdjustments: boolean;
}) => {
  const { navigate } = useRouting();
  const onSelect = useCallback(
    () => toggleSelectedDeparture(departureForListView.id),
    [departureForListView, toggleSelectedDeparture],
  );

  return (
    <Bulletin
      sx={{ pl: 1, pr: 2.5, py: 1, borderRadius: 2 }}
      key={departureForListView.id}
      greyedOut={departureForListView.archived}
      data-testid="departure-link"
      title={
        <BulletinContent
          departure={departureForListView}
          adjustments={departureWithAdjustmentsAndFlagged?.adjustments || []}
          durationDays={departureWithAdjustmentsAndFlagged?.durationDays || 0}
          loadingAdjustments={loadingAdjustments}
        />
      }
      selected={selected}
      flagged={departureWithAdjustmentsAndFlagged?.flagged || false}
      padTitle
      onSelect={onSelect}
      onClick={() => {
        navigate(
          RoutePath.ITINERARY_DEPARTURE.generatePath(
            adventureId,
            departureForListView.itinerary.id,
            departureForListView.id,
          ),
          {
            toAdd: {
              origin: view,
            },
          },
        );
      }}
    />
  );
};
