import { FC } from 'react';
import { FormParser } from '@src/design-system/forms/parsers';
import { TextField } from 'mui-rff';
import { ValidatorFactory } from '../validators';

export const NumberField: FC<{
  name: string;
  testid?: string;
  disabled?: boolean;
  required?: boolean;
  minimumValue?: number;
}> = ({ name, testid, disabled, required, minimumValue }) => {
  const requiredValidator = ValidatorFactory.createRequired('required', minimumValue);

  return (
    <TextField
      name={name}
      data-testid={testid}
      onFocus={(event) => event.target.select()}
      disabled={disabled}
      required={required}
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*|Multiple',
        sx: { textAlign: 'right', width: '90px' },
      }}
      fullWidth={false}
      fieldProps={{
        parse: FormParser.parseNumber,
        validate: required ? requiredValidator : undefined,
      }}
      sx={{ height: '56px' }}
    />
  );
};
