import {
  MinimalAccommodationFieldsFragment,
  MinimalEngagementFieldsFragment,
  TimelineForAdjustmentViewFragment,
} from '@flashpack/graphql';

export type AccommodationProperties =
  | 'title'
  | 'checkInDay'
  | 'checkOutDay'
  | 'checkInTime'
  | 'checkOutTime'
  | 'singleRoomName'
  | 'twinRoomName'
  | 'allocationSingleRoom'
  | 'allocationTwinRoom';

type EngagementCommonProperties =
  | 'startDay'
  | 'endDay'
  | 'startTime'
  | 'endTime'
  | 'startLocation'
  | 'endLocation'
  | 'description';

export type ActivityProperties =
  | EngagementCommonProperties
  | 'title'
  | 'foodAndDrink'
  | 'mealIncluded'
  | 'challenges';

export type TransferProperties = EngagementCommonProperties | 'mode';

export type ChangeType = 'Accommodation' | 'Activity' | 'Transfer';

export type ChangeDetail = {
  property: AccommodationProperties | ActivityProperties | TransferProperties;
  oldValue: string;
  newValue: string;
};

export type Change = {
  type: ChangeType;
  action: 'update' | 'create' | 'delete';
  title: string;
  details: ChangeDetail[];
};

export type UngroupedAdjustment = {
  day: number;
  change: Change;
};

export type Adjustment = {
  day: number;
  changes: Change[];
};

export const getDepartureAdjustments = (
  itineraryTimeline: TimelineForAdjustmentViewFragment,
  departureTimeline: TimelineForAdjustmentViewFragment,
): Adjustment[] => {
  const accommodationAdjustments = getAccommodationAdjustments(
    itineraryTimeline.accommodations,
    departureTimeline.accommodations,
  );
  const engagementAdjustments = getEngagementAdjustments(
    itineraryTimeline.engagements,
    departureTimeline.engagements,
  );
  return groupChangesByDay([...accommodationAdjustments, ...engagementAdjustments]);
};

const getAccommodationAdjustments = (
  itineraryAccommodations: MinimalAccommodationFieldsFragment[],
  departureAccommodations: MinimalAccommodationFieldsFragment[],
): UngroupedAdjustment[] => {
  const newAccommodationAdjustments = getNewAccommodationAdjustments(
    departureAccommodations,
  );
  const deletedAccommodationAdjustments = getDeletedAccommodationAdjustments(
    itineraryAccommodations,
    departureAccommodations,
  );
  const updatedAccommodationAdjustments = getUpdatedAccommodationAdjustments(
    itineraryAccommodations,
    departureAccommodations,
  );
  return [
    ...newAccommodationAdjustments,
    ...deletedAccommodationAdjustments,
    ...updatedAccommodationAdjustments,
  ];
};

export const getNewAccommodationAdjustments = (
  departureAccommodations: MinimalAccommodationFieldsFragment[],
): UngroupedAdjustment[] =>
  departureAccommodations
    .filter((accommodation) => !accommodation.parentAccommodationId) // Filter to only include new accommodations
    .flatMap((accommodation) =>
      generateDaysArray(accommodation.checkIn.day, accommodation.checkOut.day).map(
        (day) => ({
          day,
          change: {
            type: 'Accommodation',
            action: 'create',
            title: accommodation.title,
            details: [],
          },
        }),
      ),
    );

export const getDeletedAccommodationAdjustments = (
  itineraryAccommodations: MinimalAccommodationFieldsFragment[],
  departureAccommodations: MinimalAccommodationFieldsFragment[],
): UngroupedAdjustment[] =>
  itineraryAccommodations
    .filter((itineraryAccommodation) => {
      const accommodationExistsInDeparture = departureAccommodations.some(
        (departureAccommodation) =>
          departureAccommodation.parentAccommodationId === itineraryAccommodation.id,
      );
      return !accommodationExistsInDeparture;
    }) // Filter to only include accommodations deleted from departureAccommodations
    .flatMap((itineraryAccommodation) =>
      generateDaysArray(
        itineraryAccommodation.checkIn.day,
        itineraryAccommodation.checkOut.day,
      ).map((day) => ({
        day,
        change: {
          type: 'Accommodation',
          action: 'delete',
          title: itineraryAccommodation.title,
          details: [],
        },
      })),
    );

export const getUpdatedAccommodationAdjustments = (
  itineraryAccommodations: MinimalAccommodationFieldsFragment[],
  departureAccommodations: MinimalAccommodationFieldsFragment[],
): UngroupedAdjustment[] =>
  departureAccommodations
    .filter((departureAccommodation) => departureAccommodation.parentAccommodationId)
    .flatMap((departureAccommodation) => {
      const matchedItineraryAccommodation = itineraryAccommodations.find(
        (itineraryAccommodation) =>
          itineraryAccommodation.id === departureAccommodation.parentAccommodationId,
      );
      if (!matchedItineraryAccommodation) {
        return [];
      }
      const changeDetails = getAccommodationChangeDetails(
        matchedItineraryAccommodation,
        departureAccommodation,
      );
      if (changeDetails.length === 0) {
        return [];
      }
      return generateDaysArray(
        departureAccommodation.checkIn.day,
        departureAccommodation.checkOut.day,
      ).map((day) => ({
        day,
        change: {
          type: 'Accommodation',
          action: 'update',
          title: departureAccommodation.title,
          details: changeDetails,
        },
      }));
    });

export const getAccommodationChangeDetails = (
  itineraryAccommodation: MinimalAccommodationFieldsFragment,
  departureAccommodation: MinimalAccommodationFieldsFragment,
): ChangeDetail[] => {
  const changeDetails: ChangeDetail[] = [];
  if (departureAccommodation.title !== itineraryAccommodation.title) {
    changeDetails.push({
      property: 'title',
      oldValue: itineraryAccommodation.title,
      newValue: departureAccommodation.title,
    });
  }
  if (departureAccommodation.checkIn.day !== itineraryAccommodation.checkIn.day) {
    changeDetails.push({
      property: 'checkInDay',
      oldValue: itineraryAccommodation.checkIn.day.toString(),
      newValue: departureAccommodation.checkIn.day.toString(),
    });
  }
  if (departureAccommodation.checkOut.day !== itineraryAccommodation.checkOut.day) {
    changeDetails.push({
      property: 'checkOutDay',
      oldValue: itineraryAccommodation.checkOut.day.toString(),
      newValue: departureAccommodation.checkOut.day.toString(),
    });
  }
  if (departureAccommodation.checkIn.time !== itineraryAccommodation.checkIn.time) {
    changeDetails.push({
      property: 'checkInTime',
      oldValue: itineraryAccommodation.checkIn.time,
      newValue: departureAccommodation.checkIn.time,
    });
  }
  if (departureAccommodation.checkOut.time !== itineraryAccommodation.checkOut.time) {
    changeDetails.push({
      property: 'checkOutTime',
      oldValue: itineraryAccommodation.checkOut.time,
      newValue: departureAccommodation.checkOut.time,
    });
  }
  if (
    departureAccommodation.singleRoom?.name !== itineraryAccommodation.singleRoom?.name
  ) {
    changeDetails.push({
      property: 'singleRoomName',
      oldValue: itineraryAccommodation.singleRoom?.name ?? '',
      newValue: departureAccommodation.singleRoom?.name ?? '',
    });
  }
  if (departureAccommodation.twinRoom?.name !== itineraryAccommodation.twinRoom?.name) {
    changeDetails.push({
      property: 'twinRoomName',
      oldValue: itineraryAccommodation.twinRoom?.name ?? '',
      newValue: departureAccommodation.twinRoom?.name ?? '',
    });
  }
  if (
    departureAccommodation.allocationSingleRoom !==
    itineraryAccommodation.allocationSingleRoom
  ) {
    changeDetails.push({
      property: 'allocationSingleRoom',
      oldValue: `${itineraryAccommodation.allocationSingleRoom}`,
      newValue: `${departureAccommodation.allocationSingleRoom}`,
    });
  }
  if (
    departureAccommodation.allocationTwinRoom !==
    itineraryAccommodation.allocationTwinRoom
  ) {
    changeDetails.push({
      property: 'allocationTwinRoom',
      oldValue: `${itineraryAccommodation.allocationTwinRoom}`,
      newValue: `${departureAccommodation.allocationTwinRoom}`,
    });
  }
  return changeDetails;
};

const getEngagementAdjustments = (
  itineraryEngagements: MinimalEngagementFieldsFragment[],
  departureEngagements: MinimalEngagementFieldsFragment[],
): UngroupedAdjustment[] => {
  const newEngagementAdjustments = getNewEngagementAdjustments(departureEngagements);
  const deletedEngagementAdjustments = getDeletedEngagementAdjustments(
    itineraryEngagements,
    departureEngagements,
  );
  const updatedEngagementAdjustments = getUpdatedEngagementAdjustments(
    itineraryEngagements,
    departureEngagements,
  );
  return [
    ...newEngagementAdjustments,
    ...deletedEngagementAdjustments,
    ...updatedEngagementAdjustments,
  ];
};

export const getNewEngagementAdjustments = (
  departureEngagements: MinimalEngagementFieldsFragment[],
): UngroupedAdjustment[] =>
  departureEngagements
    .filter((departureEngagement) => !departureEngagement.parentEngagementId) // Filter to include only new engagements
    .flatMap((departureEngagement) =>
      generateDaysArray(
        departureEngagement.start.time.day,
        departureEngagement.end.time.day,
      ).map((day) => ({
        day,
        change: {
          type: departureEngagement.activity ? 'Activity' : 'Transfer',
          action: 'create',
          title: departureEngagement.title,
          details: [],
        },
      })),
    );

export const getDeletedEngagementAdjustments = (
  itineraryEngagements: MinimalEngagementFieldsFragment[],
  departureEngagements: MinimalEngagementFieldsFragment[],
): UngroupedAdjustment[] =>
  itineraryEngagements
    .filter((itineraryEngagement) => {
      const engagementExistsInDeparture = departureEngagements.some(
        (departureEngagement) =>
          departureEngagement.parentEngagementId === itineraryEngagement.id,
      );
      return !engagementExistsInDeparture;
    }) // Filter to only include engagements deleted in the departure
    .flatMap((itineraryEngagement) =>
      generateDaysArray(
        itineraryEngagement.start.time.day,
        itineraryEngagement.end.time.day,
      ).map((day) => ({
        day,
        change: {
          type: itineraryEngagement.activity ? 'Activity' : 'Transfer',
          action: 'delete',
          title: itineraryEngagement.title,
          details: [],
        },
      })),
    );

export const getUpdatedEngagementAdjustments = (
  itineraryEngagements: MinimalEngagementFieldsFragment[],
  departureEngagements: MinimalEngagementFieldsFragment[],
): UngroupedAdjustment[] =>
  departureEngagements
    .filter((departureEngagement) => departureEngagement.parentEngagementId)
    .flatMap((departureEngagement) => {
      const matchedItineraryEngagement = itineraryEngagements.find(
        (itineraryEngagement) =>
          itineraryEngagement.id === departureEngagement.parentEngagementId,
      );
      if (!matchedItineraryEngagement) {
        return [];
      }
      const changeDetails = getEngagementChangeDetails(
        matchedItineraryEngagement,
        departureEngagement,
      );
      if (changeDetails.length === 0) {
        return [];
      }
      return generateDaysArray(
        departureEngagement.start.time.day,
        departureEngagement.end.time.day,
      ).map((day) => ({
        day,
        change: {
          type: departureEngagement.activity ? 'Activity' : 'Transfer',
          action: 'update',
          title: departureEngagement.title,
          details: changeDetails,
        },
      }));
    });

export const getEngagementChangeDetails = (
  itineraryEngagement: MinimalEngagementFieldsFragment,
  departureEngagement: MinimalEngagementFieldsFragment,
): ChangeDetail[] => {
  const changeDetails: ChangeDetail[] = [];
  if (departureEngagement.title !== itineraryEngagement.title) {
    changeDetails.push({
      property: 'title',
      oldValue: itineraryEngagement.title,
      newValue: departureEngagement.title,
    });
  }
  if (departureEngagement.start.time.day !== itineraryEngagement.start.time.day) {
    changeDetails.push({
      property: 'startDay',
      oldValue: itineraryEngagement.start.time.day.toString(),
      newValue: departureEngagement.start.time.day.toString(),
    });
  }
  if (departureEngagement.end.time.day !== itineraryEngagement.end.time.day) {
    changeDetails.push({
      property: 'endDay',
      oldValue: itineraryEngagement.end.time.day.toString(),
      newValue: departureEngagement.end.time.day.toString(),
    });
  }
  if (departureEngagement.start.time.time !== itineraryEngagement.start.time.time) {
    changeDetails.push({
      property: 'startTime',
      oldValue: itineraryEngagement.start.time.time,
      newValue: departureEngagement.start.time.time,
    });
  }
  if (departureEngagement.end.time.time !== itineraryEngagement.end.time.time) {
    changeDetails.push({
      property: 'endTime',
      oldValue: itineraryEngagement.end.time.time,
      newValue: departureEngagement.end.time.time,
    });
  }
  if (departureEngagement.start.location !== itineraryEngagement.start.location) {
    changeDetails.push({
      property: 'startLocation',
      oldValue: itineraryEngagement.start.location,
      newValue: departureEngagement.start.location,
    });
  }
  if (departureEngagement.end.location !== itineraryEngagement.end.location) {
    changeDetails.push({
      property: 'endLocation',
      oldValue: itineraryEngagement.end.location,
      newValue: departureEngagement.end.location,
    });
  }

  if (
    departureEngagement.activity?.description !==
    itineraryEngagement.activity?.description
  ) {
    changeDetails.push({
      property: 'description',
      oldValue: itineraryEngagement.activity?.description ?? '',
      newValue: departureEngagement.activity?.description ?? '',
    });
  }
  if (
    departureEngagement.activity?.foodAndDrink !==
    itineraryEngagement.activity?.foodAndDrink
  ) {
    changeDetails.push({
      property: 'foodAndDrink',
      oldValue: itineraryEngagement.activity?.foodAndDrink ?? '',
      newValue: departureEngagement.activity?.foodAndDrink ?? '',
    });
  }
  if (
    departureEngagement.activity?.mealIncluded !==
    itineraryEngagement.activity?.mealIncluded
  ) {
    changeDetails.push({
      property: 'mealIncluded',
      oldValue: itineraryEngagement.activity?.mealIncluded ? 'Meal included' : '',
      newValue: departureEngagement.activity?.mealIncluded ? 'Meal included' : '',
    });
  }
  if (
    isActivityChallengesDifferent(
      itineraryEngagement.activity?.challenges,
      departureEngagement.activity?.challenges,
    )
  ) {
    changeDetails.push({
      property: 'challenges',
      oldValue: itineraryEngagement.activity?.challenges.join(',') ?? '',
      newValue: departureEngagement.activity?.challenges.join(',') ?? '',
    });
  }
  if (
    departureEngagement.transfer?.description !==
    itineraryEngagement.transfer?.description
  ) {
    changeDetails.push({
      property: 'description',
      oldValue: itineraryEngagement.transfer?.description ?? '',
      newValue: departureEngagement.transfer?.description ?? '',
    });
  }
  if (departureEngagement.transfer?.mode !== itineraryEngagement.transfer?.mode) {
    changeDetails.push({
      property: 'mode',
      oldValue: itineraryEngagement.transfer?.mode ?? '',
      newValue: departureEngagement.transfer?.mode ?? '',
    });
  }
  return changeDetails;
};

// Utility function to generate an array of days for each accommodation
export const generateDaysArray = (startDay: number, endDay: number): number[] =>
  Array.from({ length: endDay - startDay + 1 }, (_, i) => startDay + i);

export const groupChangesByDay = (
  ungroupedAdjustments: UngroupedAdjustment[],
): Adjustment[] => {
  const grouped = new Map<number, Change[]>();

  ungroupedAdjustments.forEach(({ day, change }) => {
    if (!grouped.has(day)) {
      grouped.set(day, []);
    }
    grouped.get(day)?.push(change);
  });

  return Array.from(grouped, ([day, changes]) => ({
    day,
    changes: sortChangeByType(changes),
  }));
};

const typePriority: Record<ChangeType, number> = {
  Accommodation: 1,
  Transfer: 2,
  Activity: 3,
};

const sortChangeByType = (objects: Change[]): Change[] => {
  return objects.sort((a, b) => typePriority[a.type] - typePriority[b.type]);
};

export const isActivityChallengesDifferent = (
  challengeA?: string[],
  challengeB?: string[],
) => {
  if (!challengeA && !challengeB) {
    return false;
  }
  if (!challengeA || !challengeB) {
    return true;
  }
  if (challengeA.length !== challengeB.length) {
    return true;
  }
  return !challengeA.every((item) => challengeB.includes(item));
};
