import { Stack } from '@mui/material';
import { Comments } from '@src/itinerary/common/comments/Comments';
import { CommentThreadDocument } from '@flashpack/graphql';
import { AddComment } from '@src/itinerary/common/comments/AddComment';
import { useQuery } from '@apollo/client';

type PropTypes = {
  threadId: string;
};

export const CommentsSection = ({ threadId }: PropTypes) => {
  const { data, error, loading } = useQuery(CommentThreadDocument, {
    variables: { commentThreadId: threadId },
  });

  if (loading) {
    return <></>;
  }
  if (!data) {
    throw new Error('Data not loaded');
  }

  const comments = data?.commentThread?.comments || [];

  return (
    <Stack gap={1}>
      <Comments error={!!error} loading={loading} comments={comments} />
      <AddComment commentThreadId={threadId} />
    </Stack>
  );
};
