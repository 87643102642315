import { useRequiredParams } from '@src/shared/useRequiredParams';
import { BulkUpdateTimelineHeader } from './BulkUpdateTimelineHeader';
import { useParams } from 'react-router-dom';
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { Box, MenuItem, OutlinedInput, Select, Skeleton } from '@mui/material';
import { Divider, flashPackTheme } from 'design-system';
import { Stack } from '@mui/system';
import Search from '@mui/icons-material/Search';
import { BulkEditAccommodationDrawer } from './BulkEditAccommodationDrawer';
import {
  AccommodationFieldsForComparisonFragment,
  DirectionOfOverlapOnBulkUpdate,
  EditEngagementsInput,
  EditEngagementsDocument,
  EngagementFieldsForComparisonFragment,
} from '@flashpack/graphql';
import { useQuery, useMutation } from '@apollo/client';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { AdventureWithItineraryAndDepartureTimelinesDocument } from '@flashpack/graphql';
import { getEventsFromAdventureDepartures } from './utils';
import { SearchedAccommodations } from './SearchedAccommodations';
import { debounce } from 'lodash';
import { SearchedEngagements } from './SearchedEngagements';
import { BulkEditTransferDrawer } from './BulkEditTransferDrawer';
import { BulkEditActivityDrawer } from './BulkEditActivityDrawer';
import { AutoResolveConflictsModal } from './auto-resolve-conflicts/AutoResolveConflictsModal';
import {
  DepartureWithEngagements,
  RescheduleSuccessModal,
} from './auto-resolve-conflicts/RescheduleSuccessModal';
import { RescheduleFailureModal } from './auto-resolve-conflicts/RescheduleFailureModal';

export type TimelineItemType = 'accommodation' | 'activity' | 'transfer';

export const BulkTimelineItemsPage = () => {
  const { adventureId } = useRequiredParams(['adventureId']);
  const { itineraryId } = useParams();
  const { safeMutation } = useSafeMutation();

  const [type, setType] = useState<TimelineItemType>('accommodation');
  const [selectedAccommodations, setSelectedAccommodations] = useState<
    AccommodationFieldsForComparisonFragment[]
  >([]);
  const [selectedEngagements, setSelectedEngagements] = useState<
    EngagementFieldsForComparisonFragment[]
  >([]);

  const [directionOfOverlapOnBulkUpdate, setDirectionOfOverlapOnBulkUpdate] =
    useState<DirectionOfOverlapOnBulkUpdate | null>(null);
  const [editEngagementsInput, setEditEngagementsInput] =
    useState<EditEngagementsInput | null>(null);

  const [isRescheduleSuccessModalOpen, setIsRescheduleSuccessModalOpen] = useState(false);
  const [isRescheduleFailureModalOpen, setIsRescheduleFailureModalOpen] = useState(false);

  const [departuresWithEngagements, setDeparturesWithEngagements] = useState<
    Array<DepartureWithEngagements>
  >([]);

  /*
  Search is debounced to prevent unnecessary queries, however inputValue
  is updated immediately and cleared when the type is changed
  */
  const [inputValue, setInputValue] = useState('');
  const [search, setSearch] = useState('');

  const searchPlaceholder = useCallback(() => {
    switch (type) {
      case 'accommodation':
        return 'e.g. "tree house"';
      case 'activity':
        return 'e.g. "zipline"';
      case 'transfer':
        return 'e.g. "ushuaia"';
    }
  }, [type]);

  const debouncedSearch = useCallback(
    debounce(
      (value: string) => {
        // if the search value has changed, clear the selected accommodations/engagements
        if (value !== search) {
          setSelectedAccommodations([]);
          setSelectedEngagements([]);
        }
        setSearch(value);
      },
      500,
      { trailing: true, leading: false },
    ),
    [],
  );

  useEffect(() => {
    debouncedSearch(inputValue);
  }, [inputValue, debouncedSearch]);

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setInputValue(event.target.value);
  };

  const { data, loading } = useQuery(
    AdventureWithItineraryAndDepartureTimelinesDocument,
    {
      variables: { id: adventureId, excludePastCancelledAndArchived: true },
      fetchPolicy: 'cache-and-network',
    },
  );

  const [editEngagements] = useMutation(EditEngagementsDocument);

  const { accommodations, transfers, activities } = getEventsFromAdventureDepartures(
    data?.adventure,
    itineraryId,
  );

  // update selected accommodations if accommodation fields changed for any selected accommodation
  useEffect(() => {
    setSelectedAccommodations((currentSelectedAccommodations) => {
      for (const selectedAccommodation of currentSelectedAccommodations) {
        const accommodation = accommodations.find(
          (accommodation) => accommodation.accommodation.id === selectedAccommodation.id,
        );
        // reset selected accommodations if accommodation title is updated and no longer matches the search string
        if (
          !accommodation?.accommodation.title.toLowerCase().includes(search.toLowerCase())
        ) {
          return [];
        }
        if (accommodation?.accommodation !== selectedAccommodation) {
          return accommodations
            .filter((acc) =>
              currentSelectedAccommodations.find(
                (selected) => selected.id === acc.accommodation.id,
              ),
            )
            .map((acc) => acc.accommodation);
        }
      }
      return currentSelectedAccommodations;
    });
  }, [accommodations, search]);

  // update selected engagements if engagement fields changed for any selected engagements
  useEffect(() => {
    setSelectedEngagements((currentSelectedEngagements) => {
      for (const selectedEngagement of currentSelectedEngagements) {
        const engagement = (type === 'activity' ? activities : transfers).find(
          (e) => e.engagement.id === selectedEngagement.id,
        );
        // reset selected engagements if engagement title is updated and no longer matches the search string
        if (!engagement?.engagement.title.toLowerCase().includes(search.toLowerCase())) {
          return [];
        }
        if (engagement?.engagement !== selectedEngagement) {
          return (type === 'activity' ? activities : transfers)
            .filter((e) =>
              currentSelectedEngagements.find(
                (selected) => selected.id === e.engagement.id,
              ),
            )
            .map((e) => e.engagement);
        }
      }
      return currentSelectedEngagements;
    });
  }, [activities, search, transfers, type]);

  const showEventList = !loading && search;

  const onConfirmEditEngagementsWithAutoResolve = async () => {
    if (!editEngagementsInput) {
      setDirectionOfOverlapOnBulkUpdate(null);
      setIsRescheduleFailureModalOpen(true);
      return;
    }

    const { response, error } = await safeMutation(
      editEngagements({
        variables: {
          input: editEngagementsInput,
        },
      }),
    );

    if (error || !response?.data?.editEngagements) {
      setDirectionOfOverlapOnBulkUpdate(null);
      setIsRescheduleFailureModalOpen(true);
      return;
    }

    const departuresWithEngagementsFormatted: DepartureWithEngagements[] =
      response.data.editEngagements.map(({ departure, updatedEngagements }) => {
        if (!departure) {
          setDirectionOfOverlapOnBulkUpdate(null);
          setIsRescheduleFailureModalOpen(true);
          throw new Error('Departure not found');
        }

        return {
          id: departure.id,
          date: departure.date,
          adventureId: departure.itinerary.adventure.id,
          itineraryId: departure.itinerary.id,
          engagements: updatedEngagements,
        };
      });

    setDeparturesWithEngagements(departuresWithEngagementsFormatted);
    setDirectionOfOverlapOnBulkUpdate(null);
    setIsRescheduleSuccessModalOpen(true);
  };

  return (
    <>
      <BulkUpdateTimelineHeader adventureId={adventureId} itineraryId={itineraryId} />
      <Divider sx={{ mt: 4 }} />
      <Box textAlign="center">
        <Stack direction="row" spacing={1} mb={2}>
          <Select
            value={type}
            onChange={(event) => {
              setType(event.target.value as TimelineItemType);
              setSearch('');
              setInputValue('');
              setSelectedAccommodations([]);
              setSelectedEngagements([]);
            }}
            sx={{
              width: '175px',
              borderColor: flashPackTheme.palette.principal.grey30,
              border: 'solid',
              borderWidth: 0.5,
              color: flashPackTheme.palette.principal.grey70,
              '&:hover': {
                backgroundColor: flashPackTheme.palette.principal.grey50,
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            data-testid="bulk-timeline-items-type"
          >
            <MenuItem value="accommodation">Accommodation</MenuItem>
            <MenuItem value="activity">Activity</MenuItem>
            <MenuItem value="transfer">Transfer</MenuItem>
          </Select>
          <OutlinedInput
            placeholder={searchPlaceholder()}
            onChange={handleInputChange}
            value={inputValue}
            endAdornment={
              <Search sx={{ color: flashPackTheme.palette.principal.grey70 }} />
            }
            sx={{ width: '100%' }}
            autoFocus
            data-testid="bulk-timeline-items-search"
          />
        </Stack>
        {loading && <SkeletonEventList count={5} />}
        {type === 'accommodation' && !loading && (
          <SearchedAccommodations
            departureAccommodations={showEventList ? accommodations : []}
            searchString={search}
            selectedAccommodations={selectedAccommodations}
            setSelectedAccommodations={setSelectedAccommodations}
          />
        )}
        {type === 'transfer' && !loading && (
          <SearchedEngagements
            departureEngagements={showEventList ? transfers : []}
            searchString={search}
            selectedEngagements={selectedEngagements}
            setSelectedEngagements={setSelectedEngagements}
            engagementType={type}
          />
        )}
        {type === 'activity' && !loading && (
          <SearchedEngagements
            departureEngagements={showEventList ? activities : []}
            searchString={search}
            selectedEngagements={selectedEngagements}
            setSelectedEngagements={setSelectedEngagements}
            engagementType={type}
          />
        )}
      </Box>
      {selectedAccommodations.length > 0 && (
        <BulkEditAccommodationDrawer
          accommodations={selectedAccommodations}
          setAccommodations={setSelectedAccommodations}
        />
      )}
      {selectedEngagements.length > 0 && type === 'transfer' && (
        <BulkEditTransferDrawer
          transfers={selectedEngagements}
          setTransfers={setSelectedEngagements}
          setDirectionOfOverlapOnBulkUpdate={setDirectionOfOverlapOnBulkUpdate}
          setEditEngagementsInput={setEditEngagementsInput}
        />
      )}
      {selectedEngagements.length > 0 && type === 'activity' && (
        <BulkEditActivityDrawer
          activities={selectedEngagements}
          setActivities={setSelectedEngagements}
          setDirectionOfOverlapOnBulkUpdate={setDirectionOfOverlapOnBulkUpdate}
          setEditEngagementsInput={setEditEngagementsInput}
        />
      )}
      {directionOfOverlapOnBulkUpdate && (
        <AutoResolveConflictsModal
          open={directionOfOverlapOnBulkUpdate !== null}
          onClose={() => setDirectionOfOverlapOnBulkUpdate(null)}
          directionOfOverlapOnBulkUpdate={directionOfOverlapOnBulkUpdate}
          onConfirm={onConfirmEditEngagementsWithAutoResolve}
          movingItemTitle={editEngagementsInput?.title || 'Multiple titles'}
        />
      )}
      <RescheduleSuccessModal
        open={isRescheduleSuccessModalOpen}
        onClose={() => setIsRescheduleSuccessModalOpen(false)}
        departuresWithEngagements={departuresWithEngagements}
      />
      <RescheduleFailureModal
        open={isRescheduleFailureModalOpen}
        onClose={() => setIsRescheduleFailureModalOpen(false)}
      />
    </>
  );
};

export const SkeletonEventList: FC<{ count: number }> = ({ count }) => {
  return (
    <Stack spacing={1}>
      {[...Array(count).keys()].map((key) => (
        <Skeleton key={key} variant="rounded" height={56} />
      ))}
    </Stack>
  );
};
