import { Stack, styled, Typography } from '@mui/material';
import { ReactElement } from 'react';

export const Info = ({
  title,
  paragraphs,
}: {
  title: string;
  paragraphs: Array<string | ReactElement>;
}) => {
  return (
    <Container>
      <Stack gap={1}>
        <Typography variant="pageHeader" sx={{ mb: 3 }}>
          {title}
        </Typography>
        <Stack gap={2}>
          {paragraphs.map((paragraph, i) => (
            <Typography key={i} variant="bodyPara">
              {paragraph}
            </Typography>
          ))}
        </Stack>
      </Stack>
    </Container>
  );
};

const Container = styled('div')(({ theme }) => ({
  textAlign: 'left',
  maxWidth: theme.spacing(50),
  margin: theme.spacing(5, 'auto', 0),

  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(15, 'auto', 0),
  },
}));
