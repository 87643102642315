import { LoadingButton } from '@mui/lab';
import { Button, Dialog, Stack, Typography } from '@mui/material';
import { TextField } from 'mui-rff';
import { composeValidators, Validator } from '@src/design-system/forms/validators';
import { UpdateItineraryDescriptionDocument } from '@flashpack/graphql';
import { FC } from 'react';
import { Form } from 'react-final-form';
import { ServerValidationErrors } from '@src/design-system/forms/ServerValidationErrors';
import { ApolloError, useMutation } from '@apollo/client';
import { FORM_ERROR } from 'final-form';
import { useToast } from '@src/shared/toast/useToast';
import { useSafeMutation } from '@src/shared/useSafeMutation';

interface PropTypes {
  itineraryId: string;
  open: boolean;
  description: string;
  onClose: () => void;
}

type FormValues = {
  description: string;
};

export const EditItineraryDialog: FC<PropTypes> = ({
  open,
  onClose,
  itineraryId,
  description,
}) => {
  const [updateItineraryDescription] = useMutation(UpdateItineraryDescriptionDocument);
  const { success, error } = useToast();
  const { safeMutation } = useSafeMutation();

  const onSubmit = async ({ description }: FormValues) => {
    await safeMutation(
      updateItineraryDescription({
        variables: { input: { id: itineraryId, description } },
      }),
      {
        onSuccess: () => {
          success('Updated itinerary description');
          onClose();
        },
        onError: () => {
          error('Unable to update itinerary description');
          onClose();
        },
      },
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Stack m={7} gap={5}>
        <Form<FormValues>
          onSubmit={onSubmit}
          initialValues={{ description }}
          render={({ handleSubmit, submitting, dirtySinceLastSubmit, submitError }) => {
            return (
              <form
                onSubmit={(values) => {
                  try {
                    void handleSubmit(values);
                  } catch (e) {
                    return {
                      [FORM_ERROR]: e,
                    };
                  }
                }}
              >
                <Stack gap={3} spacing={3} alignItems="flex-start">
                  <Typography variant="subHeader">Edit {description}</Typography>

                  <TextField
                    fieldProps={{
                      validate: composeValidators(Validator.required),
                    }}
                    autoFocus
                    name="description"
                    data-testid="edit-itinerary-description-input"
                    label="Description"
                  />

                  {!dirtySinceLastSubmit && (
                    <ServerValidationErrors error={submitError as ApolloError} />
                  )}
                  <Stack direction="row" gap={3} justifyContent="flex-end">
                    <Button variant="outlined" onClick={onClose}>
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={submitting}
                      type="submit"
                      variant="contained"
                      data-testid="edit-itinerary-submit"
                    >
                      Save
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            );
          }}
        />
      </Stack>
    </Dialog>
  );
};
