import { Dispatch, FC, SetStateAction, useState } from 'react';
import {
  BulkUpdatePromotionalDiscountsDocument,
  DepartureFinancesQueryDocument,
  ItineraryWithPromotionalDiscountsDocument,
  Scalars,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useMutation } from '@apollo/client';
import {
  BulkUpdateAmountUsdModal,
  BulkUpdateAmountUsdModalFormType,
} from './BulkUpdateAmountUsdModal';

interface PropTypes {
  open: boolean;
  onClose: () => void;
  selectedDepartureIds: Array<Scalars['UUID']>;
  setSelectedDepartureIds?: Dispatch<SetStateAction<string[]>>;
  initialValues?: BulkUpdateAmountUsdModalFormType['amountUSD'];
}

export const BulkUpdatePromotionalDiscountsModal: FC<PropTypes> = ({
  open,
  onClose,
  selectedDepartureIds,
  setSelectedDepartureIds,
  initialValues,
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { error: errorToast, success: successToast } = useToast();
  const { safeMutation } = useSafeMutation();
  const [bulkUpdatePromotionalDiscounts] = useMutation(
    BulkUpdatePromotionalDiscountsDocument,
  );

  const handleUpdatePromotionalDiscounts = async ({
    amountUSD,
  }: BulkUpdateAmountUsdModalFormType) => {
    setIsSubmitting(true);
    await safeMutation(
      bulkUpdatePromotionalDiscounts({
        variables: {
          input: {
            departureIds: selectedDepartureIds,
            amountUSD,
          },
        },
        refetchQueries: [
          ItineraryWithPromotionalDiscountsDocument,
          DepartureFinancesQueryDocument,
        ],
      }),
      {
        onSuccess: () => {
          setIsSubmitting(false);
          onClose();
          successToast('Promotional discounts updated successfully');
          setSelectedDepartureIds && setSelectedDepartureIds([]);
        },
        onError: () => {
          setIsSubmitting(false);
          errorToast('Could not update promotional discounts');
        },
      },
    );
  };

  return (
    <BulkUpdateAmountUsdModal
      open={open}
      onClose={onClose}
      confirmLoading={isSubmitting}
      onSubmit={handleUpdatePromotionalDiscounts}
      selectedDepartureIds={selectedDepartureIds}
      initialValues={initialValues}
    />
  );
};
