import { ButtonProps, styled } from '@mui/material';
import { Button } from './Button';
import { FC } from 'react';

export const PrimaryButton: FC<ButtonProps> = (props) => {
  return (
    <StyledButton variant="contained" {...props}>
      {props.children}
    </StyledButton>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    background: theme.palette.system.green70,
  },
}));
