import { useQuery } from '@apollo/client';
import {
  AdventureWithDeparturesForSignOffDocument,
  Departure,
  DepartureEdge,
  DepartureSignOffSortAndFilter,
} from '@flashpack/graphql';
import InboxIcon from '@mui/icons-material/Inbox';
import { DepartureSignOffCard } from '@src/departures/DepartureSignOffCard';
import { BlankSlate } from '@src/design-system/blank-slate/BlankSlate';
import { SkeletonBulletinList } from '@src/design-system/bulletin';
import { flashPackTheme, LoadingButton, Stack, ThumbsUpIcon } from 'design-system';
import { FC } from 'react';

interface PropTypes {
  adventureId: string;
  departureSignOffSortAndFilter: DepartureSignOffSortAndFilter;
}

export const DeparturesSignOffList: FC<PropTypes> = ({
  departureSignOffSortAndFilter,
  adventureId,
}) => {
  const { data, loading, fetchMore } = useQuery(
    AdventureWithDeparturesForSignOffDocument,
    {
      variables: {
        first: 3,
        id: adventureId,
        sortAndFilter: departureSignOffSortAndFilter,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const emptyAndLoadingState = (loading: boolean, edges: DepartureEdge[]) => {
    if (loading) {
      return <SkeletonBulletinList count={5} />;
    }

    const emptyState = !loading && edges && edges.length === 0;

    if (emptyState) {
      if (
        departureSignOffSortAndFilter === DepartureSignOffSortAndFilter.SignOffCompleted
      ) {
        return (
          <BlankSlate
            icon={
              <InboxIcon
                sx={{ height: '70px', width: '50px' }}
                style={{ color: flashPackTheme.palette.principal.grey70 }}
              />
            }
            message="No departures signed off"
          />
        );
      } else {
        return (
          <BlankSlate
            icon={
              <ThumbsUpIcon
                sx={{ height: '70px', width: '50px' }}
                style={{ color: flashPackTheme.palette.system.green100 }}
              />
            }
            message="All departures signed off"
          />
        );
      }
    }
  };

  return (
    <Stack spacing={1}>
      {emptyAndLoadingState(
        loading,
        data?.adventure.departuresForSignOff.edges as DepartureEdge[],
      )}
      {!loading &&
        data?.adventure.departuresForSignOff &&
        data?.adventure.departuresForSignOff.edges.map((edge, index) => (
          <DepartureSignOffCard key={index} departure={edge.node as Departure} />
        ))}
      {!loading && data?.adventure.departuresForSignOff.pageInfo.hasNextPage && (
        <LoadingButton
          loading={loading}
          sx={{ width: '250px', alignSelf: 'center' }}
          onClick={() => {
            void fetchMore({
              variables: {
                after: data?.adventure.departuresForSignOff.pageInfo.endCursor,
              },
              updateQuery: (previousQueryResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) {
                  return previousQueryResult;
                }
                return {
                  ...previousQueryResult,
                  adventure: {
                    ...previousQueryResult.adventure,
                    departuresForSignOff: {
                      ...previousQueryResult.adventure.departuresForSignOff,
                      edges: [
                        ...previousQueryResult.adventure.departuresForSignOff.edges,
                        ...fetchMoreResult.adventure.departuresForSignOff.edges,
                      ],
                      pageInfo: fetchMoreResult.adventure.departuresForSignOff.pageInfo,
                    },
                  },
                };
              },
            });
          }}
        >
          Load more
        </LoadingButton>
      )}
    </Stack>
  );
};
