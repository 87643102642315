import React from 'react';
import { RadioGroup } from '@mui/material';
import { Radio } from '@src/design-system/radio-button/Radio';
import { FormControlLabel } from 'design-system';

type ProductLineFilterProps = {
  value: string;
  onChange: (value: string) => void;
};

export const ProductLineFilter: React.FC<ProductLineFilterProps> = ({
  value,
  onChange,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <RadioGroup row value={value} onChange={handleChange}>
      <FormControlLabel value="all" control={<Radio />} label="All" />
      <FormControlLabel value="flash_pack" control={<Radio />} label="Flash Pack" />
      <FormControlLabel value="age_45_59" control={<Radio />} label="45-59" />
    </RadioGroup>
  );
};
