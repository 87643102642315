import { Stack, Typography, Button, Box } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { FC, ReactElement } from 'react';
import * as React from 'react';
import { ActivityTilePropTypes } from './ActivityTile';
import { TransferTilePropTypes } from './TransferTile';
import { FlagIcon } from '@src/shared/flag-icon';
import { CommentsMetadata } from '../comment/CommentsMetadata';
import { Checkbox } from 'design-system';

export interface TilePropTypes {
  title: string;
  icon?: React.ReactNode;
  description?: string;
  timing: string;
  variant: string;
  flagged: boolean;
  href: string;
  commentCount: number;
  lastCommentAt?: string;
  mealIncluded?: boolean;
  selectItem?: () => void;
  isSelected?: boolean;
  showCheckbox: boolean;
  children?: (
    | ReactElement<ActivityTilePropTypes>
    | ReactElement<TransferTilePropTypes>
  )[];
}

const BaseTile: FC<TilePropTypes> = (props) => {
  const {
    icon,
    title,
    description,
    timing,
    href,
    children,
    variant,
    flagged,
    commentCount,
    lastCommentAt,
    mealIncluded,
    selectItem,
    isSelected,
    showCheckbox,
    ...others
  } = props;

  const theme = useTheme();
  // Checkbox should not show up if the variant is 'accommodation_trailing',
  // however the gap is still needed for alignment
  const showCheckboxGap = showCheckbox && variant === 'accommodation_trailing';
  const isAccommodationTile =
    variant == 'accommodation_leading' || variant == 'accommodation_trailing';
  const heading = (
    <>
      <FlagIcon flagged={flagged} />
      <Stack
        direction="row"
        gap={2}
        alignItems={'center'}
        textAlign="left"
        sx={{ width: '100%' }}
      >
        {!isAccommodationTile && <Box width="25px">{icon}</Box>}
        <Typography variant="bodySingle">{title}</Typography>
        {mealIncluded && (
          <Typography
            variant="captionPara"
            sx={{ color: theme.palette.brand.sunset, pt: '2px' }}
          >
            Meal Included
          </Typography>
        )}

        <Typography
          variant="captionPara"
          sx={{
            color: 'principal.grey70',
            flexGrow: 1,
          }}
        >
          {description || ''}
        </Typography>
        <CommentsMetadata commentCount={commentCount} lastComment={lastCommentAt} />
        <Typography variant="bodySingle">{timing}</Typography>
      </Stack>
    </>
  );

  if (children) {
    return (
      <Stack direction={'row'} alignItems="start" justifyContent="stretch" gap={0}>
        {showCheckbox && (
          <Box pt={theme.spacing(isAccommodationTile ? 2 : 1)} px={2}>
            <Checkbox
              checked={isSelected}
              sx={{ visibility: showCheckboxGap ? 'hidden' : 'visible' }}
              onChange={selectItem ? () => selectItem() : undefined}
            />
          </Box>
        )}
        <Stack direction={'column'} sx={{ flexGrow: 1 }}>
          <Button
            href={!showCheckbox ? href : undefined}
            onClick={showCheckbox && selectItem ? () => selectItem() : undefined}
            {...others}
            sx={{ marginLeft: 0 }}
          >
            {heading}
          </Button>
          <NestedEngagements gap={1}>{children}</NestedEngagements>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack direction={'row'} alignItems="start" justifyContent="stretch">
      {showCheckbox && (
        <Box pt={theme.spacing(isAccommodationTile ? 2 : 1)} px={2}>
          <Checkbox
            checked={isSelected}
            onChange={selectItem ? () => selectItem() : undefined}
            sx={{ visibility: showCheckboxGap ? 'hidden' : 'visible' }}
          />
        </Box>
      )}
      <Button
        href={!showCheckbox ? href : undefined}
        onClick={showCheckbox && selectItem ? () => selectItem() : undefined}
        {...others}
        sx={{ flexGrow: 1, marginLeft: 0 }}
      >
        {heading}
      </Button>
    </Stack>
  );
};

export const Tile = styled(BaseTile, { name: 'Tile' })(({ theme }) => ({
  borderRadius: '2px',
  border: `1px solid ${theme.palette.principal.grey50}`,
  padding: theme.spacing(3),
  position: 'relative',
  marginLeft: 0,
}));

const NestedEngagements = styled(Stack)(({ theme }) => ({
  paddingBottom: theme.spacing(3),
  backgroundColor: theme.palette.principal.grey30,
}));
