import { Box, Stack, Typography } from '@mui/material';
import { Button, Checkbox } from 'design-system';
import { BulletinList, SkeletonBulletinList } from '@src/design-system/bulletin';
import { CreateButton } from '@src/design-system/create-button/CreateButton';
import { ReactComponent as EmptyDeparturesIcon } from './EmptyDeparturesIcon.svg';
import {
  Scalars,
  DepartureFieldsForListViewFragment,
  AdventureWithItinerariesAndDeparturesDocument,
} from '@flashpack/graphql';
import { FC, useEffect, useState } from 'react';
import { DepartureToolbar } from './DepartureToolbar';
import { FormControlLabel } from '@src/shared/FormControlLabel';
import { BlankSlate } from '@src/design-system/blank-slate/BlankSlate';
import { useQuery } from '@apollo/client';
import isEqual from 'lodash/isEqual';
import { DownloadDeparturesDataButton } from './DownloadDeparturesDataButton';
import { DepartureBulletin } from './DepartureBulletin';
import { DepartureWithAdjustmentsAndFlaggedType } from '@src/adventure/adventure-overview/AdventureDeparturesTab';
import { Search } from '@mui/icons-material';
import { RoutePath } from '@src/shared/routePath';

interface PropTypes {
  addDeparture?: () => void;
  itineraryId?: string;
  adventureId: string;
  adjustedDeparturesData?: DepartureWithAdjustmentsAndFlaggedType[];
  adjustedDeparturesLoading: boolean;
}

export enum DeparturesView {
  ADVENTURE = 'ADVENTURE',
  ITINERARY_VERSION = 'ITINERARY_VERSION',
}

export const DeparturesList: FC<PropTypes> = ({
  addDeparture,
  itineraryId,
  adventureId,
  adjustedDeparturesData,
  adjustedDeparturesLoading = false,
}) => {
  const { data, loading } = useQuery(AdventureWithItinerariesAndDeparturesDocument, {
    variables: { id: adventureId },
  });

  const view = itineraryId ? DeparturesView.ITINERARY_VERSION : DeparturesView.ADVENTURE;

  const [selectedDepartureIds, setSelectedDepartureIds] = useState<
    Array<Scalars['UUID']>
  >([]);
  const [showArchived, setShowArchived] = useState(false);
  const [filterFlagged, setFilterFlagged] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [departures, setDepartures] = useState<DepartureFieldsForListViewFragment[]>([]);

  useEffect(() => {
    if (!data) {
      return;
    }

    const flaggedDepartureIds =
      adjustedDeparturesData
        ?.filter((departure) => departure.flagged)
        .map((departure) => departure.id) ?? [];

    let filtered = data.adventure.itineraries
      .filter((i) => (itineraryId ? i.id === itineraryId : true))
      .flatMap((i) => i.departures)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

    if (!showArchived) {
      filtered = filtered.filter((d) => !d.archived);
    }
    if (filterFlagged) {
      filtered = filtered.filter((d) => flaggedDepartureIds.includes(d.id));
    }
    setDepartures(filtered);
  }, [data, showArchived, itineraryId, filterFlagged, adjustedDeparturesData]);

  useEffect(() => {
    if (!departures.length) {
      setAllSelected(false);
    } else if (selectedDepartureIds.length === departures.length) {
      setAllSelected(true);
    } else {
      setAllSelected(false);
    }
  }, [selectedDepartureIds, departures, setAllSelected]);

  useEffect(() => {
    const departureIds = departures.map((departure) => departure.id);
    const matchingDepartureIds = selectedDepartureIds.filter((id) =>
      departureIds.includes(id),
    );
    if (isEqual(matchingDepartureIds, selectedDepartureIds)) {
      // prevent state from updating to the same value
      return;
    }
    setSelectedDepartureIds(matchingDepartureIds);
  }, [departures, selectedDepartureIds, setSelectedDepartureIds]);

  const onAllSelectedChange = (checked: boolean) => {
    if (checked) {
      setSelectedDepartureIds(departures.map(({ id }) => id));
      setAllSelected(true);
    } else {
      setSelectedDepartureIds([]);
      setAllSelected(false);
    }
  };

  const toggleSelectedDeparture = (departureId: Scalars['UUID']) => {
    if (selectedDepartureIds.includes(departureId)) {
      setSelectedDepartureIds((ids) => ids.filter((id) => id !== departureId));
    } else {
      setSelectedDepartureIds((ids) => [...ids, departureId]);
    }
  };

  // If any selected departures are archived we tell the toolbar for context.
  const selectedArchivedDepartures = departures.filter(
    (x) => x.archived == true && selectedDepartureIds.includes(x.id),
  );

  if (loading) {
    return <SkeletonBulletinList count={5} />;
  }

  if (!data) {
    return null;
  }

  if (departures.length === 0) {
    return (
      <EmptyDepartures
        addDeparture={addDeparture}
        showArchived={showArchived}
        filterFlagged={filterFlagged}
        setShowArchived={setShowArchived}
        setFilterFlagged={setFilterFlagged}
      />
    );
  }

  const alternativeItineraries =
    loading || !data
      ? []
      : data.adventure.itineraries.filter(
          (itinerary) => itinerary.id !== itineraryId && !itinerary.timeline.empty,
        );

  return (
    <Box marginY={3} paddingBottom={6} sx={{ position: 'relative' }}>
      <DepartureToolbar
        selectedDepartureIds={selectedDepartureIds}
        deselectAll={() => setSelectedDepartureIds([])}
        archivable={selectedArchivedDepartures.length == 0}
        alternativeItineraries={alternativeItineraries}
      />
      {addDeparture && (
        <CreateButton
          onClick={addDeparture}
          title="Propose Departures"
          data-testid="propose-departures-button"
        />
      )}
      <Box sx={{ ml: 2.5, mt: 3, mb: 2.5 }}>
        <Stack mt={3} direction="row" justifyContent="space-between">
          <Stack direction="row">
            <FormControlLabel
              label="Select All"
              checked={allSelected}
              control={
                <Checkbox
                  checked={allSelected}
                  onChange={(e) => onAllSelectedChange(e.target.checked)}
                />
              }
            />
            <Button
              variant="outlined"
              href={
                itineraryId
                  ? RoutePath.ITINERARY_BULK_TIMELINE_ITEMS.generatePath(
                      adventureId,
                      itineraryId,
                    )
                  : RoutePath.ADVENTURE_BULK_TIMELINE_ITEMS.generatePath(adventureId)
              }
            >
              <Stack direction="row" gap={1}>
                <Search />
                <Typography>Bulk update</Typography>
              </Stack>
            </Button>
          </Stack>
          <DepartureFilters
            showArchived={showArchived}
            filterFlagged={filterFlagged}
            setShowArchived={setShowArchived}
            setFilterFlagged={setFilterFlagged}
            allDepartureIds={departures.map((d) => d.id)}
          />
        </Stack>
      </Box>
      <BulletinList>
        {departures.map((departure) => (
          <DepartureBulletin
            key={departure.id}
            departureForListView={departure}
            departureWithAdjustmentsAndFlagged={adjustedDeparturesData?.find(
              (departureWithAdjustmentsAndFlagged) =>
                departureWithAdjustmentsAndFlagged.id === departure.id,
            )}
            selected={selectedDepartureIds.includes(departure.id)}
            toggleSelectedDeparture={toggleSelectedDeparture}
            adventureId={adventureId}
            view={view}
            loadingAdjustments={adjustedDeparturesLoading}
          />
        ))}
      </BulletinList>
    </Box>
  );
};

const EmptyDepartures = ({
  addDeparture,
  showArchived,
  filterFlagged,
  setShowArchived,
  setFilterFlagged,
}: {
  addDeparture?: () => void;
  showArchived: boolean;
  filterFlagged: boolean;
  setShowArchived: (v: boolean) => void;
  setFilterFlagged: (v: boolean) => void;
}) => {
  return (
    <>
      <Stack my={2} direction="row" justifyContent="flex-end">
        <DepartureFilters
          showArchived={showArchived}
          filterFlagged={filterFlagged}
          setShowArchived={setShowArchived}
          setFilterFlagged={setFilterFlagged}
          allDepartureIds={[]}
        />
      </Stack>

      {addDeparture && (
        <BlankSlate
          icon={<EmptyDeparturesIcon />}
          message="There are no departures scheduled yet"
          actions={
            <CreateButton
              onClick={addDeparture}
              title="Propose Departures"
              data-testid="propose-departures-button"
            />
          }
        />
      )}
    </>
  );
};

const DepartureFilters = ({
  filterFlagged,
  setFilterFlagged,
  showArchived,
  setShowArchived,
  allDepartureIds,
}: {
  filterFlagged: boolean;
  setFilterFlagged: (v: boolean) => void;
  showArchived: boolean;
  setShowArchived: (v: boolean) => void;
  allDepartureIds: Scalars['UUID'][];
}) => (
  <Stack direction="row" spacing={1}>
    <FormControlLabel
      label="Show Attention Required only"
      checked={filterFlagged}
      control={
        <Checkbox
          checked={filterFlagged}
          onChange={(e) => setFilterFlagged(e.target.checked)}
        />
      }
    />
    <FormControlLabel
      label="Include Archived"
      checked={showArchived}
      control={
        <Checkbox
          checked={showArchived}
          onChange={(e) => setShowArchived(e.target.checked)}
        />
      }
    />
    {allDepartureIds.length > 0 && (
      <DownloadDeparturesDataButton selectedDepartureIds={allDepartureIds} size="large" />
    )}
  </Stack>
);
