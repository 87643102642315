import React from 'react';
import { styled } from '@mui/material';
import { Typography, TypographyProps, useTheme, useMediaQuery } from 'design-system';

type PillVariant = 'standard' | 'success' | 'warning' | 'error';

interface PillProps extends Omit<TypographyProps, 'variant'> {
  variant?: PillVariant;
  textVariant?: TypographyProps['variant'];
}

export const Pill: React.FC<PillProps> = ({
  variant = 'standard',
  textVariant = 'Body M',
  children,
  ...props
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <StyledTypography
      pillVariant={variant}
      variant={textVariant}
      typography={{ xs: 'Body S', sm: 'Body M' }}
      isMobile={isSmallScreen}
      {...props}
    >
      {children}
    </StyledTypography>
  );
};

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'pillVariant',
})<{ pillVariant: PillVariant; isMobile: boolean }>(
  ({ theme, pillVariant, isMobile }) => ({
    borderRadius: '16px',
    textTransform: 'none',
    padding: isMobile ? '4px 12px' : '2px 10px',
    fontWeight: 'normal',
    maxWidth: 'fit-content',
    maxHeight: 'fit-content',
    color: theme.palette.text.primary,

    ...(pillVariant === 'standard' && {
      backgroundColor: theme.palette.principal.grey30,
    }),
    ...(pillVariant === 'success' && {
      backgroundColor: theme.palette.system.green10,
    }),
    ...(pillVariant === 'warning' && {
      backgroundColor: theme.palette.system.amber30,
    }),
    ...(pillVariant === 'error' && {
      backgroundColor: theme.palette.system.red10,
    }),
  }),
);
