import { FC, useState } from 'react';
import EdgeSidebar from '@src/shared/edge-sidebar/EdgeSidebar';
import MessageThreadsBody from '../../message-threads/MessageThreadsBody';
import { ChatIcon } from 'design-system';
import { useDrawerContext } from '@src/shared/context/DrawerContext';
import { hasCurrentMessageThreadHash } from '../../itinerary/util';
import { useLocation } from 'react-router-dom';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { useQuery } from '@apollo/client';
import { AdventureWithItineraryTitleDocument } from '@flashpack/graphql';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { Box, Button, Typography } from '@mui/material';
import { flashPackTheme } from '@src/design-system/theme';
import { ItinerarySelect } from '@src/adventure/common/ItinerarySelect';

export const AdventureRightEdgeSidebar: FC = () => {
  const [isNewThreadExpanded, setIsNewThreadExpanded] = useState<boolean>(false);
  const [itineraryId, setItineraryId] = useState<string>('');
  const { setSidebarOpen } = useDrawerContext();
  const { hash } = useLocation();
  const hasMessageThread = hasCurrentMessageThreadHash(hash);
  const { adventureId } = useRequiredParams(['adventureId']);

  if (hasMessageThread) {
    setSidebarOpen(true);
  }

  const { data } = useQuery(AdventureWithItineraryTitleDocument, {
    variables: { adventureId },
  });

  const onClick = () => {
    setIsNewThreadExpanded(true);
  };

  const itineraryOptions = data?.adventure.itineraries
    .filter(({ archived }) => !archived)
    .map(({ id, title }) => ({
      label: title,
      value: id,
    }));

  return (
    <EdgeSidebar
      onClose={() => {
        setSidebarOpen(false);
        setItineraryId('');
        window.location.hash = '';
      }}
      anchor="right"
      headerChildren={
        <ItinerarySelect
          itineraryOptions={itineraryOptions || []}
          itineraryId={itineraryId}
          setItineraryId={setItineraryId}
        />
      }
      icon={<ChatIcon data-testid="open-message-threads-sidebar" />}
    >
      {itineraryId ? (
        <>
          <Button
            onClick={onClick}
            variant="outlined"
            data-testid="add-message-thread"
            sx={{ mb: 2 }}
          >
            <AddCircleOutlineOutlinedIcon /> &nbsp; Start a Thread
          </Button>
          <MessageThreadsBody
            setIsNewThreadExpanded={setIsNewThreadExpanded}
            isNewThreadExpanded={isNewThreadExpanded}
            itineraryId={itineraryId}
          />
        </>
      ) : (
        <Box textAlign="center" overflow="scroll" marginTop={flashPackTheme.spacing(3)}>
          <Typography padding={2} variant="bodySingle" color="GrayText">
            Select itinerary above
          </Typography>
        </Box>
      )}
    </EdgeSidebar>
  );
};

export default AdventureRightEdgeSidebar;
