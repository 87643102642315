import { FC, useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { CreateMessageThreadForm } from '@src/message-threads/CreateMessageThreadForm';
import { useQuery } from '@apollo/client';

import { ItineraryWithMessageThreadsDocument } from '@flashpack/graphql';
import { MessagesSection } from '@src/itinerary/common/messages/MessagesSection';
import { MessageThreadCard } from './MessageThreadCard';
import { useLocation } from 'react-router-dom';
import { currentMessageThreadIdFromHash } from '@src/itinerary/util';

type MessageThreadsBodyProps = {
  setIsNewThreadExpanded: (v: boolean) => void;
  isNewThreadExpanded: boolean;
  itineraryId: string;
};

export const MessageThreadsBody: FC<MessageThreadsBodyProps> = ({
  setIsNewThreadExpanded,
  isNewThreadExpanded,
  itineraryId,
}) => {
  const { data } = useQuery(ItineraryWithMessageThreadsDocument, {
    variables: { itineraryId },
  });

  const { hash } = useLocation();
  const [selectedMessageThreadId, setSelectedMessageThreadId] = useState<string | null>();

  useEffect(() => {
    if (!(hash == null || hash.length == 0)) {
      setSelectedMessageThreadId(currentMessageThreadIdFromHash(hash));
    }
  }, [hash]);

  if (!data) {
    return (
      <Box textAlign="center" overflow="scroll">
        {isNewThreadExpanded && (
          <CreateMessageThreadForm
            setIsNewThreadExpanded={setIsNewThreadExpanded}
            itineraryId={itineraryId}
          />
        )}
        <Typography padding={2} variant="bodySingle" color="GrayText">
          Failed to fetch message threads
        </Typography>
      </Box>
    );
  }

  const {
    itinerary: { messageThreads },
  } = data;

  return (
    <Box textAlign="center" overflow="scroll">
      {isNewThreadExpanded && (
        <CreateMessageThreadForm
          setIsNewThreadExpanded={setIsNewThreadExpanded}
          itineraryId={itineraryId}
        />
      )}
      {!messageThreads.length && (
        <Typography padding={2} variant="bodySingle" color="GrayText">
          Start a new thread
        </Typography>
      )}
      {messageThreads.length > 0 && (
        <Stack gap={2} paddingBottom={2}>
          {!selectedMessageThreadId &&
            messageThreads.map((thread, index) => (
              <MessageThreadCard
                key={index}
                thread={thread}
                index={index}
                setSelectedMessageThreadId={setSelectedMessageThreadId}
              />
            ))}
          {selectedMessageThreadId && (
            <MessagesSection
              threadId={selectedMessageThreadId}
              onCancel={() => {
                setSelectedMessageThreadId(null);
                window.location.hash = '';
              }}
            ></MessagesSection>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default MessageThreadsBody;
