import { Typography, styled } from '@mui/material';
import { Stack } from '@mui/system';
import { flashPackTheme } from '@src/design-system/theme';
import { FC } from 'react';
import { ChangeDetail } from './utils';

type LayoutAndDescription = {
  changeDescription: string;
  layout: {
    direction: 'column' | 'row';
    display: 'block' | 'none';
  };
};

const verticalLayoutProperties: LayoutAndDescription['layout'] = {
  direction: 'column',
  display: 'block',
};
const horizontalLayoutProperties: LayoutAndDescription['layout'] = {
  direction: 'row',
  display: 'block',
};
const noDisplayLayoutProperties: LayoutAndDescription['layout'] = {
  direction: 'row',
  display: 'none',
};

const propertyLayouts: Record<ChangeDetail['property'], LayoutAndDescription> = {
  title: { changeDescription: 'Title', layout: verticalLayoutProperties },
  checkInDay: { changeDescription: 'Check in day', layout: horizontalLayoutProperties },
  checkOutDay: { changeDescription: 'Check out day', layout: horizontalLayoutProperties },
  checkInTime: { changeDescription: 'Check in time', layout: horizontalLayoutProperties },
  checkOutTime: {
    changeDescription: 'Check out time',
    layout: horizontalLayoutProperties,
  },
  startDay: { changeDescription: 'Start day', layout: horizontalLayoutProperties },
  endDay: { changeDescription: 'End day', layout: horizontalLayoutProperties },
  startTime: { changeDescription: 'Start time', layout: horizontalLayoutProperties },
  endTime: { changeDescription: 'End time', layout: horizontalLayoutProperties },
  startLocation: {
    changeDescription: 'Start location',
    layout: verticalLayoutProperties,
  },
  endLocation: { changeDescription: 'End location', layout: verticalLayoutProperties },
  singleRoomName: {
    changeDescription: 'Single room name',
    layout: verticalLayoutProperties,
  },
  twinRoomName: { changeDescription: 'Twin room name', layout: verticalLayoutProperties },
  allocationSingleRoom: {
    changeDescription: 'Single room allocation',
    layout: horizontalLayoutProperties,
  },
  allocationTwinRoom: {
    changeDescription: 'Twin room allocation',
    layout: horizontalLayoutProperties,
  },
  foodAndDrink: {
    changeDescription: 'Food and drink',
    layout: noDisplayLayoutProperties,
  },
  mealIncluded: { changeDescription: 'Meal', layout: verticalLayoutProperties },
  challenges: { changeDescription: 'Challenges', layout: noDisplayLayoutProperties },
  mode: { changeDescription: 'Mode', layout: noDisplayLayoutProperties },
  description: { changeDescription: 'Description', layout: noDisplayLayoutProperties },
};

export const ChangeDetailDisplay: FC<ChangeDetail> = ({
  property,
  oldValue,
  newValue,
}) => {
  const layoutAndDescription = propertyLayouts[property];

  return (
    <Stack direction="column" marginBottom={flashPackTheme.spacing(1)}>
      <DetailTitle>{layoutAndDescription.changeDescription} updated</DetailTitle>
      <Stack direction={layoutAndDescription.layout.direction}>
        <Typography
          variant="bodyPara"
          sx={{
            textDecoration: 'line-through',
            color: flashPackTheme.palette.principal.grey50,
            display: layoutAndDescription.layout.display,
            lineHeight: '18px',
            mr:
              layoutAndDescription.layout.direction === 'row'
                ? flashPackTheme.spacing(1)
                : null,
          }}
        >
          {oldValue}
        </Typography>
        <Typography
          variant="bodyPara"
          sx={{ display: layoutAndDescription.layout.display, lineHeight: '18px' }}
        >
          {newValue}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const DetailTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.principal.grey70,
  variant: 'captionSingle',
  marginTop: '0',
  fontSize: '12px',
  lineHeight: '16px',
}));
