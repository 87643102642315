import { HTMLAttributes } from 'react';
import { Container, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DaySelector } from 'src/design-system/forms/day-selector/DaySelector';
import { TimeField } from 'src/design-system/forms/time-field/TimeField';

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

interface PropTypes extends HTMLAttributes<HTMLElement> {
  readonly?: boolean;
  isCheckInDayMixed?: boolean;
  isCheckOutDayMixed?: boolean;
  isBulk?: boolean;
}

export const CheckInOutPicker = ({
  readonly,
  isCheckInDayMixed,
  isCheckOutDayMixed,
  isBulk,
  ...props
}: PropTypes) => {
  return (
    <Container {...props} disableGutters>
      <Heading sx={{ color: 'principal.grey70' }}>Check-in (local time)</Heading>
      <NaiveDayTime
        name="checkIn"
        data-testid="checkIn"
        readonly={readonly}
        isDayMixed={isCheckInDayMixed}
        isBulk={isBulk}
      />
      <Heading sx={{ color: 'principal.grey70' }}>Check-out (local time)</Heading>
      <NaiveDayTime
        name="checkOut"
        linkedStartDayField="checkIn"
        data-testid="checkOut"
        readonly={readonly}
        isDayMixed={isCheckOutDayMixed}
        isBulk={isBulk}
      />
    </Container>
  );
};

type NaiveDayTimeProps = HTMLAttributes<HTMLElement> & {
  name: string;
  linkedStartDayField?: string;
  readonly?: boolean;
  isDayMixed?: boolean;
  isBulk?: boolean;
};

export const NaiveDayTime = ({
  name,
  linkedStartDayField,
  readonly,
  isDayMixed,
  isBulk,
  ...htmlAttrs
}: NaiveDayTimeProps) => (
  <div {...htmlAttrs}>
    <Stack direction="row" spacing={2}>
      <DaySelector
        name={`${name}.day`}
        linkedStartDayField={linkedStartDayField && `${linkedStartDayField}.day`}
        readonly={readonly}
        isMixed={isDayMixed}
        isBulk={isBulk}
      />
      <TimeField name={`${name}.time`} readonly={readonly} />
    </Stack>
  </div>
);
