import { TripPricesTable } from './TripPricesTable';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { EarlyBirdDiscountsTable } from './EarlyBirdDiscountsTable';
import { useCallback } from 'react';
import { GenericError } from 'design-system';
import { SelectViewDropdown } from '@src/shared/table/SelectViewDropdown';
import { RoutePath } from '@src/shared/routePath';
import { PromotionalDiscountsTable } from './PromotionalDiscountsTable';

const tableViews = [
  {
    path: 'tripPrice',
    label: 'Trip Price',
    component: TripPricesTable,
  },
  {
    path: 'earlyBirdDiscount',
    label: 'Early Bird Discount',
    component: EarlyBirdDiscountsTable,
  },
  {
    path: 'promotionalDiscount',
    label: 'Promotional Discount',
    component: PromotionalDiscountsTable,
  },
];

const viewOptions = tableViews.map((v) => {
  return {
    value: v.path,
    label: v.label,
  };
});

export const PricesPage = () => {
  const { adventureId, itineraryId, view } = useRequiredParams([
    'adventureId',
    'itineraryId',
    'view',
  ]);

  const currentView = tableViews.find((x) => x.path === view);

  const renderCanton = useCallback(
    (hasPendingChanges: boolean) => {
      return (
        <SelectViewDropdown
          hasPendingChanges={hasPendingChanges}
          adventureId={adventureId}
          itineraryId={itineraryId}
          view={view}
          viewOptions={viewOptions}
          routePath={RoutePath.ITINERARY_PRICES}
        />
      );
    },
    [adventureId, itineraryId, view],
  );

  if (!currentView) {
    return <GenericError error="The view does not exist" />;
  }

  return <currentView.component renderCanton={renderCanton} />;
};
