import { DepartureCancellationReason, DepartureStatus } from '@flashpack/graphql';

export const statusLabel = (status: DepartureStatus) => {
  switch (status) {
    case DepartureStatus.OnSale:
      return 'On Sale';
    case DepartureStatus.SyncRequired:
      return 'Proposed';
    case DepartureStatus.OnRequest:
      return 'On Request';
    case DepartureStatus.SoldOut:
      return 'Sold Out';
    case DepartureStatus.Guaranteed:
      return 'Guaranteed';
    case DepartureStatus.Departed:
      return 'Departed';
    case DepartureStatus.Completed:
      return 'Completed';
    case DepartureStatus.Cancelled:
      return 'Cancelled';
    default:
      return status;
  }
};

export const cancellationReasonOptions: Record<DepartureCancellationReason, string> = {
  [DepartureCancellationReason.DmcCancellation]: 'DMC cancellation',
  [DepartureCancellationReason.FlashpackCancellation]: 'Flash Pack cancellation',
  [DepartureCancellationReason.ConflictZone]: 'Conflict zone',
  [DepartureCancellationReason.TourDiscontinued]: 'Tour discontinued',
  [DepartureCancellationReason.UnableToSecure]: 'Unable to secure',
  [DepartureCancellationReason.Other]: 'Other (include note)',
};

export const cancellationReasonTooltip = (
  status: DepartureStatus,
  cancellationReason?: DepartureCancellationReason | null,
  cancellationReasonNote?: string | null,
) => {
  if (status !== DepartureStatus.Cancelled || !cancellationReason) {
    return '';
  }
  if (cancellationReason === DepartureCancellationReason.Other) {
    return cancellationReasonNote;
  }
  return cancellationReasonOptions[cancellationReason];
};
