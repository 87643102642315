import { FC } from 'react';
import { Stack } from '@mui/material';
import { BackButton, PageHeading } from 'design-system';
import { useQuery } from '@apollo/client';
import { AdventureWithSingleItineraryDocument, Scalars } from '@flashpack/graphql';
import { RoutePath } from '@src/shared/routePath';

interface PropTypes {
  adventureId: Scalars['UUID'];
  itineraryId?: Scalars['UUID'];
}

export const BulkUpdateTimelineHeader: FC<PropTypes> = ({ adventureId, itineraryId }) => {
  const { data } = useQuery(AdventureWithSingleItineraryDocument, {
    variables: {
      adventureId,
      itineraryId,
    },
  });

  const backLabel = itineraryId
    ? `${data?.adventure.title} (${data?.adventure.bookingEngineTourCode}) • ${data?.adventure.itineraryById?.description}`
    : `${data?.adventure.title} • ${data?.adventure.bookingEngineTourCode}`;

  const backHref = itineraryId
    ? RoutePath.ITINERARY_DEPARTURES.generatePath(adventureId, itineraryId)
    : RoutePath.ADVENTURE_DEPARTURES.generatePath(adventureId);

  return (
    <>
      <BackButton href={backHref} data-testid="departure-back-button">
        {backLabel}
      </BackButton>
      <Stack
        direction={'row'}
        spacing={2}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Stack direction={'row'} spacing={2}>
          <PageHeading data-testid="adventure-code">Bulk Update</PageHeading>
        </Stack>
      </Stack>
    </>
  );
};
