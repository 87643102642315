import { Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChainedText } from 'design-system';

export const Footer = () => {
  const parts = [
    <FooterLink
      variant="label"
      target="_blank"
      href="https://www.notion.so/flashpacktravel/Flash-Pack-Adventure-Manager-3f45b40db0eb43089861611722b5e0ab?pvs=4"
      key={0}
    >
      Guides & Tutorials
    </FooterLink>,
    <FooterLink
      variant="label"
      target="_blank"
      href="https://www.flashpack.com/privacy-policy"
      key={1}
    >
      Privacy Policy
    </FooterLink>,
  ];

  return (
    <StyledBox>
      <ChainedText parts={parts} sizeRelativeToText={1.1} />
    </StyledBox>
  );
};

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingBottom: theme.spacing(2),
  paddingTop: theme.spacing(3),
  marginTop: theme.spacing(6),
  justifyContent: 'center',
  alignItems: 'center',
  background: 'white',
  lineHeight: '13px',
  '& .MuiSvgIcon-root': {
    fontSize: '8px',
  },
}));

const FooterLink = styled(Link)(({ theme }) => ({
  color: theme.palette.principal.grey70,
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.brand.jungle,
  },
  lineHeight: '22px',
}));
