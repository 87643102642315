export enum Environment {
  Development = 'Local Development',
  Production = 'Production',
  Sandbox = 'Sandbox',
  Preview = 'Preview',
}

export const currentEnvironment: Environment = (() => {
  switch (import.meta.env.VITE_SENTRY_ENVIRONMENT) {
    case 'development':
    case undefined:
      return Environment.Development;
    case 'pr-preview':
      return Environment.Preview;
    case 'sandbox':
      return Environment.Sandbox;
    case 'production':
      return Environment.Production;
  }
})();

export function environmentIs(environment: Environment) {
  return environment === currentEnvironment;
}
