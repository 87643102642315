import { ReactNode, FC } from 'react';
import { Container, Typography, Button, Stack, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { styled } from '@mui/material/styles';
import {
  NaiveDayTimeInput,
  TimelineDocument,
  AddManualAccommodationToTimelineDocument,
} from '@flashpack/graphql';
import { GenericError, FreeTextFormField } from 'design-system';
import { ApolloError, useMutation } from '@apollo/client';
import { CheckInOutPicker } from '../../check-in-out-picker';
import { useRouting } from 'src/shared/useRouting';
import { hasServerValidationErrors } from 'src/shared/errorUtils';
import { ServerValidationErrors } from '@src/design-system/forms/ServerValidationErrors';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useTimelineContext } from '@src/shared/timeline/TimelineContext';
import { TextField } from 'mui-rff';
import { NumberField } from '@src/design-system/forms/number-field/NumberField';

interface FormPropTypes {
  onBack: () => void;
}

export const UnboundAddManualAccommodationForm: FC<FormPropTypes> = ({ onBack }) => {
  const [addManualAccommodationToItineraryMutation, { error: mutationErrors }] =
    useMutation(AddManualAccommodationToTimelineDocument, {
      refetchQueries: [TimelineDocument],
    });

  const { safeMutation } = useSafeMutation();
  const { navigate } = useRouting();
  const { timelineId } = useTimelineContext();

  if (mutationErrors && !hasServerValidationErrors(mutationErrors)) {
    return <GenericError error={mutationErrors} />;
  }

  return (
    <Form
      onSubmit={async ({
        checkIn,
        checkOut,
        description,
        roomDescriptorSingle,
        roomDescriptorTwin,
        allocationSingleRoom,
        allocationTwinRoom,
      }: {
        checkIn: NaiveDayTimeInput;
        checkOut: NaiveDayTimeInput;
        description: string;
        roomDescriptorSingle: string;
        roomDescriptorTwin: string;
        allocationSingleRoom: number;
        allocationTwinRoom: number;
      }) => {
        const { error } = await safeMutation(
          addManualAccommodationToItineraryMutation({
            variables: {
              accommodation: {
                checkIn,
                checkOut,
                description,
                roomDescriptorSingle,
                roomDescriptorTwin,
                allocationSingleRoom,
                allocationTwinRoom,
              },
              timelineId,
            },
          }),
          {
            onSuccess: () => navigate('../'),
          },
        );

        if (error instanceof ApolloError) {
          return { [FORM_ERROR]: error };
        }
      }}
      validate={({ checkOut, checkIn }) => {
        if (checkIn?.day >= checkOut?.day) {
          return {
            checkOut: { day: 'Must be after check in' },
            checkIn: { day: 'Must be before check out' },
          };
        }
      }}
      render={({ handleSubmit, submitting }) => (
        <form
          data-testid="form.manualacc"
          onSubmit={(e) => {
            void handleSubmit(e);
          }}
        >
          <Container maxWidth="sm">
            <Typography variant="pageHeader">Add Accommodation</Typography>
            <Typography variant="bodySingle">(Manual)</Typography>
            <ManualAccommodationFields />
            <ServerValidationErrors error={mutationErrors} />
            <Buttons>
              <Button onClick={onBack} variant="outlined" sx={{ marginRight: 2 }}>
                Back
              </Button>
              <LoadingButton variant="contained" type="submit" loading={submitting}>
                Add to itinerary
              </LoadingButton>
            </Buttons>
          </Container>
        </form>
      )}
    />
  );
};

export const ManualAccommodationFields: FC<{
  children?: ReactNode;
  readonly?: boolean;
  skipName?: boolean;
  isBulk?: boolean;
  areAllAccomodationsManual?: boolean;
  isCheckInDayMixed?: boolean;
  isCheckOutDayMixed?: boolean;
}> = ({
  children,
  readonly,
  skipName,
  isBulk,
  areAllAccomodationsManual = true,
  isCheckInDayMixed,
  isCheckOutDayMixed,
}) => {
  return (
    <>
      {skipName !== true && (
        <Box>
          <Heading sx={{ color: 'principal.grey70' }}>Accommodation name</Heading>
          <FreeTextFormField
            name="description"
            placeholder="eg. Amazonian Treehouses"
            testid="hotel-name-input"
            readonly={readonly}
            required
          />
        </Box>
      )}

      <Stack direction="row" justifyContent="space-between">
        <Heading sx={{ color: 'principal.grey70' }}>Rooms</Heading>
        <Heading sx={{ color: 'principal.grey70' }}>Allocations</Heading>
      </Stack>
      <Stack direction="row" gap={2}>
        <TextField
          name="roomDescriptorTwin"
          placeholder="eg. Tent on a jeep"
          data-testid="room-twin-input"
          disabled={readonly || !areAllAccomodationsManual}
          onFocus={(event) => event.target.select()}
          helperText=" "
        />
        <NumberField
          name="allocationTwinRoom"
          testid="allocation-twin-room"
          disabled={readonly}
          required
          minimumValue={0}
        />
      </Stack>
      <Stack direction="row" gap={2}>
        <TextField
          name="roomDescriptorSingle"
          placeholder="eg. Double superior treehouse"
          data-testid="room-single-input"
          disabled={readonly || !areAllAccomodationsManual}
          onFocus={(event) => event.target.select()}
          helperText=" "
        />
        <NumberField
          name="allocationSingleRoom"
          testid="allocation-single-room"
          disabled={readonly}
          required
          minimumValue={0}
        />
      </Stack>

      <CheckInOutPicker
        readonly={readonly}
        isBulk={isBulk}
        isCheckInDayMixed={isCheckInDayMixed}
        isCheckOutDayMixed={isCheckOutDayMixed}
      />
      {children}
    </>
  );
};

const Buttons = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(7),
  marginBottom: theme.spacing(6),
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(2),
}));
