import { FC, useState } from 'react';
import EdgeSidebar from '@src/shared/edge-sidebar/EdgeSidebar';
import MessageThreadsHeader from '../../message-threads/MessageThreadsHeader';
import MessageThreadsBody from '../../message-threads/MessageThreadsBody';
import { ChatIcon } from 'design-system';
import { useDrawerContext } from '@src/shared/context/DrawerContext';
import { hasCurrentMessageThreadHash } from '../util';
import { useLocation } from 'react-router-dom';
import { useRequiredParams } from '@src/shared/useRequiredParams';

export const ItineraryRightEdgeSidebar: FC = () => {
  const [isNewThreadExpanded, setIsNewThreadExpanded] = useState<boolean>(false);
  const { setSidebarOpen } = useDrawerContext();
  const { hash } = useLocation();
  const hasMessageThread = hasCurrentMessageThreadHash(hash); // TODO: Map hashed messages to adventure level threads no itinerary level threads
  const { itineraryId } = useRequiredParams(['itineraryId']);
  if (hasMessageThread) {
    setSidebarOpen(true);
  }
  return (
    <EdgeSidebar
      onClose={() => {
        setSidebarOpen(false);
        window.location.hash = '';
      }}
      anchor="right"
      headerChildren={
        <MessageThreadsHeader onClick={() => setIsNewThreadExpanded(true)} />
      }
      icon={<ChatIcon data-testid="open-message-threads-sidebar" />}
    >
      <MessageThreadsBody
        setIsNewThreadExpanded={setIsNewThreadExpanded}
        isNewThreadExpanded={isNewThreadExpanded}
        itineraryId={itineraryId}
      />
    </EdgeSidebar>
  );
};

export default ItineraryRightEdgeSidebar;
