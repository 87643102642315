import { countries } from '../../countries/worlds';
import {
  CountryCode,
  getCountryCallingCode,
  isSupportedCountry,
} from 'libphonenumber-js';

export type CountryCallingCodeOption = {
  id: string; // Unique identifier
  callingCode: string;
  label: string;
  alpha2: string;
  countryName: string;
};

const mappedCallingCodes = new Set<string>([]);

export const countriesCallingCodesAll = countries
  .filter((country) => isSupportedCountry(country.alpha2.toUpperCase()))
  .map<CountryCallingCodeOption>((country, index) => {
    const callingCode = getCountryCallingCode(
      country.alpha2.toUpperCase() as CountryCode,
    );

    return {
      id: `${country.alpha2}:+${callingCode}:${index}`, // Unique identifier
      callingCode: `+${callingCode}`, // can be duplicated (e.g. US, Canada both have +1)
      label: `+${callingCode}`,
      alpha2: country.alpha2,
      countryName: country.name,
    };
  });

/**
 * An unordered list of unique country calling codes with their respective labels.
 * Unique - not to repeat the same calling code for multiple countries (e.g. US, Canada, LatAm).
 */
const countriesCallingCodesUnique = countriesCallingCodesAll.filter((country) => {
  if (mappedCallingCodes.has(country.callingCode)) {
    return false;
  }

  mappedCallingCodes.add(country.callingCode);
  return true;
});

// we use alpha code as a unique filter
// because calling code can be duplicated - UK, Guernsey and Jersey all have +44
// as well as USA, Canada and American Samoa all have +1
const UK_ALPHA_CODE = 'gb';
const US_ALPHA_CODE = 'us';

/**
 * An ordered list of non-unique country calling codes with their respective labels.
 * All countries are present, some of the calling codes may be duplicated.
 * Sorted by country names.
 */
export const orderedCallingCodes = [
  // we hoist UK to the top
  countriesCallingCodesAll.find((c) => c.alpha2 === UK_ALPHA_CODE),
  countriesCallingCodesAll.find((c) => c.alpha2 === US_ALPHA_CODE),
  ...countriesCallingCodesAll
    .sort((a, b) => a.countryName.localeCompare(b.countryName))
    // and then remove it to avoid duplication
    .filter((c) => c.alpha2 !== UK_ALPHA_CODE && c.alpha2 !== US_ALPHA_CODE),
] as Array<CountryCallingCodeOption>;

export const getCountryForCallingCode = (callingCode: string) => {
  const match = countriesCallingCodesUnique.find((c) => c.callingCode === callingCode);
  return match?.alpha2.toUpperCase();
};

export const getCallingCodeForCountry = (countryCode: string) => {
  const match = countriesCallingCodesAll.find(
    (c) => c.alpha2.toUpperCase() === countryCode.toUpperCase(),
  );
  return match?.callingCode;
};
