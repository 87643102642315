import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { ConfirmationDialog } from '@src/design-system/dialogs/ConfirmationDialog';
import { InformationIcon } from 'design-system';
import { FC, SetStateAction } from 'react';

type PropTypes = {
  isDialogOpen: boolean;
  setIsDialogOpen: (value: SetStateAction<boolean>) => void;
  title: string;
  body: string;
};

export const TableDialogBox: FC<PropTypes> = ({
  isDialogOpen,
  setIsDialogOpen,
  title,
  body,
}) => {
  return (
    <ConfirmationDialog
      title={title}
      cancelLabel="Close"
      open={isDialogOpen}
      onCancel={() => setIsDialogOpen(false)}
      hasCustomActions={true}
      maxWidth="sm"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <InformationIcon />
        <Box sx={{ paddingX: '17px' }}>{body}</Box>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          data-testid="table-dialog-close"
          onClick={() => setIsDialogOpen(false)}
        >
          Close
        </Button>
      </Box>
    </ConfirmationDialog>
  );
};
