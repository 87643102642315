import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type DrawerContextType = {
  timelineDrawerOpen: boolean;
  setTimelineDrawerOpen: Dispatch<SetStateAction<boolean>>;
  sidebarOpen: boolean;
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
};

const DrawerContext = createContext<DrawerContextType>({
  timelineDrawerOpen: false,
  setTimelineDrawerOpen: () => {},
  sidebarOpen: false,
  setSidebarOpen: () => {},
});

type DrawerContextProps = {
  /**
   * The content of the component.
   */
  children?: ReactNode;
};

export const DrawerContextProvider: FC<DrawerContextProps> = ({ children }) => {
  const [timelineDrawerOpen, setTimelineDrawerOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  return (
    <DrawerContext.Provider
      value={{
        timelineDrawerOpen,
        setTimelineDrawerOpen,
        sidebarOpen,
        setSidebarOpen,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawerContext = () => useContext(DrawerContext);
