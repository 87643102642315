import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { LinksFragment, Maybe, UserRole } from '@flashpack/graphql';
import { FC } from 'react';
import TimelineIcon from '@mui/icons-material/Timeline';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { QuestionAnswer } from '@mui/icons-material';
import { Protected } from '@src/authentication/Protected';
import DiningIcon from '@mui/icons-material/Dining';
import { captureAdventureLinkClick } from '@src/fullStory';

export const AdventureLinks: FC<LinksFragment> = ({
  bookingsDashboardUrl,
  faqUrl,
  feedbackUrl,
  dietaryRequirementsUrl,
}) => {
  return (
    <Stack
      gap={1}
      direction="row"
      alignItems={'center'}
      flexWrap="wrap"
      justifyContent={'flex-end'}
    >
      <AdventureLink title="Feedback" url={feedbackUrl} icon={<TimelineIcon />} />
      <Protected roles={[UserRole.Flashpack]}>
        <AdventureLink title="FAQ" url={faqUrl} icon={<QuestionAnswer />} />
      </Protected>
      <AdventureLink
        title="Dietary Reqs"
        url={dietaryRequirementsUrl}
        icon={<DiningIcon />}
      />
      <AdventureLink
        title="Bookings"
        url={bookingsDashboardUrl}
        icon={<LeaderboardIcon />}
      />
    </Stack>
  );
};

const AdventureLink = ({
  title,
  url,
  icon,
}: {
  title: string;
  url: Maybe<string> | undefined;
  icon: JSX.Element;
}) => {
  if (!url) {
    return null;
  }

  return (
    <Button
      startIcon={icon}
      href={url}
      onClick={() => {
        captureAdventureLinkClick(title);
      }}
      variant="outlined"
      size="small"
      target="_blank"
    >
      {title}
    </Button>
  );
};
