import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { ButtonContainer } from '@src/design-system/bulletin';
import { FlagIcon } from '@src/shared/flag-icon';
import { StopPropagationWrapper } from '@src/design-system/StopPropagationWrapper';
import { flashPackTheme } from '@src/design-system/theme';
import { CheckboxIcon } from '@src/shared/checkbox/checkbox';
import { FC } from 'react';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

interface TimelineEventBoxProps {
  children: React.ReactNode;
  icon: React.ReactNode;
  title: string;
  subtitle?: string;
  greyBoxTitle?: string;
  selected: boolean;
  onSelect?: () => void;
  flagged?: boolean;
  adjusted?: boolean;
  testid?: string;
}

export const TimelineEventBox: FC<TimelineEventBoxProps> = ({
  children,
  icon, // Icon that appears on the top-right side of the box
  title,
  subtitle, // Grey text that appears below the title
  greyBoxTitle, // Text that appears in the grey box on the top right side of the box
  selected, // Whether the box is selected using the checkbox
  onSelect,
  flagged = false, // In case the event in the box has been flagged for attention
  adjusted = false, // In case the event in the box has been adjusted compared to the parent itinerary
  testid,
}) => {
  return (
    <ButtonContainer
      sx={{
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 1,
        textAlign: 'left',
      }}
      selected={selected}
      flagged={flagged}
      onClick={onSelect}
      data-testid={testid}
    >
      <FlagIcon flagged={flagged} />
      <Stack direction="row" justifyContent="space-around" width="100%">
        <StopPropagationWrapper allowDefault={true}>
          <CheckboxIcon onSelect={onSelect} selected={selected} />
        </StopPropagationWrapper>
        <Stack direction="column" width="100%" py={1}>
          <Stack direction="row" justifyContent="space-between" paddingBottom={1}>
            <Stack direction="column">
              <Typography variant="bodySingle">{title}</Typography>
              {subtitle && (
                <Typography
                  variant="bodySingle"
                  color={flashPackTheme.palette.principal.grey70}
                >
                  {subtitle}
                </Typography>
              )}
            </Stack>
            <Stack direction="row" spacing={2}>
              {adjusted && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: flashPackTheme.palette.principal.white,
                    borderRadius: '50%',
                    height: '24px',
                    width: '24px',
                    borderColor: flashPackTheme.palette.system.amber100,
                    borderStyle: 'solid',
                    borderWidth: '2px',
                    paddingBottom: '3px',
                  }}
                >
                  <WarningAmberRoundedIcon
                    sx={{ height: '20px' }}
                    htmlColor={flashPackTheme.palette.system.amber100}
                  />
                </Box>
              )}
              {greyBoxTitle && (
                <Box
                  sx={{
                    backgroundColor: flashPackTheme.palette.principal.grey70,
                    color: flashPackTheme.palette.principal.white,
                    paddingX: 1,
                    borderRadius: 1,
                    maxHeight: '24px',
                  }}
                >
                  {greyBoxTitle}
                </Box>
              )}
            </Stack>
          </Stack>
          {children}
        </Stack>
        <Stack textAlign="right" p={1}>
          {icon}
        </Stack>
      </Stack>
    </ButtonContainer>
  );
};
