import { useEffect, useState } from 'react';
import { Link, Navigate, useLocation } from 'react-router-dom';
import {
  useAuthentication,
  useFirebaseAuth,
} from '@src/shared/authentication/useAuthentication';
import { CircularProgress } from '@mui/material';
import { Info } from '@src/authentication/Info';
import { LocationState } from '@src/shared/useRouting';
import { RoutePath } from '@src/shared/routePath';

export const LoginCallback = () => {
  const { isSignInLink, signInWithEmailLink } = useFirebaseAuth();
  const { authenticated } = useAuthentication();
  const [loginError, setLoginError] = useState<Error | null>();
  const location = useLocation();
  const shouldAuthenticate = isSignInLink() && !authenticated;

  useEffect(() => {
    if (shouldAuthenticate) {
      signInWithEmailLink().catch((e: Error) => {
        setLoginError(e);
      });
    }
  }, [shouldAuthenticate, signInWithEmailLink]);

  if (authenticated) {
    const redirectBack = (location?.state as LocationState)?.redirectBack ?? '/';
    return <Navigate to={redirectBack} />;
  }

  if (!isSignInLink()) {
    return (
      <Info
        title="Invalid link"
        paragraphs={[
          'This is not a valid link, go to login to request a valid login link',
        ]}
      />
    );
  }

  if (loginError) {
    return (
      <Info
        title="Log in failed"
        paragraphs={[
          <>
            This URL probably expired, Please go to{' '}
            <Link to={RoutePath.LOGIN.value}>login</Link> to request a new one.
          </>,
          'If you recently got the email, check whether this is the same browser from which you requested the login link.',
        ]}
      />
    );
  }

  return <CircularProgress />;
};
