import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const COMMENT_ANCHOR_PREFIX = 'comment-';

export const useCommentsTabOrDefault = (tab: string) => {
  const { hash } = useLocation();
  let defaultTab: string = tab;
  if (hash.startsWith(`#${COMMENT_ANCHOR_PREFIX}`)) {
    defaultTab = 'comments';
  }
  return useState(defaultTab);
};

export const currentCommentIdFromHash = (hash: string) => {
  return hash.substring(COMMENT_ANCHOR_PREFIX.length + 1);
};

export const hasCurrentCommentHash = (hash: string) => {
  return hash.startsWith(`#${COMMENT_ANCHOR_PREFIX}`);
};

export const commentAnchor = ({ id }: { id: string }) => {
  return [COMMENT_ANCHOR_PREFIX, id].join('');
};
