import { FC } from 'react';
import { MenuItem, Stack, Typography } from '@mui/material';
import { Select } from 'mui-rff';
import { Validator } from '@src/design-system/forms/validators';
import { flashPackTheme } from '@src/design-system/theme';

interface ItinerarySelectPropTypes {
  itineraryOptions: { title: string; id: string }[];
}

const ItinerarySelectRFF: FC<ItinerarySelectPropTypes> = ({ itineraryOptions }) => {
  return (
    <Stack direction="row" spacing={2} sx={{ py: 3, width: flashPackTheme.spacing(40) }}>
      <Typography variant="bodySingle" sx={{ py: 1 }}>
        Itinerary
      </Typography>
      <Select
        name="itineraryId"
        displayEmpty
        fieldProps={{
          validate: Validator.required,
        }}
        sx={{
          height: flashPackTheme.spacing(5),
          backgroundColor: flashPackTheme.palette.principal.grey30,
          '&:hover': {
            backgroundColor: flashPackTheme.palette.principal.grey50,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      >
        <MenuItem value="" disabled selected>
          <Typography variant="bodyPara" color={flashPackTheme.palette.principal.grey70}>
            Select
          </Typography>
        </MenuItem>
        {itineraryOptions.map(({ id, title }) => (
          <MenuItem value={id} key={id}>
            {title}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default ItinerarySelectRFF;
