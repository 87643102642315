import { Alert, Stack, AlertProps, StackProps } from '@mui/material';
import { InfoIcon, LockedIcon, WarningIcon, Typography } from 'design-system';
import { useTheme } from '@mui/material/styles';
import { useMemo } from 'react';

interface PropTypes {
  message: string | Array<string>;
  iconType: 'LOCKED' | 'WARNING' | 'INFO'; // feel free to add more variants here
  containerSx?: StackProps['sx'];
  alertSx?: AlertProps['sx'];
}

export function WarningMessage(props: PropTypes) {
  const theme = useTheme();

  const icon = useMemo(() => {
    if (props.iconType === 'LOCKED') {
      return <LockedIcon htmlColor={theme.palette.principal.black} />;
    } else if (props.iconType === 'WARNING') {
      return <WarningIcon htmlColor={theme.palette.principal.black} />;
    } else if (props.iconType === 'INFO') {
      return (
        <InfoIcon
          htmlColor={theme.palette.principal.black}
          sx={{ width: 22, height: 22 }}
        />
      );
    }
    return null;
  }, [props.iconType, theme.palette]);

  return (
    <Stack sx={{ mt: 2, mb: 2, ...props.containerSx }}>
      <Alert
        icon={icon}
        sx={{
          color: theme.palette.principal.black,
          backgroundColor: theme.palette.system.amber30,
          alignItems: 'center',
          paddingY: 0,
          ...props.alertSx,
          '& .MuiAlert-icon': {
            pt: '9px',
            alignSelf: 'flex-start',
          },
        }}
        severity="warning"
      >
        {typeof props.message === 'string' ? (
          <Typography variant="Body S">{props.message}</Typography>
        ) : (
          props.message.map((message, index) => (
            <Typography variant="Body S" key={index}>
              {message}
            </Typography>
          ))
        )}
      </Alert>
    </Stack>
  );
}
