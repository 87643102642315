import { Typography } from '@mui/material';
import React, { FC } from 'react';
import { ConfirmationDialog } from 'src/design-system/dialogs/ConfirmationDialog';

interface PropTypes {
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
}

export const DeleteDeparturesConfirmation: FC<PropTypes> = (props) => {
  return (
    <ConfirmationDialog
      {...props}
      title="Delete departures?"
      cancelLabel="Cancel"
      confirmLabel="Delete"
    >
      <Typography sx={{ mb: 5 }} variant="bodyPara">
        You will not be able to recover departures once deleted.
      </Typography>
    </ConfirmationDialog>
  );
};
