import { useMutation, useQuery } from '@apollo/client';
import {
  Departure,
  ExtrasCostAmountsInput,
  ItineraryWithExtrasCostsDocument,
  Scalars,
  UpdateExtrasCostsDocument,
} from '@flashpack/graphql';
import { FinanceEntity } from '@src/shared/finance/utils';
import Table, { TableData, TableRowContextOption } from '@src/shared/table/Table';
import {
  departuresToTableRows,
  lockCostsFactory,
  pasteFromAbove,
  showTableOpenMenuButton,
} from '@src/shared/table/utils';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { FC, useMemo, useState } from 'react';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useToast } from '@src/shared/toast/useToast';
import { lockIconSpecifications, dialogBoxSpecifications, mapTableRow } from './utils';
import { CostsComponentProps } from './CostsPage';
import { isRowComplete, isRowPartiallyComplete } from '../util';
import { BulkUpdateExtrasCostsModal } from './BulkUpdateExtrasCostsModal';

const defaultDepartures: Departure[] = [];

export const ExtrasCostTable: FC<CostsComponentProps> = ({
  renderCanton,
  nonTableScreenHeight,
}) => {
  const { itineraryId } = useRequiredParams(['itineraryId']);
  const { safeMutation } = useSafeMutation();
  const itinerary = useQuery(ItineraryWithExtrasCostsDocument, {
    variables: {
      id: itineraryId,
    },
  });
  const [openBulkUpdateModal, setOpenBulkUpdateModal] = useState<boolean>(false);
  const [selectedDepartureIds, setSelectedDepartureIds] = useState<
    Array<Scalars['UUID']>
  >([]);

  const departures = itinerary.data?.itinerary?.departures ?? defaultDepartures;
  const costCurrency = itinerary.data?.itinerary?.costCurrency;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [updateExtrasCosts] = useMutation(UpdateExtrasCostsDocument);
  const { error: errorToast, success: successToast } = useToast();

  const costData: TableData = useMemo(() => {
    return {
      headers: [
        {
          name: 'amountSingleSupplement',
          label: 'Single Supplement',
          locked: false,
        },
        {
          name: 'amountPreNight',
          label: 'Pre-Night',
          locked: false,
        },
        {
          name: 'amountPreNightSharedTwin',
          label: 'Pre-Night Twin',
          hint: '(Per Person)',
          locked: false,
        },
        {
          name: 'amountPostNight',
          label: 'Post-Night',
          locked: false,
        },
        {
          name: 'amountPostNightSharedTwin',
          label: 'Post-Night Twin',
          hint: '(Per Person)',
          locked: false,
        },
        { name: 'amountOptional', label: 'Optional', locked: false },
      ],
      rows: departuresToTableRows(departures, lockCostsFactory('extrasCostsLocked')),
    };
  }, [departures]);

  const headerKeys = costData.headers.map((x) => x.name);

  const handleSubmitExtrasCostAmounts = async (formValues: TableData) => {
    if (formValues.rows.some((row) => isRowPartiallyComplete(headerKeys, row.values))) {
      errorToast('Partially complete rows are not allowed');
      return;
    }

    setIsSubmitting(true);
    await safeMutation(
      updateExtrasCosts({
        variables: {
          input: formValues.rows
            .filter((row) => isRowComplete(headerKeys, row.values) && !row.locked)
            .map((row) => {
              const amounts = mapTableRow(formValues, row);
              return {
                departureId: row.name,
                amounts: amounts as ExtrasCostAmountsInput,
              };
            }),
        },
        refetchQueries: [ItineraryWithExtrasCostsDocument],
      }),
      {
        onSuccess: () => {
          setIsSubmitting(false);
          successToast('Extras costs updated successfully');
        },
        onError: () => {
          setIsSubmitting(false);
          errorToast('Could not update extras costs');
        },
      },
    );
  };

  const rowOptions: TableRowContextOption[] = [pasteFromAbove];

  return (
    <>
      <BulkUpdateExtrasCostsModal
        open={openBulkUpdateModal}
        onClose={() => setOpenBulkUpdateModal(false)}
        selectedDepartureIds={selectedDepartureIds}
        setSelectedDepartureIds={setSelectedDepartureIds}
        costCurrency={costCurrency}
      />
      <Table
        initialData={costData}
        handleSubmit={handleSubmitExtrasCostAmounts}
        rowOptions={rowOptions}
        editable={true}
        isLoading={isSubmitting || itinerary.loading}
        showOpenMenuButton={showTableOpenMenuButton}
        isRequired={false}
        renderCanton={renderCanton}
        submitLabel={'Save Extras Costs'}
        tableFormId="extras-costs-table"
        entity={FinanceEntity.EXTRAS_COST}
        nonTableScreenHeight={nonTableScreenHeight}
        cellWidth={155}
        lockIconSpecifications={lockIconSpecifications}
        dialogBoxSpecifications={dialogBoxSpecifications}
        selectedRowIds={selectedDepartureIds}
        setSelectedRowIds={setSelectedDepartureIds}
        setOpenBulkUpdateModal={setOpenBulkUpdateModal}
      />
    </>
  );
};
