import { FC } from 'react';
import { Tile } from './Tile';

export interface ActivityTilePropTypes {
  key: string;
  engagementId: string;
  startLocation: string;
  endLocation: string;
  startTime: string;
  endTime: string;
  title: string;
  flagged: boolean;
  href: string;
  commentCount: number;
  lastCommentAt?: string;
  mealIncluded: boolean;
  selectEngagement?: () => void;
  isSelected?: boolean;
  showCheckbox: boolean;
}

export const ActivityTile: FC<ActivityTilePropTypes> = (props) => {
  const {
    startTime,
    endTime,
    href,
    title,
    flagged,
    commentCount,
    lastCommentAt,
    mealIncluded,
    selectEngagement,
    isSelected,
    showCheckbox = false,
  } = props;
  return (
    <Tile
      href={href}
      title={title}
      timing={`${startTime} - ${endTime}`}
      data-testid="checkin-Activity"
      variant="activity"
      flagged={flagged}
      commentCount={commentCount}
      lastCommentAt={lastCommentAt}
      mealIncluded={mealIncluded}
      selectItem={selectEngagement}
      isSelected={isSelected}
      showCheckbox={showCheckbox}
      sx={showCheckbox ? { marginLeft: 0 } : {}}
    ></Tile>
  );
};
