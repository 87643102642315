import * as SentryReact from '@sentry/react';
import * as SentryBrowser from '@sentry/browser';

export const initSentry = () => {
  SentryReact.init({
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    dsn: import.meta.env.VITE_SENTRY_CLIENT_DSN,
    sendDefaultPii: false,
    integrations: [
      SentryBrowser.browserTracingIntegration(),
      SentryBrowser.httpClientIntegration({
        failedRequestTargets: [import.meta.env.VITE_APOLLO_URI],
      }),
    ],
    release: import.meta.env.VITE_SENTRY_RELEASE,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
};
