import { countries } from '../countries/worlds';
import {
  CountryCode,
  getCountryCallingCode,
  isSupportedCountry,
} from 'libphonenumber-js';

type CountryCallingCodeOption = {
  callingCode: string;
  label: string;
  alpha2: string;
};

const mappedCallingCodes = new Set<string>([]);

const countriesCallingCodesAll = countries
  .filter((country) => isSupportedCountry(country.alpha2.toUpperCase()))
  .map<CountryCallingCodeOption>((country) => {
    const callingCode = getCountryCallingCode(
      country.alpha2.toUpperCase() as CountryCode,
    );

    return {
      callingCode: `+${callingCode}`,
      label: `+${callingCode}`,
      alpha2: country.alpha2,
    };
  });

/**
 * An unsorted list of unique country calling codes with their respective labels.
 * Unique - not to repeat the same calling code for multiple countries (e.g. US, Canada, LatAm).
 */
const countriesCallingCodesUnique = countriesCallingCodesAll.filter((country) => {
  if (mappedCallingCodes.has(country.callingCode)) {
    return false;
  }

  mappedCallingCodes.add(country.callingCode);
  return true;
});

const UK_CALLING_CODE = '+44';

export const orderedCallingCodes = [
  countriesCallingCodesUnique.find((c) => c.callingCode == UK_CALLING_CODE),
  ...countriesCallingCodesUnique.sort((a, b) =>
    a.callingCode.localeCompare(b.callingCode),
  ),
] as Array<CountryCallingCodeOption>;

export const getCountryForCallingCode = (callingCode: string) => {
  const match = countriesCallingCodesUnique.find((c) => c.callingCode === callingCode);
  return match?.alpha2.toUpperCase();
};

export const getCallingCodeForCountry = (countryCode: string) => {
  const match = countriesCallingCodesAll.find(
    (c) => c.alpha2.toUpperCase() === countryCode.toUpperCase(),
  );
  return match?.callingCode;
};
