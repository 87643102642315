import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { OpenInNewTab } from 'design-system';
import { HotelInfoForViewing } from '@src/shared/accommodation/types';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { RoutePath } from '@src/shared/routePath';
import { useRouting } from '@src/shared/useRouting';
import { HotelRating } from '../common/HotelRating';
import { getLabelForHotelKind, HotelKind } from '../common/hotelUtils';
import { FC, ReactNode } from 'react';
import { HotelApprovalStatusIndicator } from './HotelApprovalStatusIndicator';

interface PropTypes {
  hotelInfo: HotelInfoForViewing;
  onClick?: (_id: string) => void;
  view: 'search' | 'full';
}

const HotelCardInfoRow = styled(Stack)(({ theme }) => ({
  color: theme.palette.principal.grey70,
  overflow: 'hidden',
}));
HotelCardInfoRow.defaultProps = { direction: 'row', gap: 1 };

const HotelInfoCard = styled(Card)(({ theme }) => ({
  background: theme.palette.principal.grey30,
  border: 'none',
  boxShadow: 'none',
  borderRadius: '2px',
}));

const AccommodationHotelLink = ({ link }: { link: string }) => {
  return (
    <IconButton onClick={(event) => event.stopPropagation()} target="_blank" href={link}>
      <OpenInNewTab sx={{ width: 18, height: 18 }} />
    </IconButton>
  );
};

const BlurredBackdrop = styled(Box)(() => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  background: 'rgba(32, 32, 32, 0.5)',
  backdropFilter: 'blur(5px)',
}));

const ClosedChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  borderRadius: '2px',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.principal.black,
  color: theme.palette.principal.white,
  fontSize: '22px',
  fontWeight: 700,
  lineHeight: '26px',
  height: 'auto',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

const SmallAccommodationName: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <Typography variant="bodySingle" sx={{ lineHeight: '24px', height: '48px' }}>
      {children}
    </Typography>
  );
};

const LargeAccommodationName: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <Typography variant="subHeader" sx={{ lineHeight: '32px', height: '64px' }}>
      {children}
    </Typography>
  );
};

export const HotelCard: FC<PropTypes> = ({ hotelInfo, onClick, view }) => {
  const {
    accommodationName,
    thumbnailImageUrl,
    kind,
    starRating,
    locationName,
    isHotelClosed,
    hotelId,
    status,
  } = hotelInfo;

  const { createLink } = useRouting();
  const link = hotelId
    ? createLink(RoutePath.HOTELS.value, {
        toAdd: { hotelId: hotelId, mode: 'detached' },
      })
    : null;
  const defaultCursor = onClick !== undefined ? 'pointer' : 'default';
  const isSearchView = view === 'search';
  const cardHoverStyling = isSearchView
    ? {
        boxShadow: '0px 10px 20px -5px rgba(32, 32, 32, 0.2)',
      }
    : {};

  const AccommodationName = isSearchView
    ? SmallAccommodationName
    : LargeAccommodationName;
  const viewThumbnailMaxHeight = isSearchView ? '220px' : '260px';
  const cardContentPadding = isSearchView ? '16px' : '24px';
  return (
    <HotelInfoCard
      sx={{ cursor: defaultCursor, '&:hover': cardHoverStyling }}
      onClick={() => (onClick && hotelId ? onClick(hotelId) : undefined)}
      data-testid="hotel-card"
    >
      <Box sx={{ position: 'relative' }}>
        <CardMedia
          sx={{ height: viewThumbnailMaxHeight }}
          image={thumbnailImageUrl}
          title={`Photo of ${accommodationName}`}
        />
        {isHotelClosed && <BlurredBackdrop></BlurredBackdrop>}
        {isHotelClosed && <ClosedChip variant="filled" label="Closed" />}
        {status && (
          <HotelApprovalStatusIndicator status={status} sx={{ right: 10, top: 10 }} />
        )}
      </Box>
      <CardContent
        sx={{
          padding: cardContentPadding,
          paddingBottom: cardContentPadding,
          '&:last-child': {
            paddingBottom: cardContentPadding,
          },
        }}
      >
        <Stack gap={1}>
          <HotelCardInfoRow>
            {kind && (
              <Typography variant="label" component="span">
                {getLabelForHotelKind(kind as HotelKind)}
              </Typography>
            )}
            <Box sx={{ display: 'flex' }}>
              <HotelRating sx={{ flexGrow: 1 }} value={starRating} />
            </Box>
          </HotelCardInfoRow>
          <AccommodationName>{accommodationName}</AccommodationName>
          {locationName && (
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <HotelCardInfoRow>
                <LocationOnIcon sx={{ color: 'inherit' }} fontSize="small" />
                <Typography
                  variant="captionSingle"
                  sx={{ marginBottom: 0, color: 'inherit', whiteSpace: 'nowrap' }}
                  title={locationName}
                >
                  {locationName}
                </Typography>
              </HotelCardInfoRow>
              {link && <AccommodationHotelLink link={link} />}
            </Stack>
          )}
        </Stack>
      </CardContent>
    </HotelInfoCard>
  );
};
