import { FC } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { UnarchiveIcon, ArchiveIcon } from 'design-system';
import { useMutation } from '@apollo/client';
import {
  AdventureWithItinerariesAndDeparturesDocument,
  ArchiveDeparturesDocument,
  Scalars,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';

interface Props {
  isWhite?: boolean;
  isMultiple: boolean;
  departureIds: Scalars['UUID'][];
  archived: boolean;
  deselectAll: () => void;
}

export const ArchiveDepartureButton: FC<Props> = (props) => {
  const { departureIds, archived } = props;
  const [archiveDeparturesMutation, { loading }] = useMutation(ArchiveDeparturesDocument);
  const { success, warning, error } = useToast();

  const archiveDeparture = async () => {
    try {
      const { data } = await archiveDeparturesMutation({
        variables: { departureIds, archived: !archived },
        refetchQueries: [AdventureWithItinerariesAndDeparturesDocument],
      });

      const { message, useSuccessToast } = archiveToastMessage(
        archived,
        props.isMultiple,
        departureIds.length,
        data?.archiveDepartures.length || 0,
      );

      if (useSuccessToast) {
        success(message);
      } else {
        warning(message);
      }

      if (props.isMultiple) {
        props.deselectAll();
      }
    } catch (err) {
      error(`Error trying to ${archived ? 'unarchive' : 'archive'} departure`);
    }
  };

  const archiveTooltipTitle = archived
    ? 'Unarchive'
    : 'Archive (Completed, Cancelled or Unsynced Departures only)';

  return (
    <Tooltip title={archiveTooltipTitle}>
      <IconButton
        data-testid="archive-departures"
        onClick={() => void archiveDeparture()}
      >
        {loading ? (
          <CircularProgress
            sx={{ color: props.isWhite ? 'white' : 'black' }}
            size="24px"
          />
        ) : archived ? (
          <UnarchiveIcon htmlColor={props.isWhite ? 'white' : ''} />
        ) : (
          <ArchiveIcon htmlColor={props.isWhite ? 'white' : ''} />
        )}
      </IconButton>
    </Tooltip>
  );
};

const archiveToastMessage = (
  archived: boolean,
  isMultiple: boolean,
  attemptedArchivals: number,
  successfulArchivals: number,
): { message: string; useSuccessToast: boolean } => {
  const verb = archived ? 'unarchived' : 'archived';
  const failureReasonMessage = archived
    ? ''
    : ', only Completed, Cancelled or Unsynced departures can be archived';

  if (!isMultiple && attemptedArchivals === successfulArchivals) {
    return {
      message: `Departure was ${verb}`,
      useSuccessToast: true,
    };
  }
  if (isMultiple && attemptedArchivals === successfulArchivals) {
    return {
      message: `${successfulArchivals} of ${attemptedArchivals} selected departures were ${verb}`,
      useSuccessToast: true,
    };
  }
  if (isMultiple && archived && successfulArchivals > 0) {
    return {
      message: `${successfulArchivals} of ${attemptedArchivals} selected departures were ${verb}, only archived departures can be unarchived`,
      useSuccessToast: true,
    };
  }
  if (isMultiple && successfulArchivals > 0) {
    return {
      message: `${successfulArchivals} of ${attemptedArchivals} selected departures were ${verb}${failureReasonMessage}`,
      useSuccessToast: true,
    };
  }
  return {
    message: `Departure${isMultiple ? 's' : ''} not ${verb}${failureReasonMessage}`,
    useSuccessToast: false,
  };
};
