import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';

type PropTypes = {
  icon: React.ReactNode;
  message: string;
  actions?: React.ReactNode;
};

export const BlankSlate = ({ icon, message, actions }: PropTypes) => {
  const theme = useTheme();

  return (
    <Stack direction="column" my={5} alignItems="center" spacing={3}>
      {icon}
      <Typography
        variant="bodySingle"
        sx={{
          lineHeight: '20px',
          color: theme.palette.principal.grey70,
        }}
      >
        {message}
      </Typography>
      {actions}
    </Stack>
  );
};
