import { FC, useContext } from 'react';
import { GenericError } from 'design-system';
import { EditEngagementsDocument } from '@flashpack/graphql';
import { useRouting } from 'src/shared/useRouting';
import { useToast } from 'src/shared/toast/useToast';
import { mapFormValuesToEditActivityInput } from 'src/itinerary/activity/common/activityFormMapper';
import { FormState } from 'final-form';
import isEqual from 'lodash.isequal';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { SharedTabsContext } from '@src/shared/context/SharedTabsContext';
import { WarningMessage } from '@src/design-system/warning-message/WarningMessage';
import { useMutation } from '@apollo/client';
import { EditActivityFormValues, ViewEditActivityForm } from './ViewEditActivityForm';

type PropTypes = {
  isTimelineLocked?: boolean;
  isEditable: boolean;
};

const lockedTimelineMessage =
  'This itinerary has departures running and cannot be edited.';

export const ActivityInfoTab: FC<PropTypes> = ({ isTimelineLocked, isEditable }) => {
  const { adventureId, itineraryId, activityId } = useRequiredParams([
    'adventureId',
    'itineraryId',
    'activityId',
  ]);

  const { activityForm } = useContext(SharedTabsContext);

  const { success: successToast, error: errorToast } = useToast();
  const { navigate } = useRouting();
  const [editEngagements, { error }] = useMutation(EditEngagementsDocument);
  const { safeMutation } = useSafeMutation();

  if (!adventureId || !itineraryId || !activityId) {
    return <GenericError error="Malformed url, cannot render edit activity page" />;
  }

  const shouldSubscribeToFormChanges = !!activityForm?.state;

  const onSubmit = async (formValues: EditActivityFormValues) => {
    await safeMutation(
      editEngagements({
        variables: {
          input: mapFormValuesToEditActivityInput(formValues),
        },
      }),
      {
        onSuccess: () => {
          successToast('Activity successfully edited');
        },
        onUnexpectedError: () => {
          errorToast('There was an issue while saving this activity - try again later');
        },
        onServerValidationError: (error) => {
          errorToast(error.message);
        },
      },
    );
    activityForm?.setHasPendingChanges(false);
  };

  const onFormStateChange = (
    formState: FormState<EditActivityFormValues, Partial<EditActivityFormValues>>,
  ) => {
    if (!shouldSubscribeToFormChanges) {
      return;
    }
    // We avoid calls to the contextual setState if the data is unchanged
    if (isEqual(formState.values, activityForm.state)) {
      return;
    }
    activityForm.setHasPendingChanges(true);
    activityForm.setState({
      ...formState.values,
    });
  };

  const navigateToTimeline = () => {
    navigate('../');
  };

  return (
    <>
      {isTimelineLocked && (
        <WarningMessage message={lockedTimelineMessage} iconType="LOCKED" />
      )}
      <ViewEditActivityForm<EditActivityFormValues>
        initialValues={activityForm?.state}
        onSubmit={onSubmit}
        onCancel={navigateToTimeline}
        error={error}
        onChange={onFormStateChange}
        locked={isTimelineLocked || !isEditable}
      />
    </>
  );
};
