import { Chip, Typography, useTheme } from '@mui/material';
import { FC } from 'react';

export type TripGuaranteedIndicatorProps = {
  isGuaranteed: boolean;
};

export const TripGuaranteedIndicator: FC<TripGuaranteedIndicatorProps> = ({
  isGuaranteed,
}) => {
  const theme = useTheme();

  return (
    <>
      {isGuaranteed && (
        <Chip
          sx={{
            color: theme.palette.system.green100,
            backgroundColor: theme.palette.system.green10,
          }}
          label={<Typography variant="bodySingle">Guaranteed</Typography>}
        />
      )}
    </>
  );
};
