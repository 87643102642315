import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { SingleComment } from '@src/itinerary/common/comments/SingleComment';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import {
  commentAnchor,
  currentCommentIdFromHash,
  hasCurrentCommentHash,
} from '@src/shared/timeline/util';
import { CommentFragment } from '@flashpack/graphql';

interface PropTypes {
  loading: boolean;
  error: boolean;
  comments: Array<CommentFragment>;
}

const defaultCommentBoxHeight = 430;

export const Comments: FC<PropTypes> = (props) => {
  const { error, loading, comments } = props;
  const { hash } = useLocation();

  const noCommentsPresent = !comments || comments.length === 0;

  const commentBox = useRef<HTMLDivElement>(null);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);

  const scrollToComment = useCallback(() => {
    if (commentBox.current) {
      const commentIdToScrollTo = hasCurrentCommentHash(hash)
        ? currentCommentIdFromHash(hash)
        : comments.slice(-1)[0]?.id;
      commentIdToScrollTo &&
        document
          .getElementById(commentAnchor({ id: commentIdToScrollTo }))
          ?.scrollIntoView({ block: 'end', inline: 'nearest' });
    }
  }, [comments, hash]);

  // Once the comments are loaded in, we can figure out if the box is large
  // enough to be scrollable
  useEffect(() => {
    setIsScrollable(() => {
      return (
        !!commentBox.current && commentBox.current.scrollHeight > defaultCommentBoxHeight
      );
    });
    // We should still scroll to bottom whenever comments change (e.g. a new comment is added)
    scrollToComment();
  }, [comments, scrollToComment]);

  // If is scrollable is set, then we scroll to the bottom. This needs to happen
  // after isScrollable is set to true, because isScrollable will affect
  // the height of the element due to extra padding
  useEffect(() => {
    if (isScrollable) {
      scrollToComment();
    }
  }, [isScrollable, scrollToComment]);

  if (loading) {
    return (
      <CommentsStack gap={2}>
        <Skeleton variant="rectangular" height="124px" />
        <Skeleton variant="rectangular" height="124px" />
        <Skeleton variant="rectangular" height="124px" />
      </CommentsStack>
    );
  }
  const currentCommentId = currentCommentIdFromHash(hash);
  return (
    <CommentsBox position="relative">
      {isScrollable && (
        <Box
          sx={{
            height: '47px',
            width: '100%',
            background:
              'linear-gradient(180deg, #FFFFFF 20.21%, rgba(246, 246, 246, 0) 100%)',
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        ></Box>
      )}
      <CommentsStack
        // We add a larger padding when the box is scrollable so that
        // the gradient doesn't hide the top comment.
        sx={{
          paddingTop: isScrollable ? 4 : 2,
          paddingBottom: 2,
          '& > :first-of-type': {
            marginTop: 'auto',
          },
        }}
        gap={2}
        ref={commentBox}
      >
        {error && (
          <Typography variant="bodySingle">
            Comments failed to load - Try again later
          </Typography>
        )}
        {!error && noCommentsPresent && (
          <Typography
            align="center"
            sx={{
              my: 0,
              position: 'absolute',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            variant="bodySingle"
          >
            No comments to show
          </Typography>
        )}
        {!error &&
          comments.map((comment) => {
            return (
              <SingleComment
                key={comment.id}
                hilight={comment.id == currentCommentId}
                {...comment}
              />
            );
          })}
      </CommentsStack>
    </CommentsBox>
  );
};

const CommentsBox = styled(Box)(({ theme }) => ({
  background: theme.palette.principal.grey30,
}));

const CommentsStack = styled(Stack)(({ theme }) => ({
  height: `${defaultCommentBoxHeight}px`,
  overflowY: 'auto',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));
