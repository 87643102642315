import { FC } from 'react';
import { Typography, Button, Box, CircularProgress, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { styled } from '@mui/material/styles';
import {
  NaiveDayTimeInput,
  RoomGroup,
  Maybe,
  TimelineDocument,
  HotelDetailsDocument,
  AddRateHawkAccommodationToTimelineDocument,
} from '@flashpack/graphql';
import { AutocompleteField } from 'src/design-system/forms/autocomplete/AutocompleteField';
import { GenericError } from 'design-system';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { HotelCard } from 'src/hotels/hotels-overview/HotelCard';
import { CheckInOutPicker } from '../../check-in-out-picker';
import { useRouting } from 'src/shared/useRouting';
import { ServerValidationErrors } from '@src/design-system/forms/ServerValidationErrors';
import { hasServerValidationErrors } from 'src/shared/errorUtils';
import { createRoomAutocompleteOptions } from '@src/hotels/hotel-details/room-explorer/roomUtils';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useTimelineContext } from '@src/shared/timeline/TimelineContext';
import { extractInfoFromRateHawkProperty } from '@src/shared/accommodation/utils';
import { NumberField } from '@src/design-system/forms/number-field/NumberField';

interface FormPropTypes {
  hotelId: string;
  itineraryId: string;
  onBack: () => void;
}

export const UnboundAddRatehawkAccommodationForm: FC<FormPropTypes> = ({
  hotelId,
  itineraryId,
  onBack,
}) => {
  const hotelDetailsQuery = useQuery(HotelDetailsDocument, {
    variables: { id: hotelId },
  });
  const [addRatehawkAccommodationToItineraryMutation, { error: mutationErrors }] =
    useMutation(AddRateHawkAccommodationToTimelineDocument, {
      refetchQueries: [TimelineDocument],
    });
  const { safeMutation } = useSafeMutation();
  const { timelineId } = useTimelineContext();

  const hotelDetails = hotelDetailsQuery.data?.hotel;

  const finishedLoading = !hotelDetailsQuery.loading;

  const { navigate } = useRouting();

  if (!finishedLoading) {
    return <CircularProgress />;
  } else if (!hotelDetails) {
    return (
      <GenericError
        error={
          hotelDetailsQuery.error ||
          `Failed to load itinerary ${itineraryId} or hotel ${hotelId}`
        }
      />
    );
  }

  if (mutationErrors && !hasServerValidationErrors(mutationErrors)) {
    return <GenericError error={mutationErrors} />;
  }

  return (
    <Form
      onSubmit={async ({
        checkIn,
        checkOut,
        roomDescriptorSingle,
        roomDescriptorTwin,
        allocationSingleRoom,
        allocationTwinRoom,
      }: {
        checkIn: NaiveDayTimeInput;
        checkOut: NaiveDayTimeInput;
        roomDescriptorSingle: string;
        roomDescriptorTwin: string;
        allocationSingleRoom: number;
        allocationTwinRoom: number;
      }) => {
        const { error } = await safeMutation(
          addRatehawkAccommodationToItineraryMutation({
            variables: {
              accommodation: {
                checkIn,
                checkOut,
                hotelId: hotelDetails.id,
                roomDescriptorSingle,
                roomDescriptorTwin,
                allocationSingleRoom,
                allocationTwinRoom,
              },
              timelineId,
            },
          }),
          {
            onSuccess: () => navigate('../'),
          },
        );

        if (error instanceof ApolloError) {
          return { [FORM_ERROR]: error.message };
        }
      }}
      validate={({ checkOut, checkIn }) => {
        if (checkIn?.day >= checkOut?.day) {
          return {
            checkOut: { day: 'Must be after check in' },
            checkIn: { day: 'Must be before check out' },
          };
        }
      }}
      render={({ handleSubmit, submitting }) =>
        hotelDetails && (
          <form
            onSubmit={(e) => {
              void handleSubmit(e);
            }}
          >
            <Typography variant="subHeader">Add Accommodation</Typography>
            {hotelDetails && (
              <HotelCard
                view="full"
                hotelInfo={extractInfoFromRateHawkProperty(hotelDetails)}
              />
            )}
            <RatehawkAccommodationFields
              rooms={
                hotelDetails?.room_groups.filter(
                  (g: Maybe<RoomGroup>): g is RoomGroup => !!g,
                ) ?? []
              }
            />
            <ServerValidationErrors error={mutationErrors} />
            <Buttons>
              <Button onClick={onBack} variant="outlined" sx={{ marginRight: 2 }}>
                Back
              </Button>
              <LoadingButton
                variant="contained"
                type="submit"
                loading={submitting}
                data-testid="button-add-to-itinerary"
              >
                Add to itinerary
              </LoadingButton>
            </Buttons>
          </form>
        )
      }
    />
  );
};

export const RatehawkAccommodationFields: FC<{
  rooms: RoomGroup[];
  readonly?: boolean;
  isBulk?: boolean;
  isRoomDescriptorTwinMixed?: boolean;
  isRoomDescriptorSingleMixed?: boolean;
  isCheckInDayMixed?: boolean;
  isCheckOutDayMixed?: boolean;
}> = ({
  rooms,
  readonly,
  isBulk,
  isRoomDescriptorTwinMixed,
  isRoomDescriptorSingleMixed,
  isCheckInDayMixed,
  isCheckOutDayMixed,
}) => {
  const twinRoomOptions = createRoomAutocompleteOptions(rooms);
  const singleRoomOptions = createRoomAutocompleteOptions(rooms);
  if (isRoomDescriptorTwinMixed) {
    twinRoomOptions.push({
      value: 'Multiple twin room types',
      label: 'Multiple twin room types',
    });
  }
  if (isRoomDescriptorSingleMixed) {
    singleRoomOptions.push({
      value: 'Multiple single room types',
      label: 'Multiple single room types',
    });
  }

  return (
    <Box maxWidth="sm">
      <Stack direction="row" justifyContent="space-between">
        <Heading sx={{ color: 'principal.grey70' }}>Rooms</Heading>
        <Heading sx={{ color: 'principal.grey70' }}>Allocations</Heading>
      </Stack>
      <Stack gap={2}>
        <Stack direction="row" gap={2}>
          <AutocompleteField
            options={twinRoomOptions}
            fullWidth
            getOptionValue={(option) => option.value}
            label="Select a twin room type"
            name="roomDescriptorTwin"
            data-testid="select-twin-room"
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.value}>
                  {option?.label}
                </li>
              );
            }}
            readOnly={readonly}
          />
          <NumberField
            name="allocationTwinRoom"
            testid="allocation-twin-room"
            disabled={readonly}
            required
            minimumValue={0}
          />
        </Stack>
        <Stack direction="row" gap={2} sx={{ mb: 3 }}>
          <AutocompleteField
            options={singleRoomOptions}
            fullWidth
            getOptionValue={(option) => option.value}
            label="Select a single room type"
            name="roomDescriptorSingle"
            data-testid="select-single-room"
            renderOption={(props, option) => {
              return (
                <li {...props} key={option.value}>
                  {option?.label}
                </li>
              );
            }}
            readOnly={readonly}
          />
          <NumberField
            name="allocationSingleRoom"
            testid="allocation-single-room"
            disabled={readonly}
            required
            minimumValue={0}
          />
        </Stack>
      </Stack>
      {
        <CheckInOutPicker
          data-testid="check-in-out-picker"
          readonly={readonly}
          isBulk={isBulk}
          isCheckInDayMixed={isCheckInDayMixed}
          isCheckOutDayMixed={isCheckOutDayMixed}
        />
      }
    </Box>
  );
};

const Buttons = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(7),
  marginBottom: theme.spacing(6),
}));

const Heading = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[400],
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(2),
}));
