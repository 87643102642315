import { HighlightInfo } from '@src/shared/date-picker/DatePicker';
import { MultiDatePicker } from '@src/shared/multi-date-picker/MultiDatePicker';
import React, { FC } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';

interface PropTypes {
  name: string;
  validate?: (value: Date[], formValues: object) => string | undefined;
  highlights?: HighlightInfo[];
  fixedSelectedDates?: { duration: number; date: Date }[];
  longestAccommodationDuration: number;
  itineraryDurationDays: number;
  onChange?: (value: Date[]) => void;
}

export const MultiDatePickerField: FC<
  PropTypes & Partial<React.ComponentProps<typeof MultiDatePicker>>
> = (props) => {
  const {
    name,
    validate,
    highlights = [],
    fixedSelectedDates = [],
    itineraryDurationDays,
    longestAccommodationDuration,
    onChange,
    ...datePickerProps
  } = props;

  const handleValidate = (value: Date[], formValues: object | undefined) => {
    if (validate) {
      return validate(value, formValues as object);
    }
  };

  const renderDatePicker: (
    props: FieldRenderProps<Date[], HTMLElement, Date[] | null>,
  ) => React.ReactNode = ({ input, meta }) => {
    const onChange = (e: Date[]) => {
      input.onChange(e);
      if (props.onChange) {
        props.onChange(e);
      }
    };
    return (
      <MultiDatePicker
        {...datePickerProps}
        selectedDates={input.value || []}
        fixedSelectedDates={fixedSelectedDates}
        longestAccommodationDuration={longestAccommodationDuration}
        itineraryDurationDays={itineraryDurationDays}
        highlights={highlights}
        onSelectedDatesChange={onChange}
        errorMessage={(meta.touched && (meta.error as string)) || ''}
      />
    );
  };

  return (
    <Field<Date[], HTMLElement, Date[] | null>
      name={name}
      validate={handleValidate}
      render={renderDatePicker}
    />
  );
};
