import { Button, Stack, Typography } from '@mui/material';

import { FC, useState } from 'react';
import { FloatingToolbar } from '@src/design-system/floating-toolbar/FloatingToolbar';
import { FinanceEntity } from './utils';
import {
  ConfirmationDialog,
  confirmationDialogLabels,
} from '@src/design-system/dialogs/ConfirmationDialog';

interface PropTypes {
  visible: boolean;
  changesCount: number;
  formId: string;
  handleCancelUpdate: () => void;
  submitLabel: string;
  entity: FinanceEntity;
}

export const UpdateAmountsToolbar: FC<PropTypes> = ({
  visible,
  changesCount,
  formId,
  handleCancelUpdate,
  submitLabel,
  entity,
}) => {
  const [userConfirmClose, setUserConfirmClose] = useState<boolean>(false);

  return (
    <>
      <FloatingToolbar visible={visible}>
        <>
          <Typography variant="bodySingle" sx={{ color: 'white' }}>
            {changesCount} {entity}
            {changesCount > 1 && 's'} updated
          </Typography>
          <Stack direction="row" gap={2}>
            <Button
              title="Submit"
              variant="light-contained"
              type="submit"
              form={formId}
              disabled={changesCount === 0}
              data-testid="update-amounts-submit-button"
            >
              {submitLabel}
            </Button>
            <Button
              title="Cancel"
              onClick={() => setUserConfirmClose(true)}
              variant="light-outlined"
            >
              Exit &amp; Lose Changes
            </Button>
          </Stack>
        </>
      </FloatingToolbar>
      <ConfirmationDialog
        title={confirmationDialogLabels.title}
        cancelLabel={confirmationDialogLabels.cancelLabel}
        confirmLabel={confirmationDialogLabels.confirmLabel}
        onCancel={() => {
          handleCancelUpdate();
          setUserConfirmClose(false);
        }}
        onConfirm={() => {
          setUserConfirmClose(false);
        }}
        open={userConfirmClose}
      >
        <Typography sx={{ mb: 5 }} variant="bodyPara">
          If you &quot;Exit without Saving&quot; you will lose all your progress
        </Typography>
      </ConfirmationDialog>
    </>
  );
};
