import { FC } from 'react';
import { AddAccommodation } from 'src/itinerary/add-accommodation/AddAccommodation';
import { ItineraryDrawer } from 'src/itinerary/common/ItineraryDrawer';
import AddHomeWorkOutlinedIcon from '@mui/icons-material/AddHomeWorkOutlined';

interface PropTypes {
  adventureCode?: string;
}

export const AddAccommodationPage: FC<PropTypes> = ({ adventureCode }) => {
  return (
    <ItineraryDrawer
      title="Accommodation"
      icon={<AddHomeWorkOutlinedIcon />}
      warnForChangesNotSaved={true}
    >
      <AddAccommodation adventureCode={adventureCode} />
    </ItineraryDrawer>
  );
};
