import { FC } from 'react';
import { AutocompleteField } from 'src/design-system/forms/autocomplete/AutocompleteField';
import {
  adventureTypeBackgroundColor,
  adventureTypeTitle,
} from '@src/shared/adventure/util';
import { Chip } from '@mui/material';
import { AdventureTypes } from '@flashpack/graphql';

type AdventureTypesProps = {
  name: string;
};

export const AdventureTypesSelector: FC<AdventureTypesProps> = ({ name }) => {
  return (
    <>
      <AutocompleteField<AdventureTypes, false, true, false>
        name={name}
        options={Object.values(AdventureTypes)}
        label="Adventure Type"
        renderOption={(props, option) => {
          return (
            <span {...props}>
              <Chip
                size={'small'}
                label={adventureTypeTitle(option)}
                sx={{
                  background: adventureTypeBackgroundColor(option),
                  width: 'fit-content',
                }}
              />
            </span>
          );
        }}
        getOptionLabel={(option) => {
          return adventureTypeTitle(option);
        }}
        fullWidth
      />
    </>
  );
};
