import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { TextField } from 'mui-rff';
import { ConfirmationDialog } from '@src/design-system/dialogs/ConfirmationDialog';
import { Dispatch, FC, SetStateAction, useState } from 'react';
import { Form } from 'react-final-form';
import { flashPackTheme } from '@src/design-system/theme';
import { TextButton, LoadingButton } from 'design-system';
import { FormParser } from '@src/design-system/forms/parsers';
import { pluralDepartures } from '@src/departures/utils';
import {
  BulkUpdateExtrasCostsDocument,
  DepartureFinancesQueryDocument,
  ItineraryWithExtrasCostsDocument,
  Scalars,
  SupportedCurrencySymbol,
} from '@flashpack/graphql';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { ApolloError, useMutation } from '@apollo/client';
import { useToast } from '@src/shared/toast/useToast';
import { currencyOptions } from './CurrencyDropdown';
import usePermissions from '@src/shared/usePermissions';

interface PropTypes {
  open: boolean;
  onClose: () => void;
  selectedDepartureIds: Array<Scalars['UUID']>;
  setSelectedDepartureIds?: Dispatch<SetStateAction<string[]>>;
  costCurrency?: SupportedCurrencySymbol | null;
  initialValues?: BulkUpdateExtrasCostsModalFormType | null;
  locked?: boolean;
}

export type BulkUpdateExtrasCostsModalFormType = {
  amountSingleSupplement: number;
  amountPreNight: number;
  amountPostNight: number;
  amountPreNightSharedTwin: number;
  amountPostNightSharedTwin: number;
  amountOptional: number;
};

export const BulkUpdateExtrasCostsModal: FC<PropTypes> = ({
  open,
  onClose,
  selectedDepartureIds,
  setSelectedDepartureIds,
  costCurrency,
  initialValues,
  locked = false,
  ...props
}) => {
  const { safeMutation } = useSafeMutation();
  const { canUserSubmitForm } = usePermissions();
  const [bulkUpdateExtrasCosts] = useMutation(BulkUpdateExtrasCostsDocument);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { error: errorToast, success: successToast } = useToast();

  const title = `Update ${pluralDepartures(selectedDepartureIds.length)}?`;
  const lockForm = !canUserSubmitForm(locked);

  const initialFormValues: Partial<BulkUpdateExtrasCostsModalFormType> = {
    amountSingleSupplement: initialValues?.amountSingleSupplement,
    amountPreNight: initialValues?.amountPreNight,
    amountPostNight: initialValues?.amountPostNight,
    amountPreNightSharedTwin: initialValues?.amountPreNightSharedTwin,
    amountPostNightSharedTwin: initialValues?.amountPostNightSharedTwin,
    amountOptional: initialValues?.amountOptional,
  };

  const handleSubmitExtrasCosts = async (values: BulkUpdateExtrasCostsModalFormType) => {
    setIsSubmitting(true);
    await safeMutation(
      bulkUpdateExtrasCosts({
        variables: {
          input: {
            departureIds: selectedDepartureIds,
            amounts: values,
          },
        },
        refetchQueries: [
          ItineraryWithExtrasCostsDocument,
          DepartureFinancesQueryDocument,
        ],
      }),
      {
        onSuccess: () => {
          setIsSubmitting(false);
          onClose();
          successToast('Extras costs updated successfully');
          setSelectedDepartureIds && setSelectedDepartureIds([]);
        },
        onError: (err) => {
          setIsSubmitting(false);
          if (err instanceof ApolloError) {
            errorToast(err.message);
          } else {
            errorToast('Could not update extras costs');
          }
        },
      },
    );
  };

  const currencyDisplayed = currencyOptions.find(
    (option) => option.value === costCurrency,
  )?.label;

  const validateForm = (values: BulkUpdateExtrasCostsModalFormType) => {
    const errors: { _form?: string } = {};

    if (Object.values(values).every((v) => !v)) {
      errors._form = 'At least one field needs to be filled';
    }

    return errors;
  };

  return (
    <ConfirmationDialog
      {...props}
      open={open}
      onCancel={onClose}
      title={title}
      maxWidth={'sm'}
      hasCustomActions={true}
      cancelLabel="Cancel"
      confirmLabel="Update"
    >
      <Typography variant="captionPara" color={flashPackTheme.palette.principal.grey70}>
        Extras Costs are in {currencyDisplayed ? currencyDisplayed : '...'}
      </Typography>

      <Form<BulkUpdateExtrasCostsModalFormType>
        onSubmit={handleSubmitExtrasCosts}
        initialValues={initialFormValues}
        validate={validateForm}
        render={({ handleSubmit, submitFailed, errors }) => (
          <form onSubmit={(v) => void handleSubmit(v)}>
            <Stack direction="row" spacing={2}>
              <Stack alignItems="end">
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="bodySingle" textAlign="right">
                    Single Supplement
                  </Typography>
                  <TextField
                    name="amountSingleSupplement"
                    title="Enter a new cost to update all selected instances"
                    disabled={lockForm}
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    fieldProps={{
                      parse: FormParser.parseNumber,
                    }}
                    sx={{
                      width: flashPackTheme.spacing(10),
                      paddingY: flashPackTheme.spacing(1),
                    }}
                  />
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Tooltip title="Enter a new cost to update all selected instances">
                    <Typography variant="bodySingle">Pre Night</Typography>
                  </Tooltip>
                  <TextField
                    name="amountPreNight"
                    title="Enter a new cost to update all selected instances"
                    disabled={lockForm}
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    fieldProps={{
                      parse: FormParser.parseNumber,
                    }}
                    sx={{
                      width: flashPackTheme.spacing(10),
                      paddingY: flashPackTheme.spacing(1),
                    }}
                  />
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Tooltip title="Enter a new cost to update all selected instances">
                    <Typography variant="bodySingle">Post-Night</Typography>
                  </Tooltip>
                  <TextField
                    name="amountPostNight"
                    title="Enter a new cost to update all selected instances"
                    disabled={lockForm}
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    fieldProps={{
                      parse: FormParser.parseNumber,
                    }}
                    sx={{
                      width: flashPackTheme.spacing(10),
                      paddingY: flashPackTheme.spacing(1),
                    }}
                  />
                </Stack>
              </Stack>
              <Stack alignItems="end">
                <Stack direction="row" spacing={2} alignItems="center">
                  <Tooltip title="Enter a new cost to update all selected instances">
                    <Box>
                      <Typography variant="bodySingle" textAlign="right">
                        Pre-Night Shared Twin
                      </Typography>
                      <Typography variant="captionPara" textAlign="right">
                        (per person)
                      </Typography>
                    </Box>
                  </Tooltip>
                  <TextField
                    name="amountPreNightSharedTwin"
                    title="Enter a new cost to update all selected instances"
                    disabled={lockForm}
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    fieldProps={{
                      parse: FormParser.parseNumber,
                    }}
                    sx={{
                      width: flashPackTheme.spacing(10),
                      paddingY: flashPackTheme.spacing(1),
                    }}
                  />
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Tooltip title="Enter a new cost to update all selected instances">
                    <Box>
                      <Typography variant="bodySingle" textAlign="right">
                        Post-Night Shared Twin
                      </Typography>
                      <Typography variant="captionPara" textAlign="right">
                        (per person)
                      </Typography>
                    </Box>
                  </Tooltip>
                  <TextField
                    name="amountPostNightSharedTwin"
                    title="Enter a new cost to update all selected instances"
                    disabled={lockForm}
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    fieldProps={{
                      parse: FormParser.parseNumber,
                    }}
                    sx={{
                      width: flashPackTheme.spacing(10),
                      paddingY: flashPackTheme.spacing(1),
                    }}
                  />
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Tooltip title="Enter a new cost to update all selected instances">
                    <Typography variant="bodySingle">Optional</Typography>
                  </Tooltip>
                  <TextField
                    name="amountOptional"
                    title="Enter a new cost to update all selected instances"
                    disabled={lockForm}
                    inputProps={{
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    fieldProps={{
                      parse: FormParser.parseNumber,
                    }}
                    sx={{
                      width: flashPackTheme.spacing(10),
                      paddingY: flashPackTheme.spacing(1),
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
            {submitFailed && errors?._form && (
              <Typography variant="captionPara" color="error" sx={{ marginTop: 2 }}>
                {errors._form}
              </Typography>
            )}
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ marginTop: flashPackTheme.spacing(2) }}
            >
              <TextButton
                variant={'outlined'}
                sx={{ width: flashPackTheme.spacing(15) }}
                onClick={onClose}
              >
                Cancel
              </TextButton>
              <LoadingButton
                variant="contained"
                sx={{ width: flashPackTheme.spacing(18) }}
                disabled={lockForm}
                type="submit"
                data-testid="submit-bulk-update-extras-costs-button"
                loading={isSubmitting}
              >
                Update
              </LoadingButton>
            </Stack>
          </form>
        )}
      />
    </ConfirmationDialog>
  );
};
