import { Typography } from '@mui/material';
import { TextField } from 'mui-rff';
import { ConfirmationDialog } from '@src/design-system/dialogs/ConfirmationDialog';
import { FC } from 'react';
import { Form } from 'react-final-form';
import { flashPackTheme } from '@src/design-system/theme';
import { FormParser } from '@src/design-system/forms/parsers';
import { Scalars, UserRole } from '@flashpack/graphql';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';
import { pluralDepartures } from '@src/departures/utils';

type PropTypes = {
  open: boolean;
  onClose: () => void;
  confirmLoading: boolean;
  onSubmit: (values: BulkUpdateAmountUsdModalFormType) => Promise<void>;
  initialValues?: BulkUpdateAmountUsdModalFormType['amountUSD'];
  selectedDepartureIds: Array<Scalars['UUID']>;
  isPrice?: boolean;
};

export type BulkUpdateAmountUsdModalFormType = {
  amountUSD: number;
};

export const BulkUpdateAmountUsdModal: FC<PropTypes> = ({
  open,
  onClose,
  confirmLoading,
  onSubmit,
  initialValues,
  selectedDepartureIds,
  isPrice,
}) => {
  const { currentUser } = useAuthorization();
  const title = `Update ${pluralDepartures(selectedDepartureIds.length)}?`;

  return (
    <ConfirmationDialog
      open={open}
      onCancel={onClose}
      title={title}
      maxWidth={'sm'}
      cancelLabel="Cancel"
      confirmLabel="Update"
      confirmLoading={confirmLoading}
      confirmFormId="bulk-update-amount-usd-modal" // Form ID to connect to submit button
      disableConfirmButton={currentUser?.role !== UserRole.Superuser}
    >
      <Typography variant="captionPara" color={flashPackTheme.palette.principal.grey70}>
        {isPrice ? 'Prices' : 'Discounts'} are all converted from 🇺🇸USD (United States
        Dollar)
      </Typography>

      <Form<BulkUpdateAmountUsdModalFormType>
        onSubmit={onSubmit}
        initialValues={{
          amountUSD: initialValues,
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={(v) => void handleSubmit(v)} id="bulk-update-amount-usd-modal">
            <Typography variant="bodySingle">
              {isPrice ? 'Trip Price' : 'Promotional Discount'} (in 🇺🇸USD)
            </Typography>
            <TextField
              autoFocus
              name="amountUSD"
              required={true}
              disabled={currentUser?.role !== UserRole.Superuser || confirmLoading}
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
              fieldProps={{
                parse: FormParser.parseNumber,
              }}
              sx={{
                width: flashPackTheme.spacing(10),
                paddingY: flashPackTheme.spacing(1),
              }}
            />
          </form>
        )}
      />
    </ConfirmationDialog>
  );
};
