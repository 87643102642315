import { Chip, Stack, styled } from '@mui/material';
import { CommentIcon, TimePassedIcon } from 'design-system';
import { formatDistance } from 'date-fns';
import { toShortDurationFormat } from '@src/shared/dateUtils';
import { FC } from 'react';

interface PropTypes {
  commentCount: number;
  lastComment?: string | Date | null;
}

const CommentInfo = styled(Chip)(({ theme }) => ({
  color: theme.palette.principal.grey70,
  backgroundColor: theme.palette.principal.grey30,
  borderRadius: '2px',
  fontSize: theme.typography.micro.fontSize,
  lineHeight: theme.typography.micro.fontSize,
  padding: `${theme.spacing(1)} ${theme.spacing(0.5)}`,
}));

const CircleDivider = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.principal.grey50,
  width: 6,
  height: 6,
  borderRadius: '100%',
  margin: 0,
}));

const now = new Date();

export const CommentsMetadata: FC<PropTypes> = (props) => {
  if (props.commentCount === 0) {
    return null;
  }

  return (
    <CommentInfo
      label={
        <Stack
          direction="row"
          alignItems="center"
          gap={'5px'}
          sx={{ marginX: -1 }}
          divider={<CircleDivider />}
        >
          <Stack direction="row" gap={'4px'} alignItems="center">
            <CommentIcon sx={{ fontSize: 'inherit' }} />
            {props.commentCount}
          </Stack>

          {props.lastComment && (
            <Stack direction="row" gap={'4px'} alignItems="center">
              <TimePassedIcon sx={{ fontSize: 'inherit' }} />
              {toShortDurationFormat(
                formatDistance(new Date(props.lastComment), now, { addSuffix: false }),
                true,
              )}
            </Stack>
          )}
        </Stack>
      }
    />
  );
};
