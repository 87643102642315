import { FC } from 'react';
import { Zoom, styled } from '@mui/material';
import { AttentionRequiredIcon } from 'design-system';

interface PropTypes {
  flagged?: boolean;
}

const IconWrapper = styled('div')({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: -13,
  // together with top:0 and bottom:0 it will center the element vertically; can't use transform as it's used by parent
  margin: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export const FlagIcon: FC<PropTypes> = ({ flagged }) => (
  <Zoom in={flagged}>
    <IconWrapper>
      <AttentionRequiredIcon />
    </IconWrapper>
  </Zoom>
);
