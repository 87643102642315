import { styled, useTheme } from '@mui/material/styles';
import { Typography, Stack, Button } from '@mui/material';
import { Container } from '@mui/system';
import { FC, useState } from 'react';

export const CookieConsent: FC = () => {
  const [isCookieConsented, setIsCookieConsented] = useState<boolean>(
    localStorage.getItem('cookieConsent') != null,
  );
  const theme = useTheme();

  const dismissBanner: () => void = () => {
    localStorage.setItem('cookieConsent', `${Date.now()}`);
    setIsCookieConsented(true);
  };

  if (isCookieConsented) {
    return null;
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      sx={{ backgroundColor: theme.palette.principal.grey70 }}
      data-testid="cookie-consent-banner"
    >
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography
            variant="captionPara"
            padding={1}
            color={theme.palette.principal.white}
          >
            We use functional &amp; tracking cookies to deliver this experience. See our
            <PrivacyLink href="https://www.flashpack.com/privacy-policy/" target="_blank">
              Privacy Policy
            </PrivacyLink>
            for more.
          </Typography>
          <DismissButton onClick={dismissBanner}>Dismiss</DismissButton>
        </Stack>
      </Container>
    </Stack>
  );
};

const DismissButton = styled(Button)(({ theme }) => ({
  color: theme.palette.principal.white,
  fontWeight: 'bold',
  fontSize: '0.9em',
  '&:hover': {
    backgroundColor: theme.palette.principal.grey70,
  },
}));
DismissButton.defaultProps = {
  size: 'small',
};

const PrivacyLink = styled('a')(({ theme }) => ({
  color: theme.palette.principal.grey30,
  cursor: 'pointer',
  fontWeight: 'bold',
  marginInline: '0.2em',
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.principal.grey30,
  },
}));
