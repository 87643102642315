import { ApolloError } from '@apollo/client';

export const getServerValidationErrors = (error: ApolloError): string[] => {
  return error.graphQLErrors
    .filter((x) => x.extensions.code == 'SERVER_VALIDATION')
    .map((x) => x.message);
};

export const hasServerValidationErrors = (error?: ApolloError): boolean => {
  return !!error && getServerValidationErrors(error).length !== 0;
};

export const hasAnyErrorWithCode = (error?: unknown, code?: string): boolean => {
  if (!error || !code) {
    return false;
  }

  if (!(error instanceof ApolloError)) {
    return false;
  }

  return !!error.graphQLErrors.find((x) => x.extensions.code == code);
};
