import { createContext, useContext } from 'react';

export const TimelineContext = createContext<{
  timelineId: string;
  routeLocation: string;
}>({
  timelineId: '',
  routeLocation: '',
});

export const useTimelineContext = () => useContext(TimelineContext);
