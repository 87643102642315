import {
  endDayNotBeforeStartDayValidator,
  endTimeNotBeforeStartTimeValidator,
  startDayNotAfterEndDayValidator,
  startTimeNotAfterEndTimeValidator,
} from 'src/itinerary/common/itineraryValidators';

export type Engagement = {
  start: {
    day: number;
    time: string;
  };
  end: {
    day: number;
    time: string;
  };
};

export const engagementStartDayValidator = (_: number | null, formValues?: object) =>
  startDayNotAfterEndDayValidator(
    (formValues as Engagement).start,
    (formValues as Engagement).end,
  );

export const engagementStartTimeValidator = (_: string | null, formValues?: object) =>
  startTimeNotAfterEndTimeValidator(
    (formValues as Engagement).start,
    (formValues as Engagement).end,
  );

export const engagementEndDayValidator = (_: number | null, formValues?: object) =>
  endDayNotBeforeStartDayValidator(
    (formValues as Engagement).start,
    (formValues as Engagement).end,
  );

export const engagementEndTimeValidator = (_: string | null, formValues?: object) =>
  endTimeNotBeforeStartTimeValidator(
    (formValues as Engagement).start,
    (formValues as Engagement).end,
  );
