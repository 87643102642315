import { ItineraryDrawer } from 'src/itinerary/common/ItineraryDrawer';
import { TransferIcon } from 'design-system';
import {
  TransferForm,
  CreateTransferFormValues,
} from 'src/itinerary/transfer/common/TransferForm';
import { AddEngagementToTimelineDocument, TimelineDocument } from '@flashpack/graphql';
import { useToast } from 'src/shared/toast/useToast';
import { useRouting } from 'src/shared/useRouting';
import { TransferMapper } from '@src/itinerary/transfer/common/transferMapper';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useTimelineContext } from '@src/shared/timeline/TimelineContext';
import { useMutation } from '@apollo/client';

export const AddTransferPage = () => {
  const [addEngagement, { error }] = useMutation(AddEngagementToTimelineDocument);
  const { success, error: errorToast } = useToast();
  const { navigate } = useRouting();
  const { safeMutation } = useSafeMutation();
  const { timelineId } = useTimelineContext();

  const navigateToTimeline = () => {
    navigate('../');
  };

  const onSubmit = async (values: CreateTransferFormValues) => {
    await safeMutation(
      addEngagement({
        variables: {
          timelineId,
          engagement: TransferMapper.toCreateTransferInput(values),
        },
        refetchQueries: [TimelineDocument],
      }),
      {
        onSuccess: () => {
          success('Transfer successfully created');
          navigateToTimeline();
        },
        onUnexpectedError: () => {
          errorToast('There was an issue while saving this transfer - try again later');
        },
        onServerValidationError: (error) => {
          errorToast(error.message);
        },
      },
    );
  };

  return (
    <ItineraryDrawer
      title="Transfer"
      icon={<TransferIcon />}
      warnForChangesNotSaved={true}
    >
      <TransferForm
        saveBtnLabel="Add to itinerary"
        onSubmit={onSubmit}
        onCancel={navigateToTimeline}
        error={error}
      />
    </ItineraryDrawer>
  );
};
