import {
  Autocomplete as MuiRffAutocomplete,
  AutocompleteProps,
  showErrorOnBlur,
} from 'mui-rff';
import { CircleArrowDown } from 'design-system';
import { useTheme, styled } from '@mui/material/styles';
import { useForm } from 'react-final-form';

const AutocompleteWrapper = styled('div')(({ theme }) => ({
  width: '100%',

  '&[data-readonly="true"]': {
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.principal.grey30,
      color: theme.palette.principal.grey70,

      '&:focus-visible': {
        outline: 'none',
      },

      '& .MuiInputBase-input': {
        WebkitTextFillColor: theme.palette.principal.grey70,
      },
    },
    // no need to show popup indicator as it's readonly, we can't open the dropdown
    '& .MuiAutocomplete-popupIndicator': {
      visibility: 'hidden',
    },

    // label that's placed on top of input, a "previous" sibling
    '& .MuiFormLabel-root.Mui-disabled': {
      color: theme.palette.principal.grey70,
    },
  },
}));

export const AutocompleteField = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
) => {
  const theme = useTheme();
  const form = useForm();

  return (
    <AutocompleteWrapper data-readonly={props.readOnly}>
      <MuiRffAutocomplete
        onBlur={() => form.blur(props.name)}
        showError={showErrorOnBlur}
        popupIcon={<CircleArrowDown fill={theme.palette.primary.main} />}
        disabled={props.readOnly}
        // if readonly - no need to ask user to fill in the field
        label={props.readOnly ? '' : props.label}
        {...props}
      />
    </AutocompleteWrapper>
  );
};
