import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
  Theme,
  Typography,
  styled,
  useTheme,
} from '@mui/material';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { ArrowDownIcon } from 'design-system';

type DropdownOption = {
  label: string;
  value: string | number;
};

type BaseProps = {
  label: string;
  placeholder?: string;
  options: DropdownOption[];
  dropdownId: string;
  name: string;
  errorMessage?: string;
};

type DropdownProps = BaseProps & SelectProps;

const Dropdown: React.FC<DropdownProps> = (props) => {
  const {
    label,
    placeholder,
    options,
    dropdownId,
    disabled,
    native,
    errorMessage,
    ...rest
  } = props;

  const error = !!errorMessage || props.error;
  const value = props.value;

  const theme = useTheme();
  const placeholderDisplayed = value === '';

  const labelColor =
    value && !error ? theme.palette.brand.jungle : theme.palette.principal.grey70;

  return (
    <Box>
      <InputLabel sx={{ marginBottom: '-4px' }} id={`${dropdownId}-label`} shrink={true}>
        <Typography
          variant="captionPara"
          color={labelColor}
          sx={{ textTransform: 'none' }}
        >
          {label}
        </Typography>
      </InputLabel>

      <StyledSelect
        {...rest}
        fullWidth
        native={native}
        disabled={!!disabled}
        displayEmpty
        hasSelection={!!value}
        placeholderDisplayed={placeholderDisplayed}
        labelId={`${dropdownId}-label`}
        id={`${dropdownId}`}
        variant="outlined"
        inputProps={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          ...(props.inputProps || {}),
        }}
        IconComponent={ArrowDownIcon}
        MenuProps={{
          sx(theme) {
            return makeMenuProps(theme);
          },
        }}
        error={error}
      >
        {placeholder && native && (
          <option disabled value="">
            {placeholder}
          </option>
        )}
        {placeholder && !native && (
          <MenuItem disabled value="">
            <Typography
              variant={'bodyPara'}
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              color={theme.palette.principal.grey70}
            >
              {placeholder}
            </Typography>
          </MenuItem>
        )}
        {options.map((option, optionIdx) => {
          const isSelected = option.value === value;
          if (native) {
            return (
              <option key={`${optionIdx}-${option.value}`} value={option.value}>
                {option.label}
              </option>
            );
          }
          return (
            <MenuItem key={`${optionIdx}-${option.value}`} value={option.value}>
              <Typography
                variant={isSelected ? 'bodySingle' : 'bodyPara'}
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {option.label}
              </Typography>
            </MenuItem>
          );
        })}
      </StyledSelect>
      {error && errorMessage && (
        <Typography
          align="right"
          variant="captionPara"
          color={theme.palette.system.red100}
          className="Mui-error"
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

type ManualDropdownProps = BaseProps & SelectProps;

export const ManualDropdown: React.FC<ManualDropdownProps> = (props) => {
  return <Dropdown {...props} />;
};

type AllowedDropdownProps = Omit<SelectProps, 'value' | 'input'> & {
  onChange?: (e: string) => void;
};
type DropdownFormFieldProps = BaseProps & AllowedDropdownProps & FieldRenderProps<string>;

export const DropdownFormField: React.FC<DropdownFormFieldProps> = ({
  input,
  ...rest
}) => {
  const onChange = (e: SelectChangeEvent<unknown>) => {
    input.onChange(e);
    if (rest.onChange) {
      rest.onChange(e.target.value as string);
    }
  };
  return (
    <Dropdown
      {...rest}
      value={input.value}
      onChange={onChange}
      errorMessage={(rest.meta.touched && (rest.meta.error as string)) || ''}
      inputProps={{ onBlur: input.onBlur, onFocus: input.onFocus }}
    />
  );
};

const StyledSelect = styled(Select, {
  shouldForwardProp: (props) =>
    props !== 'placeholderDisplayed' &&
    props !== 'hasSelection' &&
    props !== 'disabled' &&
    props !== 'theme',
})<
  SelectProps & {
    hasSelection: boolean;
    disabled: boolean;
    placeholderDisplayed: boolean;
  }
>(({ theme, hasSelection, disabled, placeholderDisplayed }) => ({
  height: '40px',
  maxHeight: '40px',
  '&.MuiSelect-icon': {
    color: theme.palette.brand.jungle,
  },
  '.Mui-disabled': {
    color: theme.palette.principal.grey70,
  },
  '& .MuiNativeSelect-select, & .MuiSelect-select': {
    ...theme.typography.bodySingle,
    border: '1px solid',
    borderColor: 'transparent',
    backgroundColor: theme.palette.principal.grey30,
    color: hasSelection ? theme.palette.brand.jungle : theme.palette.principal.grey70,
    padding: '7px 32px 7px 16px',
  },
  '& .MuiSelect-select.Mui-selected': {
    backgroundColor: theme.palette.brand.jungle,
    color: theme.palette.principal.white,
  },
  // Conditional styles when an item is selected
  '& .MuiFormLabel-root': {
    color: theme.palette.principal.black,
  },
  ...(hasSelection && {
    '& .MuiNativeSelect-select, & .MuiSelect-select': {
      ...theme.typography.bodySingle,
      padding: '7px 32px 7px 16px',
      border: '1px solid',
      borderColor: 'transparent',
      backgroundColor: theme.palette.system.green10,
      textOverflow: 'ellipsis',
    },
  }),
  '&.Mui-error': {
    '& .MuiTypography-root ': !placeholderDisplayed && {
      color: theme.palette.system.red100,
      fontWeight: 500,
    },
    '.MuiInputBase-input': {
      backgroundColor: theme.palette.system.red10,
      color: theme.palette.principal.grey70,
    },
    '& .MuiSelect-select:hover': {
      borderColor: disabled ? 'transparent' : theme.palette.system.red100,
      transition: 'border-color 0.2s', // Adding the transition for hover animation
    },
  },

  '& legend': { display: 'none' },
  '& fieldset': { top: 0, border: 'none' },
}));

const makeMenuProps = (theme: Theme) => ({
  '& .MuiMenu-paper': {
    backgroundColor: theme.palette.principal.white,
    color: theme.palette.principal.black,
    borderRadius: '4px',
    '& .MuiList-root': {
      padding: 0,
    },
  },
  '& .MuiMenuItem-root': {
    padding: '0px 16px',
  },
  '& .MuiMenuItem-root:hover': {
    backgroundColor: theme.palette.principal.grey30,
    color: theme.palette.principal.black,
  },
  '& .MuiMenuItem-root.Mui-selected': {
    backgroundColor: theme.palette.system.green10,
    color: theme.palette.brand.jungle,
  },
});
