import parseISO from 'date-fns/parseISO';

/**
 * Parses the provided date in UTC+0 timezone.
 * @param dateString the date string to parse.
 * @returns the date.
 */
export const parseISOWithUTCZeroTimezone = (dateString?: string | null): Date => {
  return parseISO(appendUTCZeroTimezone(dateString));
};

const appendUTCZeroTimezone = (date?: string | null) => {
  if (date) {
    return date + '+0:00';
  }
  return '';
};

// If we ever need more customization here and the lib still doesn't have it,
// we might use this trick from lib issues: https://github.com/date-fns/date-fns/issues/1706#issuecomment-836601089
export const toShortDurationFormat = (
  formattedDuration: string,
  skipUncertaintyPrefix = false,
) => {
  let durationInShortFormat = formattedDuration
    .replace('hours', 'h')
    .replace('hour', 'h')
    .replace('minutes', 'm')
    .replace('minute', 'm')
    .replace('days', 'd')
    .replace('years', 'y')
    .replace('year', 'y');

  if (skipUncertaintyPrefix) {
    durationInShortFormat = durationInShortFormat
      .replace('about', '')
      .replace('over', '>')
      .replace('less than a', '<1'); // 0..59 seconds case
  }

  return durationInShortFormat;
};

const longMonthDateYearFormat = new Intl.DateTimeFormat('en-GB', {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
});

export const dateToLongMonthDateYearFormat = (date: Date): string => {
  const dateInParts = longMonthDateYearFormat.formatToParts(date);
  const partValues = dateInParts.map((p) => {
    if (p.type === 'day') {
      return addOrdinalToNumber(p.value);
    }
    return p.value;
  });
  return partValues.join('');
};

export const addOrdinalToNumber = (value: string) => {
  if (['11', '12', '13'].includes(value)) {
    return value + 'th';
  }
  const finalNumber = value.slice(-1);
  switch (finalNumber) {
    case '1':
      return value + 'st';
    case '2':
      return value + 'nd';
    case '3':
      return value + 'rd';
    default:
      return value + 'th';
  }
};
