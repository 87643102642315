import { polyfillCountryFlagEmojis } from 'country-flag-emoji-polyfill';

/**
 * In Windows OS, at the moment of writing this, there are some inconsistencies
 * across browsers regarding emoji for country flags. We use a 3rd party polyfill
 * to enable this functionality in one line.
 *
 * The setup is pretty easy:
 * 1. to import this polyfill and run it
 * 2. to add a special font to the root HTML component, either via global styles,
 *    or via styling library we use.
 *
 * reference: https://github.com/talkjs/country-flag-emoji-polyfill
 */
export function initiateCountryFlagEmojis() {
  polyfillCountryFlagEmojis();
}
