import { useMutation, useQuery } from '@apollo/client';
import { MenuItem, Select, SelectChangeEvent, Skeleton, styled } from '@mui/material';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';
import { isVisibleTo } from '@src/authentication/Protected';
import {
  ItineraryDocument,
  SupportedCurrencySymbol,
  UpdateItineraryCostCurrencyDocument,
  UserRole,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';
import { useSafeMutation } from '@src/shared/useSafeMutation';

export const currencyOptions = [
  {
    value: SupportedCurrencySymbol.Usd,
    label: '🇺🇸 United States Dollar (USD)',
  },
  {
    value: SupportedCurrencySymbol.Gbp,
    label: '🇬🇧 British Pound (GBP)',
  },
  {
    value: SupportedCurrencySymbol.Eur,
    label: '🇪🇺 Euro (EUR)',
  },
  {
    value: SupportedCurrencySymbol.Aud,
    label: '🇦🇺 Australian Dollar (AUD)',
  },
  {
    value: SupportedCurrencySymbol.Cad,
    label: '🇨🇦 Canadian Dollar (CAD)',
  },
  {
    value: SupportedCurrencySymbol.Hkd,
    label: '🇭🇰 Hong Kong Dollar (HKD)',
  },
  {
    value: SupportedCurrencySymbol.Aed,
    label: '🇦🇪 Arab Emirates Dollar (AED)',
  },
  {
    value: SupportedCurrencySymbol.Jpy,
    label: '🇯🇵 Japanese Yen (JPY)',
  },
  {
    value: SupportedCurrencySymbol.Isk,
    label: '🇮🇸 Icelandic Krona (ISK)',
  },
  {
    value: SupportedCurrencySymbol.Zar,
    label: '🇿🇦 South African Rand (ZAR)',
  },
];

const CurrencySelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.principal.white,
  color: theme.palette.brand.jungle,
  width: '150px',
  textOverflow: 'ellipsis',
}));

export const CurrencySelectDropdown = ({ itineraryId }: { itineraryId: string }) => {
  const [updateItineraryCostCurrency] = useMutation(UpdateItineraryCostCurrencyDocument);
  const { success, error } = useToast();
  const { safeMutation } = useSafeMutation();
  const { data: itineraryData, loading } = useQuery(ItineraryDocument, {
    variables: { id: itineraryId },
  });
  const { currentUser } = useAuthorization();

  const isUserPermittedChangeCurrency = isVisibleTo(currentUser, [
    UserRole.Flashpack,
    UserRole.Superuser,
  ]);

  if (loading) {
    return <Skeleton variant="rounded" width={150} height={40} />;
  }

  const currentValue = itineraryData?.itinerary.costCurrency;

  const onChange = async (event: SelectChangeEvent<unknown>) => {
    await safeMutation(
      updateItineraryCostCurrency({
        variables: {
          input: {
            id: itineraryId,
            costCurrency: event.target.value as SupportedCurrencySymbol,
          },
        },
      }),
      {
        onSuccess: () => success('Updated currency cost for itinerary.'),
        onError: () => error('Unable to update currency for itinerary costs.'),
      },
    );
  };

  return (
    <>
      <CurrencySelect
        id="select-currency"
        name="select.currency"
        defaultValue={currentValue}
        fullWidth
        onChange={(event) => void onChange(event)}
        data-testid="select-currency"
        disabled={!isUserPermittedChangeCurrency}
      >
        {currencyOptions.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </CurrencySelect>
    </>
  );
};
