import { Radio as MuiRadio, RadioProps as MuiRadioProps, useTheme } from '@mui/material';
import { RadioCheckedIcon, RadioUncheckedIcon } from 'design-system';
import { FC, useMemo } from 'react';

export type RadioProps = MuiRadioProps;

export const Radio: FC<RadioProps> = (props) => {
  const theme = useTheme();

  const color = useMemo(() => {
    if (props.disabled) {
      return theme.palette.principal.grey70;
    }
    return theme.palette.brand.jungle;
  }, [theme, props.disabled]);

  return (
    <MuiRadio
      icon={<RadioUncheckedIcon />}
      checkedIcon={<RadioCheckedIcon sx={{ color }} />}
      {...props}
    />
  );
};
