import React, { useCallback, useEffect } from 'react';
import {
  Box,
  BoxProps,
  Collapse,
  FormControl,
  FormControlLabel,
  RadioGroup,
  RadioGroupProps,
  styled,
} from '@mui/material';

import { Radio } from '../radio-button';

export const StyledFormControlWrapper = styled(FormControl)({
  width: '100%',
});

export const StyledRadioGroupWrapper = styled(RadioGroup)({
  gap: 12,
});

export const BaseRadioAccordionItem = styled(Box)(({ theme }) => ({
  border: `1px solid`,
  borderRadius: 8,
  borderColor: theme.palette.principal.grey50,
  padding: theme.spacing(2),
  margin: 0,
  cursor: 'pointer',
  ...theme.typography['Body M'],
}));

export const DisabledRadioAccordionItem = styled(BaseRadioAccordionItem)<BoxProps>(
  ({ theme }) => ({
    opacity: 0.7,
    backgroundColor: `${theme.palette.grey[300]} !important`,
    pointerEvents: 'none',
    '& .MuiFormControlLabel-label': {
      ...theme.typography['Body M'],
    },
  }),
);

interface RadioAccordionItemProps extends Omit<BoxProps, 'content'> {
  control: React.ReactNode;
  icon: React.ReactNode;
  content?: React.ReactNode;
  selected?: boolean;
  disabled?: boolean;
}

const RadioAccordionItem: React.FC<RadioAccordionItemProps> = ({
  control,
  icon,
  selected,
  content,
  disabled,
  ...rest
}) => {
  let StyledRadioAccordionItem = BaseRadioAccordionItem;
  if (disabled) {
    StyledRadioAccordionItem = DisabledRadioAccordionItem;
  }

  return (
    <StyledRadioAccordionItem {...rest}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={selected && content ? 1 : 0}
        sx={{
          transition: 'margin 300ms',
          '& .MuiTypography-root': { fontWeight: selected ? 600 : 400 },
        }}
      >
        {control}
        {icon}
      </Box>
      <Collapse
        unmountOnExit
        in={selected}
        sx={{ cursor: 'initial', marginLeft: '38px' }}
      >
        {content}
      </Collapse>
    </StyledRadioAccordionItem>
  );
};

export type RadioAccordionGroupOption = {
  value: string;
  label: string | React.ReactNode; // list item label
  content: React.ReactNode; // expandable content
  endAdornment?: React.ReactNode; // icon or another text aligned to the right
};

export interface RadioAccordionGroupProps extends RadioGroupProps {
  options: RadioAccordionGroupOption[];
  defaultValue?: string;
  onSelectedChange?: (selectedValue: string) => void;
  testid?: string;
}

/**
 * A radio group with expandable radio items.
 */
export const RadioAccordionGroup: React.FC<RadioAccordionGroupProps> = ({
  options,
  defaultValue,
  onSelectedChange,
  testid,
}) => {
  const [value, setValue] = React.useState(defaultValue || null);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    },
    [setValue],
  );

  // reuse click handler between list items; detect clicked item by a custom data attribute
  const handleItemClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    const selectedValue = e.currentTarget.getAttribute('data-value') || null;
    setValue(selectedValue);
  }, []);

  // tell parent value has changed every time it changes
  useEffect(() => {
    if (value) {
      onSelectedChange?.(value);
    }
  }, [value, onSelectedChange]);

  return (
    <StyledFormControlWrapper>
      <StyledRadioGroupWrapper data-testid={testid} value={value} onChange={handleChange}>
        {options.map((option) => (
          <RadioAccordionItem
            key={option.value}
            data-value={option.value}
            onClick={handleItemClick}
            control={
              <StyledFormControlLabel
                value={option.value}
                selected={value === option.value}
                control={<Radio />}
                label={option.label}
              />
            }
            icon={option.endAdornment}
            selected={value === option.value}
            content={option.content}
          />
        ))}
      </StyledRadioGroupWrapper>
    </StyledFormControlWrapper>
  );
};

const StyledFormControlLabel = styled(FormControlLabel, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<{ selected?: boolean }>(({ theme, selected }) => ({
  fontWeight: selected ? 600 : 400,
  '& .MuiFormControlLabel-label': {
    ...theme.typography['Body M'],
    fontWeight: selected ? 600 : 400,
  },
}));
