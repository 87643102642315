import { Dispatch, SetStateAction } from 'react';
import { flashPackTheme, Typography } from 'design-system';
import { DaysFilter } from './DaysFilter';
import { ReactComponent as NoResultsFromSearchIcon } from './NoResultsFromSearch.svg';

export const NoMatchesFound = ({
  daysArray,
  selectedDay,
  setSelectedDay,
}: {
  daysArray: number[];
  selectedDay: number | null;
  setSelectedDay: Dispatch<SetStateAction<number | null>>;
}) => {
  return (
    <>
      {selectedDay && (
        <DaysFilter
          selectedDay={selectedDay}
          daysArray={daysArray}
          setSelectedDay={setSelectedDay}
        />
      )}
      <Typography
        variant="h3"
        color={flashPackTheme.palette.principal.grey70}
        my={2}
        mt={4}
      >
        None of the departures match your search!
      </Typography>
      <NoResultsFromSearchIcon />
    </>
  );
};
