import { Dialog, Typography, Box, Button, Stack } from '@mui/material';
import { FC, useCallback } from 'react';
import { Divider, flashPackTheme } from 'design-system';
import MoveItemsLaterAnimation from './moveItemsLaterAnimation.gif';
import MoveItemsEarlierAnimation from './moveItemsEarlierAnimation.gif';
import MoveItemsEarlierAndLaterAnimation from './moveItemsEarlierAndLaterAnimation.gif';
import { DirectionOfOverlapOnBulkUpdate } from '@flashpack/graphql';

interface PropTypes {
  open: boolean;
  onConfirm: () => Promise<void>;
  onClose: () => void;
  movingItemTitle: string;
  directionOfOverlapOnBulkUpdate: DirectionOfOverlapOnBulkUpdate;
}

export const AutoResolveConflictsModal: FC<PropTypes> = (props) => {
  const { open, onConfirm, onClose, directionOfOverlapOnBulkUpdate, movingItemTitle } =
    props;

  const isMovingEarlier =
    directionOfOverlapOnBulkUpdate.willConflictingItemsAutoResolveToMoveEarlier;
  const isMovingLater =
    directionOfOverlapOnBulkUpdate.willConflictingItemsAutoResolveToMoveLater;

  const getAnimation = useCallback(() => {
    if (isMovingEarlier && isMovingLater) {
      return MoveItemsEarlierAndLaterAnimation;
    } else if (isMovingEarlier) {
      return MoveItemsEarlierAnimation;
    } else if (isMovingLater) {
      return MoveItemsLaterAnimation;
    }
  }, [isMovingEarlier, isMovingLater]);

  const movingItemTitleText = (
    <span
      style={{
        backgroundColor: flashPackTheme.palette.system.green30,
        borderRadius: '5px',
        padding: '2px',
      }}
    >
      {movingItemTitle}
    </span>
  );

  const otherItemsText = (
    <span style={{ color: flashPackTheme.palette.system.red100 }}>other events</span>
  );

  const getEarlierAndOrLaterText = useCallback(() => {
    if (isMovingEarlier && isMovingLater) {
      return 'start earlier and end later';
    } else if (isMovingEarlier) {
      return 'start earlier';
    } else if (isMovingLater) {
      return 'end later';
    }
  }, [isMovingEarlier, isMovingLater]);

  const getBeforeAndOrAfterText = useCallback(() => {
    if (isMovingEarlier && isMovingLater) {
      return 'before and after';
    } else if (isMovingEarlier) {
      return 'before';
    } else if (isMovingLater) {
      return 'after';
    }
  }, [isMovingEarlier, isMovingLater]);

  const onConfirmHandler = () => {
    // The button click handler does not accept promises, so we need to catch the promise
    onConfirm().catch(() => {});
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { width: '780px', maxWidth: 'none' } }}
    >
      <Stack direction="row">
        <Stack padding={4} sx={{ background: flashPackTheme.palette.principal.grey30 }}>
          <Typography variant="pageHeader" mb={2}>
            Auto Resolve Conflicts?
          </Typography>
          <Typography variant="bodyPara" my={3}>
            Updating {movingItemTitleText} to <b>{getEarlierAndOrLaterText()}</b> would
            cause it to conflict with {otherItemsText} scheduled at the same time.
          </Typography>
          <Typography variant="bodyPara">
            Click “Auto Resolve” to automatically move those {otherItemsText} so that they
            happen <b>{getBeforeAndOrAfterText()}</b> {movingItemTitleText} starts.
          </Typography>
          <Typography variant="bodyPara" my={3}>
            Those {otherItemsText} will fill any <b>unscheduled time</b> in the departures
            - like in this example 👉
          </Typography>
        </Stack>
        <Box>
          <img src={getAnimation()} />
        </Box>
      </Stack>
      <Divider sx={{ m: 0 }} />
      <Stack direction="row" justifyContent="space-between" padding={2.5}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onConfirmHandler}>
          Auto Resolve
        </Button>
      </Stack>
    </Dialog>
  );
};
