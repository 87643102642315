import React, { FC, InputHTMLAttributes } from 'react';
import { Checkbox, CheckboxProps } from './Checkbox';
import { FieldRenderProps } from 'react-final-form';

type AllowedCheckboxProps = Omit<CheckboxProps, 'checked' | 'onChange'> & {
  onChange?: () => void;
};
type CheckboxFieldPropTypes = FieldRenderProps<boolean> & AllowedCheckboxProps;

export const CheckboxFormField: FC<CheckboxFieldPropTypes> = (props) => {
  const { input, meta, validate, ...rest } = props;

  const onChange = (e: React.ChangeEvent) => {
    input.onChange(e);
    rest.onChange?.();
  };

  return (
    <Checkbox
      {...rest}
      checked={input.checked}
      error={Boolean(meta.touched && meta.error)}
      onChange={onChange}
      onFocus={input.onFocus}
      onBlur={input.onBlur}
      inputProps={{ 'data-testid': input.name } as InputHTMLAttributes<HTMLInputElement>}
    />
  );
};
