import { Stack, Typography, useTheme } from '@mui/material';
import { ManualDropdown } from 'design-system';
import { ExclamationIcon } from 'design-system';
import { orderedCallingCodes } from './phoneNumberUtils';
import { FreeTextMui } from 'design-system';

import {
  formatIncompletePhoneNumber,
  parseIncompletePhoneNumber,
} from 'libphonenumber-js';

import { FC, useEffect, useState, useMemo } from 'react';

const options = orderedCallingCodes.map((code) => ({
  value: code.callingCode,
  label: code.label,
}));

export interface PhoneNumberFieldProps {
  label?: string;
  value: string;
  setValue: (newValue: string) => void;
  defaultCallingCode: string;
  freeTextProps?: React.ComponentProps<typeof FreeTextMui>;
}

export const PhoneNumberField: FC<PhoneNumberFieldProps> = ({
  label,
  value,
  setValue,
  defaultCallingCode,
  freeTextProps,
}) => {
  const [callingCode, setCallingCode] = useState(defaultCallingCode);
  const [nationalPhoneNumber, setNationalPhoneNumber] = useState('');
  const theme = useTheme();

  useEffect(() => {
    const formatted = formatIncompletePhoneNumber(value);
    const resolvedCallingCode = value ? formatted.split(' ')[0] : defaultCallingCode;
    setCallingCode(resolvedCallingCode);

    const nationalPhoneNumber = formatted.split(' ').slice(1).join('');
    setNationalPhoneNumber(nationalPhoneNumber);
  }, [value, defaultCallingCode]);

  const onNationalNumberChange = (newNationalPhoneNumber: string) => {
    setValue(parseIncompletePhoneNumber(`${callingCode}${newNationalPhoneNumber}`));
  };

  const onCallingCodeChange = (newCallingCode: string) => {
    setValue(parseIncompletePhoneNumber(`${newCallingCode}${nationalPhoneNumber}`));
  };

  const formatted = formatIncompletePhoneNumber(value);
  const formattedNationalNumber = formatted.split(' ').slice(1).join(' ');

  const helperTextColor = useMemo(() => {
    if (freeTextProps?.error) {
      return theme.palette.system.red100;
    }
    return theme.palette.principal.grey70;
  }, [freeTextProps?.error, theme]);

  return (
    <Stack>
      {label && (
        <Typography sx={{ mb: 0.5, color: helperTextColor }} variant="label_Figma">
          {label}
        </Typography>
      )}
      <Stack gap={2} direction="row" alignItems="flex-start">
        <ManualDropdown
          label=""
          native
          sx={{ mt: '3px', width: '92px' }}
          name="phone-number"
          dropdownId="phone-input"
          data-testid="phone-country-code-dropdown"
          onChange={(e) => onCallingCodeChange(e.target.value as string)}
          value={callingCode}
          options={options}
        />
        <FreeTextMui
          fullWidth
          value={formattedNationalNumber}
          onChange={(e) => onNationalNumberChange(e.target.value)}
          {...freeTextProps}
          inputProps={{
            type: 'tel',
            ...freeTextProps?.inputProps,
          }}
          InputProps={{
            endAdornment: freeTextProps?.error && (
              <ExclamationIcon style={{ pointerEvents: 'none' }} />
            ),
            ...freeTextProps?.InputProps,
          }}
        />
      </Stack>
    </Stack>
  );
};
