import { useLazyQuery } from '@apollo/client';
import { DownloadOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Tooltip, useTheme } from '@mui/material';
import { Protected } from '@src/authentication/Protected';
import { StopPropagationWrapper } from '@src/design-system/StopPropagationWrapper';
import { DepartureManifestCsvDocument, UserRole } from '@flashpack/graphql';
import csvDownload from 'json-to-csv-export';

export const DownloadDepartureManifest = ({ departureId }: { departureId: string }) => {
  const theme = useTheme();
  const [getDepartureManifestCsv, { loading: loadingDepartureManifestCsv }] =
    useLazyQuery(DepartureManifestCsvDocument, {
      onCompleted: (response) => {
        const manifestData = response.departure.manifestCsvData;
        csvDownload({
          data: manifestData.rows.map(({ __typename, ...v }) => v),
          headers: manifestData.headers,
          delimiter: ',',
          filename: manifestData.fileName,
        });
      },
    });

  return (
    <StopPropagationWrapper>
      <Protected roles={[UserRole.Flashpack]}>
        <Box sx={{ width: '24px', mt: 1 }}>
          <Tooltip title="Download Onboarding">
            {loadingDepartureManifestCsv ? (
              <CircularProgress size={15} />
            ) : (
              <DownloadOutlined
                sx={{ cursor: 'pointer', color: theme.palette.principal.grey70 }}
                onClick={() =>
                  void getDepartureManifestCsv({
                    variables: { departureId },
                  })
                }
              />
            )}
          </Tooltip>
        </Box>
      </Protected>
    </StopPropagationWrapper>
  );
};
