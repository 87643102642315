import { useEffect, useState } from 'react';
import { Container, Drawer, Stack } from '@mui/material';
import {
  useAuthentication,
  useFirebaseAuth,
} from '@src/shared/authentication/useAuthentication';
import { CookieConsent } from './CookieConsent';
import { useAuthorization } from '../authentication/AuthorizationProvider';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { AppBar } from './AppBar';
import { NavigationDrawer } from './NavigationDrawer';
import { Snackbar } from './Snackbar';
import { Footer } from '@src/shared/footer';
import { useDrawerContext } from '@src/shared/context/DrawerContext';
import { ItineraryDrawerSize } from '@src/itinerary/common/ItineraryDrawer';
import { useApolloClient } from '@apollo/client';
import { sidebarWidths } from '@src/shared/edge-sidebar/EdgeSidebar';

export const AppLayout = () => {
  const [isMenuDrawerOpen, setIsMenuDrawerOpen] = useState<boolean>(false);
  const { currentUser } = useAuthorization();
  const { authenticated } = useAuthentication();
  const { signOut } = useFirebaseAuth();
  const navigate = useNavigate();
  const { timelineDrawerOpen, sidebarOpen } = useDrawerContext();
  const apolloClient = useApolloClient();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { adventureId } = useParams();

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const calculateContainerSxProps = (
    timelineDrawerOpen: boolean,
    sidebarOpen: boolean,
    sidebarVisible: boolean,
  ) => {
    if (timelineDrawerOpen) {
      return {
        ml: 0,
        width: {
          xs: windowWidth - ItineraryDrawerSize.small,
          xl: windowWidth - ItineraryDrawerSize.big,
        },
      };
    }
    if (sidebarVisible) {
      const sidebarWidth = sidebarOpen ? sidebarWidths.open : sidebarWidths.closed;
      const marginLeft = {
        xs: 0,
        lg: sidebarOpen ? 0 : 'auto',
      };
      return {
        ml: marginLeft,
        width: windowWidth - sidebarWidth,
      };
    }
    return {};
  };

  const containerSxProps = calculateContainerSxProps(
    timelineDrawerOpen,
    sidebarOpen,
    !!adventureId,
  );

  const onSignOut = () => {
    void signOut();
    void apolloClient.resetStore();
    navigate('/');
  };

  return (
    <>
      <Stack sx={{ minHeight: ['100vh', '--webkit-fill-available'], display: 'flex' }}>
        <AppBar
          openNavDrawer={() => setIsMenuDrawerOpen(true)}
          currentUser={currentUser}
          authenticated={authenticated}
          signOut={onSignOut}
        />
        <Drawer open={isMenuDrawerOpen} onClose={() => setIsMenuDrawerOpen(false)}>
          <NavigationDrawer />
        </Drawer>
        <CookieConsent />
        <Container sx={{ flexGrow: 1, ...containerSxProps }}>
          <Snackbar limit={3} data-testid="toast" />
          <Outlet />
        </Container>
        <Footer />
      </Stack>
    </>
  );
};
