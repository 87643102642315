import { ApolloError } from '@apollo/client';
import { getServerValidationErrors } from 'src/shared/errorUtils';
import { FormHelperText } from '@mui/material';

export const ServerValidationErrors = ({ error }: { error?: ApolloError }) => {
  if (!error) {
    return null;
  }

  const serverValidationErrors = getServerValidationErrors(error);

  if (serverValidationErrors.length == 0) {
    return null;
  }

  return (
    <div>
      {serverValidationErrors.map((errorMessage) => (
        <FormHelperText key={errorMessage} error>
          {errorMessage}
        </FormHelperText>
      ))}
    </div>
  );
};
