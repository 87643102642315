import { createContext, FC, ReactNode, useContext, useState } from 'react';

type Map = google.maps.Map;
type PlacesService = google.maps.places.PlacesService;

type GooglePlacesContextType = {
  initialized: boolean;
  initialize: (_map: Map) => void;
  findPlaceFromQuery: (
    _query: string,
    _lat: number,
    _lng: number,
  ) => Promise<{ place_id?: string }>;
};

const defaultValue = {
  initialized: false,
  initialize: (_map) => {},
  findPlaceFromQuery: () => new Promise((resolve) => resolve({})),
} as GooglePlacesContextType;

export const GooglePlacesContext = createContext<GooglePlacesContextType>(defaultValue);

export const GooglePlacesProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [placesService, setPlacesService] = useState<PlacesService | null>(null);

  const initialize = (map: Map) => {
    const service = new google.maps.places.PlacesService(map);
    setPlacesService(service);
  };

  const findPlaceFromQuery = (
    query: string,
    lat: number,
    lng: number,
  ): Promise<{ place_id?: string }> => {
    const request: google.maps.places.FindPlaceFromQueryRequest = {
      query,
      fields: ['place_id'],
      locationBias: {
        lat,
        lng,
      },
    };

    return new Promise((resolve) => {
      placesService?.findPlaceFromQuery(request, (placesResult) => {
        const moreThanOne = placesResult?.length !== 1;
        // if result is not unique return undefined
        resolve({ place_id: moreThanOne ? undefined : placesResult?.[0].place_id });
      });
    });
  };

  return (
    <GooglePlacesContext.Provider
      value={{ initialize, findPlaceFromQuery, initialized: Boolean(placesService) }}
    >
      {children}
    </GooglePlacesContext.Provider>
  );
};

export const useGooglePlaces = () => useContext(GooglePlacesContext);
