import { useState } from 'react';
import { useLocation } from 'react-router-dom';

const MESSAGE_THREAD_ANCHOR_PREFIX = 'message-thread-';

export const useMessageThreadTabOrDefault = (tab: string) => {
  const { hash } = useLocation();
  let defaultTab: string = tab;
  if (hash.startsWith(`#${MESSAGE_THREAD_ANCHOR_PREFIX}`)) {
    defaultTab = 'message-thread';
  }
  return useState(defaultTab);
};

export const currentMessageThreadIdFromHash = (hash: string) => {
  return hash.substring(MESSAGE_THREAD_ANCHOR_PREFIX.length + 1);
};

export const hasCurrentMessageThreadHash = (hash: string) => {
  return hash.startsWith(`#${MESSAGE_THREAD_ANCHOR_PREFIX}`);
};

export const MessageThreadAnchor = ({ id }: { id: string }) => {
  return [MESSAGE_THREAD_ANCHOR_PREFIX, id].join('');
};

export const isRowComplete: (
  headerKeys: string[],
  rowValues: Record<string, number | string | boolean | null>,
) => boolean = (headerKeys, rowValues) => {
  return headerKeys.every(
    (key) => rowValues.hasOwnProperty(key) && rowValues[key] !== null,
  );
};

export const isRowPartiallyComplete: (
  headerKeys: string[],
  rowValues: Record<string, number | string | boolean | null>,
) => boolean = (headerKeys, rowValues) => {
  return (
    headerKeys.some((key) => rowValues.hasOwnProperty(key) && rowValues[key] !== null) &&
    !isRowComplete(headerKeys, rowValues)
  );
};
