import { useState } from 'react';
import { Form } from 'react-final-form';
import { TextField, showErrorOnBlur } from 'mui-rff';
import { Stack, styled, Typography } from '@mui/material';
import { useFirebaseAuth } from '@src/shared/authentication/useAuthentication';

import { captureException } from '@sentry/react';
import { Validator, composeValidators } from 'src/design-system/forms/validators';
import { useToast } from '@src/shared/toast/useToast';
import { Info } from '@src/authentication/Info';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuthorization } from './AuthorizationProvider';
import { LoadingButton } from '@mui/lab';
import { LocationState } from '@src/shared/useRouting';

type LoginForm = {
  email: string;
};

export const LoginPage = () => {
  const { sendSignInLinkToEmail } = useFirebaseAuth();
  const { currentUser, loadingAuthorization } = useAuthorization();
  const [emailSent, setEmailSent] = useState(false);
  const { error: errorToast } = useToast();
  const location = useLocation();

  const onSubmit = async ({ email }: LoginForm) => {
    await sendSignInLinkToEmail({
      email,
      onSuccess: () => setEmailSent(true),
      onUnknownUser: () =>
        errorToast(
          'This email address has not been granted access to any Flash Packs. Please email your Adventure Ops contact at Flash Pack to request access.',
        ),
      onUnexpectedError: (e) => {
        errorToast('Unexpected error occurred during login');
        captureException(e, { user: { email } });
      },
    });
  };

  if (emailSent) {
    return (
      <Info
        title="Email sent"
        paragraphs={[
          'An email containing a login link has been sent to the address you provided, click it to log in.',
          'Please make sure you use the same browser when opening the log in link.',
        ]}
      />
    );
  }

  if (loadingAuthorization) {
    return null;
  }

  if (currentUser) {
    const redirectBack = (location?.state as LocationState)?.redirectBack ?? '/';
    return <Navigate replace to={redirectBack} />;
  }

  return (
    <Form<LoginForm>
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting }) => {
        return (
          <Container>
            <ProductName>Adventure Manager</ProductName>
            <Typography
              variant="captionSingle"
              sx={{ color: 'principal.grey70', marginBottom: 3 }}
            >
              By Flash Pack
            </Typography>
            <form
              onSubmit={(event) => {
                void handleSubmit();
                event?.preventDefault();
              }}
            >
              <Stack gap={1} alignItems={'center'}>
                <TextField
                  type="email"
                  helperText=" "
                  label="Email address"
                  name="email"
                  showError={showErrorOnBlur}
                  fieldProps={{
                    validate: composeValidators(Validator.required, Validator.validEmail),
                  }}
                  size="small"
                  data-testid="email-input"
                  autoComplete="email"
                />
                <LoadingButton
                  loading={submitting}
                  disabled={submitting}
                  type="submit"
                  variant="contained"
                  data-testid="login-button"
                  sx={{ width: 'fit-content', px: 8 }}
                >
                  {submitting ? 'Sending…' : 'Log in with Email'}
                </LoadingButton>
              </Stack>
            </form>
          </Container>
        );
      }}
    />
  );
};

const ProductName = styled(Typography)(({ theme }) => ({
  marginTop: 0,
  marginBottom: theme.spacing(1),
  fontFamily: 'Euclid Flash Pack, Euclid Square, Heebo, sans-serif',
  textTransform: 'uppercase',
}));
ProductName.defaultProps = {
  variant: 'h2',
  as: 'h1',
};

const Container = styled('div')(({ theme }) => ({
  textAlign: 'center',
  maxWidth: theme.spacing(50),
  margin: theme.spacing(5, 'auto', 0),

  [theme.breakpoints.up('md')]: {
    margin: theme.spacing(15, 'auto', 0),
  },
}));
