import { ItineraryWithExternalUsersDocument } from '@flashpack/graphql';
import { ItineraryDetailsPageSkeleton } from 'src/itinerary/itinerary-details/ItineraryDetailsPageSkeleton';
import { GenericError } from 'design-system';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { TimelineSection } from '@src/shared/timeline/TimelineSection';
import { RoutePath } from '@src/shared/routePath';
import { useQuery } from '@apollo/client';

export const ItineraryDetailsPage = () => {
  const { adventureId, itineraryId } = useRequiredParams(['adventureId', 'itineraryId']);

  const { data, loading, error } = useQuery(ItineraryWithExternalUsersDocument, {
    variables: { id: itineraryId },
  });

  if (loading || !data) {
    return <ItineraryDetailsPageSkeleton />;
  }
  if (error) {
    return <GenericError error={error} />;
  }

  const isItineraryLocked = !!data?.itinerary.locked;
  return (
    <TimelineSection
      locked={isItineraryLocked}
      timelineId={data?.itinerary.timeline.id}
      emptyTimelineMessage="Use the add button “+” to add components to this itinerary"
      lockedTimelineMessage="This itinerary has departures running and cannot be edited."
      routeLocation={RoutePath.ITINERARY_TIMELINE.generatePath(adventureId, itineraryId)}
    />
  );
};
