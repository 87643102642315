import { Grid, Stack, Typography } from '@mui/material';
import { Message, MessageThreadDocument } from '@flashpack/graphql';
import { AddMessage } from './AddMessage';
import { Messages } from './Messages';
import { useQuery } from '@apollo/client';

type PropTypes = {
  threadId: string;
  onCancel: () => void;
};

export const MessagesSection = ({ threadId, onCancel }: PropTypes) => {
  const { data } = useQuery(MessageThreadDocument, {
    variables: {
      id: threadId,
    },
  });

  const date = data ? new Date(data.messageThread.createdAt) : new Date();

  const threadDate = date.toLocaleString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  return data ? (
    <Stack gap={1}>
      <Grid container>
        <Grid item container xs={9} alignItems="flex-end">
          <Typography
            variant="bodySingle"
            display="inline"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {data.messageThread.title}
          </Typography>
        </Grid>

        <Grid item container xs={3} alignItems="flex-end" justifyContent="flex-end">
          <Typography
            variant="captionPara"
            sx={{
              overflow: 'hidden',
              color: 'principal.grey70',
              marginRight: 1,
            }}
          >
            {threadDate}
          </Typography>
        </Grid>
      </Grid>

      <Typography
        variant="captionPara"
        display="inline"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          textAlign: 'left',
        }}
      >
        {data.messageThread.author.email}
      </Typography>
      <Typography
        variant="bodyPara"
        sx={{
          textAlign: 'left',
        }}
      >
        {data.messageThread.content}
      </Typography>

      <Messages messages={data.messageThread.messages as Message[]} />
      <AddMessage messageThreadId={data.messageThread.id} onCancel={onCancel} />
    </Stack>
  ) : null;
};
