import { EngagementFieldsFragment, Transfer } from '@flashpack/graphql';
import { OpenInNew, WarningAmber } from '@mui/icons-material';
import { Box, Dialog, Stack, Typography } from '@mui/material';
import { getIconForTransferMode } from '@src/itinerary/transfer/common/transferMode';
import { RoutePath } from '@src/shared/routePath';
import { format, parseISO } from 'date-fns';
import { Button, CheckIcon, Link, flashPackTheme } from 'design-system';
import { FC } from 'react';

export type DepartureWithEngagements = {
  id: string;
  date: string;
  adventureId: string;
  itineraryId: string;
  engagements: (Pick<EngagementFieldsFragment, 'id' | 'title' | 'transfer'> & {
    transfer?: Pick<Transfer, 'mode'> | null;
  })[];
};

interface PropTypes {
  open: boolean;
  onClose: () => void;
  departuresWithEngagements: DepartureWithEngagements[];
}

export const RescheduleSuccessModal: FC<PropTypes> = (props) => {
  const { open, onClose, departuresWithEngagements } = props;

  const departuresWithTransferEngagements = departuresWithEngagements
    .filter((departure) =>
      departure.engagements.some((engagement) => engagement.transfer),
    )
    .map((departure) => ({
      ...departure,
      engagements: departure.engagements.filter((engagement) => engagement.transfer),
    }));

  const transferEngagementsCount = departuresWithTransferEngagements.reduce(
    (acc, departure) => acc + departure.engagements.length,
    0,
  );

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { width: '504px', maxWidth: 'none' } }}
    >
      <Stack padding={4}>
        <Stack direction="row" gap={1.5} mb={3} alignItems="center">
          <CheckIcon />
          <Typography variant="pageHeader">Reschedule Success</Typography>
        </Stack>
        {transferEngagementsCount > 0 && (
          <>
            <Box
              p={1}
              sx={{ background: flashPackTheme.palette.system.amber30, borderRadius: 2 }}
              mb={3}
              mt={1}
            >
              <Stack direction="row" gap={1}>
                <WarningAmber sx={{ width: 24, height: 24 }} />
                <Box>
                  <Typography variant="bodySingle">
                    {transferEngagementsCount} Transfers have been rescheduled.
                  </Typography>
                  <Typography variant="bodyPara">
                    We recommend you check that rescheduling these transfers is possible.
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Typography variant="bodyPara" mb={3}>
              Departures with rescheduled transfers:
            </Typography>
            <Box
              sx={{
                px: 2,
                mb: 3,
                background: flashPackTheme.palette.principal.grey30,
                maxHeight: '240px',
                overflow: 'auto',
                '::-webkit-scrollbar': { width: '8px' },
                '::-webkit-scrollbar-thumb': {
                  background: flashPackTheme.palette.principal.grey50,
                  '&:hover': {
                    backgroundColor: flashPackTheme.palette.principal.grey70,
                  },
                  borderRadius: '5px',
                },
              }}
            >
              {departuresWithTransferEngagements.map((departure) => (
                <Box key={departure.id} my={4}>
                  <Stack direction="row" gap={0.5} ml={1} mb={1.5}>
                    <Link
                      href={RoutePath.ITINERARY_DEPARTURE.generatePath(
                        departure.adventureId,
                        departure.itineraryId,
                        departure.id,
                      )}
                      target="_blank"
                      rel="noreferrer"
                      underline="none"
                    >
                      <Typography variant="bodySingle">
                        {format(parseISO(departure.date), 'dd MMM yyyy')}
                      </Typography>
                    </Link>

                    <OpenInNew sx={{ color: flashPackTheme.palette.brand.jungle }} />
                  </Stack>
                  {departure.engagements.map((engagement) => {
                    const Icon = getIconForTransferMode(engagement.transfer?.mode);
                    return (
                      <Stack direction="row" gap={1} key={engagement.id}>
                        <Icon />
                        <Typography variant="captionSingle">
                          {engagement.title}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Box>
              ))}
            </Box>
          </>
        )}
        <Button onClick={onClose} variant="contained" sx={{ alignSelf: 'center', mt: 3 }}>
          Close
        </Button>
      </Stack>
    </Dialog>
  );
};
