import { FC } from 'react';
import { Stack } from '@mui/material';
import { DepartureSettingsButton } from './DepartureSettingsButton';
import { DownloadDeparturesDataButton } from '../DownloadDeparturesDataButton';
import { Departure, Scalars, UserRole } from '@flashpack/graphql';
import { Protected } from '@src/authentication/Protected';
import { ArchiveDepartureButton } from './ArchiveDepartureButton';
import { DeleteDepartureButton } from './DeleteDepartureButton';

interface CombinedDepartureButtonRowProps {
  iconsWhite?: boolean;
}

type MultipleDepartureButtonsRowProps = CombinedDepartureButtonRowProps & {
  isMultiple: true;
  selectedDepartureIds: Scalars['UUID'][];
  archivable: boolean;
  deselectAll: () => void;
};

type SingleDepartureButtonsRowProps = CombinedDepartureButtonRowProps & {
  isMultiple: false;
  departure: Departure;
};

type DepartureButtonsRowProps =
  | MultipleDepartureButtonsRowProps
  | SingleDepartureButtonsRowProps;

export const DepartureButtonsRow: FC<DepartureButtonsRowProps> = (props) => {
  const selectedDepartureIds = props.isMultiple
    ? props.selectedDepartureIds
    : [props.departure.id];

  const archived = props.isMultiple ? !props.archivable : props.departure.archived;

  return (
    <Stack alignItems="end" justifyContent="flex-end" direction="row" gap={1}>
      {props.isMultiple ? (
        <DepartureSettingsButton isMultiple={true} departureIds={selectedDepartureIds} />
      ) : (
        <DepartureSettingsButton isMultiple={false} departure={props.departure} />
      )}
      <DownloadDeparturesDataButton
        selectedDepartureIds={selectedDepartureIds}
        size="small"
        isWhite={!!props.iconsWhite}
      />
      <ArchiveDepartureButton
        isMultiple={props.isMultiple}
        departureIds={selectedDepartureIds}
        archived={archived}
        isWhite={props.iconsWhite}
        deselectAll={props.isMultiple ? props.deselectAll : () => {}}
      />

      <Protected roles={[UserRole.Flashpack]}>
        <DeleteDepartureButton
          isMultiple={props.isMultiple}
          isWhite={props.iconsWhite}
          departureIds={selectedDepartureIds}
          deselectAll={props.isMultiple ? props.deselectAll : () => {}}
        />
      </Protected>
    </Stack>
  );
};
