import { QueryResult, useQuery } from '@apollo/client';
import { FxRatesDocument, FxRatesQuery, FxRatesQueryVariables } from '@flashpack/graphql';
import { Decimal } from 'decimal.js';

type FxRatesQueryType = () => QueryResult<FxRatesQuery, FxRatesQueryVariables>;

const convertRatestoDecimals = (data?: FxRatesQuery): FxRatesQuery | undefined => {
  if (!data) {
    return undefined;
  }
  return {
    __typename: data.__typename,
    fxRates: {
      __typename: data.fxRates.__typename,
      market: {
        ...data.fxRates.market,
        rateUSD: new Decimal(data.fxRates.market.rateUSD),
        rateGBP: new Decimal(data.fxRates.market.rateGBP),
        rateEUR: new Decimal(data.fxRates.market.rateEUR),
        rateHKD: new Decimal(data.fxRates.market.rateHKD),
        rateAUD: new Decimal(data.fxRates.market.rateAUD),
        rateAED: new Decimal(data.fxRates.market.rateAED),
        rateNZD: new Decimal(data.fxRates.market.rateNZD),
        rateCAD: new Decimal(data.fxRates.market.rateCAD),
        rateISK: new Decimal(data.fxRates.market.rateISK),
        rateJPY: new Decimal(data.fxRates.market.rateJPY),
        rateZAR: new Decimal(data.fxRates.market.rateZAR),
      },
      pricing: data.fxRates.pricing
        ? {
            ...data.fxRates.pricing,
            rateUSD: new Decimal(data.fxRates.pricing.rateUSD),
            rateGBP: new Decimal(data.fxRates.pricing.rateGBP),
            rateEUR: new Decimal(data.fxRates.pricing.rateEUR),
            rateHKD: new Decimal(data.fxRates.pricing.rateHKD),
            rateAUD: new Decimal(data.fxRates.pricing.rateAUD),
            rateAED: new Decimal(data.fxRates.pricing.rateAED),
            rateNZD: new Decimal(data.fxRates.pricing.rateNZD),
            rateCAD: new Decimal(data.fxRates.pricing.rateCAD),
            rateISK: new Decimal(data.fxRates.pricing.rateISK),
            rateJPY: new Decimal(data.fxRates.pricing.rateJPY),
            rateZAR: new Decimal(data.fxRates.pricing.rateZAR),
          }
        : undefined,
    },
  };
};

export const useFxRatesWithDecimalsQuery: FxRatesQueryType = () => {
  const { data, ...rest } = useQuery(FxRatesDocument);

  const resultingData = convertRatestoDecimals(data);

  return {
    ...rest,
    data: resultingData,
  };
};
