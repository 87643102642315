import { Departure, AccommodationFieldsForComparisonFragment } from '@flashpack/graphql';
import { FC, memo } from 'react';
import { AccommodationMobileIcon } from 'design-system';
import { TimelineEventBox } from './TimelineEventBox';
import { format, parseISO } from 'date-fns';
import { AccommodationDetails } from '@src/shared/accommodation/AccommodationDetails';

interface Props {
  departureDate: Departure['date'];
  accommodation: AccommodationFieldsForComparisonFragment;
  selected: boolean;
  onSelect: () => void;
  greyedOut?: boolean;
  flagged?: boolean;
  adjusted?: boolean;
  testid?: string;
}

const TimelineEventBoxAccommodation: FC<Props> = ({
  departureDate,
  accommodation,
  selected,
  onSelect,
  flagged = false,
  adjusted = false,
  testid,
}) => (
  <TimelineEventBox
    icon={<AccommodationMobileIcon sx={{ fill: 'none' }} />}
    title={accommodation.title}
    greyBoxTitle={format(parseISO(departureDate), 'dd MMM yyyy')}
    selected={selected}
    onSelect={onSelect}
    flagged={flagged}
    adjusted={adjusted}
    testid={testid}
  >
    <AccommodationDetails departureDate={departureDate} accommodation={accommodation} />
  </TimelineEventBox>
);

export const MemoizedTimelineEventBoxAccommodation = memo(
  TimelineEventBoxAccommodation,
  (prevProps, nextProps) =>
    prevProps.accommodation === nextProps.accommodation &&
    prevProps.selected === nextProps.selected,
);
