import format from 'date-fns/format';
import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';

export const calculateCommentTime = (
  commentDateTimeIsoString: string,
  now: Date = new Date(),
): string => {
  const commentDate = new Date(commentDateTimeIsoString);

  const duration = intervalToDuration({
    start: commentDate,
    end: now,
  });

  const onlySeconds =
    [
      duration.years,
      duration.months,
      duration.weeks,
      duration.days,
      duration.hours,
      duration.minutes,
    ].filter(Boolean).length === 0;

  if (onlySeconds) {
    return 'Just now';
  }

  if (duration?.days && duration?.days > 0) {
    return format(commentDate, 'dd MMM yyyy - HH:mm');
  }

  return `${formatDuration({ ...duration, seconds: 0 })} ago`;
};
