import { Typography } from '@mui/material';
import { Stack, styled } from '@mui/system';
import { flashPackTheme } from '@src/design-system/theme';
import { FC } from 'react';

interface DetailProps {
  title: string;
  content: string;
  subcontent?: string;
  width?: number;
}

export const TimelineEventBoxDetail: FC<DetailProps> = ({
  title,
  content,
  subcontent,
  ...props
}) => {
  return (
    <StyledStack
      direction="column"
      justifyContent="space-between"
      mr={3}
      style={{ whiteSpace: 'nowrap' }}
      {...props}
    >
      <Typography variant="captionPara">{title}</Typography>
      <Typography variant="bodySingle" style={{ whiteSpace: 'nowrap' }}>
        {content}
        <span style={{ color: flashPackTheme.palette.principal.grey70 }}>
          {subcontent}
        </span>
      </Typography>
    </StyledStack>
  );
};

const StyledStack = styled(Stack)(({ theme }) => ({
  whiteSpace: 'nowrap',
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(1),
  },
}));
