const HotelKindToLabel = {
  Resort: 'Resort',
  Sanatorium: 'Sanatorium',
  Guesthouse: 'Guesthouse',
  'Mini-hotel': 'Mini Hotel',
  Castle: 'Castle',
  Hotel: 'Hotel',
  Boutique_and_Design: 'Boutique and Design',
  Apartment: 'Apartment',
  Cottages_and_Houses: 'Cottages and Houses',
  Farm: 'Farm',
  Villas_and_Bungalows: 'Villas and Bungalows',
  Camping: 'Camping',
  Hostel: 'Hostel',
  BNB: 'BNB',
  'Apart-hotel': 'Apartment Hotel',
};

export type HotelKind = keyof typeof HotelKindToLabel | null;

const unknownHotelKindLabel = 'Unknown';

export const getLabelForHotelKind = (hotelKind?: HotelKind) => {
  if (!hotelKind) {
    return unknownHotelKindLabel;
  }

  return HotelKindToLabel[hotelKind];
};
