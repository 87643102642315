import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import {
  ActivityIcon,
  FreeTextFormField,
  LoadingButton,
  Stack,
  Validator,
  flashPackTheme,
} from 'design-system';
import { ItineraryDrawer } from 'src/itinerary/common/ItineraryDrawer';
import {
  DirectionOfOverlapOnBulkUpdate,
  EditEngagementsDocument,
  EditEngagementsInput,
  EngagementFieldsForComparisonFragment,
  OverlappingDirectionForBulkUpdateEngagementsAutoResolveDocument,
} from '@flashpack/graphql';
import { Button, TextField, Typography } from '@mui/material';
import {
  challengesOptions,
  createMappedChallengeValues,
  EditActivityFormValues,
} from '@src/itinerary/activity/view-activity/ViewEditActivityForm';
import { Form, FormSpy } from 'react-final-form';
import { NaiveDayTime } from '@src/itinerary/check-in-out-picker/CheckInOutPicker';
import { AutocompleteField } from '@src/design-system/forms/autocomplete/AutocompleteField';
import { getFormValuesFromActivities, getPayloadForEditActivities } from './utils';
import {
  engagementEndDayValidator,
  engagementEndTimeValidator,
  engagementStartDayValidator,
  engagementStartTimeValidator,
} from '@src/itinerary/common/engagementFormValidators';
import set from 'lodash/set';
import { FormState, ValidationErrors } from 'final-form';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useToast } from 'src/shared/toast/useToast';
import _ from 'lodash';
import { TextField as RFFTextField } from 'mui-rff';
import { DeleteEngagements } from '@src/itinerary/common/DeleteEngagements';

interface PropTypes {
  activities: EngagementFieldsForComparisonFragment[];
  setActivities: Dispatch<SetStateAction<EngagementFieldsForComparisonFragment[]>>;
  setDirectionOfOverlapOnBulkUpdate: Dispatch<
    SetStateAction<DirectionOfOverlapOnBulkUpdate | null>
  >;
  setEditEngagementsInput: Dispatch<SetStateAction<EditEngagementsInput | null>>;
}

type NonUndefined<T> = T extends undefined ? never : T;
type ValidatorFunction<T> = (values: T) => ValidationErrors;

export const BulkEditActivityDrawer: FC<PropTypes> = (props) => {
  const {
    activities,
    setActivities,
    setDirectionOfOverlapOnBulkUpdate,
    setEditEngagementsInput,
  } = props;
  const [editEngagements] = useMutation(EditEngagementsDocument);
  const { safeMutation } = useSafeMutation();
  const [overlappingDirectionForBulkUpdateEngagementsAutoResolve] = useLazyQuery(
    OverlappingDirectionForBulkUpdateEngagementsAutoResolveDocument,
  );
  const { success: successToast, error: errorToast } = useToast();
  const [hasPendingChanges, setHasPendingChanges] = useState(false);

  /*
  The formValuesFromEngagements shows whether we have multiple lists for challenges,
  if we do, we should show the challenges as mixed values, and set the form value
  to an empty array as the AutocompleteField component does not accept a string value
  */
  const initialValueChallenges = (initialValues: EditActivityFormValues) => {
    return initialValues.activity?.challenges === 'Multiple'
      ? []
      : initialValues.activity?.challenges;
  };

  const formValuesFromEngagements = getFormValuesFromActivities(activities);
  const isChallengeValuesMixedInitially =
    formValuesFromEngagements.activity?.challenges === 'Multiple';
  // isChallengeValuesMixed is set before initialValues to capture whether Challenges is mixed
  const [isChallengeValuesMixed, setIsChallengeValuesMixed] = useState(
    formValuesFromEngagements.activity?.challenges === 'Multiple',
  );
  formValuesFromEngagements.activity!.challenges = initialValueChallenges(
    formValuesFromEngagements,
  );

  const [initialValues, setInitialValues] = useState(formValuesFromEngagements);

  useEffect(() => {
    const newInitialValues = getFormValuesFromActivities(activities);

    setIsChallengeValuesMixed(newInitialValues.activity?.challenges === 'Multiple');

    newInitialValues.activity!.challenges = initialValueChallenges(newInitialValues);

    setInitialValues(newInitialValues);
    setHasPendingChanges(false);
  }, [activities]);

  const getMealIncludedOptionLabel = (option: boolean | 'Multiple'): string => {
    if (option === true) {
      return 'Meal included';
    } else if (option === false) {
      return 'Meal not included';
    } else {
      return 'Multiple';
    }
  };

  const validate = useCallback<ValidatorFunction<EditActivityFormValues>>(
    ({ start, end }) => {
      const validators = {
        'start.time.time': engagementStartTimeValidator,
        'end.time.time': engagementEndTimeValidator,
        'start.time.day': engagementStartDayValidator,
        'end.time.day': engagementEndDayValidator,
      };

      // validates the start and end times of an engagement.
      // The validation errors are accumulated and returned in an object, where each key is the
      // path of the field that failed validation, and the value is the result of the validation
      // function for that field.
      return Object.entries(validators).reduce((errors, [path, validator]) => {
        return set(
          errors,
          path,
          validator(null, {
            start: start.time ?? {},
            end: end.time ?? {},
          }),
        );
      }, {} as NonUndefined<ValidationErrors>);
    },
    [],
  );

  const handleClose = () => {
    setActivities([]);
  };

  const onFormStateChange = (
    formState: FormState<EditActivityFormValues, Partial<EditActivityFormValues>>,
  ) => {
    // We avoid calls to the contextual setState if the data is unchanged
    if (_.isEqual(formState.values, initialValues)) {
      return;
    }
    setHasPendingChanges(true);
    setInitialValues(formState.values);
  };

  const onSubmit = async (formValues: EditActivityFormValues) => {
    const editActivitiesInput = getPayloadForEditActivities(formValues);
    const { data: overlapData } =
      await overlappingDirectionForBulkUpdateEngagementsAutoResolve({
        variables: {
          input: editActivitiesInput,
        },
      });
    if (
      overlapData?.overlappingDirectionForBulkUpdateEngagementsAutoResolve
        .willConflictingItemsAutoResolveToMoveEarlier ||
      overlapData?.overlappingDirectionForBulkUpdateEngagementsAutoResolve
        .willConflictingItemsAutoResolveToMoveLater
    ) {
      setDirectionOfOverlapOnBulkUpdate(
        overlapData?.overlappingDirectionForBulkUpdateEngagementsAutoResolve,
      );
      setEditEngagementsInput(editActivitiesInput);
      return;
    }

    await safeMutation(
      editEngagements({
        variables: {
          input: editActivitiesInput,
        },
      }),
      {
        onSuccess: () => {
          setHasPendingChanges(false);
          successToast(`${activities.length} activities successfully updated`);
        },
        onUnexpectedError: () => {
          errorToast(
            `There was an issue while saving ${activities.length > 1 ? 'these activities' : 'this activity'} - try again later`,
          );
        },
        onServerValidationError: (error) => {
          errorToast(error.message);
        },
      },
    );
  };

  return (
    <ItineraryDrawer
      title={`${activities.length} activitie${activities.length > 1 && 's'} selected`}
      icon={<ActivityIcon />}
      handleClose={handleClose}
      warnForChangesNotSaved={hasPendingChanges}
    >
      <Form<EditActivityFormValues>
        validate={validate}
        initialValues={initialValues}
        onSubmit={onSubmit}
        render={({ handleSubmit, valid, values, submitting, form }) => (
          <form onSubmit={(v) => void handleSubmit(v)}>
            <Stack direction="column" gap={2}>
              <Stack direction="column" gap={1} sx={{ mt: 0 }}>
                <Typography
                  variant="bodySingle"
                  color={flashPackTheme.palette.principal.grey70}
                >
                  Activity Title
                </Typography>
                <FreeTextFormField
                  name="title"
                  placeholder="E.g Hozuagawa boat tour"
                  testid="activity-title"
                  onFocus={(event) => event.target.select()}
                  fieldProps={{
                    validate: Validator.required,
                  }}
                />
              </Stack>
              <Stack direction="column" gap={1}>
                <Typography
                  variant="bodySingle"
                  mb={1}
                  color={flashPackTheme.palette.principal.grey70}
                >
                  Start
                </Typography>
                <NaiveDayTime
                  name="start.time"
                  data-testid="activity-start"
                  readonly={false}
                  isDayMixed={true}
                  isBulk={true}
                />

                <RFFTextField
                  name="start.location"
                  label="Start Location"
                  data-testid="activity-start-location"
                  fieldProps={{
                    validate: Validator.required,
                  }}
                  onFocus={(event) => event.target.select()}
                />
              </Stack>
              <Stack direction="column" gap={1}>
                <Typography
                  variant="bodySingle"
                  mb={1}
                  color={flashPackTheme.palette.principal.grey70}
                >
                  Finish
                </Typography>
                <NaiveDayTime
                  name="end.time"
                  data-testid="activity-end"
                  readonly={false}
                  isDayMixed={true}
                  isBulk={true}
                />
                <RFFTextField
                  name="end.location"
                  data-testid="activity-end-location"
                  label="End Location"
                  fieldProps={{
                    parse: (value: string) => (value === '' ? null : value),
                  }}
                  onFocus={(event) => event.target.select()}
                />
              </Stack>
              <Stack direction="column" gap={1}>
                <Typography
                  variant="bodySingle"
                  color={flashPackTheme.palette.principal.grey70}
                >
                  Activity description
                </Typography>
                <FreeTextFormField
                  name="activity.description"
                  placeholder="Optional"
                  testid="activity-description"
                  multiline
                  rows={5}
                  fieldProps={{
                    parse: (value: string) => (value === '' ? null : value),
                  }}
                  onFocus={(event) => event.target.select()}
                />
              </Stack>
              <Stack direction="column" gap={1} mt={2}>
                {isChallengeValuesMixed ? (
                  // Placeholder TextField to show mixed values
                  <TextField
                    label="Challenges"
                    value="Mixed Challenge Values"
                    onFocus={() => setIsChallengeValuesMixed(false)}
                  />
                ) : (
                  <AutocompleteField<{ value: string }, true, true, false>
                    name="activity.challenges"
                    label={'Challenges'}
                    options={challengesOptions}
                    data-testid="activity-challenges"
                    helperText={
                      isChallengeValuesMixedInitially &&
                      !values.activity?.challenges?.length
                        ? 'If empty, challenges in all activities will be cleared'
                        : ''
                    }
                    getOptionValue={(option) => option.value}
                    multiple
                    fullWidth
                    onFocus={setIsChallengeValuesMixed.bind(null, false)}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    getOptionLabel={(option) => {
                      return (
                        challengesOptions.find((item) => item.value === option.value)
                          ?.label || ''
                      );
                    }}
                    value={createMappedChallengeValues(values.activity?.challenges)}
                  />
                )}
              </Stack>
              <Stack direction="column" gap={1} mt={2}>
                <AutocompleteField
                  name="activity.mealIncluded"
                  options={[true, false, 'Multiple']}
                  getOptionLabel={getMealIncludedOptionLabel}
                  getOptionDisabled={(option) => option === 'Multiple'}
                  helperText=" "
                  label={'Meal Included'}
                  fullWidth
                  value={values.activity?.mealIncluded}
                />
                <Typography
                  variant="bodySingle"
                  color={flashPackTheme.palette.principal.grey70}
                >
                  Food & Drink description
                </Typography>
                <FreeTextFormField
                  name="activity.foodAndDrink"
                  placeholder="Optional"
                  testid="activity-food-drink"
                  multiline
                  rows={5}
                  fieldProps={{
                    parse: (value: string) => (value === '' ? null : value),
                  }}
                  onFocus={(event) => event.target.select()}
                />
              </Stack>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              mt={5}
              mb={7}
              alignItems="center"
            >
              <DeleteEngagements engagementName="activity" engagementIds={values.ids} />
              <Stack direction="row" justifyContent="flex-end" gap={1}>
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  data-testid="save-activity"
                  type="button" // this is a button to avoid accidental form submission on enter
                  onClick={() => {
                    void form.submit();
                  }}
                  loading={submitting}
                  disabled={!hasPendingChanges || !valid || submitting}
                >
                  Save Changes
                </LoadingButton>
              </Stack>
            </Stack>
            <FormSpy
              subscription={{ values: true }}
              onChange={(
                props: FormState<EditActivityFormValues, Partial<EditActivityFormValues>>,
              ) => onFormStateChange(props)}
            />
          </form>
        )}
      ></Form>
    </ItineraryDrawer>
  );
};
