import { LoadingButton } from '@mui/lab';
import { Box, Dialog, Stack, Typography, useTheme } from '@mui/material';
import { InlineConfirmation } from '@src/design-system/dialogs/InlineConfirmation';
import {
  AddExternalUserToItineraryDocument,
  ItineraryHeaderDocument,
  RemoveExternalUserFromItineraryDocument,
} from '@flashpack/graphql';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { TextField } from 'mui-rff';
import { FC } from 'react';
import * as React from 'react';
import { Form } from 'react-final-form';
import { Validator, composeValidators } from 'src/design-system/forms/validators';
import { useToast } from 'src/shared/toast/useToast';

import { ItineraryWithExternalUsersDocument } from '@flashpack/graphql';
import { useMutation } from '@apollo/client';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';

interface PropTypes {
  itineraryId: string;
  description: string;
  externalUsers?: Array<{ email: string }>;
  open: boolean;
  onClose: () => void;
}

type FormValues = {
  email: string;
};

export const ShareItineraryDialog: FC<PropTypes> = (props) => {
  const { itineraryId, description, externalUsers = [], onClose, open } = props;
  const { success, error } = useToast();
  const [addExternalUserToItinerary] = useMutation(AddExternalUserToItineraryDocument);
  const [removeExternalUserFromItinerary] = useMutation(
    RemoveExternalUserFromItineraryDocument,
  );
  const { safeMutation } = useSafeMutation();

  const onSubmit = ({ email }: FormValues) => {
    return safeMutation(
      addExternalUserToItinerary({
        variables: { email, itineraryId },
        refetchQueries: [ItineraryWithExternalUsersDocument, ItineraryHeaderDocument],
      }),
      {
        onSuccess: () => {
          success(`Itinerary shared with ${email}`);
          onClose();
        },
        onError: () => {
          error('An error occurred while sharing the itinerary');
        },
      },
    );
  };
  const { currentUser } = useAuthorization();

  const onRemove = (email: string) => {
    return safeMutation(
      removeExternalUserFromItinerary({
        variables: { email, itineraryId },
        refetchQueries: [ItineraryWithExternalUsersDocument, ItineraryHeaderDocument],
      }),
      {
        onSuccess: () => {
          success(`Itinerary is no longer shared with ${email}`);
          onClose();
        },
        onServerValidationError: (e) => error(e.message),
        onUnexpectedError: () => {
          error('An error occurred while removing the user from the itinerary');
        },
      },
    );
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <Stack m={7} gap={5}>
        <Typography variant="subHeader">Share {description}</Typography>
        <GreyBody1>All flashpack.com email addresses have access by default.</GreyBody1>
        {externalUsers.length !== 0 && (
          <Stack>
            <GreyBody1>Non-Flash Pack email addresses with access:</GreyBody1>
            <div>
              {externalUsers.map(({ email }) => (
                <Box pt={1} key={email} display="flex" justifyContent="space-between">
                  <Typography variant="bodySingle">{email}</Typography>
                  {currentUser?.email != email && (
                    <InlineConfirmation
                      inline
                      confirmationText=""
                      btnSize="small"
                      onConfirm={() => onRemove(email)}
                    >
                      <Typography data-testid="remove-share-itinerary">Remove</Typography>
                    </InlineConfirmation>
                  )}
                </Box>
              ))}
            </div>
          </Stack>
        )}
        <Form<FormValues>
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => {
            return (
              <form
                onSubmit={(values) => {
                  void handleSubmit(values);
                }}
              >
                <Stack gap={2} alignItems="flex-start">
                  <GreyBody1>Add a new email address</GreyBody1>
                  <TextField
                    helperText=" "
                    fieldProps={{
                      validate: composeValidators(
                        Validator.required,
                        Validator.validEmail,
                      ),
                    }}
                    placeholder="john@doe.com"
                    name="email"
                    data-testid="share-itinerary-email-input"
                  />
                  <LoadingButton
                    loading={submitting}
                    type="submit"
                    variant="outlined"
                    data-testid="share-itinerary-submit"
                  >
                    Add to itinerary
                  </LoadingButton>
                </Stack>
              </form>
            );
          }}
        />
      </Stack>
    </Dialog>
  );
};

const GreyBody1: FC<{ children: React.ReactNode }> = ({ children }) => {
  const theme = useTheme();

  return (
    <Typography variant="bodyPara" sx={{ color: theme.palette.principal.grey70 }}>
      {children}
    </Typography>
  );
};
