import { FC, ReactNode } from 'react';
import { SharedTabsContextType, SharedTabsContext } from './SharedTabsContext';

type SharedTabsContextProps = {
  /**
   * The content of the component.
   */
  children?: ReactNode;
  /**
   * The formState(s) we want to keep track off.
   */
  value: SharedTabsContextType;
};

export const SharedTabsContextProvider: FC<SharedTabsContextProps> = ({
  children,
  value,
}) => {
  return (
    <div>
      <SharedTabsContext.Provider value={value}>{children}</SharedTabsContext.Provider>
    </div>
  );
};
