import { Stack, Typography } from '@mui/material';
import { ConfirmationDialog } from '@src/design-system/dialogs/ConfirmationDialog';
import { ExclamationIcon } from 'design-system';
import React, { FC } from 'react';

interface PropTypes {
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
}

export const DeletePackLeaderDialog: FC<PropTypes> = (props) => {
  return (
    <ConfirmationDialog
      {...props}
      title="Delete Pack Leader?"
      cancelLabel="Cancel"
      confirmLabel="Delete"
      maxWidth="xs"
    >
      <Stack direction="row" gap={2}>
        <div>
          <ExclamationIcon />
        </div>
        <Typography variant="bodyPara">
          Deleting the pack leader will remove them from any upcoming departures they are
          assigned to. These will need to be replaced.
        </Typography>
      </Stack>
    </ConfirmationDialog>
  );
};
