import { LoadingButton } from '@mui/lab';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { useLazyQuery } from '@apollo/client';
import { CostsCsvData, ItineraryWithCostsCsvDataDocument } from '@flashpack/graphql';
import csvDownload from 'json-to-csv-export';

export const ExportCostsButton = ({ itineraryId }: { itineraryId: string }) => {
  const [getCostsCsvData, { loading }] = useLazyQuery(ItineraryWithCostsCsvDataDocument, {
    onCompleted: (data) => {
      csvDownload({
        data: getMappedCsvRows(data.itinerary.costsCsvData),
        headers: data.itinerary.costsCsvData.headers,
        delimiter: ',',
        filename: 'costs_data',
      });
    },
  });

  const handleDownloadClick = () => {
    void getCostsCsvData({
      variables: { id: itineraryId },
    });
  };

  return (
    <LoadingButton
      startIcon={<DownloadOutlinedIcon />}
      onClick={handleDownloadClick}
      variant="outlined"
      loading={loading}
      data-testid="export-costs"
    >
      CSV Export
    </LoadingButton>
  );
};

/**
 * Maps data from server (rows/headers) to costs CSV format that will be downloaded.
 */
const getMappedCsvRows = ({ rows }: CostsCsvData) => {
  return rows.map((row) => [
    row.departureCode,
    row.costCurrency,
    row.passengerCosts?.amount4Pax ?? '',
    row.passengerCosts?.amount5Pax ?? '',
    row.passengerCosts?.amount6Pax ?? '',
    row.passengerCosts?.amount7Pax ?? '',
    row.passengerCosts?.amount8Pax ?? '',
    row.passengerCosts?.amount9Pax ?? '',
    row.passengerCosts?.amount10Pax ?? '',
    row.passengerCosts?.amount11Pax ?? '',
    row.passengerCosts?.amount12Pax ?? '',
    row.passengerCosts?.amount13Pax ?? '',
    row.passengerCosts?.amount14Pax ?? '',
    row.passengerCosts?.amount15Pax ?? '',
    row.passengerCosts?.amount16Pax ?? '',
    row.passengerCosts?.amount17Pax ?? '',
    row.passengerCosts?.amount18Pax ?? '',
    row.mandatoryCosts?.amountOther ?? '',
    row.mandatoryCosts?.amountInternalFlight ?? '',
    row.mandatoryCosts?.amountTransfer ?? '',
    row.extrasCosts?.amountSingleSupplement ?? '',
    row.extrasCosts?.amountPreNight ?? '',
    row.extrasCosts?.amountPreNightSharedTwin ?? '',
    row.extrasCosts?.amountPostNight ?? '',
    row.extrasCosts?.amountPostNightSharedTwin ?? '',
    row.extrasCosts?.amountOptional ?? '',
  ]);
};
