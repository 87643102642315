import { Dispatch, FC, SetStateAction, useState } from 'react';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useMutation } from '@apollo/client';
import {
  BulkUpdateDeparturePricesDocument,
  DepartureFinancesQueryDocument,
  ItineraryWithTripPricesDocument,
  Scalars,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';
import {
  BulkUpdateAmountUsdModal,
  BulkUpdateAmountUsdModalFormType,
} from './BulkUpdateAmountUsdModal';

interface PropTypes {
  open: boolean;
  onClose: () => void;
  selectedDepartureIds: Array<Scalars['UUID']>;
  setSelectedDepartureIds?: Dispatch<SetStateAction<string[]>>;
  initialValues?: BulkUpdateAmountUsdModalFormType['amountUSD'];
}

export const BulkUpdatePricesModal: FC<PropTypes> = ({
  open,
  onClose,
  selectedDepartureIds,
  setSelectedDepartureIds,
  initialValues,
}) => {
  const { safeMutation } = useSafeMutation();
  const [bulkUpdateDeparturePrices] = useMutation(BulkUpdateDeparturePricesDocument);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { error: errorToast, success: successToast } = useToast();

  const handleBulkSubmitPricingAmounts = async ({
    amountUSD,
  }: BulkUpdateAmountUsdModalFormType) => {
    setIsSubmitting(true);
    await safeMutation(
      bulkUpdateDeparturePrices({
        variables: {
          input: {
            departureIds: selectedDepartureIds,
            amountUSD,
          },
        },
        refetchQueries: [ItineraryWithTripPricesDocument, DepartureFinancesQueryDocument],
      }),
      {
        onSuccess: () => {
          setIsSubmitting(false);
          onClose();
          successToast('Trip prices updated successfully');
          setSelectedDepartureIds && setSelectedDepartureIds([]);
        },
        onError: () => {
          setIsSubmitting(false);
          errorToast('Could not update trip prices');
        },
      },
    );
  };

  return (
    <BulkUpdateAmountUsdModal
      open={open}
      onClose={onClose}
      confirmLoading={isSubmitting}
      onSubmit={handleBulkSubmitPricingAmounts}
      selectedDepartureIds={selectedDepartureIds}
      initialValues={initialValues}
      isPrice
    />
  );
};
