// Placeholder until we get a decision on the following
// https://linear.app/flash-pack/issue/PT1-265/sort-out-time-representation-and-storage

import { NaiveDayTimeInput } from '@flashpack/graphql';

export const calculateNaiveDuration = (
  start: NaiveDayTimeInput,
  end: NaiveDayTimeInput,
): [number, number, number] => {
  const dayDifference = end.day && start.day ? end.day - start.day : 0;

  const additionalMinutesByDays = dayDifference * 24 * 60;

  const [startHours, startMinutes] = start.time.split(':');
  const [endHours, endMinutes] = end.time.split(':');
  const hourDifference = parseInt(endHours) - parseInt(startHours);
  const minuteDifference = parseInt(endMinutes) - parseInt(startMinutes);
  const totalMinutes = hourDifference * 60 + minuteDifference + additionalMinutesByDays;
  const hourDuration = Math.floor(totalMinutes / 60);
  const minuteDuration = totalMinutes % 60;

  return [hourDuration, minuteDuration, totalMinutes];
};
