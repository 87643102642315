import { AssignPackLeadersDialog } from '@src/departures/AssignPackLeadersDialog';
import { DeparturePackLeader } from '@src/departures/DeparturePackLeader';
import { DepartureFieldsForListViewFragment } from '@flashpack/graphql';
import { FC, useState } from 'react';
import { Box } from '@mui/material';

interface PropTypes {
  departure: DepartureFieldsForListViewFragment;
}

export const PackLeaderSelection: FC<PropTypes> = ({ departure }) => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  return (
    <Box>
      <AssignPackLeadersDialog
        onClose={() => setDialogOpen(false)}
        open={dialogOpen}
        departureId={departure.id}
        itineraryId={departure.itinerary.id}
      />
      <DeparturePackLeader
        disabled={!departure.canChangePackLeader}
        onClick={() => setDialogOpen(true)}
        packLeader={departure.packLeader ?? undefined}
      />
    </Box>
  );
};
