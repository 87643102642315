import { AdventureManagerUser, UserRole } from '@flashpack/graphql';
import { ReactNode } from 'react';
import { useAuthorization } from './AuthorizationProvider';

type PropType = {
  children: ReactNode;
  roles: UserRole[];
};

/**
 * Renders the given children based on the given user roles.
 * SUPERUSER role is implied automatically whenever we pass the "FLASHPACK" role - so that
 * it's not required to pass this role explicitly.
 *
 * @param {Array<string>} roles - List of roles that are allowed to see the content.
 * @param {React.ReactNode} children - React element(s) to render
 * @returns {React.ReactNode} - The React node to be rendered, either the children or nothing.
 */
export const Protected = ({ roles, children }: PropType) => {
  const { currentUser } = useAuthorization();

  if (isVisibleTo(currentUser, roles)) {
    return <>{children}</>;
  }

  return null;
};

export const isVisibleTo = (
  currentUser: AdventureManagerUser | undefined,
  roles: UserRole[],
) => {
  const allRoles = [...roles];
  if (allRoles.includes(UserRole.Flashpack)) {
    allRoles.push(UserRole.Superuser);
  }

  if (currentUser && allRoles.includes(currentUser?.role)) {
    return true;
  }

  return false;
};
