import { FC } from 'react';

import { Typography, useTheme } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { ExclamationIcon } from 'design-system';
import { NaiveDayTimeInput, TransferMode } from '@flashpack/graphql';
import { calculateNaiveDuration } from '@src/shared/naiveDayTimeUtils';
import { DayTime } from '@src/shared/timeline/bulk-timeline-items/utils';

type PropTypes = {
  start?: DayTime;
  end?: DayTime;
  mode?: string;
};

export const TransferLength: FC<PropTypes> = ({ start, end, mode }) => {
  const theme = useTheme();

  const transferDuration = formatDuration(start, end);

  const isLongTransfer =
    transferDuration &&
    transferDuration?.totalMinutes >= 300 &&
    (mode === TransferMode.Minibus || mode === TransferMode.AirportTransfer);

  const transferTimeBgColor = transferDuration
    ? isLongTransfer
      ? theme.palette.system.amber10
      : theme.palette.principal.grey30
    : undefined;

  return (
    <Box>
      <Stack
        direction={'row'}
        justifyContent="space-between"
        padding={2}
        sx={{ backgroundColor: transferTimeBgColor }}
      >
        <Stack direction={'row'}>
          {isLongTransfer && (
            <ExclamationIcon htmlColor={theme.palette.system.amber100} />
          )}
          <Typography sx={{ paddingLeft: isLongTransfer ? 1 : 0 }} variant="bodyPara">
            Transfer Time
          </Typography>
        </Stack>
        <Typography variant="bodySingle">{transferDuration.label}</Typography>
      </Stack>
      <Typography variant="captionSingle" textAlign="right" sx={{ padding: 1 }}>
        {isLongTransfer ? 'Long travel time - Consider another option?' : ' '}
      </Typography>
    </Box>
  );
};

const formatDuration = (start?: DayTime, end?: DayTime) => {
  const emptyState = { label: `0hrs 0mins`, totalMinutes: 0 };
  if (!start || !end) {
    return emptyState;
  }
  if (
    start.day === 'Multiple' ||
    end.day === 'Multiple' ||
    start.time === 'Multiple' ||
    end.time === 'Multiple'
  ) {
    return { label: `Multiple`, totalMinutes: 0 };
  }
  const [hours, minutes, totalMinutes] = calculateNaiveDuration(
    start as NaiveDayTimeInput,
    end as NaiveDayTimeInput,
  );
  if (!totalMinutes || totalMinutes < 0) {
    return emptyState;
  }
  return { label: `${hours}hrs ${minutes}mins`, totalMinutes };
};
