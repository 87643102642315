import { createContext, FC, ReactNode, useContext } from 'react';
import * as Sentry from '@sentry/browser';

import { useAuthentication } from '@src/shared/authentication/useAuthentication';
import {
  AdventureManagerUser,
  CurrentAdventureManagerUserDocument,
  CurrentAdventureManagerUserFieldsFragment,
  UserRole,
} from '@flashpack/graphql';
import { captureException } from '@sentry/react';
import { useToast } from '@src/shared/toast/useToast';
import { useQuery } from '@apollo/client';
import * as FullStory from '@fullstory/browser';
import { currentEnvironment } from '@src/app/environment';

type AuthorizationContextType = {
  currentUser?: CurrentAdventureManagerUserFieldsFragment;
  loadingAuthorization: boolean;
};

export const AuthorizationContext = createContext<AuthorizationContextType>({
  currentUser: undefined,
  loadingAuthorization: false,
});

export const useAuthorization = () => {
  return useContext(AuthorizationContext);
};

export const AuthorizationProvider: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const { authenticated, loadingAuthentication } = useAuthentication();
  const { error: errorToast } = useToast();
  const {
    data,
    loading: loadingCurrentUser,
    error,
  } = useQuery(CurrentAdventureManagerUserDocument, {
    skip: !authenticated,
  });

  if (error) {
    // Network errors are already captured by the Apollo error link
    if (!error.networkError) {
      captureException(error);
    }
    if (!data) {
      errorToast('Unable to fetch login details');
    }
  }

  const currentUser = data?.currentAdventureManagerUser;
  if (currentUser) {
    setSentryUser(currentUser);
    setFullStoryUser(currentUser);
  }

  return (
    <AuthorizationContext.Provider
      value={{
        currentUser: currentUser || undefined,
        loadingAuthorization: loadingCurrentUser || loadingAuthentication,
      }}
    >
      {children}
    </AuthorizationContext.Provider>
  );
};

const setSentryUser = (currentUser: CurrentAdventureManagerUserFieldsFragment) => {
  if (currentUser) {
    Sentry.setUser({
      id: currentUser.id,
      email: currentUser.email,
      segment: currentUser.role,
    });
  } else {
    Sentry.setUser(null);
  }
};

const setFullStoryUser = (currentUser: CurrentAdventureManagerUserFieldsFragment) => {
  FullStory.identify(currentUser.id, {
    role: currentUser.role,
    environment: currentEnvironment,
    email: currentUser.email,
  });
};

export const MockAuthorizationProvider = ({
  role,
  children,
}: {
  role?: UserRole;
  children: ReactNode;
}) => {
  let currentUser: AdventureManagerUser;
  if (role == UserRole.Flashpack) {
    currentUser = {
      __typename: 'AdventureManagerUser',
      email: 'test-user@flashpack.com',
      id: '1',
      role,
      hasUnreadUserActivity: false,
    };
  } else if (role == UserRole.Superuser) {
    currentUser = {
      __typename: 'AdventureManagerUser',
      email: 'test-superuser@flashpack.com',
      id: '2',
      role,
      hasUnreadUserActivity: false,
    };
  } else if (role == UserRole.Dmc) {
    currentUser = {
      __typename: 'AdventureManagerUser',
      email: 'test-dmc@traveladventures.com',
      id: '3',
      role,
      hasUnreadUserActivity: false,
    };
  } else {
    throw new Error('Invalid role');
  }

  return (
    <AuthorizationContext.Provider value={{ currentUser, loadingAuthorization: false }}>
      {children}
    </AuthorizationContext.Provider>
  );
};
