import { ErrorBoundary } from '@sentry/react';
import { GenericError } from 'design-system';
import { FC } from 'react';
import * as React from 'react';

export const GlobalErrorBoundary: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <ErrorBoundary showDialog fallback={<GenericError error="Unexpected error" />}>
      {children}
    </ErrorBoundary>
  );
};
