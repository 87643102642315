import {
  TextField as MuiTextField,
  TextFieldProps,
  Theme,
  Typography,
  styled,
} from '@mui/material';

import {
  TextField as RFFTextField,
  TextFieldProps as RFFTextFieldProps,
  showErrorOnBlur,
} from 'mui-rff';
import { FC, useMemo } from 'react';
import { Field } from 'react-final-form';
import { ExclamationIcon } from 'design-system/src/icons';

export type PropTypes = Omit<TextFieldProps, string> &
  RFFTextFieldProps & {
    name: string;
    placeholder: string;
    testid?: string;
    readonly?: boolean;
    helperText?: string;
    valid?: boolean;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  };

export const FreeTextFormField: FC<PropTypes> = ({
  name,
  placeholder,
  testid,
  readonly,
  helperText = ' ',
  fieldProps,
  valid,
  onFocus,
  ...props
}) => {
  const minRows = useMemo(() => {
    if (props.minRows) {
      // if provided, pass it through
      return props.minRows;
    }
    if (props.multiline) {
      // not provided, but multiline (textarea), use 2 by default
      return 2;
    }
    // single line input, no minRows
    return undefined;
  }, [props.multiline, props.minRows]);
  return (
    <Field<string>
      name={name}
      parse={fieldProps?.parse}
      render={({ input, meta }) => {
        return (
          <FreeTextMuiRff
            valid={!!meta.valid || valid}
            fullWidth
            name={name}
            helperText={<Typography variant="label_Figma">{helperText}</Typography>}
            showError={showErrorOnBlur}
            fieldProps={fieldProps}
            InputProps={{
              ...input,
              endAdornment: props.error ? (
                <ExclamationIcon style={{ pointerEvents: 'none' }} />
              ) : undefined,
            }}
            placeholder={readonly ? '' : placeholder}
            disabled={readonly}
            minRows={minRows}
            variant="standard"
            InputLabelProps={{
              shrink: true,
            }}
            {...props}
            inputProps={{
              'data-testid': testid || fieldProps?.name,
              onFocus,
              ...(props.inputProps || {}),
            }}
          />
        );
      }}
    />
  );
};

const stylingFunction = ({ theme, valid }: { theme: Theme; valid?: boolean }) => ({
  // wrapper: inside are label, input, and outline (border around input)
  '& .MuiInputBase-root': {
    borderRadius: 4,
    overflow: 'hidden',
    paddingRight: theme.spacing(2),
    height: 40,
    backgroundColor: theme.palette.principal.grey30,
  },
  // wrapper disabled
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: theme.palette.principal.grey30,
  },
  // wrapper error
  '& .MuiInputBase-root.Mui-error': {
    backgroundColor: theme.palette.system.red10,
  },
  // we show outline only for hover/focus/disabled states
  '& .MuiInputBase-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: 'transparent',
  },
  '& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  // html input itself
  '& .MuiInputBase-root .MuiInputBase-input': {
    height: 24,
    padding: theme.spacing(1, 1, 1, 2),
    fontWeight: 500,
    color: theme.palette.brand.jungle,
  },
  '& .MuiInputBase-root.Mui-disabled .MuiInputBase-input': {
    color: theme.palette.principal.grey70,
    // override MUI, use plain color instead
    WebkitTextFillColor: 'unset',
  },
  '& .MuiInputBase-root.Mui-error .MuiInputBase-input': {
    backgroundColor: theme.palette.system.red10,
    color: theme.palette.principal.grey70,
  },
  // multiline wrapper
  '& .MuiInputBase-root.MuiInputBase-multiline': {
    height: 'auto',
    padding: 0,
    paddingRight: theme.spacing(2),
  },
  '& .MuiInputBase-root.MuiInputBase-multiline .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 2),
  },
  /**
   * Filled in state
   * We apply these rules when:
   * - valid (not with error)
   * - validation rules applied (not focused)
   * - not disabled
   * - has any value (no placeholder); we also need to check for aria-hidden as MUI renders 2 textarea elements
   */
  '& .MuiInputBase-root:not(.Mui-error):not(.Mui-focused):not(.Mui-disabled):has(.MuiInputBase-input:not([aria-hidden]):not(:placeholder-shown))':
    {
      backgroundColor: theme.palette.system.green10,
      color: theme.palette.brand.jungle,
    },
  '& .MuiFormLabel-root:not(.Mui-error):not(.Mui-focused)': {
    color: valid ? theme.palette.brand.jungle : theme.palette.principal.grey70,
  },
  '& .MuiFormLabel-root.Mui-error': {
    color: theme.palette.system.red100,
  },
  // placeholder pseudo class styling (enable shadow DOM in devtools for debugging)
  '& .MuiInputBase-root .MuiInputBase-input::placeholder': {
    color: theme.palette.principal.grey70,
    fontWeight: 400,
    opacity: 1,
  },
  '& .MuiInputBase-root.Mui-error .MuiInputBase-input::placeholder': {
    color: theme.palette.principal.grey70,
    fontWeight: 400,
  },
  '.MuiFormLabel-root': {
    ...theme.typography.captionPara,
    color: theme.palette.principal.grey70,
    textTransform: 'none',
  },
  '& .MuiFormHelperText-root': {
    ...theme.typography.captionPara,
    color: theme.palette.principal.grey70,
    textTransform: 'none',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: theme.palette.system.red100,
  },
  // MUI underline - bottom border
  '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
    visibility: 'hidden',
  },
});

export const FreeTextMui = styled(MuiTextField)(stylingFunction); // this is the old one

type FreeTextProps = RFFTextFieldProps & {
  valid?: boolean;
};

export const FreeTextMuiRff = styled(RFFTextField, {
  shouldForwardProp: (prop) => prop !== 'valid',
})<FreeTextProps>(stylingFunction);
