import CircleIcon from '@mui/icons-material/Circle';
import { useTheme } from '@mui/material';

interface ChainedTextProps {
  parts: Array<JSX.Element | string>;
  color?: string;
  sizeRelativeToText?: number;
  marginX?: number;
}

export const ChainedText = ({
  parts,
  color,
  sizeRelativeToText = 0.7,
  marginX = 2,
}: ChainedTextProps) => {
  const theme = useTheme();
  if (!color) {
    color = theme.palette.principal.grey50;
  }

  return (
    <>
      {parts.reduce(
        (prev, curr, index) => (
          <>
            {prev}
            {index > 0 && (
              <CircleIcon
                key={`circle-${index}`}
                sx={{ color, mx: marginX, fontSize: `${sizeRelativeToText * 100}%` }}
              />
            )}
            {curr}
          </>
        ),
        <></>,
      )}
    </>
  );
};
