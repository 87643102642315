import { Box, Slide, Stack, useTheme } from '@mui/material';
import { FC } from 'react';

interface PropTypes {
  visible: boolean;
  children: JSX.Element;
}

export const FloatingToolbar: FC<PropTypes> = ({ visible, children }) => {
  const theme = useTheme();
  return (
    <Slide direction="up" in={visible} mountOnEnter unmountOnExit>
      <Box
        paddingX={3}
        sx={{
          position: 'fixed',
          bottom: '20px',
          left: 0,
          right: 0,
          zIndex: 100,
          maxWidth: theme.breakpoints.values.lg,
          margin: '0 auto',
        }}
      >
        <Stack
          paddingX={3}
          paddingY={1}
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          sx={{
            backgroundColor: 'primary.main',
            boxShadow: '0px 6px 20px 1px rgba(92, 188, 171, 0.5)',
          }}
        >
          {children}
        </Stack>
      </Box>
    </Slide>
  );
};
