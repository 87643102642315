import { TaskAlt, Sync, SyncProblem, SyncDisabled } from '@mui/icons-material';
import { Theme, Tooltip, useTheme } from '@mui/material';
import { SyncStatus } from '@flashpack/graphql';
import { FC } from 'react';

interface PropTypes {
  sync: SyncStatus;
  archivedDeparture?: boolean | null;
}

export const DepartureSyncStatus: FC<PropTypes> = ({ sync, archivedDeparture }) => {
  const theme = useTheme();

  if (archivedDeparture) {
    return (
      <Tooltip title={'Sync disabled - Archived departures are not synced'}>
        <SyncDisabled style={{ color: theme.palette.principal.grey70 }} />
      </Tooltip>
    );
  }

  return getDepartureSyncIcon(sync, theme);
};

function getDepartureSyncIcon(sync: SyncStatus, theme: Theme) {
  switch (sync) {
    case SyncStatus.Synced:
      return (
        <Tooltip title={'Synced Successfully'}>
          <TaskAlt style={{ color: theme.palette.system.green100 }} />
        </Tooltip>
      );
    case SyncStatus.ResyncNeeded:
      return (
        <Tooltip title={'Sync Required - Changes detected'}>
          <Sync style={{ color: theme.palette.system.amber100 }} />
        </Tooltip>
      );

    case SyncStatus.SyncUnsuccessful:
      return (
        <Tooltip title={'Unable to sync - Check prices and secured'}>
          <SyncProblem style={{ color: theme.palette.system.red100 }} />
        </Tooltip>
      );
    default:
      return null;
  }
}
