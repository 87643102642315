import React, { FC } from 'react';
import { TransferMode } from '@flashpack/graphql';
import { getIconForTransferMode } from '@src/itinerary/transfer/common/transferMode';
import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

type TransferDurations = Record<TransferMode, string>;

interface PropTypes {
  durations: TransferDurations;
}

export const TransferDurations: FC<PropTypes> = ({ durations }) => {
  return (
    <Stack direction="row" gap={1}>
      {(Object.keys(durations) as Array<TransferMode>).map((mode) => {
        const Icon = getIconForTransferMode(mode);

        return (
          <TransferDuration key={mode} direction="row" alignItems="center" gap={1}>
            {<Icon />}
            <Typography variant="captionSingle">{durations[mode]}</Typography>
          </TransferDuration>
        );
      })}
    </Stack>
  );
};

const TransferDuration = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.principal.grey30,
  padding: theme.spacing(1),
}));
