import { Box } from '@mui/system';
import { FC } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { ChatIcon, TimePassedIcon } from 'design-system';
import ReplyIcon from '@mui/icons-material/Reply';
import { formatDistance } from 'date-fns';
import { toShortDurationFormat } from '@src/shared/dateUtils';
import { MessageThreadFragment } from '@flashpack/graphql';
import { flashPackTheme } from '@src/design-system/theme';

type MessageThreadCardProps = {
  thread: MessageThreadFragment;
  index?: number;
  setSelectedMessageThreadId: (v: string) => void;
};

export const MessageThreadCard: FC<MessageThreadCardProps> = ({
  thread,
  index,
  setSelectedMessageThreadId,
}) => {
  const now = new Date();
  const date = new Date(thread.createdAt);

  const threadDate = date.toLocaleString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
  return (
    <Box
      sx={{
        backgroundColor: flashPackTheme.palette.principal.grey30,
        padding: 1,
        paddingLeft: 2,
        borderRadius: 2,
        textAlign: 'left',
      }}
    >
      <Grid
        container
        onClick={() => {
          setSelectedMessageThreadId(thread.id);
        }}
      >
        <Grid item container xs={9} alignItems="flex-end">
          <Typography
            variant="bodySingle"
            display="inline"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {thread.title}
          </Typography>
        </Grid>
        <Grid item container xs={3} alignItems="flex-end" justifyContent="flex-end">
          <Typography
            variant="captionPara"
            sx={{
              overflow: 'hidden',
              color: 'principal.grey70',
              marginRight: 1,
            }}
          >
            {threadDate}
          </Typography>
        </Grid>
        <Grid item container xs={12} alignItems="center" paddingY={1}>
          <Typography
            variant="captionPara"
            sx={{
              overflow: 'hidden',
              color: 'principal.grey70',
            }}
          >
            {thread.author.email}
          </Typography>
        </Grid>
        <Grid item container xs={12} alignItems="center" paddingY={1}>
          <Typography
            variant="captionPara"
            sx={{
              overflow: 'hidden',
              color: 'principal.grey70',
            }}
          >
            {thread.content}
          </Typography>
        </Grid>
        <Grid
          item
          container
          xs={9}
          alignItems="flex-end"
          justifyContent="flex-start"
          paddingBottom={1}
        >
          <ChatIcon sx={{ fontSize: flashPackTheme.spacing(2.25) }} />
          <Typography
            variant="captionPara"
            color={flashPackTheme.palette.principal.grey70}
            sx={{
              marginX: 1,
            }}
            data-testid={`message-thread-count-${index}`}
          >
            {thread.messageCount}
          </Typography>

          <TimePassedIcon sx={{ fontSize: flashPackTheme.spacing(2.25) }} />
          {thread.createdAt && (
            <Typography
              variant="captionPara"
              color={flashPackTheme.palette.principal.grey70}
              sx={{
                marginX: 1,
              }}
            >
              {toShortDurationFormat(
                formatDistance(new Date(thread.createdAt), now, {
                  addSuffix: false,
                }),
              )}
            </Typography>
          )}
        </Grid>
        <Grid item container xs={3} alignItems="flex-end" justifyContent="flex-end">
          <Button
            onClick={() => {
              setSelectedMessageThreadId(thread.id);
            }}
            sx={{
              backgroundColor: 'principal.grey30',
              color: flashPackTheme.palette.primary.light,
              padding: 1,
            }}
            data-testid={`message-thread-reply-${index}`}
          >
            <ReplyIcon />
            Reply
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};
