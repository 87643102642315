import { FC, ReactElement } from 'react';
import { ActivityTilePropTypes } from './ActivityTile';
import { TransferTilePropTypes } from './TransferTile';
import { Tile } from './Tile';

export interface AccommodationTilePropTypes {
  accommodationId: string;
  name: string;
  country?: string;
  region?: string;
  time?: string;
  children?: (
    | ReactElement<ActivityTilePropTypes>
    | ReactElement<TransferTilePropTypes>
  )[];
  href: string;
  flagged: boolean;
  commentCount: number;
  lastCommentAt?: string;
  selectAccommodation?: () => void;
  isSelected?: boolean;
  showCheckbox: boolean;
}
function getDescription(region: string | undefined, country: string | undefined) {
  let description = '';
  if (region) {
    description += region + ', ';
  }
  if (country) {
    description += country;
  }
  return description;
}
export const AccommodationLeadingTile: FC<AccommodationTilePropTypes> = (props) => {
  const {
    name,
    country,
    region,
    time,
    children,
    flagged,
    href,
    commentCount,
    lastCommentAt,
    selectAccommodation,
    isSelected,
    showCheckbox,
  } = props;
  return (
    <Tile
      href={href}
      title={name}
      description={getDescription(region, country)}
      timing={time ? `Check-in - ${time}` : ''}
      variant="accommodation_leading"
      data-testid="checkin-accommodation"
      flagged={flagged}
      commentCount={commentCount}
      lastCommentAt={lastCommentAt}
      selectItem={selectAccommodation}
      isSelected={isSelected}
      showCheckbox={showCheckbox}
    >
      {children}
    </Tile>
  );
};

export const AccommodationTrailingTile: FC<AccommodationTilePropTypes> = (props) => {
  const {
    name,
    country,
    region,
    time,
    children,
    flagged,
    href,
    commentCount,
    lastCommentAt,
    selectAccommodation,
    showCheckbox,
  } = props;

  return (
    <Tile
      href={href}
      title={name}
      description={getDescription(region, country)}
      timing={time ? `Check-out - ${time}` : ''}
      variant="accommodation_trailing"
      data-testid="checkout-accommodation"
      flagged={flagged}
      commentCount={commentCount}
      lastCommentAt={lastCommentAt}
      selectItem={selectAccommodation}
      showCheckbox={showCheckbox}
    >
      {children}
    </Tile>
  );
};
