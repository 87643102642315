import { DepartureSignOffSortAndFilter } from '@flashpack/graphql';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { DeparturesSignOffList } from './DeparturesSignOffList';
import { MenuItem, Select } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useRouting } from '@src/shared/useRouting';
import { RoutePath } from '@src/shared/routePath';

export const AdventureActionsTab = () => {
  const { adventureId } = useRequiredParams(['adventureId']);
  const [searchParams] = useSearchParams();
  const { navigate } = useRouting();

  const originOrdering = Object.values(DepartureSignOffSortAndFilter).includes(
    searchParams.get('ordering') as DepartureSignOffSortAndFilter,
  )
    ? searchParams.get('ordering')
    : DepartureSignOffSortAndFilter.MostRecent;

  return (
    <>
      <Select
        value={originOrdering}
        onChange={(event) => {
          navigate(RoutePath.ADVENTURE_ACTIONS.generatePath(adventureId), {
            toAdd: {
              ordering: event.target.value,
            },
          });
        }}
        sx={{
          width: '200px',
          mb: 2,
        }}
      >
        <MenuItem value={DepartureSignOffSortAndFilter.MostRecent}>Most Recent</MenuItem>
        <MenuItem value={DepartureSignOffSortAndFilter.LeastRecent}>
          Least Recent
        </MenuItem>
        <MenuItem value={DepartureSignOffSortAndFilter.SignOffCompleted}>
          Sign Off Completed
        </MenuItem>
      </Select>
      <DeparturesSignOffList
        adventureId={adventureId}
        departureSignOffSortAndFilter={originOrdering as DepartureSignOffSortAndFilter}
      />
    </>
  );
};
