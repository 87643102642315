import { FC, useCallback, useState } from 'react';
import { RoutePath } from 'src/shared/routePath';
import {
  Typography,
  Stack,
  styled,
  Chip,
  Tooltip,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { Checkbox, FlightSlantedIcon, GenericError } from 'design-system';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import UnarchiveOutlinedIcon from '@mui/icons-material/UnarchiveOutlined';
import {
  Bulletin,
  BulletinList,
  SkeletonBulletinList,
} from '@src/design-system/bulletin';

import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { ConfirmationDialog } from '@src/design-system/dialogs/ConfirmationDialog';
import {
  AdventureItinerariesDocument,
  ArchiveItineraryDocument,
  DuplicateItineraryDocument,
  UserRole,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';
import { FORM_ERROR } from 'final-form';
import { useMutation, useQuery } from '@apollo/client';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { Protected } from '@src/authentication/Protected';
import { CommentsMetadata } from '@src/shared/comment/CommentsMetadata';
import {
  DuplicateItineraryDialog,
  DuplicateItineraryForm,
} from './DuplicateItineraryDialog';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { FormControlLabel } from '@src/shared/FormControlLabel';

type PendingArchiveItinerary = {
  id: string;
  archived: boolean;
  description: string;
};

export type SourceItinerary = {
  id: string;
  description: string;
};

const DeparturesCount = styled(Chip)(({ theme }) => ({
  color: theme.palette.principal.grey70,
  backgroundColor: theme.palette.principal.grey30,
  borderRadius: '2px',
  fontSize: theme.typography.micro.fontSize,
  lineHeight: theme.typography.micro.fontSize,
  padding: `${theme.spacing(1)} ${theme.spacing(0.5)}`,
}));

export const AdventureItinerariesTab: FC = () => {
  const { adventureId } = useRequiredParams(['adventureId']);

  const { data, loading, error } = useQuery(AdventureItinerariesDocument, {
    variables: { id: adventureId },
  });
  const [duplicateItinerary] = useMutation(DuplicateItineraryDocument);
  const [archiveItinerary, { loading: isArchiving }] = useMutation(
    ArchiveItineraryDocument,
  );
  const { success, error: errorToast } = useToast();
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState<boolean>(false);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState<boolean>(false);
  const [pendingArchiveItinerary, setPendingArchiveItinerary] =
    useState<PendingArchiveItinerary | null>(null);
  const [sourceItinerary, setSourceItinerary] = useState<SourceItinerary | null>(null);
  const [isShowingArchivedItineraries, setIsShowingArchivedItineraries] =
    useState<boolean>(false);

  const { safeMutation } = useSafeMutation();

  const onDuplicate = useCallback(
    async ({ newDescription }: DuplicateItineraryForm) => {
      if (!sourceItinerary) {
        return;
      }
      try {
        await safeMutation(
          duplicateItinerary({
            variables: {
              itinerary: {
                id: sourceItinerary.id,
                description: newDescription,
              },
            },
            refetchQueries: [AdventureItinerariesDocument],
          }),
          {
            onSuccess: () => {
              success(`${newDescription} has been created`);
              setDuplicateDialogOpen(false);
            },
            onServerValidationError: (error) => {
              throw error;
            },
            onUnexpectedError: () =>
              errorToast("This itinerary couldn't have been duplicated at this time"),
          },
        );
      } catch (error) {
        return {
          [FORM_ERROR]: error,
        };
      }
    },
    [duplicateItinerary, errorToast, safeMutation, sourceItinerary, success],
  );

  const selectItinerary = useCallback((itinerary: SourceItinerary) => {
    setSourceItinerary(itinerary);
    setDuplicateDialogOpen(true);
  }, []);

  const handleCloseDuplicateDialog = useCallback(() => setDuplicateDialogOpen(false), []);

  const handleOpenArchiveDialog = (itinerary: PendingArchiveItinerary) => {
    setArchiveDialogOpen(true);
    setPendingArchiveItinerary(itinerary);
  };

  const archiveVersion = useCallback(async () => {
    if (!pendingArchiveItinerary) {
      return;
    }
    const { id: itineraryId, archived } = pendingArchiveItinerary;
    const verb = archived ? 'unarchive' : 'archive';
    try {
      await safeMutation(
        archiveItinerary({
          variables: {
            itinerary: { id: itineraryId, archived: !archived },
          },
        }),
        {
          onSuccess: () => {
            success(`Itinerary was ${verb}d.`);
          },
          onServerValidationError: (error) => {
            throw error;
          },
          onUnexpectedError: () =>
            errorToast("This itinerary couldn't have been archived at this time"),
        },
      );
    } catch (error) {
      return {
        [FORM_ERROR]: error,
      };
    }
    setArchiveDialogOpen(false);
    setPendingArchiveItinerary(null);
  }, [archiveItinerary, errorToast, safeMutation, pendingArchiveItinerary, success]);

  if (error) {
    return <GenericError error={error} />;
  }

  if (loading) {
    return <SkeletonBulletinList count={3} />;
  }

  const { itineraries } = data?.adventure ?? {};

  const filteredItineraries = isShowingArchivedItineraries
    ? itineraries
    : itineraries?.filter((itinerary) => !itinerary.archived);

  const archiveDialogTitle = `${
    pendingArchiveItinerary?.archived ? 'Unarchive' : 'Archive'
  } ${pendingArchiveItinerary?.description || ''}?`;

  return (
    <>
      <Stack direction="row" justifyContent="end" sx={{ my: 2, mx: 0 }}>
        <FormControlLabel
          checked={isShowingArchivedItineraries}
          label="Include Archived"
          control={
            <Checkbox
              checked={isShowingArchivedItineraries}
              onChange={() =>
                setIsShowingArchivedItineraries((currentState) => !currentState)
              }
            />
          }
        />
      </Stack>
      <BulletinList>
        {filteredItineraries?.map(
          ({ id, description, departuresCount, timeline, archived }) => (
            <Bulletin
              key={id}
              href={
                timeline.empty
                  ? RoutePath.ITINERARY_TIMELINE.generatePath(adventureId, id)
                  : RoutePath.ITINERARY_DEPARTURES.generatePath(adventureId, id)
              }
              data-testid="itinerary-details-link"
              sx={{ px: 3, py: 2 }}
              title={<Typography variant="subHeader">{description}</Typography>}
              flagged={timeline.flagged}
              greyedOut={archived}
            >
              <Protected roles={[UserRole.Flashpack, UserRole.Dmc]}>
                <DeparturesCount
                  label={departuresCount}
                  icon={<FlightSlantedIcon sx={{ fontSize: 'inherit' }} />}
                />
                <CommentsMetadata
                  commentCount={timeline.commentCount}
                  lastComment={timeline.lastCommentAt}
                />
                <Tooltip title="Duplicate">
                  <IconButton
                    onClick={() => selectItinerary({ id, description })}
                    data-testid="duplicate-itinerary-button"
                  >
                    <FileCopyOutlinedIcon fontSize="small" />
                  </IconButton>
                </Tooltip>

                <Tooltip title={archived ? 'Unarchive' : 'Archive'}>
                  <IconButton
                    onClick={() => handleOpenArchiveDialog({ id, archived, description })}
                    data-testid="archive-itinerary-button"
                    disabled={isArchiving}
                  >
                    {isArchiving ? (
                      <CircularProgress sx={{ color: 'white' }} size="24px" />
                    ) : archived ? (
                      <UnarchiveOutlinedIcon />
                    ) : (
                      <ArchiveOutlinedIcon />
                    )}
                  </IconButton>
                </Tooltip>
              </Protected>
            </Bulletin>
          ),
        )}
      </BulletinList>
      {sourceItinerary && (
        <DuplicateItineraryDialog
          sourceItinerary={sourceItinerary}
          open={duplicateDialogOpen}
          onClose={handleCloseDuplicateDialog}
          onSubmit={onDuplicate}
        />
      )}
      <ConfirmationDialog
        title={archiveDialogTitle}
        cancelLabel="Cancel"
        confirmLabel={pendingArchiveItinerary?.archived ? 'Unarchive' : 'Archive'}
        open={archiveDialogOpen}
        onCancel={() => setArchiveDialogOpen(false)}
        onConfirm={() => void archiveVersion()}
      />
    </>
  );
};
