import { Typography, Stack, Button, Link } from '@mui/material';

import {
  UserRole,
  Scalars,
  Itinerary,
  MoveDeparturesKeepExistingTimelineDocument,
  MoveDeparturesUpdateTimelineDocument,
  AdventureWithItinerariesAndDeparturesDocument,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';
import { FC, useState } from 'react';
import { FloatingToolbar } from '@src/design-system/floating-toolbar/FloatingToolbar';
import { DepartureStatusMenu } from '@src/departures/departure-status/DepartureStatusMenu';
import { Protected } from '@src/authentication/Protected';
import { MoveDepartureMenu } from '@src/departures/move-departure/MoveDepartureMenu';
import { MoveDeparturesDialog } from './MoveDeparturesDialog';
import { RoutePath } from '@src/shared/routePath';
import { DocumentNode, useMutation } from '@apollo/client';
import { CancellationReasonDialog } from './departure-status/CancellationReasonDialog';
import { pluralDepartures } from './utils';
import { DepartureButtonsRow } from './departure-settings/DepartureButtonsRow';
import { DepartureVisibilityMenu } from './departure-visibility/DepartureVisibilityMenu';

interface PropTypes {
  selectedDepartureIds: Array<Scalars['UUID']>;
  deselectAll: () => void;
  archivable: boolean;
  alternativeItineraries: Pick<Itinerary, 'id' | 'description'>[];
}

type ItineraryFromMutation = {
  id: Scalars['UUID'];
  description: string;
  adventure: {
    id: string;
  };
};

type MoveDeparturesUpdateTimelineMutationOptions = {
  variables: {
    departureIds: Array<Scalars['UUID']>;
    itineraryId: Scalars['UUID'];
  };
  refetchQueries: [DocumentNode];
};

export const DepartureToolbar: FC<PropTypes> = ({
  selectedDepartureIds,
  deselectAll,
  archivable,
  alternativeItineraries,
}) => {
  const [cancellationReasonModalOpen, setCancellationReasonModalOpen] =
    useState<boolean>(false);
  const [moveDeparturesConfirmationOpen, setMoveDeparturesConfirmationOpen] =
    useState<boolean>(false);
  const [targetItinerary, setTargetItinerary] = useState<
    Pick<Itinerary, 'id' | 'description'>
  >({ id: '', description: '' });

  const { success, error } = useToast();

  const [
    moveDeparturesKeepExistingTimeline,
    { loading: movingDeparturesKeepExistingTimeline },
  ] = useMutation(MoveDeparturesKeepExistingTimelineDocument);

  const [moveDeparturesUpdateTimeline, { loading: movingDeparturesUpdateTimeline }] =
    useMutation(MoveDeparturesUpdateTimelineDocument);

  const onMoveSelectedDepartures = (itinerary: Pick<Itinerary, 'id' | 'description'>) => {
    setMoveDeparturesConfirmationOpen(true);
    setTargetItinerary(itinerary);
  };

  const extractTargetItineraryDetails = (itinerary: ItineraryFromMutation) => {
    return {
      id: itinerary.id,
      description: itinerary.description,
      adventure: { id: itinerary.adventure.id },
    };
  };

  const executeMoveDepartureMutation = async (copyTargetTimeline: boolean) => {
    const mutationParameters: MoveDeparturesUpdateTimelineMutationOptions = {
      variables: {
        departureIds: selectedDepartureIds,
        itineraryId: targetItinerary.id,
      },
      refetchQueries: [AdventureWithItinerariesAndDeparturesDocument],
    };

    if (copyTargetTimeline) {
      const { data } = await moveDeparturesUpdateTimeline(mutationParameters);
      if (data && data.moveDeparturesUpdateTimeline) {
        return extractTargetItineraryDetails(data.moveDeparturesUpdateTimeline);
      }
    } else {
      const { data } = await moveDeparturesKeepExistingTimeline(mutationParameters);
      if (data && data.moveDeparturesKeepExistingTimeline) {
        return extractTargetItineraryDetails(data.moveDeparturesKeepExistingTimeline);
      }
    }
    throw new Error('data was not returned');
  };

  const handleMoveSelectedDepartures = async (copyTargetTimeline: boolean) => {
    try {
      const targetItineraryDetails =
        await executeMoveDepartureMutation(copyTargetTimeline);

      const destinationDepartureRoute = RoutePath.ITINERARY_DEPARTURES.generatePath(
        targetItineraryDetails.adventure.id,
        targetItineraryDetails.id,
      );
      success(
        <>
          Departures successfully moved to{' '}
          <Link href={destinationDepartureRoute}>
            {targetItineraryDetails.description}
          </Link>
        </>,
      );
      deselectAll();
    } catch (e) {
      error('Failed to move the departures');
    }
  };

  return (
    <>
      <FloatingToolbar visible={selectedDepartureIds.length > 0}>
        <>
          <Typography variant="bodySingle" sx={{ color: 'white' }}>
            Selected {pluralDepartures(selectedDepartureIds.length)}
          </Typography>
          <Stack direction={'row'} gap={1}>
            <Protected roles={[UserRole.Flashpack]}>
              <DepartureStatusMenu
                onCancel={() => setCancellationReasonModalOpen(true)}
              />
              <DepartureVisibilityMenu departureIds={selectedDepartureIds} />
            </Protected>
            <MoveDepartureMenu
              alternativeItineraries={alternativeItineraries}
              loading={
                movingDeparturesKeepExistingTimeline || movingDeparturesUpdateTimeline
              }
              onChange={(itinerary) => void onMoveSelectedDepartures(itinerary)}
            />
            <MoveDeparturesDialog
              open={moveDeparturesConfirmationOpen}
              onClose={() => {
                setMoveDeparturesConfirmationOpen(false);
              }}
              numberOfDepartures={selectedDepartureIds?.length}
              targetItineraryDescription={targetItinerary.description}
              deselectAll={deselectAll}
              handleMoveSelectedDepartures={handleMoveSelectedDepartures}
            />
            <Protected roles={[UserRole.Flashpack, UserRole.Dmc]}>
              <DepartureButtonsRow
                isMultiple={true}
                iconsWhite={true}
                selectedDepartureIds={selectedDepartureIds}
                archivable={archivable}
                deselectAll={deselectAll}
              />
            </Protected>
            <Button title="Close" variant="light-text" onClick={deselectAll}>
              Close
            </Button>
          </Stack>
        </>
      </FloatingToolbar>
      <CancellationReasonDialog
        onClose={() => setCancellationReasonModalOpen(false)}
        open={cancellationReasonModalOpen}
        onSuccess={deselectAll}
        departureIds={selectedDepartureIds}
      />
    </>
  );
};
