import { FC } from 'react';
import { CheckCircleOutlineOutlined, DoNotDisturbAltOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { DepartureStatus } from '@flashpack/graphql';
import { useTheme } from '@mui/material/styles';
import { statusLabel } from '@src/departures/departure-status/utils';

interface StatusIconProps {
  status: DepartureStatus;
}

export const StatusIcon: FC<StatusIconProps> = ({ status }) => {
  const theme = useTheme();
  const label = statusLabel(status);

  return status === DepartureStatus.Cancelled ? (
    <Tooltip arrow title={label}>
      <DoNotDisturbAltOutlined
        htmlColor={theme.palette.system.red70}
        sx={{ marginRight: theme.spacing(1) }}
      />
    </Tooltip>
  ) : (
    <Tooltip arrow title={label}>
      <CheckCircleOutlineOutlined
        htmlColor={theme.palette.system.green70}
        sx={{ marginRight: theme.spacing(1) }}
      />
    </Tooltip>
  );
};
