import { useMutation } from '@apollo/client';
import { Menu, MenuItem } from '@mui/material';
import { StopPropagationWrapper } from '@src/design-system/StopPropagationWrapper';
import {
  AdventureWithItinerariesAndDeparturesDocument,
  DepartureSecuredStatus,
  UpdateDepartureSecuredStatusDocument,
  UserRole,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { FC, useState } from 'react';
import { DepartureSecuredStatusItem } from './DepartureSecuredStatusItem';
import { securedStatusLabel } from './utils';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';

interface PropTypes {
  departureId: string;
  securedStatus: DepartureSecuredStatus;
  disabled?: boolean;
}

export const DepartureSecuredStatusMenu: FC<PropTypes> = ({
  departureId,
  securedStatus,
  disabled = false,
}) => {
  const { safeMutation } = useSafeMutation();
  const { success, error } = useToast();
  const [updateDepartureSecuredStatus] = useMutation(
    UpdateDepartureSecuredStatusDocument,
  );

  const { currentUser } = useAuthorization();
  const isFlashpackUser =
    currentUser?.role == UserRole.Flashpack || currentUser?.role == UserRole.Superuser;
  const canUpdateSecuredStatus =
    isFlashpackUser && !disabled && securedStatus !== DepartureSecuredStatus.Secured;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (e: React.MouseEvent) => {
    setAnchorEl(e.target as HTMLElement);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const securedStatusOptions = [
    DepartureSecuredStatus.Pending,
    DepartureSecuredStatus.Secured,
    DepartureSecuredStatus.NotSecured,
  ];

  const onSecuredStatusUpdate = async (securedStatus: DepartureSecuredStatus) => {
    await safeMutation(
      updateDepartureSecuredStatus({
        variables: {
          input: {
            departureId: departureId,
            securedStatus,
          },
        },
        refetchQueries: [AdventureWithItinerariesAndDeparturesDocument],
      }),
      {
        onSuccess: () => {
          success(`Departure was marked as ${securedStatusLabel(securedStatus)}`);
          handleClose();
        },
        onError: () =>
          error(`Failed to mark departure as ${securedStatusLabel(securedStatus)}`),
      },
    );
  };

  return (
    <StopPropagationWrapper>
      {canUpdateSecuredStatus && (
        <Menu
          id="departure-secured-status-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
        >
          {securedStatusOptions.map((securedStatusOption) => (
            <MenuItem
              onClick={() => void onSecuredStatusUpdate(securedStatusOption)}
              key={securedStatusOption}
              disabled={
                securedStatusOption === securedStatus ||
                securedStatusOption === DepartureSecuredStatus.Pending
              }
            >
              <DepartureSecuredStatusItem
                onClick={handleClick}
                securedStatus={securedStatusOption}
              />
            </MenuItem>
          ))}
        </Menu>
      )}
      <DepartureSecuredStatusItem
        onClick={handleClick}
        securedStatus={securedStatus}
        canUpdateSecuredStatus={canUpdateSecuredStatus}
      />
    </StopPropagationWrapper>
  );
};
