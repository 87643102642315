import React, { FC } from 'react';
import { FormControl, Icon, Typography, styled, useTheme } from '@mui/material';
import { getIconForTransferMode, transferModeOptions } from './transferMode';
import { Stack } from '@mui/system';
import { FieldRenderProps } from 'react-final-form';

export const TransferModeSelector: FC<FieldRenderProps<string>> = ({ input, meta }) => {
  const theme = useTheme();

  const handleIconClick = (value: string) => {
    input.onChange(value);
  };

  return (
    <FormControl>
      <Stack
        justifyContent="space-evenly"
        data-testid="transfer-mode"
        direction="row"
        spacing={2}
        py={2}
        sx={{
          border: meta.error && meta.touched ? '1px red solid' : 'none',
        }}
      >
        {transferModeOptions.map((option) => {
          const isSelected = input.value === option.value;
          return (
            <StyledSelectorOption
              key={option.value}
              onClick={() => handleIconClick(option.value)}
              isSelected={isSelected}
            >
              <Icon component={getIconForTransferMode(option.value)} fontSize="inherit" />
              <Typography variant="label" sx={{ textAlign: 'center' }}>
                {option.label}
              </Typography>
            </StyledSelectorOption>
          );
        })}
      </Stack>
      {meta.error && meta.touched && (
        <Typography
          variant="bodyPara"
          color={theme.palette.system.red100}
          sx={{ padding: 0 }}
        >
          Please select a transfer mode
        </Typography>
      )}
    </FormControl>
  );
};

type StyledSelectorOptionProps = {
  isSelected: boolean;
};

const StyledSelectorOption = styled(Stack)<StyledSelectorOptionProps>(
  ({ theme, isSelected }) => ({
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    py: 0,
    height: 88,
    width: 88,
    cursor: 'pointer',
    borderRadius: '2px',
    backgroundColor: isSelected ? theme.palette.brand.jungle : undefined,
    color: isSelected ? theme.palette.common.white : theme.palette.common.black,
    '& > .MuiSvgIcon-root': {
      fontSize: '48px',
    },
    ':hover': {
      backgroundColor: isSelected ? undefined : theme.palette.principal.grey30,
    },
  }),
);
