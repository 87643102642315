import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { TextField } from 'mui-rff';
import { composeValidators, Validator } from '@src/design-system/forms/validators';
import {
  AdventureRegion,
  AdventureTypes,
  UpdateAdventureDocument,
} from '@flashpack/graphql';
import { FC } from 'react';
import { Field, Form } from 'react-final-form';
import { ServerValidationErrors } from '@src/design-system/forms/ServerValidationErrors';
import { ApolloError, useMutation } from '@apollo/client';
import { FORM_ERROR } from 'final-form';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useToast } from '@src/shared/toast/useToast';
import { AdventureTypesSelector } from '@src/design-system/forms/adventure-types';
import { AutocompleteField } from '@src/design-system/forms/autocomplete/AutocompleteField';
import { countryOptions, regionOptions } from '@src/shared/countries/countryUtils';
import { CheckboxFormField } from 'design-system';

interface PropTypes {
  adventureId: string;
  open: boolean;
  adventureData: AdventureDataFormValues;
  onClose: () => void;
}

type AdventureDataFormValues = {
  title: string;
  type: AdventureTypes[];
  countries: string[];
  region: AdventureRegion;
  ATOL: boolean;
  bookingEngineTourCode: string;
};

export const EditAdventureDialog: FC<PropTypes> = ({
  open,
  onClose,
  adventureId,
  adventureData,
}) => {
  const { title, countries, region, type, ATOL, bookingEngineTourCode } = adventureData;

  const [UpdateAdventureMutation] = useMutation(UpdateAdventureDocument);
  const { success, error: errorToast } = useToast();
  const { safeMutation } = useSafeMutation();

  const onSubmit = async (input: AdventureDataFormValues) => {
    const { title, type, ATOL } = input;
    await safeMutation(
      UpdateAdventureMutation({
        variables: {
          input: {
            id: adventureId,
            title,
            type: type ?? [],
            ATOL,
          },
        },
      }),
      {
        onSuccess: () => {
          success('Adventure updated');
          onClose();
        },
        onError: () => errorToast('Failed to update Adventure'),
      },
    );
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Stack m={7} gap={5}>
        <Form<AdventureDataFormValues>
          onSubmit={onSubmit}
          initialValues={{
            title,
            type,
            region,
            countries,
            ATOL,
            bookingEngineTourCode,
          }}
          render={({ handleSubmit, submitting, dirtySinceLastSubmit, submitError }) => {
            return (
              <form
                onSubmit={(values) => {
                  try {
                    void handleSubmit(values);
                  } catch (e) {
                    return {
                      [FORM_ERROR]: e,
                    };
                  }
                }}
              >
                <Stack gap={1} spacing={1} alignItems="flex-start">
                  <Typography variant="subHeader">Edit Adventure?</Typography>

                  <TextField
                    fieldProps={{
                      validate: composeValidators(Validator.required),
                    }}
                    autoFocus
                    name="title"
                    data-testid="edit-adventureTitle-description-input"
                    label="Adventure Title"
                  />

                  <TextField
                    disabled
                    name="bookingEngineTourCode"
                    data-testid="edit-bookingEngineTourCode-input"
                    label="Adventure Code"
                    fieldProps={{ validate: Validator.required }}
                  />

                  <AutocompleteField<{ value: string }, true, true, false>
                    name="countries"
                    label="Countries"
                    options={countryOptions}
                    getOptionValue={(option) => option.value}
                    multiple
                    disabled
                    fullWidth
                    fieldProps={{ validate: Validator.required }}
                  />

                  <AutocompleteField<{ value: string }, false, true, false>
                    name="region"
                    label="Region"
                    options={regionOptions}
                    disabled
                    getOptionValue={(option) => option.value}
                    fieldProps={{ validate: Validator.required }}
                    fullWidth
                  />

                  <AdventureTypesSelector name="type" />

                  <Box display="flex" flexDirection="row" alignItems="center">
                    <Typography variant="bodySingle" color="primary" paddingY={2}>
                      ATOL:
                    </Typography>
                    <Field<boolean>
                      name="ATOL"
                      type="checkbox"
                      defaultValue={false}
                      component={CheckboxFormField}
                    />
                  </Box>

                  {!dirtySinceLastSubmit && (
                    <ServerValidationErrors error={submitError as ApolloError} />
                  )}
                  <Stack direction="row" gap={3} justifyContent="flex-end">
                    <Button variant="outlined" onClick={onClose}>
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={submitting}
                      type="submit"
                      variant="contained"
                      data-testid="edit-adventureTitle-submit"
                    >
                      Save
                    </LoadingButton>
                  </Stack>
                </Stack>
              </form>
            );
          }}
        />
      </Stack>
    </Dialog>
  );
};
