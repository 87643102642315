import { FxDataType, PricingRateFormProps } from '@src/finance/fx-table/FxTable';
import { Decimal } from 'decimal.js';

export const priceDelta = (oldPrice?: Decimal, newPrice?: string) => {
  if (!newPrice || !oldPrice) {
    return;
  }
  const delta = new Decimal(newPrice)
    .sub(oldPrice)
    .div(oldPrice)
    .mul(100)
    .toDecimalPlaces(1);
  if (delta.greaterThan(0.0)) {
    return `+${delta.toFixed(1)}%`;
  } else if (delta.equals(0.0)) {
    return `~${delta.toFixed(1)}%`;
  } else {
    return `${delta.toFixed(1)}%`;
  }
};

export const extractNewPricingRatesFromFxData = (fxData: FxDataType[]) => {
  return fxData.reduce((initialValues: PricingRateFormProps, item: FxDataType) => {
    initialValues[item.currency] = item.newPricingRate ?? '';
    return initialValues;
  }, {} as PricingRateFormProps);
};

export enum FinanceEntity {
  DEPOSIT = 'deposit',
  FX_RATE = 'FX rate',
  PRICE = 'price',
  EARLY_BIRD_DISCOUNT = 'early bird discount',
  PROMOTIONAL_DISCOUNT = 'promotional discount',
  PASSENGER_COST = 'passenger cost',
  EXTRAS_COST = 'extras cost',
  MANDATORY_COST = 'mandatory cost',
}
