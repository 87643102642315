import { Button, Stack, Typography } from '@mui/material';
import { pluralDepartures } from '@src/departures/utils';
import { FloatingToolbar } from '@src/design-system/floating-toolbar/FloatingToolbar';
import { Scalars } from '@flashpack/graphql';
import { FC } from 'react';

interface PropTypes {
  selectedRowIds: Array<Scalars['UUID']>;
  onClick: () => void;
  deselectAll: () => void;
}

export const TableToolbar: FC<PropTypes> = ({ selectedRowIds, onClick, deselectAll }) => {
  return (
    <FloatingToolbar visible={selectedRowIds.length > 0}>
      <>
        <Typography variant="bodySingle" sx={{ color: 'white' }}>
          {pluralDepartures(selectedRowIds.length)} selected
        </Typography>
        <Stack direction={'row'} gap={1}>
          <Button variant="light-contained" onClick={onClick}>
            Update All
          </Button>
          <Button title="Close" variant="light-text" onClick={deselectAll}>
            Close
          </Button>
        </Stack>
      </>
    </FloatingToolbar>
  );
};
