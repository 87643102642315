import { DepartureStatus } from '@flashpack/graphql';

const DAYS_UNTIL_RELEASE_MESSAGE_DAYS = 30;

export function daysUntilReleaseMessage(days: number) {
  if (days > 0 && days < DAYS_UNTIL_RELEASE_MESSAGE_DAYS) {
    return `${String(days)} ${days == 1 ? 'day' : 'days'} until release`;
  }
  return undefined;
}

export function pluralDepartures(count: number) {
  return [count, count == 1 ? 'departure' : 'departures'].join(' ');
}

export function isSecuredStatusMenuDisabled(departureStatus: DepartureStatus) {
  return (
    departureStatus === DepartureStatus.Departed ||
    departureStatus === DepartureStatus.Completed
  );
}
