import { AccommodationType } from '@flashpack/graphql';
import { EditActivityFormValues } from '@src/itinerary/activity/view-activity/ViewEditActivityForm';
import { EditTransferFormValues } from '@src/itinerary/transfer/common/TransferForm';
import { DraftPropertyMulti } from '@src/itinerary/view-accommodation/tabs/SelectPropertyTab';
import { createContext, Dispatch, SetStateAction } from 'react';
import { DayTime } from '../timeline/bulk-timeline-items/utils';

export type AccommodationFormState = {
  description: string | null;
  checkIn: DayTime;
  checkOut: DayTime;
  hotelId: string | null;
  type: AccommodationType;
  roomDescriptorTwin: string | null;
  roomDescriptorSingle: string | null;
  allocationTwinRoom?: number | 'Multiple' | null;
  allocationSingleRoom?: number | 'Multiple' | null;
  notes: string;
};

export type CommentFormState = {
  content: string;
};

export type ActivityFormState = EditActivityFormValues;
export type TransferFormState = EditTransferFormValues;

export type SharedTabsContextType = {
  commentForm?: {
    state: CommentFormState;
    setState: Dispatch<SetStateAction<CommentFormState>>;
  };
  accommodationForm?: {
    state: AccommodationFormState;
    setState: Dispatch<SetStateAction<AccommodationFormState>>;
    hasPendingChanges: boolean;
    setHasPendingChanges: Dispatch<SetStateAction<boolean>>;
    draftProperty: DraftPropertyMulti | null;
    setDraftProperty: Dispatch<SetStateAction<DraftPropertyMulti | null>>;
  };
  activityForm?: {
    state: EditActivityFormValues;
    setState: Dispatch<SetStateAction<EditActivityFormValues>>;
    hasPendingChanges: boolean;
    setHasPendingChanges: Dispatch<SetStateAction<boolean>>;
  };
  transferForm?: {
    state: EditTransferFormValues;
    setState: Dispatch<SetStateAction<EditTransferFormValues>>;
    hasPendingChanges: boolean;
    setHasPendingChanges: Dispatch<SetStateAction<boolean>>;
  };
};

export const SharedTabsContext = createContext<SharedTabsContextType>({});
