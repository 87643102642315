import { Typography, styled } from '@mui/material';
import { UserActivityAction } from '@flashpack/graphql';
import { FC } from 'react';
import { ActivityFeedItemProps } from './ActivityFeedItem';
export const activityExpression = (
  expressionArguments: {
    argument: string;
    type: 'typography' | 'no_typography';
  }[],
) => {
  return (
    <ActivityFeedExpressionWrapper variant="bodyPara">
      {expressionArguments.map((expression, index) => {
        if (expression.type == 'typography') {
          return (
            <Typography component="span" variant="bodySingle" key={index}>
              {expression.argument}
            </Typography>
          );
        } else {
          return expression.argument;
        }
      })}
    </ActivityFeedExpressionWrapper>
  );
};

export const ActivityFeedExpression: FC<
  Pick<ActivityFeedItemProps, 'action' | 'user' | 'subject' | 'target'>
> = ({ action, user, subject, target }) => {
  switch (action) {
    case UserActivityAction.CreatedAdventure: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' created adventure ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
      ]);
    }
    case UserActivityAction.UpdatedAdventure: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' updated adventure ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
      ]);
    }
    case UserActivityAction.CreatedEngagementTransfer: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' created transfer ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
      ]);
    }
    case UserActivityAction.UpdatedEngagementTransfer: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' updated transfer ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
      ]);
    }
    case UserActivityAction.DeletedEngagementTransfer: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' deleted a transfer', type: 'no_typography' },
      ]);
    }
    case UserActivityAction.CreatedEngagementActivity: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' created activity ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
      ]);
    }
    case UserActivityAction.UpdatedEngagementActivity: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' updated activity ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
      ]);
    }
    case UserActivityAction.DeletedEngagementActivity: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' deleted an activity', type: 'no_typography' },
      ]);
    }
    case UserActivityAction.CreatedItinerary: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' created itinerary ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
        { argument: ' from ', type: 'no_typography' },
        { argument: (target && target.title) ?? '', type: 'typography' },
      ]);
    }
    case UserActivityAction.UpdatedItineraryCostCurrency: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' updated currency for costs', type: 'no_typography' },
      ]);
    }
    case UserActivityAction.CreatedComment: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' added ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
        { argument: ' on ', type: 'no_typography' },
        { argument: (target && target.title) ?? '', type: 'typography' },
      ]);
    }
    case UserActivityAction.ProposedDeparture: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' added a departure', type: 'no_typography' },
      ]);
    }
    case UserActivityAction.FlaggedTimelineComponent: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' flagged ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
        { argument: ' as requiring attention', type: 'no_typography' },
      ]);
    }
    case UserActivityAction.UnflaggedTimelineComponent: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' removed a flag on ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
      ]);
    }
    case UserActivityAction.AddedUser: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' has added ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
      ]);
    }

    case UserActivityAction.RemovedUser: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' has removed ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
      ]);
    }
    case UserActivityAction.CreatedAccommodation: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' added accommodation ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
      ]);
    }
    case UserActivityAction.UpdatedAccommodation: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' updated accommodation ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
      ]);
    }
    case UserActivityAction.DeletedAccommodation: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' deleted an accommodation', type: 'no_typography' },
      ]);
    }
    case UserActivityAction.UpdatedDepartureStatusAccepted:
    case UserActivityAction.UpdatedDepartureStatusCancelled:
    case UserActivityAction.UpdatedDepartureStatusCompleted:
    case UserActivityAction.UpdatedDepartureStatusDeparted:
    case UserActivityAction.UpdatedDepartureStatusOnSale:
    case UserActivityAction.UpdatedDepartureStatusRejected:
    case UserActivityAction.UpdatedDepartureStatusProposed:
    case UserActivityAction.UpdatedDepartureStatusSecured:
    case UserActivityAction.CancelledDeparture: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ` ${departureActionsMap[action] || ''}`, type: 'no_typography' },
      ]);
    }
    case UserActivityAction.UpdatedPricesTripPrice:
    case UserActivityAction.UpdatedPricesEarlyBirdDiscount:
    case UserActivityAction.UpdatedPricesPromotionalDiscount:
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' updated ', type: 'no_typography' },
        { argument: ` ${priceUpdateActionsMap[action] || ''}`, type: 'no_typography' },
      ]);
    case UserActivityAction.UpdatedCostsExtras:
    case UserActivityAction.UpdatedCostsMandatory:
    case UserActivityAction.UpdatedCostsPassenger: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' updated ', type: 'no_typography' },
        { argument: ` ${costUpdateActionsMap[action] || ''}`, type: 'no_typography' },
      ]);
    }
    case UserActivityAction.BulkUpdatedPricesTripPrice:
    case UserActivityAction.BulkUpdatedPricesEarlyBirdDiscount:
    case UserActivityAction.BulkUpdatedPricesPromotionalDiscount:
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' bulk updated ', type: 'no_typography' },
        { argument: ` ${priceUpdateActionsMap[action] || ''}`, type: 'no_typography' },
      ]);
    case UserActivityAction.BulkUpdatedCostsExtras:
    case UserActivityAction.BulkUpdatedCostsMandatory:
    case UserActivityAction.BulkUpdatedCostsPassenger: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' bulk updated ', type: 'no_typography' },
        { argument: ` ${costUpdateActionsMap[action] || ''}`, type: 'no_typography' },
      ]);
    }
    case UserActivityAction.BulkUpdatedPricesTripPrice:
    case UserActivityAction.BulkUpdatedPricesEarlyBirdDiscount:
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' bulk updated ', type: 'no_typography' },
        { argument: ` ${priceUpdateActionsMap[action] || ''}`, type: 'no_typography' },
      ]);
    case UserActivityAction.AssignedPackLeader: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' assigned ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
        { argument: ' as a pack leader', type: 'no_typography' },
      ]);
    }
    case UserActivityAction.UnassignedPackLeader: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' unassigned a pack leader ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
      ]);
    }
    case UserActivityAction.CreatedMessageThread: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' started a new thread ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
      ]);
    }
    case UserActivityAction.CreatedMessage: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' left a message on ', type: 'no_typography' },
        { argument: subject.title, type: 'typography' },
      ]);
    }
    case UserActivityAction.MarkedDepartureAsSecured: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' marked a departure as secured', type: 'no_typography' },
      ]);
    }
    case UserActivityAction.MarkedDepartureAsNotSecured: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' marked a departure as not secured', type: 'no_typography' },
      ]);
    }
    case UserActivityAction.ChangedDepartureVisibilityToDraft: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' changed a departure visibility to draft', type: 'no_typography' },
      ]);
    }
    case UserActivityAction.ChangedDepartureVisibilityToLive: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' changed a departure visibility to live', type: 'no_typography' },
      ]);
    }
    case UserActivityAction.UpdatedDepartureMinToGuarantee:
    case UserActivityAction.UpdatedDepartureCapacity:
    case UserActivityAction.UpdatedDepartureMaxEarlyBirds:
    case UserActivityAction.UpdatedDepartureRoomReleaseDays: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' updated ', type: 'no_typography' },
        { argument: ` ${departureSettingsActionsMap[action]}`, type: 'typography' },
      ]);
    }
    case UserActivityAction.BulkUpdatedDepartureMinToGuarantee:
    case UserActivityAction.BulkUpdatedDepartureCapacity:
    case UserActivityAction.BulkUpdatedDepartureMaxEarlyBirds:
    case UserActivityAction.BulkUpdatedDepartureRoomReleaseDays: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' bulk updated ', type: 'no_typography' },
        { argument: ` ${departureSettingsActionsMap[action]}`, type: 'typography' },
      ]);
    }
    case UserActivityAction.SignedOffDeparture: {
      return activityExpression([
        { argument: user.email, type: 'typography' },
        { argument: ' signed off a departure', type: 'no_typography' },
      ]);
    }
    default: {
      return (
        <ActivityFeedExpressionWrapper variant="bodySingle">
          Error, no activity found
        </ActivityFeedExpressionWrapper>
      );
    }
  }
};

const departureActionsMap: Partial<Record<UserActivityAction, string>> = {
  [UserActivityAction.UpdatedDepartureStatusAccepted]: 'accepted a departure',
  [UserActivityAction.UpdatedDepartureStatusCancelled]: 'cancelled a departure',
  [UserActivityAction.UpdatedDepartureStatusCompleted]: 'marked a departure as completed',
  [UserActivityAction.UpdatedDepartureStatusDeparted]: 'marked a departure as departed',
  [UserActivityAction.UpdatedDepartureStatusOnSale]: 'marked a departure as on sale',
  [UserActivityAction.UpdatedDepartureStatusProposed]: 'proposed a departure',
  [UserActivityAction.UpdatedDepartureStatusSecured]: 'secured a departure',
  [UserActivityAction.UpdatedDepartureStatusRejected]: 'rejected a departure',
  [UserActivityAction.CancelledDeparture]: 'cancelled a departure',
};

const costUpdateActionsMap: Partial<Record<UserActivityAction, string>> = {
  [UserActivityAction.UpdatedCostsExtras]: 'extras costs',
  [UserActivityAction.UpdatedCostsMandatory]: 'mandatory costs',
  [UserActivityAction.UpdatedCostsPassenger]: 'passenger costs',
  [UserActivityAction.BulkUpdatedCostsExtras]: 'extras costs',
  [UserActivityAction.BulkUpdatedCostsMandatory]: 'mandatory costs',
  [UserActivityAction.BulkUpdatedCostsPassenger]: 'passenger costs',
};

const priceUpdateActionsMap: Partial<Record<UserActivityAction, string>> = {
  [UserActivityAction.UpdatedPricesTripPrice]: 'departure prices',
  [UserActivityAction.UpdatedPricesEarlyBirdDiscount]: 'early bird discounts',
  [UserActivityAction.BulkUpdatedPricesTripPrice]: 'departure prices',
  [UserActivityAction.BulkUpdatedPricesEarlyBirdDiscount]: 'early bird discounts',
  [UserActivityAction.UpdatedPricesPromotionalDiscount]: 'promotional discounts',
  [UserActivityAction.BulkUpdatedPricesPromotionalDiscount]: 'promotional discounts',
};

const departureSettingsActionsMap: Partial<Record<UserActivityAction, string>> = {
  [UserActivityAction.UpdatedDepartureMinToGuarantee]: 'min to guarantee',
  [UserActivityAction.UpdatedDepartureCapacity]: 'capacity',
  [UserActivityAction.UpdatedDepartureMaxEarlyBirds]: 'max early birds',
  [UserActivityAction.UpdatedDepartureRoomReleaseDays]: 'room release days',
  [UserActivityAction.BulkUpdatedDepartureMinToGuarantee]: 'min to guarantee',
  [UserActivityAction.BulkUpdatedDepartureCapacity]: 'capacity',
  [UserActivityAction.BulkUpdatedDepartureMaxEarlyBirds]: 'max early birds',
  [UserActivityAction.BulkUpdatedDepartureRoomReleaseDays]: 'room release days',
};

export const ActivityFeedExpressionWrapper = styled(Typography)(({ theme }) => ({
  paddingRight: theme.spacing(2),
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  gridArea: 'title',
}));
