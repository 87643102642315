import { Stack } from '@mui/system';
import {
  BackButton,
  Divider,
  GenericError,
  PageHeading,
  ChainedText,
} from 'design-system';
import EditIcon from '@mui/icons-material/Edit';
import {
  UserRole,
  Adventure,
  Departure,
  Scalars,
  UpdateDeparturesDocument,
  DepartureHeaderQueryDocument,
  AdventureDepartureDatesDocument,
  DepartureForDatePickerFragment,
  AdventureWithItinerariesAndDeparturesDocument,
} from '@flashpack/graphql';
import { RoutePath } from '@src/shared/routePath';
import { FC, useEffect, useState } from 'react';
import { useRouting } from '@src/shared/useRouting';
import { useToast } from '@src/shared/toast/useToast';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { UpdateRoomReleaseDialog } from '@src/departures/UpdateRoomReleaseDialog';
import { Protected } from '@src/authentication/Protected';
import { IconButton, Typography } from '@mui/material';
import { UpdateDepartureDateDialog } from '@src/departures/UpdateDepartureDateDialog';
import { dateToLongMonthDateYearFormat } from '@src/shared/dateUtils';
import { useMutation, useQuery } from '@apollo/client';
import { DeparturesView } from '@src/departures/DeparturesList';
import { parseISO } from 'date-fns';
import { DepartureSyncStatus } from './DepartureSyncStatus';
import { DepartureRowItems } from './DepartureBulletin';
import { DepartureButtonsRow } from './departure-settings/DepartureButtonsRow';
import { UpdateDepartureFinancesButton } from './departure-settings/UpdateDepartureFinancesButton';
import { OpenInNew } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

interface PropTypes {
  adventureId: Scalars['UUID'];
  itineraryId: Scalars['UUID'];
  departureId: Scalars['UUID'];
}

export const DepartureHeader: FC<PropTypes> = ({
  adventureId,
  itineraryId,
  departureId,
}) => {
  const toasts = useToast();
  const { safeMutation } = useSafeMutation();

  const [updateRoomReleaseDialogOpen, setUpdateRoomReleaseDialogOpen] =
    useState<boolean>(false);

  const [updateDepartureDateDialogOpen, setUpdateDepartureDateDialogOpen] =
    useState<boolean>(false);
  const { data: departureData } = useQuery(AdventureDepartureDatesDocument, {
    variables: { adventureId },
  });
  const existingDepartures: DepartureForDatePickerFragment[] =
    departureData?.adventure.itineraries
      .map((itinerary) => itinerary.departures)
      .flat() ?? [];

  const [updateDeparturesMutation] = useMutation(UpdateDeparturesDocument);

  const { data, error, loading, refetch } = useQuery(DepartureHeaderQueryDocument, {
    variables: { itineraryId, departureId },
  });

  const { queryParams, pathname } = useRouting<{ origin: DeparturesView }>();
  const [originView] = useState<string>(queryParams.origin);

  useEffect(() => {
    async function refetchDepartureHeader() {
      await refetch();
    }

    void refetchDepartureHeader();
  }, [refetch, pathname]);

  if (loading) {
    return (
      <>
        <BackButton href="#" disabled={true}>
          ... • ...
        </BackButton>
        <PageHeading>
          <ChainedText parts={['Departures', '...']} />
        </PageHeading>
      </>
    );
  }
  if (error) {
    <GenericError error={error} />;
  }

  const updateDeparture = async ({ roomReleaseDays }: { roomReleaseDays: number }) => {
    await safeMutation(
      updateDeparturesMutation({
        variables: {
          departures: [
            {
              id: departureId,
              roomReleaseDays,
            },
          ],
        },
        refetchQueries: [AdventureWithItinerariesAndDeparturesDocument],
      }),
      {
        onSuccess: () => {
          toasts.success(`Room release days updated`);
        },
        onError: () => {
          toasts.error('Updating room release days failed');
        },
      },
    );
    setUpdateRoomReleaseDialogOpen(false);
  };

  const departure = data?.itinerary?.departureById as Departure;
  const adventure = data?.itinerary?.adventure as Adventure;
  const roomReleaseDaysSingular = departure.roomReleaseDays === 1;

  const backLabel =
    originView === DeparturesView.ADVENTURE
      ? `${adventure.title} • ${adventure.bookingEngineTourCode}`
      : `${adventure.title} (${adventure.bookingEngineTourCode}) • ${
          data?.itinerary.description || ''
        }`;

  const backHref =
    originView === DeparturesView.ADVENTURE
      ? RoutePath.ADVENTURE_DEPARTURES.generatePath(adventureId)
      : RoutePath.ITINERARY_DEPARTURES.generatePath(adventureId, itineraryId);

  return (
    <>
      <UpdateDepartureDateDialog
        existingDepartures={existingDepartures}
        departure={departure}
        open={updateDepartureDateDialogOpen}
        onClose={() => setUpdateDepartureDateDialogOpen(false)}
      />
      <UpdateRoomReleaseDialog
        onUpdate={updateDeparture}
        open={updateRoomReleaseDialogOpen}
        onClose={() => setUpdateRoomReleaseDialogOpen(false)}
        departure={departure}
      />
      <BackButton href={backHref} data-testid="departure-back-button">
        {backLabel}
      </BackButton>
      <Stack direction="row" gap={1}>
        <PageHeading>
          <ChainedText
            parts={['Departure', dateToLongMonthDateYearFormat(parseISO(departure.date))]}
          />
        </PageHeading>
        {/* disabled edit departure date functionality for now as it adds complexity around syncing departures to booking engine
        if we need this functionality, then we will need to make changes to how we sync departures to the booking engine
        refer to this thread for more info: https://flashpack20.slack.com/archives/C056RPMDH3N/p1694780210331569}
        {/* <IconButton
          onClick={() => setUpdateDepartureDateDialogOpen(true)}
          disabled={isSubmitting}
        >
          <CalendarIcon />
        </IconButton> */}
      </Stack>
      <Stack direction="row" gap="10px" alignItems="center">
        <Typography sx={{ color: 'principal.grey70' }} variant="bodyPara">
          Room Release:
        </Typography>
        <Typography variant="bodySingle">
          {`${departure.roomReleaseDays} ${roomReleaseDaysSingular ? 'day' : 'days'}`}
        </Typography>
        <IconButton onClick={() => setUpdateRoomReleaseDialogOpen(true)}>
          <EditIcon />
        </IconButton>
        {departure.manifestUrl && (
          <Stack direction="row" gap={0.5}>
            <ManifestLink href={departure.manifestUrl} target="_blank" rel="noreferrer">
              View Manifest
            </ManifestLink>
            <OpenInNew fontSize="small" />
          </Stack>
        )}
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        mt={2}
        data-testid="departure-header"
      >
        <Stack direction="row" gap={2} alignItems="center">
          <Protected roles={[UserRole.Flashpack]}>
            {departure.sync && <DepartureSyncStatus sync={departure.sync} />}
          </Protected>
          <DepartureRowItems departure={departure} />
        </Stack>
        <Protected roles={[UserRole.Flashpack, UserRole.Dmc]}>
          <Stack alignItems="end" justifyContent="flex-end" direction="row" gap={0.2}>
            <UpdateDepartureFinancesButton
              itineraryId={itineraryId}
              departureId={departureId}
            />
            <DepartureButtonsRow isMultiple={false} departure={departure} />
          </Stack>
        </Protected>
      </Stack>
      <Divider sx={{ mt: 1 }} />
    </>
  );
};

const ManifestLink = styled('a')(({ theme }) => ({
  color: theme.palette.principal.black,
  cursor: 'pointer',
  marginInline: '0.2em',
  textDecoration: 'underline',
  '&:hover': {
    color: theme.palette.principal.black,
  },
}));
