import {
  NavigateOptions,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import queryString, { ParsedQuery } from 'query-string';

const arrayFormat = 'bracket';

interface ParamsConfig<T> {
  preserveExisting?: boolean;
  toAdd?: Partial<T>;
  toOmit?: Array<keyof T>;
}

export type LocationState = null | {
  redirectBack?: string;
};

/**
 * T is the type of the query params object containing the types of each query value.
 * @returns functions for managing routing.
 */
export const useRouting = <T,>() => {
  const { search, pathname } = useLocation();
  const defaultNavigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  const queryParams = queryString.parse(search, {
    arrayFormat,
  }) as unknown as T;

  const resolveQueryParams = (paramsConfig?: ParamsConfig<T>) => {
    if (!paramsConfig) {
      return {};
    }

    const preserveExisting = paramsConfig?.preserveExisting ?? false;
    const existingQueryParams = preserveExisting ? queryString.parse(search) : {};

    if (!paramsConfig) {
      return existingQueryParams;
    }

    const { toOmit, toAdd } = paramsConfig;
    const filteredQueryParams = omitObjectKeys(existingQueryParams, toOmit);
    return { ...filteredQueryParams, ...toAdd };
  };

  const updateQueryParams = (
    paramsConfig: ParamsConfig<T>,
    navigateOptions?: NavigateOptions,
  ) => {
    const queryParams = resolveQueryParams(paramsConfig);
    const stringified = queryString.stringify(queryParams, { arrayFormat });
    setSearchParams(stringified, navigateOptions);
  };

  const navigate = (
    pathnameOrBack: string | number,
    params?: ParamsConfig<T>,
    options?: NavigateOptions,
  ) => {
    const queryParams = resolveQueryParams(params);
    const query = queryString.stringify(queryParams);
    if (typeof pathnameOrBack === 'number') {
      defaultNavigate(pathnameOrBack);
    } else {
      defaultNavigate({ pathname: pathnameOrBack, search: query }, options);
    }
  };

  const createLink = (pathname: string, params?: ParamsConfig<T>) => {
    const queryParams = resolveQueryParams(params);
    const link = queryString.stringifyUrl({ url: pathname, query: queryParams });
    return link;
  };

  const omitObjectKeys = (raw: ParsedQuery<string>, keysToOmit?: Array<keyof T>) => {
    if (!keysToOmit) {
      return raw;
    }

    const filtered = Object.keys(raw)
      .filter((key) => !keysToOmit?.includes(key as keyof T))
      .reduce(
        (obj, key: string) => {
          // eslint-disable-next-line
        obj[key] = raw[key];
          return obj;
        },
        {} as typeof raw,
      );

    return filtered;
  };

  return {
    queryParams,
    updateQueryParams,
    navigate,
    createLink,
    pathname,
  };
};
