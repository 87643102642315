import { Checkbox } from 'design-system';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarRateIcon from '@mui/icons-material/StarRate';
import { FC } from 'react';
interface PropTypes {
  checkboxTypeProp?: checkboxType;
  onSelect?: () => unknown;
  selected?: boolean;
}

export enum checkboxType {
  favorite,
  unfavorite,
}
export const CheckboxIcon: FC<PropTypes> = ({ checkboxTypeProp, onSelect, selected }) => {
  return (
    <>
      {checkboxTypeProp !== undefined && (
        <Checkbox
          icon={
            checkboxTypeProp == checkboxType.favorite ? (
              <StarRateIcon style={{ color: '#FAD02C' }} />
            ) : (
              <StarBorderIcon />
            )
          }
          onChange={onSelect}
          checked={!!selected}
          data-testid="bulletin-checkbox"
        />
      )}
      {checkboxTypeProp === undefined && (
        <Checkbox
          onChange={onSelect}
          checked={!!selected}
          data-testid="bulletin-checkbox"
        />
      )}
    </>
  );
};
