import { getIconForTransferMode } from '@src/itinerary/transfer/common/transferMode';
import { FC } from 'react';
import { Tile } from './Tile';
import { TransferMode } from '@flashpack/graphql';

export interface TransferTilePropTypes {
  key: string;
  transferMode?: TransferMode;
  engagementId: string;
  startLocation: string;
  endLocation: string;
  startTime: string;
  endTime: string;
  href: string;
  flagged: boolean;
  commentCount: number;
  lastCommentAt?: string;
  selectEngagement?: () => void;
  isSelected?: boolean;
  showCheckbox: boolean;
}

export const TransferTile: FC<TransferTilePropTypes> = (props) => {
  const {
    startLocation,
    endLocation,
    startTime,
    endTime,
    href,
    flagged,
    transferMode,
    commentCount,
    lastCommentAt,
    selectEngagement,
    isSelected,
    showCheckbox = false,
  } = props;

  const Icon = getIconForTransferMode(transferMode);

  return (
    <Tile
      icon={<Icon />}
      href={href}
      title={`${startLocation} > ${endLocation}`}
      timing={`${startTime} - ${endTime}`}
      variant="transfer"
      flagged={flagged}
      commentCount={commentCount}
      lastCommentAt={lastCommentAt}
      showCheckbox={showCheckbox}
      selectItem={selectEngagement}
      isSelected={isSelected}
      sx={showCheckbox ? { marginLeft: 0 } : {}}
    ></Tile>
  );
};
