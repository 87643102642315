import { Button, ButtonTypeMap, Typography, useTheme } from '@mui/material';
import { FC, ReactNode, useCallback, useState } from 'react';
import { Stack } from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { logger } from '@sentry/utils';

export interface PropTypes {
  alignment?: 'start' | 'end';
  children: ReactNode;
  confirmationText?: string;
  confirmBtnLabel?: string;
  confirmBtnColor?: ButtonTypeMap['props']['color'];
  cancelBtnLabel?: string;
  btnSize?: 'small' | 'medium' | 'large';
  btnVariant?: 'text' | 'outlined' | 'contained';
  btnColor?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'error'
    | 'info'
    | 'warning';
  inline?: boolean;
  onConfirm: () => unknown;
}

export const InlineConfirmation: FC<PropTypes> = ({
  confirmationText,
  alignment,
  confirmBtnLabel,
  cancelBtnLabel,
  confirmBtnColor,
  onConfirm,
  children,
  btnSize,
  btnVariant,
  btnColor,
  inline,
}) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const handleConfirm = useCallback(async () => {
    setIsProcessing(true);

    try {
      await Promise.resolve(onConfirm());
    } catch (e) {
      logger.error(
        "Unhandled error in InlineConfirmation, please handle the error in the onConfirm handler'",
        e,
      );
    }

    setIsExpanded(false);
    setIsProcessing(false);
  }, [onConfirm]);

  if (!isExpanded) {
    return (
      <Button
        size={btnSize}
        variant={btnVariant}
        color={btnColor}
        onClick={() => setIsExpanded(true)}
        data-testid="expand-confirmation-button"
      >
        {children}
      </Button>
    );
  }

  return (
    <Wrapper inline={!!inline} alignment={alignment}>
      <Typography variant="bodyPara">{confirmationText}</Typography>
      <Stack direction="row" flexWrap="wrap" gap={theme.spacing(1)}>
        <Button
          variant="outlined"
          size={btnSize}
          disabled={isProcessing}
          onClick={() => setIsExpanded(false)}
        >
          {cancelBtnLabel}
        </Button>
        <LoadingButton
          size={btnSize}
          color={confirmBtnColor}
          variant="contained"
          onClick={() => void handleConfirm()}
          loading={isProcessing}
          data-testid="confirm-remove-button"
        >
          {confirmBtnLabel}
        </LoadingButton>
      </Stack>
    </Wrapper>
  );
};

InlineConfirmation.defaultProps = {
  confirmationText: 'Are you sure?',
  alignment: 'end',
  confirmBtnLabel: 'Confirm',
  cancelBtnLabel: 'Cancel',
  confirmBtnColor: 'error',
  inline: false,
  btnSize: 'small',
};

const Wrapper = ({
  children,
  alignment,
  inline,
}: {
  children: ReactNode;
  alignment: PropTypes['alignment'];
  inline: boolean;
}) => (
  <Stack
    direction={inline ? 'row' : 'column'}
    minHeight={inline ? '0px' : '70px'}
    alignItems={alignment}
  >
    {children}
  </Stack>
);
