import { FC, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import MUITable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { MemoizedTableBodyCell } from './TableBodyCell';
import { Paper, Typography } from '@mui/material';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { FormState } from 'final-form';
import isEqual from 'lodash.isequal';
import { styled } from '@mui/material/styles';
import {
  HeaderBox,
  TableContainerBox,
  TableHeaderCell,
  TableHeaderDivider,
  TableHeaderRow,
  TableBodyRow,
} from './Table';

interface PropTypes {
  initialData: MinimalTableData;
  handleSubmit: (values: MinimalTableData) => Promise<void>;
  isLoading?: boolean;
  tableFormId: string;
  required?: boolean;
  tooltipText?: string;
  formError?: string;
  editable?: boolean;
}

export type MinimalTableHeader = {
  name: string;
  label: string;
};

export type MinimalTableRow = {
  values: Record<string, number | null>;
};

export type MinimalTableData = {
  headers: MinimalTableHeader[];
  rows: MinimalTableRow[];
};

export const MinimalTable: FC<PropTypes> = ({
  initialData,
  handleSubmit,
  tableFormId,
  required = true,
  isLoading = false,
  tooltipText,
  formError,
  editable = true,
}) => {
  const [formValues, setFormValues] = useState<MinimalTableData>(initialData);

  const onFormStateChange = (
    formState: FormState<MinimalTableData, Partial<MinimalTableData>>,
  ) => {
    // we avoid updating the state if the values are unchanged
    if (isEqual(formState.values, formValues)) {
      return;
    }

    setFormValues({
      ...formState.values,
      rows: formState.values.rows.map((row) => ({
        ...row,
        values: row.values ?? {},
      })),
    });
  };

  return (
    <>
      <TableContainerBox>
        <TableContainer component={TablePaper}>
          <Form
            onSubmit={handleSubmit}
            initialValues={formValues}
            mutators={{
              ...arrayMutators,
            }}
          >
            {({ handleSubmit }) => (
              <form
                onSubmit={(tableData) => void handleSubmit(tableData)}
                id={tableFormId}
              >
                <MUITable
                  sx={{
                    borderCollapse: 'separate',
                    borderSpacing: '0px 8px',
                    marginTop: '-8px',
                    paddingLeft: '20px',
                  }}
                  size="small"
                  stickyHeader
                >
                  <TableHead>
                    <TableHeaderRow>
                      {initialData.headers.map((header, index) => (
                        <TableHeaderCell key={index} cellWidth={80}>
                          <HeaderBox sx={{ flexDirection: 'column' }}>
                            <Typography variant="bodyPara">{header.label}</Typography>
                          </HeaderBox>
                          <TableHeaderDivider />
                        </TableHeaderCell>
                      ))}
                    </TableHeaderRow>
                  </TableHead>
                  <TableBody>
                    <FieldArray name="rows">
                      {({ fields }) =>
                        fields.map((row, rowIndex) => (
                          <TableBodyRow key={rowIndex}>
                            {initialData.headers.map((header, headerIndex) => (
                              <MemoizedTableBodyCell
                                key={headerIndex}
                                name={`${row}.values.${header.name}`}
                                value={formValues.rows[rowIndex].values[header.name]}
                                loading={isLoading}
                                editable={editable}
                                required={required}
                                isLast={headerIndex === initialData.headers.length - 1}
                                tooltipValue={
                                  tooltipText ? tooltipText : `${header.label}`
                                }
                                clickHandler={() => {}}
                              />
                            ))}
                          </TableBodyRow>
                        ))
                      }
                    </FieldArray>
                  </TableBody>
                </MUITable>
                <FormSpy onChange={onFormStateChange} />
              </form>
            )}
          </Form>
        </TableContainer>
      </TableContainerBox>
      <Typography variant="captionPara" color="error">
        {formError}
      </Typography>
    </>
  );
};

const TablePaper = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: theme.palette.principal.grey30,
  paddingRight: theme.spacing(1),
  overflowY: 'scroll',
  overflowX: 'scroll',
  '::-webkit-scrollbar': {
    '-webkit-appearance': 'none',
    width: '8px',
    height: '8px',
  },
  '::-webkit-scrollbar-thumb': {
    borderRadius: '5px',
    backgroundColor: theme.palette.principal.grey50,
    '&:hover': {
      backgroundColor: theme.palette.principal.grey70,
    },
  },
}));

export default MinimalTable;
