import { styled } from '@mui/material';
import { Box } from '@mui/system';

export const DayBoxItem = styled(Box)(({ theme }) => ({
  borderColor: theme.palette.principal.grey30,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderRadius: theme.spacing(0.5),
  minWidth: theme.spacing(4),
  width: '100%',
  textAlign: 'center',
}));
