import { useRequiredParams } from '@src/shared/useRequiredParams';
import { Outlet } from 'react-router-dom';
import { ItineraryHeader } from './ItineraryHeader';
import ItineraryRightEdgeSidebar from '@src/itinerary/itinerary-right-edge-sidebar/ItineraryRightEdgeSidebar';
export const ItineraryPage = () => {
  const { itineraryId } = useRequiredParams(['itineraryId']);

  return (
    <>
      <ItineraryHeader itineraryId={itineraryId} />
      <ItineraryRightEdgeSidebar />
      <Outlet />
    </>
  );
};
