import { Dispatch, FC, SetStateAction } from 'react';
import { Button, Stack, Typography } from 'design-system';
import { MenuItem } from '@mui/material';
import { Form } from 'react-final-form';
import { Validator, composeValidators } from 'src/design-system/forms/validators';
import { FloatingToolbar } from '@src/design-system/floating-toolbar/FloatingToolbar';
import { flashPackTheme } from '@src/design-system/theme';
import { Select, TextField } from 'mui-rff';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useToast } from '@src/shared/toast/useToast';
import {
  MoveDirection,
  OverlappingTimelineItemPair,
  OverlappingTimelineItemPairIfAnyDocument,
  MoveMultipleItemsInSingleTimelineDocument,
} from '@flashpack/graphql';
import { FormParser } from '@src/design-system/forms/parsers';

interface PropTypes {
  visible: boolean;
  deselectAll: () => void;
  engagementIds: string[];
  accommodationIds: string[];
  timelineId: string;
  setOverlappingTimelineItemPair: Dispatch<
    SetStateAction<OverlappingTimelineItemPair | null>
  >;
}

type MoveMultipleForm = {
  moveDirection: MoveDirection;
  moveSizeInMinutes: number;
};

export const MoveMultipleTimelineItemsToolbar: FC<PropTypes> = ({
  visible,
  deselectAll,
  engagementIds,
  accommodationIds,
  timelineId,
  setOverlappingTimelineItemPair,
}) => {
  const { safeMutation } = useSafeMutation();
  const { success, error: errorToast } = useToast();

  const [overlappingTimelineItemPairIfAny] = useLazyQuery(
    OverlappingTimelineItemPairIfAnyDocument,
  );

  const [moveMultipleItemsInSingleTimeline] = useMutation(
    MoveMultipleItemsInSingleTimelineDocument,
  );

  const onSubmit = async (formValues: MoveMultipleForm) => {
    const { data: overlapData } = await overlappingTimelineItemPairIfAny({
      variables: {
        input: {
          engagementIds: engagementIds,
          accommodationIds: accommodationIds,
          moveDirection: formValues.moveDirection,
          moveSizeInMinutes: formValues.moveSizeInMinutes,
          timelineId,
        },
      },
    });
    if (overlapData?.overlappingTimelineItemPairIfAny) {
      setOverlappingTimelineItemPair(overlapData.overlappingTimelineItemPairIfAny);
      return;
    }
    await safeMutation(
      moveMultipleItemsInSingleTimeline({
        variables: {
          input: {
            engagementIds,
            accommodationIds,
            moveDirection: formValues.moveDirection,
            moveSizeInMinutes: formValues.moveSizeInMinutes,
            timelineId,
          },
        },
      }),
      {
        onSuccess: () => {
          success('Successfully moved selected items');
        },
        onServerValidationError: (error) => {
          errorToast(error.message);
          throw error;
        },
        onUnexpectedError: () =>
          errorToast('Failed to move selected items. Please try again.'),
      },
    );
    deselectAll();
  };

  return (
    <FloatingToolbar visible={visible}>
      <>
        <Form<MoveMultipleForm>
          onSubmit={onSubmit}
          initialValues={{
            moveDirection: MoveDirection.Later,
            moveSizeInMinutes: 0,
          }}
          render={({ handleSubmit }) => (
            <form
              id="move-multiple-timeline-items-toolbar-form"
              onSubmit={(event) => void handleSubmit(event)}
            >
              <>
                <Stack
                  direction="row"
                  sx={{ color: 'white' }}
                  alignItems="center"
                  gap={1}
                >
                  <Typography key={0} variant="bodySingle" sx={{ whiteSpace: 'nowrap' }}>
                    Move selected items
                  </Typography>
                  <Select
                    name="moveDirection"
                    variant="outlined"
                    sx={{
                      color: flashPackTheme.palette.principal.white,
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: flashPackTheme.palette.common.white,
                      },
                      '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: flashPackTheme.palette.common.white,
                        borderWidth: flashPackTheme.spacing(0.25),
                      },
                      '.MuiSelect-icon': {
                        color: flashPackTheme.palette.common.white,
                      },
                      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: flashPackTheme.palette.common.white,
                      },
                    }}
                  >
                    <MenuItem value={MoveDirection.Later}>later</MenuItem>
                    <MenuItem value={MoveDirection.Earlier}>earlier</MenuItem>
                  </Select>
                  <Typography key={0} variant="bodySingle" sx={{ whiteSpace: 'nowrap' }}>
                    by
                  </Typography>
                  <TextField
                    name="moveSizeInMinutes"
                    type="number"
                    onFocus={(event) => event.target.select()}
                    fieldProps={{
                      parse: FormParser.parseNumber,
                      validate: composeValidators(
                        Validator.required,
                        Validator.validMoveMinutesSize,
                      ),
                    }}
                    sx={{
                      '.MuiOutlinedInput-root': {
                        color: flashPackTheme.palette.common.white,
                        // width: '200px',
                        '& fieldset': {
                          borderColor: flashPackTheme.palette.common.white,
                        },
                        '&:hover fieldset': {
                          borderColor: flashPackTheme.palette.common.white,
                          borderWidth: flashPackTheme.spacing(0.25),
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: flashPackTheme.palette.common.white,
                        },
                      },
                    }}
                  />
                  <Typography key={0} variant="bodySingle" sx={{ whiteSpace: 'nowrap' }}>
                    minutes
                  </Typography>
                </Stack>
              </>
            </form>
          )}
        />
        <Stack direction="row" alignItems="center" gap={1}>
          <Button
            variant="light-contained"
            type="submit"
            form="move-multiple-timeline-items-toolbar-form"
          >
            Move items
          </Button>
          <Button variant="light-outlined" onClick={deselectAll}>
            Cancel
          </Button>
        </Stack>
      </>
    </FloatingToolbar>
  );
};
