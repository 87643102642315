import { FC, useContext } from 'react';
import { Box, GenericError } from 'design-system';
import { EditEngagementsDocument } from '@flashpack/graphql';
import { useRouting } from 'src/shared/useRouting';
import { useToast } from 'src/shared/toast/useToast';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { SharedTabsContext } from '@src/shared/context/SharedTabsContext';
import { EditTransferFormValues, TransferForm } from '../common/TransferForm';
import { TransferMapper } from '../common/transferMapper';
import { useMutation } from '@apollo/client';
import { WarningMessage } from '@src/design-system/warning-message/WarningMessage';

type PropTypes = {
  isTimelineLocked?: boolean;
  isEditable: boolean;
};

const lockedTimelineMessage =
  'This itinerary has departures running and cannot be edited.';

export const TransferInfoTab: FC<PropTypes> = ({ isTimelineLocked, isEditable }) => {
  const { adventureId, itineraryId, transferId } = useRequiredParams([
    'transferId',
    'itineraryId',
    'adventureId',
  ]);

  const { transferForm } = useContext(SharedTabsContext);

  const { success: successToast, error: errorToast } = useToast();
  const { navigate } = useRouting();
  const [editEngagements, { error }] = useMutation(EditEngagementsDocument);
  const { safeMutation } = useSafeMutation();

  if (!adventureId || !itineraryId || !transferId) {
    return <GenericError error="Malformed url, cannot render edit activity page" />;
  }

  const onEdit = async (formValues: EditTransferFormValues) => {
    const input = TransferMapper.toEditTransferInput(formValues);

    await safeMutation(editEngagements({ variables: { input } }), {
      onSuccess: () => {
        successToast('Transfer successfully edited');
      },
      onUnexpectedError: () => {
        errorToast('There was an issue while saving this transfer - try again later');
      },
      onServerValidationError: (error) => {
        errorToast(error.message);
      },
    });
    transferForm?.setHasPendingChanges(false);
  };

  const navigateToItineraryTimeline = () => {
    navigate('../');
  };

  return (
    <Box>
      {isTimelineLocked && (
        <WarningMessage message={lockedTimelineMessage} iconType="LOCKED" />
      )}
      <TransferForm<EditTransferFormValues>
        initialValues={transferForm?.state}
        onSubmit={onEdit}
        onCancel={navigateToItineraryTimeline}
        error={error}
        locked={isTimelineLocked || !isEditable}
        actionOnDelete={navigateToItineraryTimeline}
      />
    </Box>
  );
};
