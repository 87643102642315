import { useRequiredParams } from '@src/shared/useRequiredParams';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { AdventureTabs } from './AdventureTabs';
import { AdventureHeader } from '@src/adventure/adventure-overview/AdventureHeader';
import AdventureRightEdgeSidebar from './AdventureRightEdgeSidebar';

export const ADVENTURE_TAB_HANDLE = 'adventureTab';

export enum AdventureTab {
  ACTIONS = 'Actions',
  ITINERARIES = 'Itineraries',
  SETTINGS = 'Settings',
  DEPARTURES = 'Departures',
}

export const AdventurePage: FC = () => {
  const { adventureId } = useRequiredParams(['adventureId']);

  return (
    <>
      <AdventureHeader adventureId={adventureId} />
      <AdventureTabs adventureId={adventureId} />
      <AdventureRightEdgeSidebar />
      <Outlet />
    </>
  );
};
