import { FC, useState } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { Protected } from '@src/authentication/Protected';
import {
  AdventureWithItinerariesAndDeparturesDocument,
  Departure,
  DepartureHeaderQueryDocument,
  Scalars,
  UpdateDepartureSettingsDocument,
  UserRole,
} from '@flashpack/graphql';
import SettingsIcon from '@mui/icons-material/Settings';
import { UpdateDepartureSettingsDialog } from '../UpdateDepartureSettingsDialog';
import { useMutation } from '@apollo/client';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useToast } from '@src/shared/toast/useToast';

type DepartureSettingsUpdateManyProps = {
  isMultiple: true;
  departureIds: Scalars['UUID'][];
};

type DepartureSettingsUpdateOneProps = {
  isMultiple: false;
  departure: Departure;
};

type DepartureSettingsButtonProps =
  | DepartureSettingsUpdateManyProps
  | DepartureSettingsUpdateOneProps;

export const DepartureSettingsButton: FC<DepartureSettingsButtonProps> = (props) => {
  const departure = props.isMultiple ? undefined : props.departure;
  const departureIds = props.isMultiple ? props.departureIds : [props.departure.id];

  const [settingsDialogOpen, setSettingsDialogOpen] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { safeMutation } = useSafeMutation();
  const toast = useToast();
  const [updateDepartureSettingsMutation] = useMutation(UpdateDepartureSettingsDocument);

  const updateDepartureSettings = async ({
    roomReleaseDays,
    capacity,
    toGuarantee,
    earlyBirds,
    manifestUrl,
  }: {
    roomReleaseDays: number;
    capacity?: number;
    toGuarantee?: number;
    earlyBirds?: number;
    manifestUrl?: string;
  }) => {
    setIsSubmitting(true);
    await safeMutation(
      updateDepartureSettingsMutation({
        variables: {
          input: {
            departureIds,
            capacity,
            minToGuarantee: toGuarantee,
            maxEarlyBirds: earlyBirds,
            roomReleaseDays,
            manifestUrl,
          },
        },
        refetchQueries: [
          props.isMultiple
            ? AdventureWithItinerariesAndDeparturesDocument
            : DepartureHeaderQueryDocument,
        ],
      }),
      {
        onSuccess: () => {
          toast.success(`Departure settings updated`);
          setIsSubmitting(false);
          setSettingsDialogOpen(false);
        },
        onError: () => {
          toast.error('Updating departure settings failed');
          setIsSubmitting(false);
        },
      },
    );
  };

  return (
    <Protected roles={[UserRole.Flashpack, UserRole.Dmc]}>
      <Tooltip title="Settings">
        <IconButton
          onClick={() => setSettingsDialogOpen(true)}
          sx={{ color: departure ? '' : 'white' }}
          data-testid="departure-settings-button"
        >
          {isSubmitting ? <CircularProgress size="24px" /> : <SettingsIcon />}
        </IconButton>
      </Tooltip>
      <UpdateDepartureSettingsDialog
        open={settingsDialogOpen}
        departure={departure}
        onUpdate={updateDepartureSettings}
        onClose={() => setSettingsDialogOpen(false)}
      />
    </Protected>
  );
};
