import { useMutation } from '@apollo/client';
import { Button, Stack } from '@mui/material';
import { TextButton } from 'design-system';
import { ConfirmationDialog } from '@src/design-system/dialogs/ConfirmationDialog';
import { DateChangeField } from '@src/design-system/forms/date-change-field/DateChangeField';
import { ServerValidationErrors } from '@src/design-system/forms/ServerValidationErrors';
import {
  Departure,
  DepartureForDatePickerFragment,
  DepartureHeaderQueryDocument,
  UpdateDeparturesDocument,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { formatISO, isSameDay, parseISO } from 'date-fns';
import { FC } from 'react';
import { Form } from 'react-final-form';

interface PropTypes {
  existingDepartures: DepartureForDatePickerFragment[];
  departure: Departure;
  onClose: () => void;
  open: boolean;
}

export const UpdateDepartureDateDialog: FC<PropTypes> = (props) => {
  const { departure, existingDepartures } = props;
  const initialDepartureDate = parseISO(departure.date);

  const toasts = useToast();
  const { safeMutation } = useSafeMutation();

  const [updateDeparturesMutation, { error }] = useMutation(UpdateDeparturesDocument, {
    refetchQueries: [DepartureHeaderQueryDocument],
  });

  const onUpdate = async ({ id, date }: { id: string; date: Date }) => {
    await safeMutation(
      updateDeparturesMutation({
        variables: {
          departures: [
            {
              id,
              date: formatISO(date, { representation: 'date' }),
            },
          ],
        },
      }),
      {
        onSuccess: () => {
          toasts.success('Departure date updated');
          props.onClose();
        },
      },
    );
  };

  return (
    <ConfirmationDialog
      {...props}
      title="Update departure date?"
      maxWidth="sm"
      onCancel={props.onClose}
      hasCustomActions
    >
      <Form<{ id: string; date: Date }>
        keepDirtyOnReinitialize
        initialValues={{ id: departure.id, date: initialDepartureDate }}
        onSubmit={(v) => void onUpdate(v)}
        render={({ handleSubmit, dirtySinceLastSubmit }) => (
          <form onSubmit={(e) => void handleSubmit(e)}>
            <Stack alignItems="center">
              <DateChangeField
                highlights={existingDepartures.map((d) => {
                  return {
                    date: parseISO(d.date),
                    softHighlightDuration: d.timeline.durationDays,
                    heavyHighlightDuration: d.timeline.longestAccommodationDurationDays,
                  };
                })}
                validate={(day) =>
                  isSameDay(day, initialDepartureDate) ? 'Required' : undefined
                }
                name="date"
                validationError={
                  !dirtySinceLastSubmit && <ServerValidationErrors error={error} />
                }
              />
            </Stack>

            <Stack mt={5} direction="row" gap={3} justifyContent="space-between">
              <TextButton onClick={props.onClose}>Cancel</TextButton>
              <Button type="submit" variant="contained">
                Update
              </Button>
            </Stack>
          </form>
        )}
      />
    </ConfirmationDialog>
  );
};
