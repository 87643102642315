import { FC } from 'react';
import { InlineConfirmation } from 'src/design-system/dialogs/InlineConfirmation';
import {
  AdventureWithItineraryAndDepartureTimelinesDocument,
  DeleteEngagementsDocument,
  TimelineDocument,
} from '@flashpack/graphql';
import { useToast } from 'src/shared/toast/useToast';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useMutation } from '@apollo/client';
import { DeleteTrashCanIcon, Stack, Typography } from 'design-system';

interface PropTypes {
  engagementName: 'activity' | 'transfer';
  engagementIds: string[];
  actionOnDelete?: () => void;
}

export const DeleteEngagements: FC<PropTypes> = ({
  engagementIds,
  engagementName,
  actionOnDelete,
}) => {
  const [deleteEngagements] = useMutation(DeleteEngagementsDocument);
  const { success, error: errorToast } = useToast();
  const { safeMutation } = useSafeMutation();

  const onDelete = async () => {
    await safeMutation(
      deleteEngagements({
        variables: { engagementIds },
        refetchQueries: [
          TimelineDocument,
          AdventureWithItineraryAndDepartureTimelinesDocument,
        ],
      }),
      {
        onSuccess: () => {
          if (actionOnDelete) {
            actionOnDelete();
          }
          success(`Successfully removed ${engagementName}`);
        },
        onError: () =>
          errorToast(`Unexpected error occurred while deleting ${engagementName}`),
      },
    );
  };

  return (
    <InlineConfirmation
      confirmBtnLabel="Confirm"
      btnVariant="text"
      btnColor="error"
      btnSize="large"
      onConfirm={onDelete}
      alignment="start"
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
        <DeleteTrashCanIcon sx={{ height: '18px' }} />
        <Typography>Delete</Typography>
      </Stack>
    </InlineConfirmation>
  );
};
