import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EngagementFieldsFragmentWithDeparture } from './utils';
import { Engagement, EngagementFieldsForComparisonFragment } from '@flashpack/graphql';
import { MemoizedTimelineEventBoxEngagement } from './TimelineEventBoxEngagement';
import { Box, Stack } from '@mui/system';
import { Checkbox, flashPackTheme, Typography } from 'design-system';
import { ReactComponent as EmptyActivityIcon } from './EmptyActivityIcon.svg';
import { ReactComponent as EmptyTransferIcon } from './EmptyTransferIcon.svg';
import { DaysFilter } from './DaysFilter';
import { NoMatchesFound } from './NoMatchesFound';

type Props = {
  departureEngagements: EngagementFieldsFragmentWithDeparture[];
  searchString: string;
  selectedEngagements: EngagementFieldsForComparisonFragment[];
  setSelectedEngagements: Dispatch<
    SetStateAction<EngagementFieldsForComparisonFragment[]>
  >;
  engagementType: 'activity' | 'transfer';
};

export const SearchedEngagements: FC<Props> = ({
  departureEngagements,
  searchString,
  selectedEngagements,
  setSelectedEngagements,
  engagementType,
}) => {
  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedDay, setSelectedDay] = useState<number | null>(null);
  const maxDay = departureEngagements.reduce(
    (max, e) => Math.max(max, e.engagement.end.time.day),
    0,
  );
  const daysArray = Array.from({ length: maxDay }, (_, i) => i + 1);

  const filteredEngagements = useMemo(
    () =>
      departureEngagements.filter((departureEngagement) => {
        const titleMatches = departureEngagement.engagement.title
          .toLowerCase()
          .includes(searchString.toLowerCase());
        const dayMatches =
          !selectedDay ||
          (selectedDay >= departureEngagement.engagement.start.time.day &&
            selectedDay <= departureEngagement.engagement.end.time.day);

        return titleMatches && dayMatches;
      }),
    [departureEngagements, searchString, selectedDay],
  );

  const toggleSelect = useCallback(
    (engagement: EngagementFieldsForComparisonFragment) => {
      setSelectedEngagements((selectedEngagements) => {
        if (
          selectedEngagements.find(
            (selectedEngagement) => selectedEngagement.id === engagement.id,
          )
        ) {
          setSelectedAll(false);
          return selectedEngagements.filter(
            (selectedEngagement) => selectedEngagement.id !== engagement.id,
          );
        }
        if (selectedEngagements.length === filteredEngagements.length - 1) {
          setSelectedAll(true);
        }
        return [...selectedEngagements, engagement];
      });
    },
    [setSelectedEngagements, filteredEngagements.length],
  );

  const toggleSelectAll = () => {
    if (selectedAll) {
      setSelectedEngagements([]);
    } else {
      setSelectedEngagements(filteredEngagements.map((e) => e.engagement));
    }
    setSelectedAll(!selectedAll);
  };

  // Clear selectedAll when search string changes
  useEffect(() => {
    setSelectedAll(false);
  }, [searchString]);

  // When drawer is closed, selectedAccommodations are cleared, so select all must be false
  useEffect(() => {
    if (selectedEngagements.length === 0) {
      setSelectedAll(false);
    }
  }, [selectedEngagements.length]);

  if (departureEngagements.length === 0) {
    return (
      <Box mt={4}>
        <Typography variant="h3" color={flashPackTheme.palette.principal.grey70} my={2}>
          Find the {engagementType} you want to change by{' '}
          {engagementType === 'transfer' ? 'location' : 'name'}
        </Typography>
        {engagementType === 'activity' ? <EmptyActivityIcon /> : <EmptyTransferIcon />}
      </Box>
    );
  }

  if (filteredEngagements.length === 0) {
    return (
      <NoMatchesFound
        daysArray={daysArray}
        selectedDay={selectedDay}
        setSelectedDay={setSelectedDay}
      />
    );
  }

  return (
    <Stack spacing={1}>
      <DaysFilter
        selectedDay={selectedDay}
        daysArray={daysArray}
        setSelectedDay={setSelectedDay}
      />
      <Stack direction="row" alignItems="center">
        <Checkbox
          checked={selectedAll}
          onChange={toggleSelectAll}
          data-testid="select-all-engagement-items"
        />
        <Typography color={flashPackTheme.palette.principal.grey70}>
          {`Select all ${filteredEngagements.length} result${filteredEngagements.length > 1 ? 's' : ''}`}
        </Typography>
      </Stack>
      {filteredEngagements.map(({ engagement, departureDate }, key) => (
        <MemoizedTimelineEventBoxEngagement
          key={engagement.id}
          selected={
            !!selectedEngagements.find(
              (selectedEngagement) => selectedEngagement.id === engagement.id,
            )
          }
          onSelect={() => {
            toggleSelect(engagement);
          }}
          departureDate={departureDate}
          engagement={engagement as Engagement}
          testid={`searched-engagement-item-${key}`}
        />
      ))}
    </Stack>
  );
};
