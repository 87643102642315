import { FC, useState } from 'react';
import { Button, Container, Stack, Typography, useTheme } from '@mui/material';
import { MultiDatePickerField } from '@src/design-system/forms/multi-date-picker/MultiDatePickerField';
import { Field, Form, FormSpy } from 'react-final-form';
import { TextField } from 'mui-rff';
import { ValidatorFactory } from '@src/design-system/forms/validators';
import { FormParser } from '@src/design-system/forms/parsers';
import { LoadingButton } from '@mui/lab';
import { FormControlLabel } from '@src/shared/FormControlLabel';
import { DeparturePrivateGroup } from '@src/departures/DeparturePrivateGroup';
import { Circle } from '@mui/icons-material';
import { DepartureForDatePickerFragment } from '@flashpack/graphql';
import { HighlightInfo, SpecialDate } from '@src/shared/date-picker/DatePicker';
import { parseISO } from 'date-fns';
import { us } from '@src/public-holidays/us';
import { CheckboxFormField } from 'design-system';
import { FormState } from 'final-form';
import ItinerarySelectRFF from '@src/departures/ItinerarySelectRFF';

interface ProposeDepartureFormPropTypes {
  onSubmit: (values: ProposeDepartureFormValues) => Promise<void>;
  onCancel: () => void;
  existingDepartures: DepartureForDatePickerFragment[];
  itineraryDurationDays: number;
  itineraryLongestAccommodationDurationDays: number;
  handleSelectItinerary?: (itineraryId: string | undefined) => void;
  itineraryOptions?: { title: string; id: string }[];
  currentDate?: Date;
}

export interface ProposeDepartureFormValues {
  departureDates: Date[];
  roomRelease: number;
  privateGroup: boolean;
  itineraryId?: string;
}

export const ProposeDeparturesForm: FC<ProposeDepartureFormPropTypes> = ({
  onSubmit,
  onCancel,
  existingDepartures = [],
  itineraryDurationDays,
  itineraryLongestAccommodationDurationDays,
  handleSelectItinerary,
  itineraryOptions,
  currentDate,
}) => {
  const [selectedDates, setSelectedDates] = useState<Date[]>([]);
  const theme = useTheme();
  const specialDates: SpecialDate[] = us.map((record) => ({
    date: record.date,
    name: record.name,
    color: theme.palette.system.blue100,
  }));

  const datePickerHighlights: HighlightInfo[] = existingDepartures.map((d) => {
    return {
      date: parseISO(d.date),
      softHighlightDuration: d.timeline.durationDays,
      heavyHighlightDuration: d.timeline.longestAccommodationDurationDays,
    };
  });

  const newHighlights: HighlightInfo[] = selectedDates.map((d) => {
    return {
      date: d,
      softHighlightDuration: itineraryDurationDays,
      heavyHighlightDuration: itineraryLongestAccommodationDurationDays,
    };
  });

  const setDepartureDates = (e: Date[]) => {
    setSelectedDates(e);
  };

  return (
    <Container maxWidth="md">
      <Form<ProposeDepartureFormValues>
        onSubmit={(v) => onSubmit(v)}
        onChange={(e: Date[]) => void setDepartureDates(e)}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={(e) => void handleSubmit(e)}>
              <Stack spacing={6}>
                <Stack>
                  {itineraryOptions && handleSelectItinerary && (
                    <ItinerarySelectRFF itineraryOptions={itineraryOptions} />
                  )}
                  <MultiDatePickerField
                    validate={ValidatorFactory.createRequired(
                      'Please choose at least one departure date',
                    )}
                    name="departureDates"
                    currentDate={currentDate}
                    data-testid="departure-date-picker"
                    highlights={[...newHighlights, ...datePickerHighlights]}
                    fixedSelectedDates={existingDepartures.map((d) => ({
                      date: parseISO(d.date),
                      duration: d.timeline.durationDays,
                    }))}
                    itineraryDurationDays={itineraryDurationDays}
                    longestAccommodationDuration={
                      itineraryLongestAccommodationDurationDays
                    }
                    specialDates={specialDates}
                    onChange={(e) => void setDepartureDates(e)}
                  />
                  <Stack mt={2}>
                    <Typography variant="captionPara" margin={0}>
                      <Circle
                        sx={{
                          color: theme.palette.system.green10,
                          borderColor: theme.palette.primary.main,
                          borderWidth: 1,
                          borderStyle: 'solid',
                          borderRadius: '50%',
                          marginX: 1,
                          fontSize: '70%',
                        }}
                      />
                      Max length of stay
                    </Typography>
                    <Typography variant="captionPara" margin={0}>
                      <Circle
                        sx={{
                          color: theme.palette.primary.main,
                          marginX: 1,
                          fontSize: '70%',
                        }}
                      />
                      Departure date
                    </Typography>
                    <Typography variant="captionPara" margin={0}>
                      <Circle
                        sx={{
                          color: theme.palette.system.blue100,
                          marginX: 1,
                          fontSize: '70%',
                        }}
                      />
                      US Public Holidays
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" margin={0} justifyContent="space-between">
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="start"
                    alignItems="center"
                  >
                    <Typography variant="bodyPara">
                      Room release for all selected departures is
                    </Typography>
                    <TextField
                      fullWidth={false}
                      placeholder="eg 30"
                      InputProps={{ sx: { width: '80px' } }}
                      size="small"
                      name="roomRelease"
                      fieldProps={{
                        validate: ValidatorFactory.createRequired('Required'),
                        parse: FormParser.parseNumber,
                      }}
                      data-testid="room-release-field"
                    />
                    <Typography variant="bodyPara">days.</Typography>
                  </Stack>

                  <FormControlLabel
                    checked={values.privateGroup}
                    label={
                      <DeparturePrivateGroup
                        label="Private group booking"
                        colorVariant={values.privateGroup ? 'colored' : 'greyed'}
                      />
                    }
                    control={
                      <Field<boolean>
                        name="privateGroup"
                        initialValue={false}
                        component={CheckboxFormField}
                      />
                    }
                  />
                </Stack>
                <Stack spacing={2} alignItems="center">
                  <LoadingButton
                    loading={submitting}
                    sx={{ width: '220px' }}
                    variant="contained"
                    type="submit"
                    data-testid="propose-departures-submit"
                  >
                    Propose Departures
                  </LoadingButton>
                  <Button
                    onClick={() => onCancel()}
                    sx={{ width: '220px' }}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </Stack>
                <FormSpy
                  subscription={{ values: true }}
                  onChange={
                    handleSelectItinerary
                      ? ({
                          values,
                        }: FormState<
                          ProposeDepartureFormValues,
                          Partial<ProposeDepartureFormValues>
                        >) => {
                          handleSelectItinerary(values.itineraryId);
                        }
                      : () => {}
                  }
                />
              </Stack>
            </form>
          );
        }}
      />
    </Container>
  );
};
