import {
  RateHawkAccommodation,
  ManualAccommodation,
  TransferMode,
} from '@flashpack/graphql';
import _groupBy from 'lodash.groupby';
import { AccommodationTilePropTypes } from './AccommodationTile';
import { TransferTile, TransferTilePropTypes } from './TransferTile';
import { ActivityTile, ActivityTilePropTypes } from './ActivityTile';
import { calculateNaiveDuration } from '../naiveDayTimeUtils';
import { Engagement } from '@flashpack/graphql';
import add from 'date-fns/add';
import { addDays, format, formatDuration, intervalToDuration, parseISO } from 'date-fns';
import { toShortDurationFormat } from '@src/shared/dateUtils';
import { RoutePath } from '@src/shared/routePath';
import { TimelineShape } from './TimelineSchedule';

import { isRateHawkAccommodation } from '../accommodation/utils';

export enum TileType {
  ACCOMMODATION_LEADING,
  ACCOMMODATION_TRAILING,
  ENGAGEMENT_TRANSFER,
  ENGAGEMENT_ACTIVITY,
}

type TileBaseProps = {
  day: number;
  time: string;
  tileType: TileType;
  flagged: boolean;
  href: string;
  lastCommentAt?: string;
  commentCount: number;
};

type Tile = AccommodationTile | TransferTile | ActivityTile;
type AccommodationTile = TileBaseProps & AccommodationTilePropTypes;
type TransferTile = TileBaseProps & TransferTilePropTypes;
type ActivityTile = TileBaseProps & ActivityTilePropTypes;

export const convertToGroupedTilesByDay = (
  timeline: TimelineShape,
  showCheckbox?: boolean,
  isSelectedEngagement?: (id: string) => boolean,
  selectEngagement?: (id: string) => void,
) => {
  const accommodationTiles = getAccommodationTiles(timeline);
  const engagementTiles = getEngagementTiles(timeline);

  const allTiles: Tile[] = [...accommodationTiles, ...engagementTiles];
  const tilesGroupedByDay = _groupBy(allTiles, 'day');

  const sortedByTime = Object.keys(tilesGroupedByDay).reduce(
    (groupedByDay, day) => {
      groupedByDay[day] = tilesGroupedByDay[day].sort(compareTileTime);
      return groupedByDay;
    },
    {} as typeof tilesGroupedByDay,
  );

  // Nested engagement tiles for accommodations where accommodations overlap.
  Object.keys(sortedByTime).map((day) => {
    const accomTiles = sortedByTime[day].filter((x) => isAccommodationTile(x));
    let tiles: Tile[] = [...accomTiles];

    const otherTiles = sortedByTime[day].filter((x) => !isAccommodationTile(x));
    const nestedTiles: Tile[] = [];

    // If there are no engagements this day we don't need to do any work.
    if (otherTiles.length == 0) {
      return;
    }

    // For each engagement see if it overlaps an accommodation.
    otherTiles.forEach((otherTile) => {
      accomTiles.forEach((accommodation) => {
        if (
          isAccommodationTile(accommodation) &&
          isWithinAccommodation(accommodation, otherTile)
        ) {
          const children = accommodation.children || [];

          if (isActivityTile(otherTile)) {
            children.push(
              <ActivityTile
                {...otherTile}
                showCheckbox={showCheckbox || false}
                selectEngagement={
                  selectEngagement
                    ? () => selectEngagement(otherTile.engagementId)
                    : undefined
                }
                isSelected={
                  isSelectedEngagement
                    ? isSelectedEngagement(otherTile.engagementId)
                    : undefined
                }
              />,
            );
          }
          if (isTransferTile(otherTile)) {
            children.push(
              <TransferTile
                {...otherTile}
                showCheckbox={showCheckbox || false}
                selectEngagement={
                  selectEngagement
                    ? () => selectEngagement(otherTile.engagementId)
                    : undefined
                }
                isSelected={
                  isSelectedEngagement
                    ? isSelectedEngagement(otherTile.engagementId)
                    : undefined
                }
              />,
            );
          }

          if (children.length) {
            nestedTiles.push(otherTile);
            accommodation.children = children;
          }
        }
      });
    });

    // Any engagements which did not overlap an accommodation get put back in as individual nodes.
    tiles = tiles.concat(otherTiles.filter((t) => !nestedTiles.includes(t)));

    sortedByTime[day] = tiles.sort(compareTileTime); // need to sort again, individual engagement nodes were not sorted
  });

  return sortedByTime;
};

const isWithinAccommodation = (
  accommodation: AccommodationTile,
  engagementTile: Tile,
) => {
  if (
    accommodation.tileType == TileType.ACCOMMODATION_LEADING &&
    engagementTile.time < accommodation.time // checkInTime
  ) {
    return false;
  }

  if (
    accommodation.tileType == TileType.ACCOMMODATION_TRAILING &&
    engagementTile.time >= accommodation.time // checkOutTime
  ) {
    return false;
  }

  return true;
};

const getEngagementTiles = (
  timeline: TimelineShape,
): Array<ActivityTile | TransferTile> => {
  if (!timeline || !timeline.engagements) {
    return [];
  }

  const engagements = timeline.engagements;

  return engagements.map((engagement) => {
    const engagementTileProps = {
      day: engagement.start.time.day,
      time: engagement.start.time.time,
      startTime: engagement.start.time.time,
      endTime: engagement.end.time.time,
      startLocation: engagement.start.location,
      endLocation: engagement.end.location,
      engagementId: engagement.id,
      flagged: engagement.flagged,
      lastCommentAt: engagement.commentThread.lastCommentAt,
      commentCount: engagement.commentThread.commentCount,
    };

    if (engagement.activity) {
      const href = RoutePath.VIEW_ACTIVITY.generateRelativePath(engagement.id);
      return {
        key: engagement.id,
        tileType: TileType.ENGAGEMENT_ACTIVITY,
        title: engagement.title,
        href,
        mealIncluded: engagement.activity.mealIncluded,
        ...engagementTileProps,
      } as ActivityTile;
    }
    const href = RoutePath.VIEW_TRANSFER.generateRelativePath(engagement.id);
    return {
      key: engagement.id,
      tileType: TileType.ENGAGEMENT_TRANSFER,
      transferMode: engagement.transfer?.mode,
      href,
      ...engagementTileProps,
    } as TransferTile;
  });
};

const getAccommodationTiles = (timeline?: TimelineShape) => {
  if (!timeline || !timeline.accommodations) {
    return [];
  }

  const accommodations = timeline.accommodations as Array<
    RateHawkAccommodation | ManualAccommodation
  >;

  return accommodations
    .map((acc) => {
      const tiles = [];

      for (let day = acc.checkIn.day; day <= acc.checkOut.day; day++) {
        const includeCheckIn = day === acc.checkIn.day;
        const includeCheckOut = day === acc.checkOut.day;

        const getTileTime = () => {
          if (includeCheckIn) {
            return acc.checkIn.time;
          }
          if (includeCheckOut) {
            return acc.checkOut.time;
          }
          return null;
        };

        const type = includeCheckIn
          ? TileType.ACCOMMODATION_LEADING
          : TileType.ACCOMMODATION_TRAILING;

        const href = RoutePath.VIEW_ACCOMMODATION.generateRelativePath(acc.id);

        const tile = {
          tileType: type,
          day,
          time: getTileTime(),
          accommodationId: acc.id,
          flagged: acc.flagged,
          href,
          lastCommentAt: acc.commentThread.lastCommentAt,
          commentCount: acc.commentThread.commentCount,
          ...resolveAccommodationTypeDependantProps(acc),
        } as AccommodationTile;

        tiles.push(tile);
      }

      return tiles;
    })
    .flat();
};

export const resolveAccommodationTypeDependantProps = (
  accommodation: RateHawkAccommodation | ManualAccommodation,
): Partial<AccommodationTilePropTypes> => {
  if (isRateHawkAccommodation(accommodation)) {
    return {
      name: accommodation.hotel?.name,
      country: accommodation.hotel?.location?.country?.name,
      region: accommodation.hotel?.location?.region,
    };
  } else {
    return {
      name: accommodation.description,
    };
  }
};

/**
 * Compares the time attribute of the provided tiles.
 * Tiles without time specified take priority.
 */
const compareTileTime = (tileA: TileBaseProps, tileB: TileBaseProps) => {
  if (!tileA.time) {
    return -1;
  }
  if (!tileB.time) {
    return 1;
  }
  if (tileA.time < tileB.time) {
    return -1;
  }
  if (tileA.time > tileB.time) {
    return 1;
  }
  return 0;
};

export const timelineIsEmpty = (timeline: TimelineShape): boolean => {
  const hasAccommodations = timeline?.accommodations?.length > 0;
  const hasEngagements = timeline?.engagements?.length > 0;
  return !(hasAccommodations || hasEngagements);
};

export const isAccommodationTile = (tile: Tile): tile is AccommodationTile => {
  return (
    tile.tileType === TileType.ACCOMMODATION_LEADING ||
    tile.tileType === TileType.ACCOMMODATION_TRAILING
  );
};

export const isTransferTile = (tile: Tile): tile is TransferTile => {
  return tile.tileType === TileType.ENGAGEMENT_TRANSFER;
};

export const isActivityTile = (tile: Tile): tile is ActivityTile => {
  return tile.tileType === TileType.ENGAGEMENT_ACTIVITY;
};

export const calculateTransferDurationByMode = (timeline: TimelineShape) => {
  const transfers = timeline.engagements.filter((engagement) => !!engagement.transfer);

  const transfersGroupedByMode = _groupBy(transfers, 'transfer.mode') as Record<
    TransferMode,
    Array<Engagement>
  >;

  const totalTransferDurationByMode = (
    Object.keys(transfersGroupedByMode) as Array<TransferMode>
  ).reduce(
    (durationByTransferMode, mode: TransferMode) => {
      let totalTransferTimeForMode = 0;

      transfersGroupedByMode[mode].forEach((transfer) => {
        const [, , totalMinutes] = calculateNaiveDuration(
          transfer.start.time,
          transfer.end.time,
        );

        totalTransferTimeForMode += totalMinutes;
      });

      const start = new Date();
      const end = add(start, { minutes: totalTransferTimeForMode });
      const formattedDuration = formatDuration(intervalToDuration({ start, end }));
      durationByTransferMode[mode] = toShortDurationFormat(formattedDuration);

      return durationByTransferMode;
    },
    {} as Record<TransferMode, string>,
  );

  return totalTransferDurationByMode;
};

export const convertDayToDate = (initialDate: string, day: number) => {
  // 'day' is +1 indexed so we need to subtract 1 d
  const newDate = addDays(parseISO(initialDate), day - 1);
  return `${format(newDate, 'd LLL')}`;
};
