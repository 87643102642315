import {
  Adventure,
  Engagement,
  Itinerary,
  UserActivityAction,
  UserActivityReference,
  UserActivityReferenceFragment,
} from '@flashpack/graphql';
import { ActivityFeedItemProps } from './ActivityFeedItem';
import { generatePath } from 'react-router-dom';
import { RoutePath } from '@src/shared/routePath';
import { commentAnchor } from '@src/shared/timeline/util';
import { MessageThreadAnchor } from '@src/itinerary/util';
import { adventureIdsForBetaReleaseDepartureSignOffs } from '@src/adventure/common/utils';
import { Environment, environmentIs } from '@src/app/environment';

export function generateActivityFeedItemUrl({
  action,
  departure,
  adventure,
  itinerary,
  subject,
  target,
}: Pick<
  ActivityFeedItemProps,
  'action' | 'departure' | 'adventure' | 'itinerary' | 'subject' | 'target' | 'timeline'
>): string | null {
  // TODO: Complete this function to create URL links for each activity item

  if (
    subject.__typename === 'MissingUserActivityReference' ||
    target?.__typename === 'MissingUserActivityReference'
  ) {
    return null;
  }

  switch (action) {
    case UserActivityAction.CreatedItinerary: {
      return RoutePath.ITINERARY_TIMELINE.generatePath(
        adventure?.id || '',
        itinerary?.id || '',
      );
    }
    case UserActivityAction.BulkUpdatedDepartureCapacity:
    case UserActivityAction.BulkUpdatedDepartureMaxEarlyBirds:
    case UserActivityAction.BulkUpdatedDepartureMinToGuarantee:
    case UserActivityAction.BulkUpdatedDepartureRoomReleaseDays: {
      return RoutePath.ITINERARY_DEPARTURES.generatePath(
        adventure?.id || '',
        itinerary?.id || '',
      );
    }
    case UserActivityAction.CreatedAdventure:
    case UserActivityAction.UpdatedAdventure: {
      // TODO: take the users to adventure actions tab in all adventures when full release as that is the default tab
      if (
        !environmentIs(Environment.Production) ||
        adventureIdsForBetaReleaseDepartureSignOffs.includes(adventure?.id || '')
      ) {
        return RoutePath.ADVENTURE_ACTIONS.generatePath(adventure?.id || '');
      }
      return RoutePath.ADVENTURE_ITINERARIES.generatePath(adventure?.id || '');
    }

    case UserActivityAction.CreatedEngagementActivity:
    case UserActivityAction.UpdatedEngagementActivity:
    case UserActivityAction.CreatedEngagementTransfer:
    case UserActivityAction.UpdatedEngagementTransfer:
    case UserActivityAction.FlaggedTimelineComponent:
    case UserActivityAction.UnflaggedTimelineComponent: {
      return generateActivityFeedItemUrlForTimelineComponent({
        departure,
        adventure,
        itinerary,
        timelineComponent: subject,
      });
    }
    case UserActivityAction.CreatedComment: {
      return generateActivityFeedItemUrlForComment({
        departure,
        adventure,
        itinerary,
        comment: subject,
        target,
      });
    }
    case UserActivityAction.ProposedDeparture:
    case UserActivityAction.CancelledDeparture:
    case UserActivityAction.UpdatedDepartureStatusAccepted:
    case UserActivityAction.UpdatedDepartureStatusCancelled:
    case UserActivityAction.UpdatedDepartureStatusCompleted:
    case UserActivityAction.UpdatedDepartureStatusDeparted:
    case UserActivityAction.UpdatedDepartureStatusOnSale:
    case UserActivityAction.UpdatedDepartureStatusProposed:
    case UserActivityAction.UpdatedDepartureStatusSecured:
    case UserActivityAction.UpdatedDepartureStatusRejected:
    case UserActivityAction.AssignedPackLeader:
    case UserActivityAction.MarkedDepartureAsNotSecured:
    case UserActivityAction.MarkedDepartureAsSecured:
    case UserActivityAction.ChangedDepartureVisibilityToDraft:
    case UserActivityAction.ChangedDepartureVisibilityToLive:
    case UserActivityAction.UpdatedDepartureCapacity:
    case UserActivityAction.UpdatedDepartureMinToGuarantee:
    case UserActivityAction.UpdatedDepartureMaxEarlyBirds:
    case UserActivityAction.UpdatedDepartureRoomReleaseDays:
    case UserActivityAction.UnassignedPackLeader:
    case UserActivityAction.SignedOffDeparture: {
      if (!adventure || !itinerary || !departure) {
        throw 'Missing a property to create a departure link';
      }
      return RoutePath.ITINERARY_DEPARTURE.generatePath(
        adventure.id,
        itinerary.id,
        departure.id,
      );
    }
    case UserActivityAction.AddedUser: {
      return generateActivityFeedItemUrlForSharingItinerary({
        adventure,
        itinerary,
      });
    }

    case UserActivityAction.UpdatedPricesTripPrice:
    case UserActivityAction.UpdatedPricesEarlyBirdDiscount:
    case UserActivityAction.BulkUpdatedPricesTripPrice:
    case UserActivityAction.BulkUpdatedPricesEarlyBirdDiscount:
    case UserActivityAction.UpdatedPricesPromotionalDiscount:
    case UserActivityAction.BulkUpdatedPricesPromotionalDiscount: {
      return RoutePath.ITINERARY_PRICES.generatePath(
        adventure?.id ?? '',
        itinerary?.id ?? '',
        priceActionMap[action],
      );
    }
    case UserActivityAction.UpdatedItineraryCostCurrency:
    case UserActivityAction.UpdatedCostsExtras:
    case UserActivityAction.UpdatedCostsMandatory:
    case UserActivityAction.UpdatedCostsPassenger:
    case UserActivityAction.BulkUpdatedCostsExtras:
    case UserActivityAction.BulkUpdatedCostsMandatory:
    case UserActivityAction.BulkUpdatedCostsPassenger: {
      return RoutePath.ITINERARY_COSTS.generatePath(
        adventure?.id ?? '',
        itinerary?.id ?? '',
        costActionMap[action],
      );
    }

    case UserActivityAction.RemovedUser: {
      return generateActivityFeedItemUrlForSharingItinerary({
        adventure,
        itinerary,
      });
    }

    case UserActivityAction.CreatedAccommodation: {
      return generateActivityFeedItemUrlForTimelineComponent({
        departure,
        adventure,
        itinerary,
        timelineComponent: subject,
      });
    }
    case UserActivityAction.UpdatedAccommodation: {
      return generateActivityFeedItemUrlForTimelineComponent({
        departure,
        adventure,
        itinerary,
        timelineComponent: subject,
      });
    }
    case UserActivityAction.CreatedMessage:
    case UserActivityAction.CreatedMessageThread: {
      return generateActivityFeedItemUrlForMessageThread({
        departure,
        adventure,
        itinerary,
        thread: subject,
        target,
      });
    }
    default: {
      return './';
    }
  }
}
const isEngagement = (
  target: UserActivityReference & { __typename: string },
): target is Engagement => target?.__typename === 'Engagement';

const isEngagementActivity = (
  target: UserActivityReference & { __typename: string },
): boolean => {
  if (isEngagement(target)) {
    return target.activity ? true : false;
  }
  return false;
};

const generateActivityFeedItemUrlForComment = ({
  adventure,
  itinerary,
  departure,
  target,
  comment,
}: Pick<ActivityFeedItemProps, 'departure' | 'adventure' | 'itinerary' | 'target'> & {
  comment: UserActivityReference;
}): string => {
  if (!target) {
    throw 'Target is required for comment activity feed item';
  }
  return [
    generateActivityFeedItemUrlForTimelineComponent({
      departure,
      adventure,
      itinerary,
      timelineComponent: target,
    }),
    '#',
    commentAnchor({ id: comment.id }),
  ].join('');
};

const generateActivityFeedItemUrlForMessageThread = ({
  adventure,
  itinerary,
  target,
  thread,
}: Pick<ActivityFeedItemProps, 'departure' | 'adventure' | 'itinerary' | 'target'> & {
  thread: UserActivityReference;
}): string => {
  if (!target) {
    throw 'Target is required for message activity feed item';
  }
  if (!adventure || !itinerary) {
    throw 'Adventure and Itinerary are required for message activity feed item';
  }
  return [
    RoutePath.ITINERARY_DEPARTURES.generatePath(adventure?.id || '', itinerary?.id || ''),
    '#',
    MessageThreadAnchor({ id: thread.id }),
  ].join('');
};

const generateActivityFeedItemUrlForTimelineComponent = ({
  adventure,
  itinerary,
  departure,
  timelineComponent,
}: Pick<ActivityFeedItemProps, 'departure' | 'adventure' | 'itinerary'> & {
  timelineComponent: UserActivityReferenceFragment;
}): string => {
  const routePathParams = {
    adventureId: adventure?.id || '',
    itineraryId: itinerary?.id || '',
    departureId: departure?.id || '',
    accommodationId: timelineComponent?.id || '',
    activityId: timelineComponent?.id,
    transferId: timelineComponent?.id,
  };
  let routePath = '';
  if (departure) {
    routePath =
      timelineComponent?.__typename !== 'Engagement'
        ? RoutePath.ITINERARY_DEPARTURE_VIEW_ACCOMMODATION.value
        : isEngagementActivity(timelineComponent)
          ? RoutePath.ITINERARY_DEPARTURE_VIEW_ACTIVITY.value
          : RoutePath.ITINERARY_DEPARTURE_VIEW_TRANSFER.value;
  } else {
    routePath =
      timelineComponent?.__typename !== 'Engagement'
        ? RoutePath.VIEW_ACCOMMODATION.value
        : isEngagementActivity(timelineComponent)
          ? RoutePath.VIEW_ACTIVITY.value
          : RoutePath.VIEW_TRANSFER.value;
  }
  return generatePath(routePath, routePathParams);
};

const generateActivityFeedItemUrlForSharingItinerary = ({
  adventure,
  itinerary,
}: {
  adventure: Pick<Adventure, 'id' | 'bookingEngineTourCode'> | null | undefined;
  itinerary: Pick<Itinerary, 'id' | 'description'> | null | undefined;
}) => {
  return RoutePath.ITINERARY_TIMELINE.generatePath(
    adventure?.id || '',
    itinerary?.id || '',
  );
};

type UserActivityUpdatedCostAction =
  | UserActivityAction.UpdatedItineraryCostCurrency
  | UserActivityAction.UpdatedCostsExtras
  | UserActivityAction.UpdatedCostsMandatory
  | UserActivityAction.UpdatedCostsPassenger
  | UserActivityAction.BulkUpdatedCostsExtras
  | UserActivityAction.BulkUpdatedCostsMandatory
  | UserActivityAction.BulkUpdatedCostsPassenger;

type UserActivityUpdatedPriceAction =
  | UserActivityAction.UpdatedPricesTripPrice
  | UserActivityAction.UpdatedPricesEarlyBirdDiscount
  | UserActivityAction.BulkUpdatedPricesTripPrice
  | UserActivityAction.BulkUpdatedPricesEarlyBirdDiscount
  | UserActivityAction.UpdatedPricesPromotionalDiscount
  | UserActivityAction.BulkUpdatedPricesPromotionalDiscount;

const costActionMap: Record<UserActivityUpdatedCostAction, string> = {
  [UserActivityAction.UpdatedItineraryCostCurrency]: 'passengersCost', // default cost page view
  [UserActivityAction.UpdatedCostsExtras]: 'extrasCost',
  [UserActivityAction.UpdatedCostsMandatory]: 'mandatoryCost',
  [UserActivityAction.UpdatedCostsPassenger]: 'passengersCost',
  [UserActivityAction.BulkUpdatedCostsExtras]: 'extrasCost',
  [UserActivityAction.BulkUpdatedCostsMandatory]: 'mandatoryCost',
  [UserActivityAction.BulkUpdatedCostsPassenger]: 'passengersCost',
};

const priceActionMap: Record<UserActivityUpdatedPriceAction, string> = {
  [UserActivityAction.UpdatedPricesTripPrice]: 'tripPrice',
  [UserActivityAction.UpdatedPricesEarlyBirdDiscount]: 'earlyBirdDiscount',
  [UserActivityAction.BulkUpdatedPricesTripPrice]: 'tripPrice',
  [UserActivityAction.BulkUpdatedPricesEarlyBirdDiscount]: 'earlyBirdDiscount',
  [UserActivityAction.UpdatedPricesPromotionalDiscount]: 'promotionalDiscount',
  [UserActivityAction.BulkUpdatedPricesPromotionalDiscount]: 'promotionalDiscount',
};
