import { DepartureMetaDocument } from '@flashpack/graphql';
import { TimelineSection } from '@src/shared/timeline/TimelineSection';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { DepartureHeader } from './DepartureHeader';
import { RoutePath } from '@src/shared/routePath';
import { useQuery } from '@apollo/client';

export const ItineraryDeparture = () => {
  const { adventureId, itineraryId, departureId } = useRequiredParams([
    'adventureId',
    'itineraryId',
    'departureId',
  ]);

  const { data } = useQuery(DepartureMetaDocument, {
    variables: { itineraryId, departureId },
  });

  const timelineId = data?.itinerary.departureById.timeline.id;

  return (
    <>
      <DepartureHeader
        adventureId={adventureId}
        itineraryId={itineraryId}
        departureId={departureId}
      />
      {timelineId && (
        <TimelineSection
          timelineId={timelineId}
          emptyTimelineMessage="Use the add button “+” to add components to this departure"
          lockedTimelineMessage="This departure cannot be edited."
          departureDate={data?.itinerary.departureById.date}
          routeLocation={RoutePath.ITINERARY_DEPARTURE.generatePath(
            adventureId,
            itineraryId,
            departureId,
          )}
        />
      )}
    </>
  );
};
