import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FC } from 'react';
import { ChangeDetailDisplay, DetailTitle } from './AdjustmentChangeDetailDisplay';
import { Change } from './utils';

export const ChangeDisplay: FC<Change> = (props) => {
  const { action, title, type } = props;

  const typeAbbreviation = (type: Change['type']) => {
    switch (type) {
      case 'Accommodation':
        return 'Accom.';
      case 'Activity':
        return 'Activity';
      case 'Transfer':
        return 'Transfer';
      default:
        const _exhaustiveCheck: never = type;
        throw new Error('Unhandled type');
    }
  };

  switch (action) {
    case 'delete':
      return (
        <Stack direction="column">
          <Typography variant="bodySingle">{title}</Typography>
          <DetailTitle>{typeAbbreviation(type)} deleted</DetailTitle>
        </Stack>
      );
    case 'create':
      return (
        <Stack direction="column">
          <Typography variant="bodySingle">{title}</Typography>
          <DetailTitle>{typeAbbreviation(type)} added</DetailTitle>
        </Stack>
      );
    case 'update':
      return (
        <Stack direction="column">
          <Typography variant="bodySingle">{title}</Typography>
          {props.details.map((detail) => (
            <ChangeDetailDisplay key={detail.property} {...detail} />
          ))}
        </Stack>
      );
    default:
      const _exhaustiveCheck: never = action;
      throw new Error('Unhandled type' + type);
  }
};
