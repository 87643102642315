import { FC, useState } from 'react';
import { Box, Container, OutlinedInput, Stack } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { UserRole } from '@flashpack/graphql';
import { AdventureList } from './AdventureList';
import { RoutePath } from 'src/shared/routePath';
import { Protected } from '@src/authentication/Protected';
import { PageHeading } from 'design-system';
import { CreateButton } from '@src/design-system/create-button/CreateButton';

export const AdventureListPage: FC = () => {
  const [searchFilter, changeSearchFilter] = useState<string>();

  return (
    <Container sx={{ mt: 5 }}>
      <HeadingAndSearch>
        <PageHeading>Adventures</PageHeading>

        <Stack direction={'row'} spacing={2} justifyContent="flex-end">
          <OutlinedInput
            data-testid="adventure-search-code"
            sx={{ borderRadius: '2px' }}
            placeholder="e.g. AR1, Argentina"
            endAdornment={<SearchIcon />}
            onChange={({ target: { value } }) => changeSearchFilter(value)}
          ></OutlinedInput>
          <Protected roles={[UserRole.Flashpack]}>
            <CreateButton title="New Adventure" href={RoutePath.ADD_ADVENTURE.value} />
          </Protected>
        </Stack>
      </HeadingAndSearch>

      <AdventureList searchFilter={searchFilter} />
    </Container>
  );
};

const HeadingAndSearch = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(6),
  alignItems: 'center',
  gap: theme.spacing(2),
  justifyContent: 'space-between',
}));
