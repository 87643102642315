import React from 'react';
import { styled } from '@mui/material/styles';
import { Autocomplete, AutocompleteProps, Paper } from '@mui/material';
import { AutocompleteValue } from '@mui/material/Autocomplete';

import { BaseInput } from '../BaseInput';
import { TextInputProps } from '../TextInput/TextInput';
import { Box } from '../../containers';
import { OpenDropdown } from '../../icons';
import DropdownOption, { DropdownOptionItem } from './DropdownOption';

type ChildTextInputProps = TextInputProps;

export type DropdownProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  'renderInput' | 'onChange'
> & {
  onChange?: (
    event: React.SyntheticEvent,
    selectedOption: AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>,
  ) => void;
  name: string;
  active?: boolean;
  TextInputProps?: Omit<ChildTextInputProps, 'name' | 'onChange'>;
};

const Dropdown = <
  T extends { value: string; label: string },
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: DropdownProps<T, Multiple, DisableClearable, FreeSolo>,
) => {
  const { TextInputProps, name, ...rest } = props;

  return (
    <StyledAutocompleteWrapper>
      <Autocomplete
        {...rest}
        clearIcon={null}
        PaperComponent={StyledPaper}
        popupIcon={<OpenDropdown sx={{ width: '16.5px', height: '16.5px' }} />}
        renderOption={(props, option, state) => {
          return (
            <DropdownOption
              {...props}
              option={option as DropdownOptionItem}
              selected={state.selected}
            />
          );
        }}
        renderInput={(params) => (
          <BaseInput
            ref={params.InputProps.ref}
            name={name}
            {...params}
            {...TextInputProps}
            inputProps={{
              ...params.inputProps, // spread render input props first
              ...(TextInputProps?.inputProps || {}),
              onChange: params.inputProps.onChange,
            }}
            sx={{
              ...TextInputProps?.sx,
            }}
          />
        )}
      />
    </StyledAutocompleteWrapper>
  );
};

// wrapper for popup component which will be rendered in the portal
// outside of the Autocomplete component
const StyledPaper = styled(Paper)(({ theme: _theme }) => ({
  // when the option labels are too small, we need to set a minimum width
  minWidth: 220,

  // options list wrapper
  '& .MuiAutocomplete-listbox': {
    paddingTop: 0,
    paddingBottom: 0,
    maxHeight: '50vh',
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
  },
}));

const StyledAutocompleteWrapper = styled(Box)(({ theme }) => ({
  // to avoid labels taking full width, and instead to take the required minimum
  width: 'fit-content',

  // label - description, extraDescription, etc
  '& .MuiFormLabel-root': {
    whiteSpace: 'initial', // to allow soft wrap in small screens
  },
  '& .MuiInputBase-root': {
    paddingRight: '14px !important', // this is to avoid the padding added by the clear icon
    cursor: 'pointer',
  },
  '& .MuiInputBase-root.Mui-disabled': {
    cursor: 'initial',
  },
  '& .MuiAutocomplete-option': {
    padding: '8px 16px',
    ...theme.typography['Body M'],
  },
}));

export default Dropdown;
