import { FC, ReactNode } from 'react';
import { Drawer, IconButton } from '@mui/material';
import { flashPackTheme } from '@src/design-system/theme';
import Stack from '@mui/system/Stack';
import Close from '@mui/icons-material/Close';
import { useDrawerContext } from '../context/DrawerContext';

type EdgeSidebarProps = {
  headerChildren?: ReactNode;
  children?: ReactNode;
  anchor: 'right' | 'left' | 'top' | 'bottom';
  icon: ReactNode;
  onClose: () => void;
};

export const sidebarWidths = {
  open: 464,
  closed: 64,
};

export const EdgeSidebar: FC<EdgeSidebarProps> = ({
  children,
  headerChildren,
  anchor,
  icon,
  onClose,
}) => {
  const { sidebarOpen, setSidebarOpen } = useDrawerContext();

  return (
    <Drawer
      variant="permanent"
      anchor={anchor}
      open={sidebarOpen}
      PaperProps={{
        sx: {
          width: sidebarOpen ? sidebarWidths.open : sidebarWidths.closed,
          padding: sidebarOpen ? flashPackTheme.spacing(2) : flashPackTheme.spacing(1),
          borderLeft: `1px solid ${flashPackTheme.palette.principal.grey50}`,
          transition: 'width 225ms cubic-bezier(0, 0, 0.2, 1)',
          height: `calc(100vh - ${flashPackTheme.spacing(8)})`,
        },
      }}
    >
      {sidebarOpen ? (
        <>
          <Header onClose={onClose}>{headerChildren}</Header>
          {children}
        </>
      ) : (
        <IconButton onClick={() => setSidebarOpen(true)} sx={{ marginX: 'auto' }}>
          {icon}
        </IconButton>
      )}
    </Drawer>
  );
};

type HeaderProps = {
  onClose: () => void;
  children: ReactNode;
};

const Header = ({ children, onClose }: HeaderProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ position: 'sticky', backgroundColor: flashPackTheme.palette.principal.white }}
      paddingBottom={2}
    >
      <Stack direction="row" alignItems="center">
        {children}
      </Stack>
      <IconButton onClick={onClose} data-testid="close-drawer">
        <Close />
      </IconButton>
    </Stack>
  );
};

export default EdgeSidebar;
