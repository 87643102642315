import { AdventureRegion } from '@flashpack/graphql';
import { countries, Country } from './worlds';

export const countryOptions = countries.map((country) => ({
  label: country.name,
  value: country.alpha2,
}));

export const regionOptions: {
  label: string;
  value: AdventureRegion;
}[] = [
  {
    value: AdventureRegion.Africa,
    label: 'Africa',
  },
  {
    value: AdventureRegion.Antarctica,
    label: 'Antarctica',
  },
  {
    value: AdventureRegion.Asia,
    label: 'Asia',
  },
  {
    value: AdventureRegion.Australasia,
    label: 'Australasia',
  },
  {
    value: AdventureRegion.CentralAmerica,
    label: 'Central America',
  },
  {
    value: AdventureRegion.Europe,
    label: 'Europe',
  },
  {
    value: AdventureRegion.FarEast,
    label: 'Far East',
  },
  {
    value: AdventureRegion.MiddleEast,
    label: 'Middle East',
  },
  {
    value: AdventureRegion.NorthAmerica,
    label: 'North America',
  },
  {
    value: AdventureRegion.SouthAmerica,
    label: 'South America',
  },
  {
    value: AdventureRegion.Unknown,
    label: 'Unknown',
  },
];

export class BadCountryCodeError extends Error {}

const countryByCode: { [index: string]: Country } = countries.reduce((acc, country) => {
  return {
    ...acc,
    [country.alpha2]: country,
  };
}, {});

export const countryFromCode = (code: string, throwOnInvalid = true): Country => {
  const casedCode = code.toLocaleLowerCase();

  if (!casedCode.match(/^[a-z]{2}$/) && throwOnInvalid) {
    throw new BadCountryCodeError(
      'Country code is incorrect length or contains illegal characters',
    );
  }

  if (!(casedCode in countryByCode) && throwOnInvalid) {
    throw new BadCountryCodeError('Unknown country code');
  }

  return countryByCode[casedCode];
};

export const getCountryNames = function getCountryNames(code: string) {
  const countries = code.split('_').slice(0, -1);
  return countries.map((countryCode) => countryFromCode(countryCode)?.name).join(' - ');
};

export const createPrioritizedCountryOptions = (prioritizedCountryCodes: string[]) => {
  const prioritizedCountries = countryOptions.filter((country) =>
    prioritizedCountryCodes.includes(country.value),
  );
  const restOfCountries = countryOptions.filter(
    (country) => !prioritizedCountryCodes.includes(country.value),
  );
  return [...prioritizedCountries, ...restOfCountries];
};
