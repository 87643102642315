import { ApolloError } from '@apollo/client';
import { captureException } from '@sentry/react';
import { hasServerValidationErrors } from '@src/shared/errorUtils';

type SafePromiseCallbacks = {
  /**
   * This callback is fired if the promise resolves without errors.
   */
  onSuccess?: () => void;
  /**
   * This callback is fired whenever the promise throws, regardless of the error type.
   */
  onError?: (err: unknown) => void;
  /**
   * This callback is fired whenever the promise throws and the error is ApolloError.
   */
  onServerValidationError?: (err: ApolloError) => void;
  /**
   * This callback is fired whenever the promise throws and the error is not ApolloError.
   */
  onUnexpectedError?: (err: unknown) => void;
};

export const useSafeMutation = () => {
  const safeMutation = async <T,>(
    promise: Promise<T>,
    callbacks: SafePromiseCallbacks = {},
  ) => {
    const noOp = () => {};

    const {
      onSuccess = noOp,
      onError = noOp,
      onServerValidationError = noOp,
      onUnexpectedError = noOp,
    } = callbacks;

    try {
      const response = await promise;
      onSuccess();
      return { response };
    } catch (e) {
      onError(e);

      if (e instanceof ApolloError) {
        if (hasServerValidationErrors(e)) {
          onServerValidationError(e);
        } else {
          onUnexpectedError(e);
        }
      } else {
        captureException(e);
        onUnexpectedError(e);
      }

      return { error: e };
    }
  };

  return { safeMutation };
};
