import { CssBaseline, GlobalStyles } from '@mui/material';
import {
  StyledEngineProvider,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { flashPackTheme } from 'src/design-system/theme';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from 'src/apolloClient';
import { globalStyles } from 'src/design-system/GlobalStyle';
import { GooglePlacesProvider } from 'src/shared/GooglePlacesContext';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { AuthenticationProvider } from '../authentication/AuthenticationProvider';
import { GlobalErrorBoundary } from '@src/shared/GlobalErrorBoundary';
import { AppRouter } from './AppRouter';
import { AuthorizationProvider } from '../authentication/AuthorizationProvider';
import { DrawerContextProvider } from '@src/shared/context/DrawerContext';

function App() {
  return (
    <GlobalErrorBoundary>
      <StyledEngineProvider injectFirst>
        <GooglePlacesProvider>
          <MuiThemeProvider theme={flashPackTheme}>
            <CssBaseline />
            <GlobalStyles styles={globalStyles} />
            <ApolloProvider client={apolloClient}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <AuthenticationProvider>
                  <AuthorizationProvider>
                    <DrawerContextProvider>
                      <AppRouter />
                    </DrawerContextProvider>
                  </AuthorizationProvider>
                </AuthenticationProvider>
              </LocalizationProvider>
            </ApolloProvider>
          </MuiThemeProvider>
        </GooglePlacesProvider>
      </StyledEngineProvider>
    </GlobalErrorBoundary>
  );
}

export default App;
