import { Drawer, IconButton, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useRouting } from '../../shared/useRouting';
import { flashPackTheme } from '../../design-system/theme';
import { Close } from '@mui/icons-material';
import {
  ConfirmationDialog,
  confirmationDialogLabels,
} from '@src/design-system/dialogs/ConfirmationDialog';
import { TimelineContext } from '@src/shared/timeline/TimelineContext';
import { useDrawerContext } from '@src/shared/context/DrawerContext';

interface PropTypes {
  children: ReactNode;
  title: string;
  icon: ReactNode;
  warnForChangesNotSaved?: boolean;
  handleClose?: () => void;
}

export const ItineraryDrawerSize = {
  small: 500,
  big: 600,
};

export const ItineraryDrawer: FC<PropTypes> = (props) => {
  const { title, icon, children, warnForChangesNotSaved, handleClose } = props;
  const { navigate } = useRouting();
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const { routeLocation } = useContext(TimelineContext);
  const { setTimelineDrawerOpen } = useDrawerContext();
  const onConfirmClose = () => {
    if (handleClose) {
      handleClose();
    } else {
      navigate(routeLocation);
    }
  };

  const onClose = () => {
    warnForChangesNotSaved ? setDialogOpen(true) : onConfirmClose();
  };

  useEffect(() => {
    setTimelineDrawerOpen(true);
    return () => setTimelineDrawerOpen(false);
  }, [setTimelineDrawerOpen]);

  return (
    <Drawer
      variant="permanent"
      PaperProps={{
        sx: {
          width: {
            xs: ItineraryDrawerSize.small,
            xl: ItineraryDrawerSize.big,
          },
          padding: flashPackTheme.spacing(4),
          borderLeft: `1px solid ${flashPackTheme.palette.principal.grey50}`,
        },
      }}
      anchor="right"
      open
      onClose={onClose}
    >
      <Stack direction="column">
        <Header title={title} icon={icon} onClose={onClose} />
        {children}
      </Stack>
      {warnForChangesNotSaved ? (
        <ConfirmationDialog
          open={dialogOpen}
          onCancel={onConfirmClose}
          onConfirm={() => setDialogOpen(false)}
          title={confirmationDialogLabels.title}
          cancelLabel={confirmationDialogLabels.cancelLabel}
          confirmLabel={confirmationDialogLabels.confirmLabel}
          maxWidth="sm"
        >
          <Typography sx={{ mb: 5 }} variant="bodyPara">
            {confirmationDialogLabels.content}
          </Typography>
        </ConfirmationDialog>
      ) : null}
    </Drawer>
  );
};

type HeaderProps = {
  onClose: () => void;
  title: string;
  icon: ReactNode;
};
const Header = ({ title, icon, onClose }: HeaderProps) => {
  const theme = useTheme();
  return (
    <Stack direction="row" justifyContent="space-between" marginBottom={2}>
      <Stack direction="row" alignItems="center" gap={2}>
        {icon}
        <Typography variant={'bodySingle'} color={theme.palette.principal.grey70}>
          {title}
        </Typography>
      </Stack>
      <IconButton onClick={onClose} data-testid="close-drawer">
        <Close />
      </IconButton>
    </Stack>
  );
};
export { confirmationDialogLabels };
