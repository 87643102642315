import { useMutation } from '@apollo/client';
import { Button, Menu, MenuItem } from '@mui/material';
import { StopPropagationWrapper } from '@src/design-system/StopPropagationWrapper';
import {
  AdventureWithItinerariesAndDeparturesDocument,
  DepartureVisibility,
  UpdateDepartureVisibilityDocument,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { FC, useState } from 'react';
import { DepartureVisibilityItem } from './DepartureVisibilityItem';
import { OpenMenu } from '../departure-status/icons/DepartureStatusIcon';

interface PropTypes {
  departureIds: string[];
  visibility?: DepartureVisibility | null;
}

export const DepartureVisibilityMenu: FC<PropTypes> = ({ departureIds, visibility }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (e: React.MouseEvent) => {
    setAnchorEl(e.target as HTMLElement);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const visibilityOptions = [DepartureVisibility.Draft, DepartureVisibility.Live];

  const isLive = visibility === DepartureVisibility.Live;

  const { success, error } = useToast();
  const [updateDepartureVisibilityMutation] = useMutation(
    UpdateDepartureVisibilityDocument,
  );
  const { safeMutation } = useSafeMutation();

  const onUpdateDepartureVisibility = async (visibility: DepartureVisibility) => {
    const toastMessage = isLive
      ? 'Updated departure visibility to Draft'
      : 'Updated departure visibility to Live';

    await safeMutation(
      updateDepartureVisibilityMutation({
        variables: {
          input: {
            departureIds,
            visibility,
          },
        },
        refetchQueries: [AdventureWithItinerariesAndDeparturesDocument],
      }),
      {
        onSuccess: () => {
          success(toastMessage);
          handleClose();
        },
        onError: () => {
          error('Failed to update departure visibility');
        },
      },
    );
  };

  return (
    <StopPropagationWrapper>
      <Menu
        id="departure-visibility-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
      >
        {visibilityOptions.map((visibilityOption) => (
          <MenuItem
            onClick={() => void onUpdateDepartureVisibility(visibilityOption)}
            key={visibilityOption}
            disabled={visibilityOption === visibility}
          >
            <DepartureVisibilityItem
              onClick={handleClick}
              visibility={visibilityOption}
            />
          </MenuItem>
        ))}
      </Menu>
      {visibility && (
        <DepartureVisibilityItem onClick={handleClick} visibility={visibility} />
      )}
      {!visibility && (
        <Button
          onClick={handleClick}
          variant="light-outlined"
          endIcon={<OpenMenu fill="white" />}
        >
          Change visibility
        </Button>
      )}
    </StopPropagationWrapper>
  );
};
