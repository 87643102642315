import { FC } from 'react';
import { IconButton, CircularProgress, Tooltip } from '@mui/material';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import csvDownload from 'json-to-csv-export';
import {
  Scalars,
  DeparturesCsvData,
  DeparturesCsvDataDocument,
  ExtrasCsvData,
  UserRole,
} from '@flashpack/graphql';
import { useLazyQuery } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Protected } from '@src/authentication/Protected';

interface PropTypes {
  selectedDepartureIds: Array<Scalars['UUID']>;
  size?: 'small' | 'large';
  isWhite?: boolean;
}

export const DownloadDeparturesDataButton: FC<PropTypes> = ({
  selectedDepartureIds,
  size,
  isWhite = true,
}) => {
  const [getDeparturesCsvData, { loading: loadingDownload }] = useLazyQuery(
    DeparturesCsvDataDocument,
    {
      onCompleted: (data) => {
        if (!data.departuresCsvData.departures) {
          return;
        }
        if (!data.departuresCsvData.extras) {
          return;
        }
        const departureData: DeparturesCsvData = data.departuresCsvData.departures;
        const extrasData: ExtrasCsvData = data.departuresCsvData.extras;
        csvDownload({
          data: getMappedDepartureCsvRows(departureData),
          headers: departureData.headers,
          delimiter: ',',
          filename: 'departures_data',
        });
        csvDownload({
          data: getMappedExtraCsvRows(extrasData),
          headers: extrasData.headers,
          delimiter: ',',
          filename: 'extras_data',
        });
      },
    },
  );

  /**
   * Maps data from server (rows/headers) to CSV format that will be downloaded.
   * Also adds default values.
   */
  const getMappedDepartureCsvRows = ({ rows, headers }: DeparturesCsvData) => {
    const blankRow = headers.reduce(
      (acc, header) => {
        acc[header] = '';
        return acc;
      },
      {} as Record<string, string>,
    );

    const defaultValues = {
      visibility: 'live',
      insider: 0,
      status: 'on_sale',
      web_promo: 0,
      enable_installments: 1,
      enable_promo_codes: 1,
      black_friday_promo: 0,
    };

    return rows.map((entry) => ({
      ...blankRow,
      ...defaultValues,
      tour_code: entry.tourCode,
      code: entry.code,
      departure_date: entry.departureDate,
      return_date: entry.returnDate,
      final_balance_due: entry.finalBalanceDue,
      on_request_switch: entry.onRequestSwitch,
      notes: entry.notes ?? '',
      price_gbp: entry.tripPrice?.amountGBP ?? '',
      price_usd: entry.tripPrice?.amountUSD ?? '',
      price_nzd: entry.tripPrice?.amountNZD ?? '',
      price_hkd: entry.tripPrice?.amountHKD ?? '',
      price_aud: entry.tripPrice?.amountAUD ?? '',
      price_aed: entry.tripPrice?.amountAED ?? '',
      price_eur: entry.tripPrice?.amountEUR ?? '',
      price_cad: entry.tripPrice?.amountCAD ?? '',

      deposit_gbp: entry.deposit.amountGBP,
      deposit_usd: entry.deposit.amountUSD,
      deposit_nzd: entry.deposit.amountNZD,
      deposit_hkd: entry.deposit.amountHKD,
      deposit_aud: entry.deposit.amountAUD,
      deposit_aed: entry.deposit.amountAED,
      deposit_eur: entry.deposit.amountEUR,
      deposit_cad: entry.deposit.amountCAD,

      early_bird_discount_gbp: entry.earlyBirdDiscount?.amountGBP ?? '',
      early_bird_discount_usd: entry.earlyBirdDiscount?.amountUSD ?? '',
      early_bird_discount_nzd: entry.earlyBirdDiscount?.amountNZD ?? '',
      early_bird_discount_hkd: entry.earlyBirdDiscount?.amountHKD ?? '',
      early_bird_discount_aud: entry.earlyBirdDiscount?.amountAUD ?? '',
      early_bird_discount_aed: entry.earlyBirdDiscount?.amountAED ?? '',
      early_bird_discount_eur: entry.earlyBirdDiscount?.amountEUR ?? '',
      early_bird_discount_cad: entry.earlyBirdDiscount?.amountCAD ?? '',
    }));
  };

  /**
   * Maps data from server (rows/headers) to CSV format that will be downloaded.
   * Also adds default values.
   */
  const getMappedExtraCsvRows = ({ rows, headers }: ExtrasCsvData) => {
    const blankRow = headers.reduce(
      (acc, header) => {
        acc[header] = '';
        return acc;
      },
      {} as Record<string, string>,
    );

    return rows.map((entry) => ({
      ...blankRow,
      reference: entry.reference,
      departure_code: entry.departureCode,
      price_gbp: entry.amounts?.amountGBP ?? '',
      price_usd: entry.amounts?.amountUSD ?? '',
      price_nzd: entry.amounts?.amountNZD ?? '',
      price_hkd: entry.amounts?.amountHKD ?? '',
      price_aud: entry.amounts?.amountAUD ?? '',
      price_aed: entry.amounts?.amountAED ?? '',
      price_eur: entry.amounts?.amountEUR ?? '',
      price_cad: entry.amounts?.amountCAD ?? '',
    }));
  };

  const downloadSelected = () => {
    void getDeparturesCsvData({
      variables: { departureIds: selectedDepartureIds },
    });
  };

  return (
    <Protected roles={[UserRole.Flashpack]}>
      {size == 'large' ? (
        <LoadingButton
          startIcon={<DownloadOutlinedIcon />}
          onClick={() => downloadSelected()}
          variant="outlined"
          loading={loadingDownload}
          data-testid="download-selected-departures"
        >
          CSV Export
        </LoadingButton>
      ) : (
        <Tooltip title={'Download CSV'}>
          <IconButton
            onClick={() => downloadSelected()}
            data-testid="download-selected-departures"
          >
            {loadingDownload ? (
              <CircularProgress sx={{ color: isWhite ? 'white' : '' }} size="24px" />
            ) : (
              <DownloadOutlinedIcon htmlColor={isWhite ? 'white' : ''} />
            )}
          </IconButton>
        </Tooltip>
      )}
    </Protected>
  );
};
