import { Stack, Switch, Typography } from '@mui/material';
import { Checkbox, EmptyTimelineIcon } from 'design-system';
import { FC, useMemo, useState } from 'react';
import {
  calculateTransferDurationByMode,
  convertToGroupedTilesByDay,
  isAccommodationTile,
  isActivityTile,
  isTransferTile,
  timelineIsEmpty,
  TileType,
  convertDayToDate,
} from './timelineUtils';
import { AccommodationTrailingTile, AccommodationLeadingTile } from './AccommodationTile';
import { TimelineContent } from '@mui/lab';
import {
  AccommodationFieldsFragment,
  EngagementFieldsFragment,
  OverlappingTimelineItemPair,
} from '@flashpack/graphql';
import { TransferTile } from './TransferTile';
import { ActivityTile } from './ActivityTile';
import { styled } from '@mui/material/styles';

import { TransferDurations } from '@src/itinerary/transfer/common/TransferDurations';
import { WarningMessage } from '@src/design-system/warning-message/WarningMessage';
import { BlankSlate } from '@src/design-system/blank-slate/BlankSlate';
import { FormControlLabel } from '@src/shared/FormControlLabel';
import { MoveMultipleTimelineItemsToolbar } from './MoveMultipleTimelineItemsToolbar';
import { ConflictModal } from './ConflictModal';

export interface TimelineShape {
  id: string;
  accommodations: AccommodationFieldsFragment[];
  engagements: EngagementFieldsFragment[];
}

interface PropTypes {
  locked?: boolean;
  timeline?: TimelineShape | null;
  emptyTimelineMessage: string;
  lockedTimelineMessage: string;
  departureDate?: string;
}

export const TimelineSchedule: FC<PropTypes> = ({
  timeline,
  locked,
  emptyTimelineMessage,
  lockedTimelineMessage,
  departureDate,
}) => {
  const [showAccommodationOnly, setShowAccommodationOnly] = useState(false);
  const [showCheckboxes, setShowCheckboxes] = useState(false);
  const [overlappingTimelineItemPair, setOverlappingTimelineItemPair] =
    useState<OverlappingTimelineItemPair | null>(null);

  const [selectedAccommodationIds, setSelectedAccommodationIds] = useState<string[]>([]);
  const [selectedEngagementIds, setSelectedEngagementIds] = useState<string[]>([]);
  const selectAccommodation = (id: string) => {
    if (selectedAccommodationIds.includes(id)) {
      setSelectedAccommodationIds(selectedAccommodationIds.filter((a) => a !== id));
    } else {
      setSelectedAccommodationIds([...selectedAccommodationIds, id]);
    }
  };

  const selectEngagement = (id: string) => {
    if (selectedEngagementIds.includes(id)) {
      setSelectedEngagementIds(selectedEngagementIds.filter((a) => a !== id));
    } else {
      setSelectedEngagementIds([...selectedEngagementIds, id]);
    }
  };

  const isSelectedAccommodation = (id: string) => {
    return selectedAccommodationIds.includes(id);
  };

  const isSelectedEngagement = (id: string) => {
    return selectedEngagementIds.includes(id);
  };

  const transferDurations = useMemo(() => {
    if (!timeline) {
      return null;
    }
    return calculateTransferDurationByMode(timeline);
  }, [timeline]);

  if (!timeline) {
    return null;
  }
  const isTimelineEmpty = timelineIsEmpty(timeline);

  if (isTimelineEmpty) {
    return <BlankSlate icon={<EmptyTimelineIcon />} message={emptyTimelineMessage} />;
  }

  const groupedTilesByDay = convertToGroupedTilesByDay(
    showAccommodationOnly ? { ...timeline, engagements: [] } : timeline,
    showCheckboxes,
    isSelectedEngagement,
    selectEngagement,
  );
  const shouldShowAccommodationFilter =
    timeline.accommodations.length > 0 && timeline.engagements.length > 0;

  return (
    <>
      {locked && <WarningMessage message={lockedTimelineMessage} iconType="LOCKED" />}
      <Stack
        direction="row"
        justifyContent={'space-between'}
        alignItems={'center'}
        mt={2}
        mb={3}
      >
        {transferDurations && <TransferDurations durations={transferDurations} />}
        <Stack direction="row" spacing={2}>
          {departureDate && (
            <FormControlLabel
              label="Move multiple"
              checked={showCheckboxes}
              control={
                <Switch
                  checked={showCheckboxes}
                  onChange={() => {
                    setShowCheckboxes(!showCheckboxes);
                    setSelectedAccommodationIds([]);
                    setSelectedEngagementIds([]);
                  }}
                />
              }
            />
          )}
          {shouldShowAccommodationFilter && (
            <FormControlLabel
              label="Show Accommodation only"
              checked={showAccommodationOnly}
              control={
                <Checkbox
                  data-testid="show-accommodation-only"
                  checked={showAccommodationOnly}
                  onChange={() =>
                    showAccommodationOnly
                      ? setShowAccommodationOnly(false)
                      : setShowAccommodationOnly(true)
                  }
                />
              }
            />
          )}
        </Stack>
      </Stack>
      <Stack sx={{ gap: 3 }}>
        {Object.keys(groupedTilesByDay).map((day) => {
          const tiles = groupedTilesByDay[day];
          return (
            <Stack key={day} display="space-between" direction="row">
              <Stack alignItems="center">
                <DayHeading variant="bodySingle">Day {day}</DayHeading>
                {departureDate && (
                  <TimelineItemDate>
                    {convertDayToDate(departureDate, parseInt(day))}
                  </TimelineItemDate>
                )}
              </Stack>
              <Stack gap={3} sx={{ flexGrow: 1 }} data-testid="timeline-content">
                {tiles.map((tile) => {
                  if (isAccommodationTile(tile)) {
                    if (tile.tileType === TileType.ACCOMMODATION_LEADING) {
                      return (
                        <AccommodationLeadingTile
                          {...tile}
                          key={tile.accommodationId}
                          selectAccommodation={() =>
                            selectAccommodation(tile.accommodationId)
                          }
                          isSelected={isSelectedAccommodation(tile.accommodationId)}
                          showCheckbox={showCheckboxes}
                        />
                      );
                    } else if (tile.tileType === TileType.ACCOMMODATION_TRAILING) {
                      return (
                        <AccommodationTrailingTile
                          {...tile}
                          key={tile.accommodationId}
                          selectAccommodation={() =>
                            selectAccommodation(tile.accommodationId)
                          }
                          showCheckbox={showCheckboxes}
                        />
                      );
                    }
                  }
                  if (isTransferTile(tile)) {
                    return (
                      <TransferTile
                        {...tile}
                        key={tile.engagementId}
                        selectEngagement={() => selectEngagement(tile.engagementId)}
                        isSelected={isSelectedEngagement(tile.engagementId)}
                        showCheckbox={showCheckboxes}
                      />
                    );
                  }
                  if (isActivityTile(tile)) {
                    return (
                      <ActivityTile
                        {...tile}
                        key={tile.engagementId}
                        selectEngagement={() => selectEngagement(tile.engagementId)}
                        isSelected={isSelectedEngagement(tile.engagementId)}
                        showCheckbox={showCheckboxes}
                      />
                    );
                  }

                  return null;
                })}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
      <MoveMultipleTimelineItemsToolbar
        visible={selectedAccommodationIds.length > 0 || selectedEngagementIds.length > 0}
        deselectAll={() => {
          setSelectedAccommodationIds([]);
          setSelectedEngagementIds([]);
        }}
        engagementIds={selectedEngagementIds}
        accommodationIds={selectedAccommodationIds}
        timelineId={timeline.id}
        setOverlappingTimelineItemPair={setOverlappingTimelineItemPair}
      />
      {overlappingTimelineItemPair && (
        <ConflictModal
          open={overlappingTimelineItemPair !== null}
          onClose={() => setOverlappingTimelineItemPair(null)}
          overlappingTimelineItemPair={overlappingTimelineItemPair}
        />
      )}
    </>
  );
};

const DayHeading = styled(Typography)(({ theme }) => ({
  width: '100px',
  display: 'flex',
  justifyContent: 'center',
  textTransform: 'uppercase',
  textAlign: 'center',
  padding: 0,
  color: theme.palette.secondary.main,
  flexGrow: 0,
}));

const TimelineItemDate = styled(TimelineContent)(({ theme }) => ({
  color: theme.palette.secondary.main,
  display: 'inline',
  padding: '0 0 0 0',
  margin: '0 0 0 0',
  textTransform: 'none',
}));
