import { FC, useState } from 'react';
import { DepartureStatusMenu } from '@src/departures/departure-status/DepartureStatusMenu';
import { Departure } from '@flashpack/graphql';
import { CancellationReasonDialog } from '@src/departures/departure-status/CancellationReasonDialog';
import { Box, Tooltip } from '@mui/material';
import { cancellationReasonTooltip } from './utils';

interface PropTypes {
  departure: Pick<
    Departure,
    'id' | 'status' | 'cancellationReason' | 'cancellationReasonNote'
  >;
}

export const DepartureStatusChange: FC<PropTypes> = ({ departure }) => {
  const [cancellationReasonModalOpen, setCancellationReasonModalOpen] = useState(false);

  return (
    <>
      <CancellationReasonDialog
        onClose={() => setCancellationReasonModalOpen(false)}
        open={cancellationReasonModalOpen}
        departureIds={[departure.id]}
      />
      <Tooltip
        title={cancellationReasonTooltip(
          departure.status,
          departure.cancellationReason,
          departure.cancellationReasonNote,
        )}
      >
        <Box>
          <DepartureStatusMenu
            onCancel={() => setCancellationReasonModalOpen(true)}
            status={departure.status}
          />
        </Box>
      </Tooltip>
    </>
  );
};
