import HotelIcon from '@mui/icons-material/Hotel';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
  List,
  ListSubheader,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { RoutePath } from '@src/shared/routePath';
import { UserRole } from '@flashpack/graphql';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';
import { ReactElement } from 'react';
import { FinanceIcon } from 'design-system';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';

export const NavigationDrawer = () => {
  const { currentUser } = useAuthorization();
  const role = currentUser?.role ?? UserRole.Guest;
  const filteredItems = navigationItems.filter((item) => {
    if (item.roles.includes(role)) {
      return item;
    }
    if (role === UserRole.Superuser && item.roles.includes(UserRole.Flashpack)) {
      return item;
    }
  });
  return (
    <List>
      <ListSubheader>Adventure Manager</ListSubheader>
      {filteredItems.map((item) => {
        return (
          <ListItem key={item.label} disablePadding>
            <ListItemButton href={item.link}>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.label} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export type NavigationItem = {
  link: string;
  label: string;
  icon: ReactElement;
  roles: UserRole[];
};

const navigationItems: NavigationItem[] = [
  {
    link: RoutePath.HOTELS.value,
    label: 'Hotel analysis',
    icon: <HotelIcon color="secondary" />,
    roles: [UserRole.Flashpack, UserRole.Dmc],
  },
  {
    link: RoutePath.ADVENTURES.value,
    label: 'Adventures',
    icon: <CalendarMonthIcon color="secondary" />,
    roles: [UserRole.Flashpack, UserRole.Dmc],
  },
  {
    link: RoutePath.FINANCE.value,
    label: 'Finance',
    icon: <FinanceIcon color="secondary" />,
    roles: [UserRole.Flashpack],
  },
  {
    label: 'Innovation',
    link: RoutePath.INNOVATION.value,
    icon: <ScienceOutlinedIcon color="secondary" />,
    roles: [UserRole.Flashpack, UserRole.Superuser],
  },
];
