import { Chip, Typography, useTheme } from '@mui/material';
import { DepartureSecuredStatus } from '@flashpack/graphql';
import { FC, useCallback } from 'react';
import { securedStatusLabel } from './utils';

interface PropTypes {
  securedStatus: DepartureSecuredStatus;
  onClick?: (e: React.MouseEvent) => void;
  canUpdateSecuredStatus?: boolean;
}

export const DepartureSecuredStatusItem: FC<PropTypes> = (props) => {
  const { securedStatus, onClick, canUpdateSecuredStatus = true } = props;
  const theme = useTheme();

  const textColor = useCallback(() => {
    switch (securedStatus) {
      case DepartureSecuredStatus.Pending:
        return theme.palette.principal.grey70;
      case DepartureSecuredStatus.Secured:
        return theme.palette.system.green100;
      case DepartureSecuredStatus.NotSecured:
        return theme.palette.system.red100;
      default:
        return null;
    }
  }, [securedStatus, theme]);

  const backgroundColor = useCallback(() => {
    switch (securedStatus) {
      case DepartureSecuredStatus.Pending:
        return theme.palette.principal.grey30;
      case DepartureSecuredStatus.Secured:
        return theme.palette.system.green10;
      case DepartureSecuredStatus.NotSecured:
        return theme.palette.system.red10;
      default:
        return null;
    }
  }, [securedStatus, theme]);

  const backgroundColorOnHover = useCallback(() => {
    if (!canUpdateSecuredStatus) {
      return backgroundColor();
    }

    switch (securedStatus) {
      case DepartureSecuredStatus.Pending:
        return theme.palette.principal.grey50;
      case DepartureSecuredStatus.NotSecured:
        return theme.palette.system.red30;
      default:
        return backgroundColor();
    }
  }, [canUpdateSecuredStatus, securedStatus, backgroundColor, theme]);

  return (
    <Chip
      onClick={onClick}
      sx={{
        color: textColor,
        border: canUpdateSecuredStatus
          ? `1px solid ${theme.palette.principal.grey50}`
          : null,
        '&:hover': {
          backgroundColor: backgroundColorOnHover,
        },
        backgroundColor,
        cursor: canUpdateSecuredStatus ? 'pointer' : 'default',
      }}
      label={
        <Typography variant="bodySingle">{securedStatusLabel(securedStatus)}</Typography>
      }
    />
  );
};
