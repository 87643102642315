import { FailureIcon, SuccessIcon, WarningIcon } from 'design-system';
import { ReactElement } from 'react';
import { toast, Theme, ToastPosition, ToastOptions } from 'react-toastify';

type ToastMessageType = string | ReactElement;

export const useToast = (position?: ToastPosition) => {
  const defaultOptions = {
    theme: 'colored' as Theme,
    closeButton: false,
    position: position || toast.POSITION.BOTTOM_CENTER,
    hideProgressBar: true,
  };

  const success = (message: ToastMessageType, options?: ToastOptions) => {
    toast.success(message, { icon: SuccessIcon, ...defaultOptions, ...options });
  };

  const error = (message: string | ReactElement, options?: ToastOptions) => {
    toast.error(message, {
      icon: FailureIcon,
      ...defaultOptions,
      ...options,
    });
  };

  const warning = (message: ToastMessageType, options?: ToastOptions) => {
    toast.warning(message, { icon: WarningIcon, ...defaultOptions, ...options });
  };

  const info = (message: ToastMessageType, options?: ToastOptions) => {
    toast.info(message, { ...defaultOptions, ...options });
  };

  return { success, error, warning, info };
};
