import { Popover, Skeleton } from '@mui/material';
import { Stack } from '@mui/system';
import { flashPackTheme } from '@src/design-system/theme';
import { useRef, FC, useState } from 'react';
import { DepartureDayAdjustmentsPopover } from './DepartureDayAdjustmentsPopover';
import { Adjustment } from './utils';
import { DayBoxItem } from '@src/shared/DayBoxItem';

interface PropTypes {
  days: number;
  adjustments: Adjustment[];
  loadingAdjustments: boolean;
}

export const PerDayAdjustments: FC<PropTypes> = ({
  days,
  adjustments,
  loadingAdjustments,
}) => {
  if (loadingAdjustments) {
    return <Skeleton variant="rectangular" height={24} />;
  }

  if (days === 0) {
    return <DayBoxItem>Add accommodation in timeline</DayBoxItem>;
  }

  const daysArray = [...Array(days).keys()].map((i) => i + 1);

  return (
    <Stack direction="row" spacing={1} width="100%">
      {daysArray.map((day) => {
        return (
          <DayBox
            key={day}
            day={day}
            adjustment={adjustments.find((adjustment) => adjustment.day === day)}
          />
        );
      })}
    </Stack>
  );
};

const DayBox: FC<{
  day: number;
  adjustment?: Adjustment;
}> = ({ day, adjustment }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverAnchor = useRef<HTMLDivElement>(null);

  const adjustedDayStyle =
    adjustment && adjustment.changes.length
      ? {
          color: flashPackTheme.palette.principal.black,
          background: `linear-gradient(225deg, ${
            flashPackTheme.palette.system.amber100
          } ${flashPackTheme.spacing(1)}, ${flashPackTheme.palette.system.amber10} 0)`,
          '&:hover': {
            background: `linear-gradient(225deg, ${
              flashPackTheme.palette.system.amber100
            } ${flashPackTheme.spacing(1)}, ${flashPackTheme.palette.system.amber30} 0)`,
            cursor: 'pointer',
          },
        }
      : {
          color: flashPackTheme.palette.principal.grey70,
          background: flashPackTheme.palette.common.white,
          '&:hover': {
            background: flashPackTheme.palette.common.white,
            cursor: 'default',
          },
        };

  if (!adjustment || !adjustment.changes.length) {
    return (
      <DayBoxItem
        sx={{
          ...adjustedDayStyle,
        }}
      >
        {day}
      </DayBoxItem>
    );
  }

  const openPopover = () => {
    setIsPopoverOpen(true);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  return (
    <>
      <DayBoxItem
        onMouseEnter={openPopover}
        onMouseLeave={closePopover}
        ref={popoverAnchor}
        sx={adjustedDayStyle}
      >
        {day}
      </DayBoxItem>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={isPopoverOpen}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        container={() => document.body}
        onClose={closePopover}
        disableRestoreFocus
        PaperProps={{
          onMouseEnter: openPopover,
          onMouseLeave: closePopover,
          sx: { pointerEvents: 'auto' },
        }}
      >
        <DepartureDayAdjustmentsPopover adjustment={adjustment} />
      </Popover>
    </>
  );
};
