import { FC, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { BackButton, GenericError, PageHeading, ChainedText } from 'design-system';
import { Protected } from '@src/authentication/Protected';
import { ShareItineraryDialog } from '@src/itinerary/common/ShareItineraryDialog';
import { RoutePath } from '@src/shared/routePath';
import { ItineraryHeaderDocument, UserRole } from '@flashpack/graphql';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import EditIcon from '@mui/icons-material/Edit';
import { EditItineraryDialog } from '../common/EditItineraryDialog';
import { ItineraryTabs } from './ItineraryTabs';
import { useQuery } from '@apollo/client';
import { AdventureLinks } from '@src/adventure/adventure-overview/AdventureLinks';
import { Stack } from '@mui/system';
import { itenararyAdventureTitle } from '@src/shared/adventure/util';

interface PropTypes {
  itineraryId: string;
}

export const ITINERARY_TAB_HANDLE = 'itineraryTab';

export enum ItineraryTab {
  ITINERARY = 'Itinerary',
  DEPARTURES = 'Departures',
  PACK_LEADERS = 'Pack Leaders',
  PRICES = 'Prices & Discounts',
  COSTS = 'Cost',
}

export const ItineraryHeader: FC<PropTypes> = ({ itineraryId }) => {
  const { data, loading, error } = useQuery(ItineraryHeaderDocument, {
    variables: {
      id: itineraryId,
    },
  });

  const [shareItineraryDialogOpen, setShareItineraryDialogOpen] = useState(false);
  const [editItineraryDialogOpen, setEditItineraryDialogOpen] = useState(false);

  if (loading) {
    return (
      <>
        <BackButton href="#" disabled={true}>
          All itineraries
        </BackButton>
        <PageHeading>
          <ChainedText parts={['...', '...']} />
        </PageHeading>
      </>
    );
  }

  if (error) {
    return <GenericError error={error} />;
  }

  if (!data) {
    throw new Error('Data not loaded');
  }

  const {
    externalUsers,
    adventure: { id: adventureId, title: adventureTitle },
    description: itineraryDescription,
  } = data.itinerary;

  return (
    <>
      <BackButton href={RoutePath.ADVENTURE_ITINERARIES.generatePath(adventureId)}>
        All itineraries
      </BackButton>

      <Protected roles={[UserRole.Flashpack, UserRole.Dmc]}>
        <EditItineraryDialog
          itineraryId={itineraryId}
          open={editItineraryDialogOpen}
          onClose={() => setEditItineraryDialogOpen(false)}
          description={itineraryDescription}
        />
        <ShareItineraryDialog
          onClose={() => setShareItineraryDialogOpen(false)}
          externalUsers={externalUsers}
          description={itineraryDescription}
          itineraryId={itineraryId}
          open={shareItineraryDialogOpen}
        />
      </Protected>

      <Stack
        direction={'row'}
        spacing={2}
        justifyContent={'space-between'}
        alignItems={'flex-end'}
      >
        <Stack direction={'row'} spacing={2} alignItems={'flex-end'}>
          <PageHeading>
            <ChainedText
              parts={[
                itenararyAdventureTitle(adventureTitle, data),
                itineraryDescription,
              ]}
            />
          </PageHeading>
          <Protected roles={[UserRole.Flashpack, UserRole.Dmc]}>
            <Tooltip arrow placement="bottom" title="Edit this itinerary">
              <IconButton
                onClick={() => setEditItineraryDialogOpen(true)}
                data-testid="edit-itinerary"
              >
                <EditIcon htmlColor="#999999" />
              </IconButton>
            </Tooltip>
            <Tooltip arrow placement="bottom" title="Share this itinerary">
              <span>
                <IconButton
                  onClick={() => setShareItineraryDialogOpen(true)}
                  data-testid="share-itinerary"
                >
                  <PersonAddAltIcon htmlColor="#999999" />
                </IconButton>
              </span>
            </Tooltip>
          </Protected>
        </Stack>
        <AdventureLinks {...data.itinerary.adventure} />
      </Stack>

      <ItineraryTabs
        adventureId={adventureId}
        itineraryId={itineraryId}
        showDepartures={!(loading || data?.itinerary.timeline.empty)}
        showCostAndPricing={!loading && !!data?.itinerary.departuresCount}
      />
    </>
  );
};
