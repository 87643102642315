import { Dialog, Typography, Stack, MenuItem, Box } from '@mui/material';
import { TextButton } from 'design-system';
import { FC } from 'react';
import { StopPropagationWrapper } from '../../design-system/StopPropagationWrapper';
import { Form } from 'react-final-form';
import { TextField, Select } from 'mui-rff';
import { flashPackTheme } from '../../design-system/theme';
import { Validator } from '@src/design-system/forms/validators';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useMutation } from '@apollo/client';
import {
  Scalars,
  CancelDeparturesDocument,
  DepartureCancellationReason,
} from '@flashpack/graphql';
import { useToast } from '@src/shared/toast/useToast';
import { LoadingButton } from '@mui/lab';
import { cancellationReasonOptions } from './utils';

interface PropTypes {
  onSuccess?: () => void;
  onClose: () => void;
  open: boolean;
  departureIds: Scalars['UUID'][];
}

type CancellationReasonFormType = {
  cancellationReason: DepartureCancellationReason;
  cancellationReasonNote: string;
};

type CancellationReasonFormErrors = {
  cancellationReason?: string;
  cancellationReasonNote?: string;
};

export const CancellationReasonDialog: FC<PropTypes> = ({
  open,
  onSuccess,
  onClose,
  departureIds,
}) => {
  const { safeMutation } = useSafeMutation();
  const { success, error } = useToast();
  const [cancelDepartures, { loading }] = useMutation(CancelDeparturesDocument);

  const onCancelSelectedDepartures = async (formInput: CancellationReasonFormType) => {
    const { cancellationReason, cancellationReasonNote } = formInput;
    const input = { departureIds, cancellationReason, cancellationReasonNote };

    await safeMutation(cancelDepartures({ variables: { input } }), {
      onSuccess: () => {
        success('Departure(s) cancellation successful');
        if (onSuccess) {
          onSuccess();
        }
        onClose();
      },
      onError: () => {
        error('Failed to cancel departure(s)');
        onClose();
      },
    });
  };

  const validateForm = (values: CancellationReasonFormType) => {
    const errors: CancellationReasonFormErrors = {
      cancellationReason: undefined,
      cancellationReasonNote: undefined,
    };
    if (values.cancellationReason === undefined) {
      errors.cancellationReason = 'Required';
    }
    if (
      values.cancellationReason === DepartureCancellationReason.Other &&
      !values.cancellationReasonNote
    ) {
      errors.cancellationReasonNote = 'Notes are required when selecting "Other"';
    }

    return errors;
  };

  return (
    <StopPropagationWrapper allowDefault>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={onClose}
        data-testid="cancellation-reason-modal-dialog"
      >
        <Stack gap={3} padding={5}>
          <Typography variant="subHeader">Cancel Departure(s)?</Typography>
          <Form<CancellationReasonFormType>
            validate={validateForm}
            onSubmit={onCancelSelectedDepartures}
            render={({ handleSubmit, values }) => (
              <form onSubmit={(v) => void handleSubmit(v)}>
                <Stack direction="row" alignItems="center">
                  <Box width="50%">
                    <Typography variant="bodySingle">Reason for cancellation</Typography>
                  </Box>
                  <Box width="50%">
                    <Select
                      name="cancellationReason"
                      displayEmpty
                      fieldProps={{ validate: Validator.required }}
                    >
                      <MenuItem value="" disabled>
                        <Typography
                          variant="bodyPara"
                          color={flashPackTheme.palette.principal.grey70}
                        >
                          Select
                        </Typography>
                      </MenuItem>
                      {Object.entries(cancellationReasonOptions).map(([value, label]) => (
                        <MenuItem value={value} key={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Stack>

                <Stack my={2}>
                  <Stack direction="row" alignItems="center" my={1}>
                    <Typography variant="bodySingle">Additional notes</Typography>
                    {values.cancellationReason !== DepartureCancellationReason.Other && (
                      <Typography variant="bodyPara">&nbsp;&#40;optional&#41;</Typography>
                    )}
                  </Stack>
                  <TextField
                    name="cancellationReasonNote"
                    placeholder="Add more detail"
                    multiline
                    rows={3}
                  />
                </Stack>

                <Stack direction="row" justifyContent="space-between">
                  <TextButton onClick={onClose}>Cancel</TextButton>
                  <LoadingButton
                    variant="contained"
                    type="submit"
                    data-testid="submit-cancellation-reason-button"
                    loading={loading}
                  >
                    Confirm
                  </LoadingButton>
                </Stack>
              </form>
            )}
          />
        </Stack>
      </Dialog>
    </StopPropagationWrapper>
  );
};
