import { FC, useState } from 'react';
import { Container, Typography, Button, Stack } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { HotelPicker } from 'src/hotels/hotel-picker/HotelPicker';
import { UnboundAddManualAccommodationForm } from './add-manual-accommodation/AddManualAccommodation';
import { UnboundAddRatehawkAccommodationForm } from './add-ratehawk-accommodation/AddRatehawkAccommodation';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { adventureCodeToIndividualCountryCodes } from '@src/shared/adventure/util';

interface PropTypes {
  adventureCode?: string;
}

export const AddAccommodation: FC<PropTypes> = ({ adventureCode }) => {
  const { itineraryId } = useRequiredParams(['adventureId', 'itineraryId']);
  const [hotelName, setHotelName] = useState<string>('');
  const [hotelId, setHotelId] = useState<string>('');
  const [isManual, setManualAccommodation] = useState<boolean>(false);
  const countryCodes = adventureCodeToIndividualCountryCodes(adventureCode ?? '');

  const selectHotelAccommodation = (hotelId: string) => {
    setHotelId(hotelId);
  };

  if (isManual) {
    return (
      <UnboundAddManualAccommodationForm onBack={() => setManualAccommodation(false)} />
    );
  } else if (hotelId) {
    return (
      <UnboundAddRatehawkAccommodationForm
        hotelId={hotelId}
        itineraryId={itineraryId}
        onBack={() => setHotelId('')}
      />
    );
  } else {
    return (
      <Container maxWidth="sm">
        <HotelPicker
          hotelName={hotelName}
          setHotelName={setHotelName}
          onHotelSelected={selectHotelAccommodation}
          showClosedHotels={false}
          countryFilter={countryCodes}
        >
          <Stack direction="row" justifyContent="end">
            <Button onClick={() => setManualAccommodation(true)}>
              <Typography variant="bodySingle">Add accommodation manually</Typography>
              <ChevronRight />
            </Button>
          </Stack>
        </HotelPicker>
      </Container>
    );
  }
};
