import { Stack, Typography } from '@mui/material';
import { DatePicker, HighlightInfo } from '@src/shared/date-picker/DatePicker';
import { format, isSameDay } from 'date-fns';
import React, { FC } from 'react';
import { Field } from 'react-final-form';

interface PropTypes {
  name: string;
  highlights: HighlightInfo[];
  validationError?: React.ReactNode;
  validate?: (value: Date, formValues: object) => string | undefined;
}

export const DateChangeField: FC<PropTypes> = (props) => {
  const { name, highlights, validate, validationError } = props;

  const handleValidate = (value: Date, formValues: object | undefined) => {
    if (validate) {
      return validate(value, formValues as object);
    }
  };

  return (
    <Field<Date, HTMLElement, Date>
      name={name}
      validate={handleValidate}
      render={({ input, meta }) => {
        const initialDateFormatted = meta.initial
          ? format(meta.initial, 'dd MMM yyyy')
          : '';

        const changeTo = meta.pristine
          ? 'Select date'
          : format(input?.value, 'dd MMM yyyy');

        const showError = !!meta.touched && !!meta.error;

        return (
          <>
            <Stack alignItems={'flex-start'}>
              <DatePicker
                value={input.value}
                isDaySelected={(day) => isSameDay(day, input.value)}
                highlights={highlights}
                onChange={input.onChange}
                errorMessage={(meta.touched && (meta.error as string)) || ''}
              />
              <Stack direction="row" sx={{ mt: 3 }} gap={1}>
                <Typography variant="bodySingle">
                  {`Change from ${initialDateFormatted} to `}
                </Typography>
                {showError && (
                  <Typography sx={{ color: 'error.main' }} variant="bodyPara">
                    {meta.error}
                  </Typography>
                )}
                {!showError && (
                  <Typography
                    variant="bodySingle"
                    color={meta.pristine ? 'principal.grey70' : 'principal.black'}
                  >
                    {changeTo}
                  </Typography>
                )}
              </Stack>
            </Stack>
            {validationError}
          </>
        );
      }}
    />
  );
};
