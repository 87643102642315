import {
  ChatIcon,
  DangerRedIcon,
  AirplaneIcon,
  ThumbsUpIcon,
  FailureIcon,
  DepartureSecuredIcon,
  ExclamationIcon,
  FlagIcon,
  EmptyAvatarIcon,
  FinanceIcon,
} from 'design-system';
import {
  HikingOutlined,
  MovingOutlined,
  HotelOutlined,
  PersonAddOutlined,
  PersonRemoveOutlined,
  DeleteForever,
  RemoveModeratorOutlined,
  SvgIconComponent,
  VisibilityOutlined,
  VisibilityOffOutlined,
  TaskAlt,
} from '@mui/icons-material';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import { FC } from 'react';
import { UserActivityAction } from '@flashpack/graphql';
import { MUIStyledCommonProps } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

interface ActivityFeedIconProps {
  action: UserActivityAction;
  flagged: boolean;
}

export const ActivityFeedIcon: FC<ActivityFeedIconProps & MUIStyledCommonProps> = ({
  action,
  flagged,
  ...props
}) => {
  const theme = useTheme();

  if (flagged) {
    return <DangerRedIcon {...props} />;
  }

  const SvgActionIcon = actionIconMap[action];
  return <SvgActionIcon {...props} htmlColor={theme.palette.principal.grey70} />;
};

const actionIconMap: Record<UserActivityAction, SvgIconComponent> = {
  [UserActivityAction.CreatedComment]: ChatIcon,
  [UserActivityAction.ProposedDeparture]: AirplaneIcon,
  [UserActivityAction.UpdatedDepartureStatusAccepted]: ThumbsUpIcon,
  [UserActivityAction.UpdatedDepartureStatusCancelled]: FailureIcon,
  [UserActivityAction.UpdatedDepartureStatusCompleted]: AirplaneIcon,
  [UserActivityAction.UpdatedDepartureStatusDeparted]: AirplaneIcon,
  [UserActivityAction.UpdatedDepartureStatusOnSale]: AirplaneIcon,
  [UserActivityAction.UpdatedDepartureStatusProposed]: AirplaneIcon,
  [UserActivityAction.UpdatedDepartureStatusRejected]: CancelOutlinedIcon,
  [UserActivityAction.UpdatedDepartureStatusSecured]: DepartureSecuredIcon,
  [UserActivityAction.CancelledDeparture]: FailureIcon,
  [UserActivityAction.FlaggedTimelineComponent]: ExclamationIcon,
  [UserActivityAction.UnflaggedTimelineComponent]: ExclamationIcon,
  [UserActivityAction.CreatedItinerary]: AddLocationAltOutlinedIcon,
  [UserActivityAction.CreatedAdventure]: HikingOutlined,
  [UserActivityAction.UpdatedAdventure]: HikingOutlined,
  [UserActivityAction.CreatedEngagementActivity]: FlagIcon,
  [UserActivityAction.UpdatedEngagementActivity]: FlagIcon,
  [UserActivityAction.CreatedEngagementTransfer]: MovingOutlined,
  [UserActivityAction.UpdatedEngagementTransfer]: MovingOutlined,
  [UserActivityAction.DeletedEngagementActivity]: DeleteForever,
  [UserActivityAction.DeletedEngagementTransfer]: DeleteForever,
  [UserActivityAction.CreatedAccommodation]: HotelOutlined,
  [UserActivityAction.UpdatedAccommodation]: HotelOutlined,
  [UserActivityAction.RemovedUser]: PersonRemoveOutlined,
  [UserActivityAction.AddedUser]: PersonAddOutlined,
  [UserActivityAction.DeletedAccommodation]: DeleteForever,
  [UserActivityAction.AssignedPackLeader]: EmptyAvatarIcon,
  [UserActivityAction.UnassignedPackLeader]: EmptyAvatarIcon,
  [UserActivityAction.UpdatedCostsExtras]: FinanceIcon,
  [UserActivityAction.UpdatedCostsMandatory]: FinanceIcon,
  [UserActivityAction.UpdatedCostsPassenger]: FinanceIcon,
  [UserActivityAction.CreatedMessageThread]: ChatIcon,
  [UserActivityAction.CreatedMessage]: ChatIcon,
  [UserActivityAction.BulkUpdatedCostsExtras]: FinanceIcon,
  [UserActivityAction.BulkUpdatedCostsMandatory]: FinanceIcon,
  [UserActivityAction.BulkUpdatedCostsPassenger]: FinanceIcon,
  [UserActivityAction.UpdatedPricesTripPrice]: FinanceIcon,
  [UserActivityAction.UpdatedPricesEarlyBirdDiscount]: FinanceIcon,
  [UserActivityAction.UpdatedPricesPromotionalDiscount]: FinanceIcon,
  [UserActivityAction.BulkUpdatedPricesTripPrice]: FinanceIcon,
  [UserActivityAction.BulkUpdatedPricesEarlyBirdDiscount]: FinanceIcon,
  [UserActivityAction.BulkUpdatedPricesPromotionalDiscount]: FinanceIcon,
  [UserActivityAction.MarkedDepartureAsSecured]: DepartureSecuredIcon,
  [UserActivityAction.MarkedDepartureAsNotSecured]: RemoveModeratorOutlined,
  [UserActivityAction.ChangedDepartureVisibilityToLive]: VisibilityOutlined,
  [UserActivityAction.ChangedDepartureVisibilityToDraft]: VisibilityOffOutlined,
  [UserActivityAction.UpdatedItineraryCostCurrency]: FinanceIcon,
  [UserActivityAction.UpdatedDepartureMinToGuarantee]: AirplaneIcon,
  [UserActivityAction.UpdatedDepartureCapacity]: AirplaneIcon,
  [UserActivityAction.UpdatedDepartureMaxEarlyBirds]: AirplaneIcon,
  [UserActivityAction.UpdatedDepartureRoomReleaseDays]: AirplaneIcon,
  [UserActivityAction.BulkUpdatedDepartureMinToGuarantee]: AirplaneIcon,
  [UserActivityAction.BulkUpdatedDepartureCapacity]: AirplaneIcon,
  [UserActivityAction.BulkUpdatedDepartureMaxEarlyBirds]: AirplaneIcon,
  [UserActivityAction.BulkUpdatedDepartureRoomReleaseDays]: AirplaneIcon,
  [UserActivityAction.SignedOffDeparture]: TaskAlt,
};
