import { Departure, Engagement, Scalars } from '@flashpack/graphql';
import { FC, memo } from 'react';
import { ActivityMobileIcon, Vehicle4WDTransferIcon } from 'design-system';
import { calculateDateFromDepartureAndDay } from './utils';
import { TimelineEventBox } from './TimelineEventBox';
import { format, parseISO } from 'date-fns';
import { TimelineEventBoxDetail } from './TimelineEventBoxDetail';
import { Box } from '@mui/system';
import { flashPackTheme } from '@src/design-system/theme';

interface Props {
  departureDate: Departure['date'];
  engagement: Engagement;
  selected: boolean;
  onSelect: (id: Scalars['UUID']) => void;
  greyedOut?: boolean;
  flagged?: boolean;
  adjusted?: boolean;
  testid?: string;
}

const TimelineEventBoxEngagement: FC<Props> = ({
  departureDate,
  engagement,
  selected,
  onSelect,
  flagged = false,
  adjusted = false,
  testid,
}) => {
  const onSelectEngagement = () => {
    onSelect(engagement.id);
  };

  const title = engagement.title;
  const icon = engagement.activity ? (
    <ActivityMobileIcon htmlColor={flashPackTheme.palette.principal.white} />
  ) : (
    <Vehicle4WDTransferIcon />
  );
  const startTitle = engagement.activity ? 'Start' : 'Depart';
  const endTitle = engagement.activity ? 'End' : 'Arrive';

  return (
    <TimelineEventBox
      icon={icon}
      title={title || ''}
      greyBoxTitle={format(parseISO(departureDate), 'dd MMM yyyy')}
      selected={selected}
      onSelect={onSelectEngagement}
      flagged={flagged}
      adjusted={adjusted}
      testid={testid}
    >
      <Box display="flex" flexDirection="row" flexWrap="wrap">
        <TimelineEventBoxDetail
          title={startTitle}
          content={`${engagement.start.time.time} • ${calculateDateFromDepartureAndDay(departureDate, engagement.start.time.day)} • `}
          subcontent={`Day ${engagement.start.time.day}`}
        />
        <TimelineEventBoxDetail
          title={endTitle}
          content={`${engagement.end.time.time} • ${calculateDateFromDepartureAndDay(departureDate, engagement.end.time.day)} • `}
          subcontent={`Day ${engagement.end.time.day}`}
        />
      </Box>
    </TimelineEventBox>
  );
};

export const MemoizedTimelineEventBoxEngagement = memo(
  TimelineEventBoxEngagement,
  (prevProps, nextProps) =>
    prevProps.engagement === nextProps.engagement &&
    prevProps.selected === nextProps.selected,
);
