import { FC } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Button from '@mui/material/Button';

type MessageThreadsHeaderProps = {
  onClick: () => void;
};

export const MessageThreadsHeader: FC<MessageThreadsHeaderProps> = ({ onClick }) => {
  return (
    <Button onClick={onClick} variant="outlined" data-testid="add-message-thread">
      <AddCircleOutlineOutlinedIcon /> &nbsp; Start a Thread
    </Button>
  );
};

export default MessageThreadsHeader;
