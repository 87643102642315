const parseNumber = (value: string): number | undefined => {
  const onlyNumbers = value?.replace(/[^\d]/g, '');

  if (!onlyNumbers) {
    return;
  }

  return parseInt(onlyNumbers);
};

const parseDecimal = (value: string) => {
  if (!value) {
    return null;
  }
  return value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
};

export const FormParser = {
  parseNumber,
  parseDecimal,
};
