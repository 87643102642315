import { Dialog, Typography, Stack, Breakpoint } from '@mui/material';
import { TextButton } from 'design-system';
import { FC, ReactNode } from 'react';
import { StopPropagationWrapper } from '../StopPropagationWrapper';
import { LoadingButton } from '@mui/lab';

interface PropTypes {
  title?: string;
  children?: ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
  onConfirm?: () => void;
  onCancel: () => void;
  /**
   * When clicking outside of the dialog the onCancel handler would
   * typically trigger, this inverts the behaviour if the UI is inverted.
   */
  invertedCancel?: boolean;
  open: boolean;
  hasCustomActions?: boolean;
  maxWidth?: Breakpoint;
  confirmLoading?: boolean;
  confirmFormId?: string;
  disableConfirmButton?: boolean;
}

export const confirmationDialogLabels = {
  title: 'Lose Progress?',
  cancelLabel: 'Exit without Saving',
  confirmLabel: 'Keep Editing',
  content: 'If you "Exit without Saving" you will lose all your progress',
};

export const ConfirmationDialog: FC<PropTypes> = (props) => {
  const {
    title,
    children,
    confirmLabel,
    cancelLabel,
    open,
    onConfirm,
    onCancel,
    hasCustomActions = false,
    maxWidth = 'xs',
    invertedCancel = false,
    confirmLoading = false,
    confirmFormId,
    disableConfirmButton = false,
  } = props;

  return (
    <StopPropagationWrapper allowDefault>
      <Dialog
        maxWidth={maxWidth}
        fullWidth
        open={open}
        onClose={invertedCancel ? onConfirm : onCancel}
        data-testid="modal-dialog"
      >
        <Stack gap={3} padding={5}>
          <Typography variant="subHeader">{title}</Typography>

          {children}

          {!hasCustomActions && (
            <Stack direction="row" gap={3} justifyContent="space-between">
              <TextButton onClick={onCancel}>{cancelLabel}</TextButton>
              <LoadingButton
                variant="contained"
                disabled={disableConfirmButton || confirmLoading}
                onClick={onConfirm}
                data-testid="confirm-button"
                type="submit"
                loading={confirmLoading}
                form={confirmFormId}
              >
                {confirmLabel}
              </LoadingButton>
            </Stack>
          )}
        </Stack>
      </Dialog>
    </StopPropagationWrapper>
  );
};

ConfirmationDialog.defaultProps = {
  title: 'Confirm action',
  children: 'Are you sure you want to confirm this action?',
  confirmLabel: 'Yes',
  cancelLabel: 'No',
};
