import { Typography } from '@mui/material';
import { ReactNode } from 'react';

export const PageHeading = ({ children }: { children: ReactNode }) => {
  return (
    <Typography variant="pageHeader" component="h1" sx={{ lineHeight: '40px' }}>
      {children}
    </Typography>
  );
};
