import { Button, Dialog, Box, Typography, Stack } from '@mui/material';
import { FC } from 'react';

interface PropTypes {
  open: boolean;
  onClose: () => void;
  email: string;
}

export const ChangeEmailAddressDialog: FC<PropTypes> = ({ open, onClose, email }) => {
  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <Box sx={{ padding: 5 }}>
        <Typography variant="subHeader">Change email address</Typography>
        <Stack mt={5} gap={3}>
          <Typography variant="bodySingle">
            You are accessing this itinerary as {email}
          </Typography>
          <Typography variant="bodyPara">
            To change your email or add another user, please email{' '}
            <a href="mailto:adventureops@flashpack.com">adventureops@flashpack.com</a>
          </Typography>
        </Stack>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 3 }}>
          <Button variant="contained" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
