import { ApolloError, useMutation } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Stack, Box } from '@mui/system';
import { TextField } from 'mui-rff';
import { FC, useState } from 'react';
import {
  CreateMessageThreadDocument,
  ItineraryWithMessageThreadsDocument,
} from '@flashpack/graphql';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useToast } from '@src/shared/toast/useToast';
import { Button, Typography } from '@mui/material';
import { ServerValidationErrors } from '@src/design-system/forms/ServerValidationErrors';
import { FORM_ERROR } from 'final-form';
import { Form } from 'react-final-form';
import {
  ConfirmationDialog,
  confirmationDialogLabels,
} from '@src/design-system/dialogs/ConfirmationDialog';
import { useTheme } from '@mui/material/styles';

export type CreateMessageThreadFormValues = {
  title: string;
  content: string;
};

type props = {
  setIsNewThreadExpanded: (v: boolean) => void;
  itineraryId: string;
};

export const CreateMessageThreadForm: FC<props> = ({
  setIsNewThreadExpanded,
  itineraryId,
}) => {
  const [createMessageThread] = useMutation(CreateMessageThreadDocument);
  const { success, error: errorToast } = useToast();
  const { safeMutation } = useSafeMutation();
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isTitleSet, setTitle] = useState<boolean>(false);
  const [isContentSet, setContent] = useState<boolean>(false);
  const theme = useTheme();

  const onSubmit = async ({ title, content }: CreateMessageThreadFormValues) => {
    await safeMutation(
      createMessageThread({
        variables: {
          input: {
            title,
            content,
            itineraryId,
          },
        },
        refetchQueries: [ItineraryWithMessageThreadsDocument],
      }),
      {
        onSuccess: () => {
          setIsNewThreadExpanded(false);
          success('Message Thread created');
        },
        onError: () => errorToast('Failed to create message thread'),
      },
    );
  };

  return (
    <Box>
      <Stack
        gap={5}
        sx={{
          backgroundColor: theme.palette.principal.grey30,
          p: 2,
          borderRadius: 2,
          my: 2,
        }}
      >
        <Form<CreateMessageThreadFormValues>
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting, submitError, form }) => {
            function resetForm() {
              form.reset();
              setTitle(false);
              setContent(false);
            }
            return (
              <form
                onSubmit={(values) => {
                  try {
                    void handleSubmit(values);
                    resetForm();
                  } catch (e) {
                    return {
                      [FORM_ERROR]: e,
                    };
                  }
                }}
              >
                <Stack spacing={1} alignItems="flex-start">
                  <TextField
                    autoFocus
                    name="title"
                    placeholder="Topic name"
                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => (
                      setIsDialogOpen(false), setTitle(event.target.value.length > 0)
                    )}
                    sx={{
                      '& .MuiInputBase-root': {
                        borderRadius: '2px',
                        borderColor: 'principal.grey70',
                        backgroundColor: theme.palette.principal.white,
                      },
                      '& .MuiFormHelperText-root': {
                        fontSize: 'micro.fontSize',
                        lineHeight: '13px',
                        marginTop: '3px',
                      },
                    }}
                  />
                  <TextField
                    multiline
                    helperText=" "
                    rows={4}
                    sx={{
                      '& .MuiInputBase-root': {
                        borderRadius: '2px',
                        borderColor: 'principal.grey70',
                        backgroundColor: theme.palette.principal.white,
                      },
                      '& .MuiFormHelperText-root': {
                        fontSize: 'micro.fontSize',
                        lineHeight: '13px',
                        marginTop: '3px',
                      },
                    }}
                    autoFocus
                    name="content"
                    placeholder="Write a message"
                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => (
                      setIsDialogOpen(false), setContent(event.target.value.length > 0)
                    )}
                  />

                  {<ServerValidationErrors error={submitError as ApolloError} />}
                  <Stack direction="row" justifyContent="space-between" width="100%">
                    <Button
                      variant="outlined"
                      data-testid="create-message-thread-form-cancel"
                      onClick={() =>
                        isTitleSet || isContentSet
                          ? setIsDialogOpen(true)
                          : setIsNewThreadExpanded(false)
                      }
                      sx={{
                        backgroundColor: theme.palette.principal.grey30,
                        borderWidth: 0,
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      loading={submitting}
                      type="submit"
                      variant="contained"
                      data-testid="create-message-thread-form-submit"
                      disabled={!isTitleSet}
                    >
                      Send
                    </LoadingButton>
                  </Stack>
                  {isDialogOpen ? (
                    <ConfirmationDialog
                      open={isDialogOpen}
                      onConfirm={() => setIsDialogOpen(false)}
                      title={confirmationDialogLabels.title}
                      cancelLabel={confirmationDialogLabels.cancelLabel}
                      confirmLabel={confirmationDialogLabels.confirmLabel}
                      maxWidth="sm"
                      onCancel={() => {
                        setIsDialogOpen(false);
                        setIsNewThreadExpanded(false);
                        resetForm();
                      }}
                    >
                      <Typography sx={{ mb: 5 }} variant="bodyPara">
                        {confirmationDialogLabels.content}
                      </Typography>
                    </ConfirmationDialog>
                  ) : null}
                </Stack>
              </form>
            );
          }}
        />
      </Stack>
    </Box>
  );
};
