import {
  BoatTransferIcon,
  CoachTransferIcon,
  FlightTransferIcon,
  MinibusTransferIcon,
  TaxiTransferIcon,
  TrainTransferIcon,
  Vehicle4WDTransferIcon,
} from 'design-system';
import { TransferMode } from '@flashpack/graphql';
export const transferModeOptions = [
  { value: TransferMode.Minibus, label: 'Minibus' },
  { value: TransferMode.Vehicle_4Wd, label: '4 x 4' },
  { value: TransferMode.Coach, label: 'Coach' },
  { value: TransferMode.Boat, label: 'Boat' },
  { value: TransferMode.Airplane, label: 'Flight' },
  { value: TransferMode.Train, label: 'Train' },
  { value: TransferMode.AirportTransfer, label: 'Airport Transfer' },
];

export const getIconForTransferMode = (transferMode?: TransferMode) => {
  switch (transferMode) {
    case TransferMode.Minibus:
      return MinibusTransferIcon;
    case TransferMode.Coach:
      return CoachTransferIcon;
    case TransferMode.Boat:
      return BoatTransferIcon;
    case TransferMode.Airplane:
      return FlightTransferIcon;
    case TransferMode.Train:
      return TrainTransferIcon;
    case TransferMode.AirportTransfer:
      return TaxiTransferIcon;
    case TransferMode.Vehicle_4Wd:
      return Vehicle4WDTransferIcon;
    default:
      return TaxiTransferIcon;
  }
};
