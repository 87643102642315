import AddHomeWorkOutlined from '@mui/icons-material/AddHomeWorkOutlined';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import {
  Backdrop,
  Fab,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  SpeedDialIcon,
} from '@mui/material';
import { TransferIcon } from 'design-system';
import { useRouting } from '@src/shared/useRouting';
import { useRef, useState } from 'react';
import { RoutePath } from 'src/shared/routePath';

export const ItineraryDetailsActions = () => {
  const anchor = useRef(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { navigate } = useRouting();
  const handleAction = (url: string) => {
    handleClose();
    navigate(url);
  };

  return (
    <>
      <Backdrop open={open} />
      <Fab
        ref={anchor}
        color="primary"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={handleOpen}
        data-testid="speed-dial"
      >
        <SpeedDialIcon />
      </Fab>
      <Menu
        open={open}
        anchorEl={anchor.current}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        onClose={handleClose}
      >
        <div>
          <MenuItem
            onClick={() => handleAction(RoutePath.ADD_ACTIVITY.generateRelativePath())}
            data-testid="add-activity"
          >
            <ListItemIcon>
              <AddLocationAltOutlinedIcon />
            </ListItemIcon>
            <ListItemText>Add an activity</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() =>
              handleAction(RoutePath.ADD_ACCOMMODATION.generateRelativePath())
            }
            data-testid="add-accommodation"
          >
            <ListItemIcon>
              <AddHomeWorkOutlined />
            </ListItemIcon>
            <ListItemText>Add an accommodation</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => handleAction(RoutePath.ADD_TRANSFER.generateRelativePath())}
            data-testid="add-transfer"
          >
            <ListItemIcon>
              <TransferIcon />
            </ListItemIcon>
            <ListItemText>Add a transfer</ListItemText>
          </MenuItem>
        </div>
      </Menu>
    </>
  );
};
