import { styled, Typography } from '@mui/material';

export const FormattedText = styled(Typography)({});

FormattedText.defaultProps = {
  variant: 'bodyPara',
  sx: {
    whiteSpace: 'pre-line',
  },
};
