import React, { FC } from 'react';
import {
  // eslint-disable-next-line no-restricted-imports
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps,
  useTheme,
} from '@mui/material';

type CheckboxFormControlLabelProps = FormControlLabelProps & {
  checked?: boolean;
  checkedLabelColor?: string;
  uncheckedLabelColor?: string;
};

export const FormControlLabel: FC<CheckboxFormControlLabelProps> = ({
  control,
  label,
  checked = false,
  checkedLabelColor,
  uncheckedLabelColor,
  ...forwardProps
}) => {
  const theme = useTheme();

  const getLabelColor = () => {
    if (forwardProps.disabled) {
      return theme.palette.principal.grey70;
    } else if (checked) {
      return checkedLabelColor || theme.palette.principal.black;
    }
    return uncheckedLabelColor || theme.palette.principal.black;
  };

  const labelColor = getLabelColor();

  // default variant
  return (
    <MuiFormControlLabel
      {...forwardProps}
      sx={{ color: labelColor, ...(forwardProps.sx || {}) }}
      control={control}
      label={label}
    />
  );
};
