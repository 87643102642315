import { TableData, TableRow } from '@src/shared/table/Table';
import { isFuture, parseISO } from 'date-fns';

export type CostTypeLocked =
  | 'passengerCostsLocked'
  | 'extrasCostsLocked'
  | 'mandatoryCostsLocked';

export type CostTypes = 'passengersCost' | 'extrasCost' | 'mandatoryCost';
interface PassengerCostsTableMeta {
  departureDate: string;
}

const isDepartureDateInFuture = (date: string) => {
  return isFuture(parseISO(date));
};

export const toolTipTextFunction = (row: TableRow) => {
  const departureDate = (row.meta as PassengerCostsTableMeta).departureDate;
  return isDepartureDateInFuture(departureDate)
    ? 'Costs are fixed. Reach out to a member of the Flash Pack team to alter these costs.'
    : `Costs are fixed. The departure date (${row.label}) has passed`;
};

export const dialogBoxTextFunction = (row: TableRow) => {
  const departureDate = (row.meta as PassengerCostsTableMeta).departureDate;
  return isDepartureDateInFuture(departureDate)
    ? `Costs for this departure (${row.label}) have been agreed and cannot be changed. If you need to change these costs, reach out to a member of the Flash Pack team to discuss.`
    : `Costs for this departure date (${row.label}) cannot be changed as the date of departure for this particular departure has already passed`;
};

export const lockIconSpecifications = {
  isClickable: true,
  hasToolTip: true,
  toolTipTextFunction: toolTipTextFunction,
};

export const dialogBoxSpecifications = {
  title: 'Cost Fixed',
  dialogBoxTextFunction: dialogBoxTextFunction,
};

export const mapTableRow = (formValues: TableData, row: TableRow) => {
  const amounts: { [key: string]: number | null } = {};
  formValues.headers.map((header) => {
    amounts[header.name] = isNaN(row.values[header.name] as number)
      ? null
      : row.values[header.name];
  });
  return amounts;
};
