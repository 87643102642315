import { FC, useState } from 'react';
import {
  AppBar as MuiAppBar,
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  Toolbar,
} from '@mui/material';
import { RoutePath } from 'src/shared/routePath';
import { ChangeEmailAddressDialog } from '@src/authentication/ChangeEmailAddressDialog';
import { LogoIcon } from 'design-system';
import {
  AccountCircle,
  Menu as MenuIcon,
  Logout,
  TravelExplore,
  RateReview,
} from '@mui/icons-material';
import { Environment, currentEnvironment, environmentIs } from './environment';
import { openApolloSandbox } from '@src/apolloClient';
import { CurrentAdventureManagerUserFieldsFragment } from '@flashpack/graphql';
import { ActivityFeed } from '@src/activity-feed/ActivityFeed';

interface PropTypes {
  openNavDrawer: () => void;
  signOut: () => void;
  authenticated: boolean;
  currentUser?: CurrentAdventureManagerUserFieldsFragment;
}

export const AppBar: FC<PropTypes> = ({
  openNavDrawer,
  authenticated,
  currentUser,
  signOut,
}) => {
  const [changeEmailDialogOpen, setChangeEmailDialogOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const FEEDBACK_FORM_URL = 'https://yqx2g1x2u4o.typeform.com/to/rHG6CBS3';
  const handleClick: React.MouseEventHandler<HTMLElement> = (e: React.MouseEvent) => {
    setAnchorEl(e.target as HTMLElement);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <NavBar>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Stack direction="row" alignItems="center">
          {authenticated && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={openNavDrawer}
            >
              <MenuIcon color="primary" />
            </IconButton>
          )}
          <IconButton href="/">
            <LogoIcon htmlColor="black" sx={{ width: 40, height: 40 }} />
          </IconButton>
        </Stack>

        <Stack direction="row" spacing={2} alignItems="center">
          {authenticated && currentUser && (
            <ActivityFeed hasUnreadUserActivity={currentUser.hasUnreadUserActivity} />
          )}
          {authenticated && (
            <Chip
              data-testid="feedback-avatar"
              icon={<RateReview />}
              label={'Give feedback'}
              variant="outlined"
              color="secondary"
              clickable={true}
              component="a"
              target="_blank"
              href={FEEDBACK_FORM_URL}
              sx={{ pl: 1 }}
            />
          )}

          {!environmentIs(Environment.Production) && (
            <Chip label={currentEnvironment} color="secondary" variant="outlined" />
          )}
          {authenticated &&
            (currentUser ? (
              <>
                <Chip
                  data-testid="user-menu-avatar"
                  icon={<AccountCircle />}
                  label={currentUser.email}
                  color="secondary"
                  variant="outlined"
                  clickable={true}
                  component="div"
                  onClick={handleClick}
                />

                <ChangeEmailAddressDialog
                  open={changeEmailDialogOpen}
                  email={currentUser.email}
                  onClose={() => setChangeEmailDialogOpen(false)}
                />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleClose}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setChangeEmailDialogOpen(true);
                    }}
                  >
                    <Box gap={1} display="flex" alignItems="center">
                      <AccountCircle />
                      <span>Change email address</span>
                    </Box>
                  </MenuItem>
                  {environmentIs(Environment.Development) && (
                    <MenuItem onClick={openApolloSandbox}>
                      <Box gap={1} display="flex" alignItems="center">
                        <TravelExplore />
                        Apollo Explorer
                      </Box>
                    </MenuItem>
                  )}
                  <MenuItem data-testid="sign-out" onClick={signOut}>
                    <Box gap={1} display="flex" alignItems="center">
                      <Logout />
                      <span>Sign out</span>
                    </Box>
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <CircularProgress color="secondary" size={20} />
            ))}
          {!authenticated && (
            <Button
              color="secondary"
              size="small"
              href={RoutePath.LOGIN.value}
              data-testid="log in"
            >
              Log in
            </Button>
          )}
        </Stack>
      </Toolbar>
    </NavBar>
  );
};

const NavBar = styled(MuiAppBar)(({ theme }) => ({
  position: 'sticky',
  backgroundColor: theme.palette.principal.white,
  boxShadow: `0px 2px 4px -1px ${theme.palette.principal.grey50}`,
}));
