import { useQuery } from '@apollo/client';
import { SkeletonBulletinList } from '@src/design-system/bulletin';
import { PackLeadersForItineraryDocument } from '@flashpack/graphql';
import { PackLeadersList } from '@src/packLeaders/PackLeadersList';
import { useRequiredParams } from '@src/shared/useRequiredParams';

export const PackLeaders = () => {
  const { itineraryId } = useRequiredParams(['itineraryId']);

  const { data, loading } = useQuery(PackLeadersForItineraryDocument, {
    variables: { itineraryId },
  });

  if (loading) {
    return <SkeletonBulletinList count={3} />;
  }

  const packLeaders = data?.itinerary.packLeaders || [];

  return <PackLeadersList itineraryId={itineraryId} packLeaders={packLeaders} />;
};
