import { Circle, RssFeed } from '@mui/icons-material';
import { Box, Zoom } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { ActivityFeedDrawer } from '@src/activity-feed/ActivityFeedDrawer';
import { captureActivityFeedIconClick } from '@src/fullStory';
import { FC, useState } from 'react';

interface PropTypes {
  hasUnreadUserActivity: boolean;
}

export const ActivityFeed: FC<PropTypes> = ({ hasUnreadUserActivity }) => {
  const [activityFeedDrawerOpen, setActivityFeedDrawerOpen] = useState<boolean>(false);

  return (
    <>
      <IconButton
        onClick={() => {
          setActivityFeedDrawerOpen(true);
          captureActivityFeedIconClick();
        }}
        sx={{ position: 'relative ' }}
        data-testid="activity-feed-icon"
      >
        <RssFeed htmlColor="black" />
        <Box
          sx={{ position: 'absolute', top: 0, right: 0, fontSize: 16 }}
          data-testid="indicator-icon"
        >
          <Zoom in={hasUnreadUserActivity}>
            <Circle htmlColor="red" fontSize="inherit" />
          </Zoom>
        </Box>
      </IconButton>
      <ActivityFeedDrawer
        open={activityFeedDrawerOpen}
        onClose={() => {
          setActivityFeedDrawerOpen(false);
        }}
      />
    </>
  );
};
