import { Button, Dialog, Stack, Typography, useTheme } from '@mui/material';
import React, { FC } from 'react';
import { Departure } from '@flashpack/graphql';
import { Form } from 'react-final-form';
import { TextField } from 'mui-rff';
import { ValidatorFactory } from '@src/design-system/forms/validators';
import { FormParser } from '@src/design-system/forms/parsers';

interface PropTypes {
  open: boolean;
  departure: Departure;
  onUpdate: ({ roomReleaseDays }: { roomReleaseDays: number }) => Promise<void>;
  onClose: () => void;
}

export const UpdateRoomReleaseDialog: FC<PropTypes> = (props) => {
  const { open, onUpdate, onClose, departure } = props;

  const { date } = departure;
  const theme = useTheme();

  return (
    <Dialog maxWidth="xs" fullWidth open={open} onClose={onClose}>
      <Stack padding={5}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Typography variant="pageHeader">Room release</Typography>
          <Typography variant="pageHeader" sx={{ color: theme.palette.principal.grey50 }}>
            •
          </Typography>
          <Typography variant="pageHeader">{date}</Typography>
        </Stack>
        <Form<{ roomReleaseDays: number }>
          initialValues={{ roomReleaseDays: departure.roomReleaseDays }}
          onSubmit={onUpdate}
          render={({ values, handleSubmit }) => {
            const daysSingular = values.roomReleaseDays === 1;

            return (
              <form onSubmit={(e) => void handleSubmit(e)}>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="flex-start"
                  sx={{ mt: 3, mb: 6 }}
                >
                  <TextField
                    fullWidth={false}
                    placeholder="e.g. 30"
                    InputProps={{ sx: { width: '100px' } }}
                    size="small"
                    name="roomReleaseDays"
                    fieldProps={{
                      validate: ValidatorFactory.createRequired('Required'),
                      parse: FormParser.parseNumber,
                    }}
                  />
                  <Typography sx={{ pt: 1 }} variant="bodyPara">
                    {`${daysSingular ? 'day' : 'days'} before departure date`}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                  <Button onClick={() => onClose()} variant="outlined">
                    Cancel
                  </Button>
                  <Button variant="contained" type="submit">
                    Update
                  </Button>
                </Stack>
              </form>
            );
          }}
        />
      </Stack>
    </Dialog>
  );
};
