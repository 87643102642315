import { LoadingButton } from '@mui/lab';
import { Box } from '@mui/system';
import { SkeletonBulletinList } from '@src/design-system/bulletin';
import { UserActivitiesDocument } from '@flashpack/graphql';
import { FC } from 'react';
import { ActivityFeedItem } from './ActivityFeedItem';
import { useTheme } from '@mui/material/styles';
import { useQuery } from '@apollo/client';
import { Typography } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { favoriteAdventureFilterString, FilterStates } from './ActivityFeedFilters';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

interface PropTypes {
  open: boolean;
  onClose: () => void;
  filterStates: FilterStates;
}

export const ActivityFeedList: FC<PropTypes> = ({ open, onClose, filterStates }) => {
  const theme = useTheme();

  const { data, loading, fetchMore } = useQuery(UserActivitiesDocument, {
    variables: {
      first: 10,
      adventureFilter: filterStates.adventure.selectedAdventure || null,
      userFilter: filterStates.user.selectedUser || null,
      typeFilter: filterStates.type.selectedType || null,
    },
    skip: !open, // Only execute the query when the component is open
    notifyOnNetworkStatusChange: true,
  });

  const iconStyling = {
    width: 80,
    height: 80,
    marginBottom: 2,
    color: 'gray',
  };

  const emptyStateTypographyStyling = {
    marginTop: '80px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingInline: '25%',
  };

  const showNoFavoritedAdventures =
    filterStates.adventure.selectedAdventure === favoriteAdventureFilterString &&
    data?.userActivities &&
    data?.userActivities.edges.length === 0;

  const showEmptyState =
    filterStates.adventure.selectedAdventure !== favoriteAdventureFilterString &&
    data?.userActivities &&
    data?.userActivities.edges.length === 0;

  return (
    <Box
      sx={{
        background: theme.palette.principal.grey30,
        padding: theme.spacing(2),
        textAlign: 'center',
        height: '100%',
      }}
      data-testid="user-activity-feed"
    >
      {loading && <SkeletonBulletinList count={10} />}
      {showNoFavoritedAdventures && (
        <Typography variant="bodySingle" sx={emptyStateTypographyStyling}>
          <StarBorderIcon sx={iconStyling} /> To create a personalised activity feed, just
          star Adventures in the Adventures menu
        </Typography>
      )}
      {showEmptyState && (
        <Typography variant="bodySingle" sx={emptyStateTypographyStyling}>
          <FilterAltIcon sx={iconStyling} /> No items found, change the filter parameters
        </Typography>
      )}
      {data?.userActivities &&
        data?.userActivities.edges.map((edge, index) => (
          <ActivityFeedItem
            key={index}
            {...edge.node}
            closeUserActivityDrawer={onClose}
          />
        ))}
      {data?.userActivities.pageInfo.hasNextPage && (
        <LoadingButton
          loading={loading}
          onClick={() =>
            void fetchMore({
              variables: {
                after: data?.userActivities.pageInfo.endCursor,
              },
            })
          }
        >
          Load more
        </LoadingButton>
      )}
    </Box>
  );
};
