import { styled } from '@mui/material/styles';
import { Tooltip } from '@mui/material';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const GreenTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: theme.spacing(27.25),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.brand.jungle,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.25),
  },
}));
