export type ImageSize = '100x100' | '1024x768' | '120x120' | '240x240' | 'x220' | 'x500';

/**
 * Populates the size placeholder in the provided image url.
 * @param imageUrl the url of the image containing the {size} placeholder
 * @param size size of the image that you can request. List of available values:
 * 100x100 — crop
 * 1024x768 — fit
 * 120x120 — crop
 * 240x240 — crop
 * x220 — fit-h
 * x500 — fit-h
 *
 *
 * crop - image is fit by the width, and is cut equally from the bottom and top till the middle part (of height's value)
 * fit-h - image is fit by the height
 * fit - image is fit into the rectangle of the size in question
 */
export const replaceImageSizePlaceholder = (
  imageUrl: string | undefined,
  size: ImageSize,
) => {
  return imageUrl?.replace('{size}', size);
};
