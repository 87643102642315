export const StopPropagationWrapper = (
  args: JSX.IntrinsicElements['span'] & { allowDefault?: boolean },
) => {
  const { allowDefault, ...rest } = args;
  return (
    <span
      onClick={(e) => {
        if (allowDefault !== true) {
          e.preventDefault();
        }
        e.stopPropagation();
        return false;
      }}
      {...rest}
    />
  );
};
