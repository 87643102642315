import { Button, Stack, Typography, styled } from '@mui/material';
import { Field, Form, FormSpy } from 'react-final-form';
import { TextField } from 'mui-rff';
import { DaySelector } from 'src/design-system/forms/day-selector/DaySelector';
import { composeValidators, Validator } from 'src/design-system/forms/validators';
import { LoadingButton } from '@mui/lab';
import { TimeField } from 'src/design-system/forms/time-field/TimeField';

import {
  engagementStartDayValidator,
  engagementStartTimeValidator,
  engagementEndTimeValidator,
  engagementEndDayValidator,
} from 'src/itinerary/common/engagementFormValidators';
import { hasServerValidationErrors } from 'src/shared/errorUtils';
import { Box, GenericError } from 'design-system';
import { ApolloError } from '@apollo/client';
import { ServerValidationErrors } from '@src/design-system/forms/ServerValidationErrors';
import { TransferMode } from '@flashpack/graphql';
import { FormState, ValidationErrors } from 'final-form';
import { useContext } from 'react';
import { SharedTabsContext } from '@src/shared/context/SharedTabsContext';
import isEqual from 'lodash.isequal';
import { TransferLength } from './TransferLength';
import { TransferModeSelector } from './TransferModeSelector';
import { AutocompleteField } from '@src/design-system/forms/autocomplete/AutocompleteField';
import { transferModeOptions } from './transferMode';
import { TransferContext } from '@src/shared/timeline/bulk-timeline-items/BulkEditTransferDrawer';
import { DayTime } from '@src/shared/timeline/bulk-timeline-items/utils';
import { DeleteEngagements } from '@src/itinerary/common/DeleteEngagements';

export interface CreateTransferFormValues {
  title: string;
  mode: TransferMode | 'Multiple';
  description: string;
  start: {
    // TODO: at some point, wrap this in a time object to be consistent with activities or vice versa
    day: DayTime['day'];
    time: DayTime['time'];
    location: string;
  };
  end: {
    day: DayTime['day'];
    time: DayTime['time'];
    location: string;
  };
}

export type EditTransferFormValues = CreateTransferFormValues & { ids: string[] };

interface PropTypes<T> {
  initialValues?: T;
  saveBtnLabel: string;
  onSubmit: (values: T) => Promise<void>;
  onCancel: () => void;
  error?: ApolloError;
  locked?: boolean;
  isBulk?: boolean;
  actionOnDelete?: () => void;
}

export const TransferForm = <T extends EditTransferFormValues | CreateTransferFormValues>(
  props: PropTypes<T>,
) => {
  const {
    onSubmit,
    onCancel,
    initialValues,
    saveBtnLabel,
    error,
    locked,
    isBulk,
    actionOnDelete,
  } = props;
  const { transferForm } = useContext(isBulk ? TransferContext : SharedTabsContext);

  if (error && !hasServerValidationErrors(error)) {
    return <GenericError error={error} />;
  }

  const shouldSubscribeToFormChanges = !!transferForm?.state;

  const onFormStateChange = (
    formState: FormState<EditTransferFormValues, Partial<EditTransferFormValues>>,
  ) => {
    if (!shouldSubscribeToFormChanges) {
      return;
    }
    // We avoid calls to the contextual setState if the data is unchanged
    if (isEqual(formState.values, transferForm.state)) {
      return;
    }
    transferForm.setHasPendingChanges(true);
    transferForm.setState({
      ...formState.values,
    });
  };

  return (
    <Form<T>
      mutators={{
        updateEndDay: ([startDay]: number[], state, tools) => {
          tools.changeValue(state, 'end.day', () => startDay);
        },
      }}
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={(values) => {
        const errors: ValidationErrors = {};
        if (!values.mode) {
          errors.mode = 'Please select a transfer type';
        }
        return errors;
      }}
      render={({ form, handleSubmit, submitting, valid, values }) => {
        return (
          <form onSubmit={(v) => void handleSubmit(v)}>
            <Stack direction="column" gap={2}>
              {isBulk ? (
                <AutocompleteField
                  options={[
                    ...transferModeOptions,
                    { value: 'Multiple', label: 'Multiple' },
                  ]}
                  fullWidth
                  getOptionValue={(option) => option.value}
                  label="Select transfer type"
                  name="mode"
                />
              ) : (
                <Field
                  name="mode"
                  fieldProps={{
                    validate: Validator.required,
                  }}
                  component={TransferModeSelector}
                />
              )}

              <Stack direction="column" gap={1} data-testid="transfer-start">
                <StyledHeader variant="bodySingle" sx={{ mb: 1 }}>
                  Depart
                </StyledHeader>
                <Stack direction="row" gap={1} data-testid="transfer-start">
                  <DaySelector
                    name="start.day"
                    readOnly={locked}
                    isMixed={values.start.day === 'Multiple'}
                    fieldProps={{
                      validate:
                        values.start.day === 'Multiple'
                          ? undefined
                          : composeValidators<number>(
                              Validator.required,
                              engagementStartDayValidator,
                            ),
                    }}
                  />
                  <TimeField
                    name="start.time"
                    label="Time (local)"
                    validate={
                      values.start.time === 'Multiple'
                        ? undefined
                        : engagementStartTimeValidator
                    }
                    readonly={locked}
                  />
                </Stack>
                <TextField
                  name="start.location"
                  label="Enter pick up location"
                  helperText=" "
                  fieldProps={{
                    validate: Validator.required,
                  }}
                  data-testid={'transfer-start-location'}
                  disabled={locked}
                  onFocus={(event) => event.target.select()}
                />
              </Stack>
              <Stack direction="column" gap={1} data-testid="transfer-end">
                <StyledHeader variant="bodySingle" sx={{ mb: 1 }}>
                  Arrive
                </StyledHeader>
                <Stack direction="row" gap={1} data-testid="transfer-end">
                  <DaySelector
                    name="end.day"
                    onChange={(_, value) => {
                      form.mutators.updateEndDay(value);
                    }}
                    isMixed={values.end.day === 'Multiple'}
                    readOnly={locked}
                    fieldProps={{
                      validate:
                        values.end.day === 'Multiple'
                          ? undefined
                          : composeValidators<number>(
                              Validator.required,
                              engagementEndDayValidator,
                            ),
                    }}
                  />
                  <TimeField
                    name="end.time"
                    label="Time (local)"
                    validate={
                      values.end.time === 'Multiple'
                        ? undefined
                        : engagementEndTimeValidator
                    }
                    readonly={locked}
                  />
                </Stack>

                <TextField
                  name="end.location"
                  label="Enter arrival destination"
                  helperText=" "
                  data-testid="transfer-end-location"
                  fieldProps={{
                    validate: Validator.required,
                  }}
                  disabled={locked}
                  onFocus={(event) => event.target.select()}
                />
              </Stack>
              <TransferLength start={values.start} end={values.end} mode={values.mode} />
              <Stack direction="column" gap={1}>
                <StyledHeader variant="bodySingle" sx={{ mb: 1 }}>
                  Additional details
                </StyledHeader>
                <TextField
                  name="description"
                  placeholder="Optional"
                  data-testid="transfer-description"
                  multiline
                  rows={5}
                  fieldProps={{
                    parse: (value: string) => (value === '' ? null : value),
                  }}
                  disabled={locked}
                  onFocus={(event) => event.target.select()}
                />
              </Stack>
              <ServerValidationErrors error={error} />
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mb={5}
              >
                {!locked && 'ids' in values ? (
                  <DeleteEngagements
                    engagementName="transfer"
                    engagementIds={values.ids}
                    actionOnDelete={actionOnDelete}
                  />
                ) : (
                  <Box />
                )}
                <Stack direction="row" alignItems="center" justifyContent="flex-end">
                  <Button onClick={onCancel} variant="outlined" sx={{ margin: 2 }}>
                    Cancel
                  </Button>
                  <LoadingButton
                    variant="contained"
                    type="button" // this is a button to avoid accidental form submission on enter
                    onClick={() => {
                      void form.submit();
                    }}
                    loading={submitting}
                    data-testid={'save-transfer'}
                    disabled={
                      (valid && !!transferForm && !transferForm?.hasPendingChanges) ||
                      submitting
                    }
                  >
                    {saveBtnLabel}
                  </LoadingButton>
                </Stack>
              </Stack>
            </Stack>
            <FormSpy
              subscription={{ values: true }}
              onChange={(
                props: FormState<EditTransferFormValues, Partial<EditTransferFormValues>>,
              ) => onFormStateChange(props)}
            />
          </form>
        );
      }}
    />
  );
};

const StyledHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.principal.grey70,
}));

TransferForm.defaultProps = {
  saveBtnLabel: 'Save Changes',
};

TransferForm.defaultProps = {
  saveBtnLabel: 'Save Changes',
  initialValues: {
    title: '',
    start: {
      day: null,
      time: '',
    },
    end: {
      day: null,
      time: '',
    },
  },
};
