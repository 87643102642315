import { Tabs, Tab } from '@mui/material';
import LinkBehavior from '@src/design-system/LinkBehaviour';
import { hasHandle, isString } from '@src/shared/hasHandle';
import { RoutePath } from '@src/shared/routePath';
import { useMatches } from 'react-router-dom';
import { ITINERARY_TAB_HANDLE, ItineraryTab } from './ItineraryHeader';
import { UserRole } from '@flashpack/graphql';
import { useAuthorization } from '@src/authentication/AuthorizationProvider';

export const ItineraryTabs = ({
  adventureId,
  itineraryId,
  showDepartures,
  showCostAndPricing,
}: {
  adventureId: string;
  itineraryId: string;
  showDepartures: boolean;
  showCostAndPricing: boolean;
}) => {
  const matches = useMatches();
  const itineraryTabMatch = matches.find(hasHandle(ITINERARY_TAB_HANDLE, isString));
  const itineraryTab = itineraryTabMatch?.handle.itineraryTab;
  const { currentUser } = useAuthorization();
  const isFlashpackUser =
    currentUser?.role === UserRole.Flashpack || currentUser?.role === UserRole.Superuser;

  return (
    <Tabs sx={{ marginTop: 2, marginBottom: 2 }} value={itineraryTab}>
      {showDepartures && (
        <Tab
          icon={ItineraryTab.DEPARTURES}
          value={ItineraryTab.DEPARTURES}
          href={RoutePath.ITINERARY_DEPARTURES.generatePath(adventureId, itineraryId)}
          LinkComponent={LinkBehavior}
          data-testid="departures-tab"
        />
      )}
      <Tab
        label={ItineraryTab.ITINERARY}
        value={ItineraryTab.ITINERARY}
        href={RoutePath.ITINERARY_TIMELINE.generatePath(adventureId, itineraryId)}
        LinkComponent={LinkBehavior}
        data-testid="itinerary-tab"
      />
      <Tab
        icon={ItineraryTab.PACK_LEADERS}
        value={ItineraryTab.PACK_LEADERS}
        href={RoutePath.ITINERARY_PACK_LEADERS.generatePath(adventureId, itineraryId)}
        LinkComponent={LinkBehavior}
        data-testid="pack-leaders-tab"
      />
      {showDepartures && showCostAndPricing && (
        <Tab
          icon={ItineraryTab.COSTS}
          value={ItineraryTab.COSTS}
          href={RoutePath.ITINERARY_COSTS.generatePath(
            adventureId,
            itineraryId,
            'passengersCost',
          )}
          LinkComponent={LinkBehavior}
          data-testid="costs-tab"
        />
      )}
      {showDepartures && showCostAndPricing && isFlashpackUser && (
        <Tab
          icon={ItineraryTab.PRICES}
          value={ItineraryTab.PRICES}
          href={RoutePath.ITINERARY_PRICES.generatePath(
            adventureId,
            itineraryId,
            'tripPrice',
          )}
          LinkComponent={LinkBehavior}
          data-testid="prices-tab"
        />
      )}
    </Tabs>
  );
};
