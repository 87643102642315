import { createContext, useContext, useEffect, useState } from 'react';
import * as FirebaseAuth from 'firebase/auth';
import { RoutePath } from '@src/shared/routePath';
import { RequestLoginMagicLinkDocument } from '@flashpack/graphql';
import { ApolloError, useMutation } from '@apollo/client';
import { isSignInWithEmailLink } from 'firebase/auth';
import * as Sentry from '@sentry/browser';

type AuthenticationContextType = {
  authenticated: boolean;
  loadingAuthentication: boolean;
};

export const AuthenticationContext = createContext<AuthenticationContextType>({
  loadingAuthentication: false,
  authenticated: false,
});

export const useAuthentication = () => {
  return useContext(AuthenticationContext);
};

const EMAIL_KEY = 'emailForSignIn';

const setSigninEmail = (email: string) => window.localStorage.setItem(EMAIL_KEY, email);
const getSigninEmail = () => window.localStorage.getItem(EMAIL_KEY);
const clearSigninEmail = () => window.localStorage.removeItem(EMAIL_KEY);

export const useFirebaseAuth = () => {
  // the currentUser is obtained asynchronously, which means if the currentUser is null, that might not be true because it might still be loading,
  // this property can be used to react accordingly, e.g block rendering in case knowing the actual state of the user is a must
  const [loadingAuthentication, setLoadingAuthentication] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [requestLoginMagicLink] = useMutation(RequestLoginMagicLinkDocument);

  const auth = FirebaseAuth.getAuth();

  useEffect(() => {
    Sentry.setTag('authenticated', authenticated);
  }, [authenticated]);

  useEffect(() => {
    const unsubscribe = FirebaseAuth.onAuthStateChanged(auth, () => {
      if (auth.currentUser) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
      setLoadingAuthentication(false);
    });

    return () => unsubscribe();
  }, [auth, setAuthenticated]);

  const signOut = async () => {
    setAuthenticated(false);
    await FirebaseAuth.signOut(auth);
  };

  const sendSignInLinkToEmail = async ({
    email,
    onSuccess,
    onUnknownUser,
    onUnexpectedError,
  }: {
    email: string;
    onSuccess: () => void;
    onUnknownUser: (message: string) => void;
    onUnexpectedError: (error: ApolloError) => void;
  }) => {
    const continueUrl = `${window.location.protocol}//${location.host}${RoutePath.LOGIN_CALLBACK.value}`;
    try {
      await requestLoginMagicLink({
        variables: {
          email,
          continueUrl,
        },
      });
      setSigninEmail(email);
      onSuccess();
    } catch (e) {
      const error = e as ApolloError;

      if (isUnknownUserError(error)) {
        return onUnknownUser(error.message);
      }
      onUnexpectedError(error);
    }
  };

  const isSignInLink = () => {
    return isSignInWithEmailLink(auth, window.location.href);
  };

  const signInWithCustomToken = async (token: string) => {
    await FirebaseAuth.setPersistence(auth, FirebaseAuth.browserLocalPersistence);
    await FirebaseAuth.signInWithCustomToken(auth, token);
  };

  const signInWithEmailLink = async () => {
    const cachedEmail = getSigninEmail();

    if (!cachedEmail) {
      throw new Error('Email missing, cannot sign in');
    }

    await FirebaseAuth.setPersistence(auth, FirebaseAuth.browserLocalPersistence);
    await FirebaseAuth.signInWithEmailLink(auth, cachedEmail, window.location.href);
    clearSigninEmail();
  };

  return {
    auth,
    signOut,
    isSignInLink,
    loadingAuthentication,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    signInWithCustomToken,
    authenticated,
  };
};

const isUnknownUserError = (error: ApolloError) => {
  return error.graphQLErrors?.find((e) => e.extensions.code === 'UNKNOWN_USER') ?? false;
};
