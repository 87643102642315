import { Box, Stack, Typography } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';
import { Message, UserRole } from '@flashpack/graphql';

import { FC, useEffect, useState } from 'react';
import { calculateCommentTime } from '@src/itinerary/common/comments/commentUtils';
import { FormattedText } from 'design-system';
import { commentAnchor } from '@src/shared/timeline/util';

export const SingleMessage: FC<Message & { hilight: boolean; index?: number }> = ({
  id,
  author,
  createdAt,
  content,
  hilight,
  index,
}) => {
  const [messageTime, setMessageTime] = useState<string>(() =>
    calculateCommentTime(createdAt, new Date()),
  );

  useEffect(() => {
    const updateCommentTime = () => {
      setMessageTime(calculateCommentTime(createdAt, new Date()));
    };

    const interval = setInterval(updateCommentTime, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [createdAt]);
  const isUserFlashpack = author?.role === UserRole.Flashpack;
  return (
    <CommentContainer
      data-testid={`thread-message-${index}`}
      id={commentAnchor({ id })}
      isFlashpack={isUserFlashpack}
      p={2}
      ml={{ md: isUserFlashpack ? 9 : 0, xs: isUserFlashpack ? 3 : 0 }}
      mr={{ md: !isUserFlashpack ? 9 : 0, xs: !isUserFlashpack ? 3 : 0 }}
      gap={1}
      hilight={hilight}
    >
      <Box display="flex" alignItems="baseline" gap={2}>
        <Typography variant="bodySingle">{author?.email}</Typography>
      </Box>
      <FormattedText sx={{ lineHeight: '20px', textAlign: 'left' }}>
        {content}
      </FormattedText>
      <Box display={'flex'} justifyContent="flex-end">
        {/* chromatic ignore element because its always changing due to its relative nature */}
        <Typography component="span" data-chromatic="ignore" variant="label">
          {messageTime}
        </Typography>
      </Box>
    </CommentContainer>
  );
};

const hilightAnimation = keyframes`
0% { outline-offset: 1px; outline-color: color-mix(in srgb, var(--outlineBaseColor) 5%, transparent); }
1% { outline-offset: 0px; outline-color: color-mix(in srgb, var(--outlineBaseColor) 10%, transparent); }
80% { outline-offset: 5px; outline-color: color-mix(in srgb, var(--outlineBaseColor) 43%, transparent); }
100% { outline-offset: 4px; outline-color: color-mix(in srgb, var(--outlineBaseColor) 10%, transparent); }
`;

const CommentContainer = styled(Stack, {
  shouldForwardProp: (propKey) => propKey !== 'isFlashpack' && propKey !== 'hilight',
})<{ isFlashpack: boolean; hilight: boolean }>(({ isFlashpack, theme, hilight }) => ({
  backgroundColor: isFlashpack
    ? theme.palette.brand.jungle
    : theme.palette.principal.grey50,
  color: isFlashpack ? theme.palette.principal.white : theme.palette.principal.black,
  borderRadius: '10px',
  outlineWidth: hilight ? '3px' : '0px',
  outlineStyle: 'solid',
  animationName: hilightAnimation,
  animationDuration: '0.6s',
  animationTimingFunction: 'ease-out',
  animationIterationCount: '3',
  animationFillMode: 'forwards',
  '--outlineBaseColor': theme.palette.system.green100,
}));
