import { FC, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  startOfDay,
  addDays,
  areIntervalsOverlapping,
  isSameMonth,
  isSameYear,
  isSameDay,
  format,
} from 'date-fns';
import { styled, Chip, Stack, FormHelperText } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Box } from '@mui/system';
import { DatePicker, HighlightInfo, SpecialDate } from '../date-picker/DatePicker';
import { ExclamationIcon } from 'design-system';

interface PropTypes {
  errorMessage?: string;
  selectedDates: Date[];
  specialDates?: SpecialDate[];
  fixedSelectedDates?: { duration: number; date: Date }[];
  itineraryDurationDays: number;
  longestAccommodationDuration: number;
  highlights: HighlightInfo[];
  onSelectedDatesChange: (value: Date[]) => void;
  currentDate?: Date;
}

export const MultiDatePicker: FC<PropTypes> = ({
  selectedDates,
  errorMessage,
  highlights,
  currentDate,
  itineraryDurationDays,
  longestAccommodationDuration,
  specialDates = [],
  fixedSelectedDates = [],
  onSelectedDatesChange,
}) => {
  const theme = useTheme();
  const [monthState, setMonthState] = useState(new Date().getMonth());
  const [yearState, setYearState] = useState(new Date().getFullYear());

  const onChange = (value: Date | null) => {
    if (!value) {
      return;
    }
    const arr = [...selectedDates];
    const date = startOfDay(value);
    const existingDate = findDate(arr, date);
    if (existingDate) {
      arr.splice(arr.indexOf(existingDate), 1);
    } else {
      arr.push(date);
    }
    onSelectedDatesChange(arr);
  };

  const findDate = (dates: Date[], date: Date) => {
    const dateTime = date.getTime();
    return dates.find((item) => item.getTime() === dateTime);
  };

  const renderDatePills = (values: { date: Date; duration: number }[]) => {
    return (
      <Box>
        {values.map((day) => {
          if (day.date.getMonth() == monthState && day.date.getFullYear() == yearState) {
            const overlapping = values.some((x) => {
              return (
                x != day &&
                areIntervalsOverlapping(
                  { start: x.date, end: addDays(x.date, longestAccommodationDuration) },
                  {
                    start: day.date,
                    end: addDays(day.date, longestAccommodationDuration),
                  },
                  { inclusive: true },
                ) == true
              );
            });

            return (
              <DatePill
                key={day.date.getTime()}
                title={formatDateString(day.date, addDays(day.date, day.duration))}
                overlapping={overlapping}
                iconColor={theme.palette.system.red100}
                onRemove={
                  fixedSelectedDates.some((x) => x === day)
                    ? undefined
                    : () => onChange(day.date)
                }
              />
            );
          }
        })}
      </Box>
    );
  };
  const datePills = renderDatePills([
    ...fixedSelectedDates,
    ...selectedDates.flatMap((e) => {
      return { date: e, duration: itineraryDurationDays };
    }),
  ]);

  function formatDateString(startDate: Date, endDate: Date): string {
    const endDateString = format(endDate, 'dd MMM yyyy');
    if (isSameDay(startDate, endDate)) {
      return endDateString;
    }
    if (isSameMonth(startDate, endDate) && isSameYear(startDate, endDate)) {
      return `${format(startDate, 'dd')} - ${endDateString}`;
    }
    if (isSameYear(startDate, endDate)) {
      return `${format(startDate, 'dd MMM')} - ${endDateString}`;
    }
    return `${format(startDate, 'dd MMM yyyy')} - ${endDateString}`;
  }

  return (
    <Stack direction="row" spacing={2}>
      <DatePicker
        isDaySelected={(day) =>
          !!(
            findDate(selectedDates, day) ||
            findDate(
              fixedSelectedDates.flatMap((e) => e.date),
              day,
            )
          )
        }
        isDayDisabled={(day) =>
          !!findDate(
            fixedSelectedDates.flatMap((e) => e.date),
            day,
          )
        }
        specialDates={specialDates}
        highlights={highlights}
        onChange={onChange}
        value={currentDate || selectedDates[0]}
        onMonthChange={(v: Date) => {
          setMonthState(v.getMonth());
          setYearState(v.getFullYear());
        }}
        onYearChange={(v: Date) => {
          setMonthState(v.getMonth());
          setYearState(v.getFullYear());
        }}
      />
      <div>
        <div>{datePills}</div>

        {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
      </div>
    </Stack>
  );
};

type DatePillProps = {
  onRemove?: () => void;
  title: string;
  key: number;
  overlapping?: boolean;
  iconColor?: string;
};

const DatePill = ({ title, onRemove, overlapping, iconColor }: DatePillProps) => (
  <StyledChip
    label={title}
    onDelete={onRemove}
    deleteIcon={onRemove ? <Close /> : undefined}
    icon={overlapping ? <ExclamationIcon style={{ color: iconColor }} /> : undefined}
  />
);

const StyledChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'selected',
})(({ theme }) => ({
  ...{
    backgroundColor: theme.palette.divider,
    color: theme.palette.common.black,
    fontWeight: theme.typography.captionPara.fontWeight,
    textTransform: 'uppercase',
    borderTopLeftRadius: '3px',
    borderBottomLeftRadius: '3px',
    borderTopRightRadius: '3px',
    borderBottomRightRadius: '3px',
    margin: '0.5em',
  },
}));
