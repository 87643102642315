import {
  isAfter,
  isBefore,
  isFuture,
  isValid,
  parseISO,
  startOfToday,
  subYears,
} from 'date-fns';
import { Decimal } from 'decimal.js';
import { FieldValidator } from 'final-form';
import {
  isValidPhoneNumber,
  validatePhoneNumberLength,
  formatIncompletePhoneNumber,
} from 'libphonenumber-js';

// eslint-disable-next-line
const VALID = undefined;

const createRequired =
  (errorMessage: string, minimumValue?: number) =>
  (value: Array<unknown> | number | string | null | undefined) => {
    if (minimumValue !== undefined && value !== undefined && !Number.isNaN(value)) {
      return (value as number) >= minimumValue ? VALID : errorMessage;
    }
    if (!isPresent(value)) {
      return errorMessage;
    }
    return VALID;
  };

/**
 *
 * @param afterDateFieldName {string} the name or dot-separated path of the field that the date must be after
 * @param errorMessage {string} the error message to display if the date is not after the specified date
 * @returns final-form field validator
 */
const createAfterDate: ({
  afterDateFieldName,
  errorMessage,
}: {
  afterDateFieldName: string;
  errorMessage: string;
}) => FieldValidator<string | null> =
  ({ afterDateFieldName, errorMessage }) =>
  (value, formValues) => {
    if (!value) {
      return;
    }
    const afterDate = (formValues as Record<string, string>)[afterDateFieldName] as
      | string
      | null
      | undefined;
    const date = parseISO(value);
    const min = parseISO(afterDate || '');
    if (date < min) {
      return errorMessage;
    }
    return VALID;
  };

const createMaxLength = (max: number) => (value: any) => {
  if (!value) {
    return;
  }
  if (value.length > max) {
    return `Must be ${max} characters or less`;
  }
  return VALID;
};

const createMaxNumber = (max: number) => (value: string | number) => {
  if (!value) {
    return;
  }
  const valueNumber = typeof value === 'string' ? parseFloat(value) : value;

  if (valueNumber > max) {
    return `Must be ${max} or less`;
  }
  return VALID;
};

const createMinNumber = (min: number) => (value: string | number) => {
  if (!value) {
    return;
  }
  const valueNumber = typeof value === 'string' ? parseFloat(value) : value;

  if (valueNumber < min) {
    return `Must be ${min} or more`;
  }
  return VALID;
};

const required = (value: Array<unknown> | string | null | undefined) => {
  const errorMessage = 'Required';
  if (!isPresent(value)) {
    return errorMessage;
  }
  return VALID;
};

const isPresent = (value: Array<unknown> | number | string | null | undefined) => {
  if (!value) {
    return false;
  }

  if (Array.isArray(value) && value.length === 0) {
    return false;
  }

  return true;
};

const validUrl = (text: string | null | undefined) => {
  if (!text) {
    return;
  }

  try {
    const url = new URL(text);

    if (['http:', 'https:', 'mailto:'].includes(url.protocol)) {
      return VALID;
    }
  } catch {
    // swallowing the error because we're only using it to check the validity of the url
  }

  return 'This URL does not appear to be valid.';
};

const validEmail = (email: string | null | undefined) => {
  if (!email) {
    return;
  }

  const valid = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  if (valid) {
    return VALID;
  } else {
    return 'The email address is not valid';
  }
};

const validPassword = (password: string | null | undefined) => {
  if (!password) {
    return;
  }

  const valid = password.length >= 6;

  if (valid) {
    return VALID;
  } else {
    return 'Password must contain at least 6 characters.';
  }
};

const validDecimal = (value: string | null | undefined) => {
  if (!value) {
    return;
  }
  const regex = /^\d+(\.\d{1,6})?$/; // regex to allow up to 6 decimal points
  if (!regex.test(value)) {
    return 'Please enter number to 6 decimal places';
  }
  if (new Decimal(value).equals(0)) {
    return 'Please enter a number greater than 0';
  }
  return VALID;
};

export const composeValidators =
  <T>(...validators: Array<FieldValidator<T>>) =>
  (value: T, formValues: object) =>
    validators.reduce(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      (error, validator) => error || validator(value, formValues),
      VALID,
    );

const dateNotInTheFuture = (dateString: string | null) => {
  if (!dateString || !isValid(parseISO(dateString))) {
    return 'The date is not valid';
  }
  const date = parseISO(dateString);

  if (isFuture(date)) {
    return 'Cannot be in the future';
  }

  return VALID;
};

const dateNotInThePast = (dateString: string | null) => {
  if (!dateString || !isValid(parseISO(dateString))) {
    return 'The date is not valid';
  }
  const date = parseISO(dateString);

  if (isBefore(date, startOfToday())) {
    return 'Cannot be in the past';
  }

  return VALID;
};

const isNotNegativeNumber = (value: number) => {
  if (value < 0) {
    return 'Please enter a positive number';
  }
  return VALID;
};

const validPhoneNumber = (v: string | undefined) => {
  if (v) {
    const formatted = formatIncompletePhoneNumber(v);
    const formattedNationalNumber = formatted.split(' ').slice(1).join(' ');

    if (!formattedNationalNumber) {
      return 'Required';
    }

    const lengthValidation = validatePhoneNumberLength(v);
    if (lengthValidation === 'TOO_LONG') {
      return 'Number too long';
    }
    if (lengthValidation === 'TOO_SHORT') {
      return 'Number too short';
    }

    if (!isValidPhoneNumber(v)) {
      return 'Not a valid number';
    }
  }
};

const validFlightDesignator = (
  flightDesignator: string | null | undefined,
): string | undefined => {
  if (!flightDesignator) {
    return 'Flight code is required';
  }

  // Regex pattern for flight designators:
  // 1. airlineDesignator: 1-3 letters (optionally wrapped in parentheses)
  // 2. optional space
  // 3. flightNumber: 1-4 digits
  // 4. optional operationalSuffix: 1 letter
  // *note - in the code and IATA standards, the concatenated code is called "flight designator"
  // but in the UI it might have a different name; adjust the error message accordingly
  const designatorPattern =
    /^(?<airlineDesignator>[A-Za-z]{1,3})[ ]?(?<flightNumber>\d{1,4})(?<operationalSuffix>[A-Za-z]?)$/;

  const match = flightDesignator.match(designatorPattern);

  if (match) {
    const {
      airlineDesignator: _airlineDesignator = '',
      flightNumber: _flightNumber = '',
      operationalSuffix: _operationalSuffix = '',
    } = match.groups as {
      airlineDesignator?: string;
      flightNumber?: string;
      operationalSuffix?: string;
    };

    return VALID;
  }

  return 'Format not recognised e.g. AA 1234';
};

const dateIsInFlashpackerRange = (dateString: string | null) => {
  // is only valid if the given date would mean a user is between 30 and 50 years old
  if (!dateString || !isValid(parseISO(dateString))) {
    return 'The date is not valid';
  }
  const date = parseISO(dateString);

  const minDate = subYears(new Date(), 60);
  const maxDate = subYears(new Date(), 30);

  if (isBefore(date, minDate) || isAfter(date, maxDate)) {
    return 'You must be age 30-59 to travel with Flash Pack';
  }

  return VALID;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const Validator: Record<string, (value: any) => typeof VALID | string> = {
  dateNotInThePast,
  dateNotInTheFuture,
  dateIsInFlashpackerRange,
  required,
  validUrl,
  validEmail,
  validPassword,
  validDecimal,
  isNotNegativeNumber,
  validPhoneNumber,
  validFlightDesignator,
};

export const ValidatorFactory = {
  createRequired,
  createAfterDate,
  createMaxLength,
  createMaxNumber,
  createMinNumber,
};
