import { Box, Skeleton } from '@mui/material';
import { SkeletonBulletinList } from '@src/design-system/bulletin';

export const ItineraryDetailsPageSkeleton = () => {
  return (
    <Box mx={10} marginY={1}>
      <Skeleton width={130} height={40} />
      <SkeletonBulletinList count={10} />
    </Box>
  );
};
