import { FC, useEffect, useState } from 'react';
import { GenericError, AccommodationIcon } from 'design-system';
import { AccommodationFieldsFragment, AccommodationType } from '@flashpack/graphql';
import { ItineraryDrawer } from 'src/itinerary/common/ItineraryDrawer';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { Box, Tab } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { AccommodationCommentsTab } from './tabs/AccommodationCommentsTab';
import { SharedTabsContextProvider } from '../../shared/context/SharedTabsContextProvider';
import {
  AccommodationFormState,
  CommentFormState,
} from '../../shared/context/SharedTabsContext';
import { AccommodationInfoTab } from './tabs/AccommodationInfoTab';
import {
  getDescription,
  getHotelId,
  getRoomDescriptorSingle,
  getRoomDescriptorTwin,
  isRateHawkAccommodation,
} from '@src/shared/accommodation/utils';
import isEqual from 'lodash.isequal';
import { DraftPropertyMulti } from './tabs/SelectPropertyTab';
import { deepStripTypename } from '../activity/common/activityFormMapper';
import { useCommentsTabOrDefault } from '@src/shared/timeline/util';

interface PropTypes {
  getAccommodation: (id: string) => AccommodationFieldsFragment;
  isTimelineLocked?: boolean;
  isEditable: boolean;
}

export const ViewEditAccommodationPage: FC<PropTypes> = ({
  getAccommodation,
  isTimelineLocked,
  isEditable,
}) => {
  const { accommodationId } = useRequiredParams(['accommodationId']);

  const [selectedTab, setSelectedTab] = useCommentsTabOrDefault('information');
  const handleTabClick = (event: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const accommodation = getAccommodation(accommodationId);

  const initialAccommodationFormValues = getInitialAccommodationFormValues(accommodation);

  const [accommodationFormState, setAccommodationFormState] =
    useState<AccommodationFormState>(initialAccommodationFormValues);
  const [accommodationFormHasPendingChanges, setAccommodationFormHasPendingChanges] =
    useState<boolean>(false);
  const [draftProperty, setDraftProperty] = useState<DraftPropertyMulti | null>(null);

  const [commentFormState, setCommentFormState] = useState<CommentFormState>({
    content: '',
  });

  useEffect(() => {
    setAccommodationFormState(getInitialAccommodationFormValues(accommodation));
    setAccommodationFormHasPendingChanges(false);
  }, [accommodationId, accommodation]);

  if (!accommodation) {
    return (
      <GenericError error="Accommodation was not provided, cannot render ViewAccommodation page" />
    );
  }

  const shouldWarnForUnsavedChanges =
    commentFormState.content !== '' ||
    !isEqual(accommodationFormState, initialAccommodationFormValues);

  return (
    <Box>
      <ItineraryDrawer
        warnForChangesNotSaved={shouldWarnForUnsavedChanges}
        title="Accommodation"
        icon={<AccommodationIcon sx={{ width: 18, height: 18 }} />}
      >
        <SharedTabsContextProvider
          value={{
            commentForm: {
              state: commentFormState,
              setState: setCommentFormState,
            },
            accommodationForm: {
              state: accommodationFormState,
              setState: setAccommodationFormState,
              hasPendingChanges: accommodationFormHasPendingChanges,
              setHasPendingChanges: setAccommodationFormHasPendingChanges,
              draftProperty: draftProperty,
              setDraftProperty: setDraftProperty,
            },
          }}
        >
          <TabContext value={selectedTab}>
            <TabList sx={{ marginBottom: 2 }} onChange={handleTabClick}>
              <Tab
                label="Information"
                value="information"
                data-testid="information-tab"
              />
              <Tab label="Comments" value="comments" data-testid="comments-tab" />
            </TabList>
            <TabPanel value="information" sx={{ padding: 0 }}>
              <AccommodationInfoTab
                accommodation={accommodation}
                isTimelineLocked={isTimelineLocked}
                isEditable={isEditable}
              />
            </TabPanel>
            <TabPanel value="comments" sx={{ padding: 0 }}>
              <AccommodationCommentsTab accommodation={accommodation} />
            </TabPanel>
          </TabContext>
        </SharedTabsContextProvider>
      </ItineraryDrawer>
    </Box>
  );
};

const getInitialAccommodationFormValues = (
  accommodation: AccommodationFieldsFragment,
): AccommodationFormState => {
  const { checkIn, checkOut } = accommodation;

  return {
    checkIn: deepStripTypename(checkIn),
    checkOut: deepStripTypename(checkOut),
    roomDescriptorTwin: getRoomDescriptorTwin(accommodation),
    roomDescriptorSingle: getRoomDescriptorSingle(accommodation),
    allocationTwinRoom: accommodation.allocationTwinRoom,
    allocationSingleRoom: accommodation.allocationSingleRoom,
    notes: accommodation.notes,
    hotelId: getHotelId(accommodation),
    description: getDescription(accommodation),
    type: isRateHawkAccommodation(accommodation)
      ? AccommodationType.RateHawk
      : AccommodationType.Manual,
  };
};
