import { LoadingButton } from '@mui/lab';
import { Stack, FormHelperText } from '@mui/material';
import { Validator } from '@src/design-system/forms/validators';
import { showErrorOnBlur, TextField } from 'mui-rff';
import { Form, FormSpy } from 'react-final-form';
import { AddCommentToThreadDocument } from '@flashpack/graphql';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { FormState, FORM_ERROR } from 'final-form';
import {
  SharedTabsContext,
  CommentFormState,
} from '@src/shared/context/SharedTabsContext';
import { useContext } from 'react';
import { useMutation } from '@apollo/client';

type PropTypes = {
  commentThreadId: string;
};

export const AddComment = ({ commentThreadId }: PropTypes) => {
  const [addCommentToThread] = useMutation(AddCommentToThreadDocument);

  const { commentForm } = useContext(SharedTabsContext);
  const shouldSubscribeToFormChanges = !!commentForm;

  const { safeMutation } = useSafeMutation();
  const onSubmit = async (
    { content }: { content: string },
    { restart }: { restart: () => void },
  ) => {
    const { error } = await safeMutation(
      addCommentToThread({ variables: { content, commentThreadId } }),
      {
        onSuccess: restart,
      },
    );
    if (error) {
      return { [FORM_ERROR]: 'Failed to save comment' };
    }
    if (shouldSubscribeToFormChanges) {
      commentForm.setState({ content: '' });
    }
  };

  const commentValue = commentForm?.state.content ?? '';

  const onFormStateChange = (
    formState: FormState<Record<string, string>, Partial<Record<string, string>>>,
  ) => {
    if (!shouldSubscribeToFormChanges) {
      return;
    }
    // We avoid calls to the contextual setState if the data is unchanged
    if (formState.values.content === commentValue) {
      return;
    }
    const formStateValues: CommentFormState = {
      content: formState.values.content ?? '',
    };
    commentForm.setState(formStateValues);
  };

  return (
    <Form<CommentFormState>
      initialValues={{ content: commentValue }}
      onSubmit={onSubmit}
      render={({ submitError, modifiedSinceLastSubmit, submitting, handleSubmit }) => (
        <form onSubmit={(event) => void handleSubmit(event)}>
          <Stack alignItems="flex-end" justifyContent="center">
            <TextField
              name="content"
              multiline
              helperText=" "
              rows={3}
              placeholder="Write comment here..."
              fieldProps={{ validate: Validator.required }}
              showError={showErrorOnBlur}
              sx={{
                '& .MuiInputBase-root': {
                  borderRadius: '2px',
                  borderColor: 'principal.grey70',
                },
                '& .MuiFormHelperText-root': {
                  fontSize: 'micro.fontSize',
                  lineHeight: '13px',
                  marginTop: '3px',
                },
              }}
              data-testid="comment-field"
              inputProps={{
                'data-testid': 'comment-field-textarea',
              }}
            />
            {shouldSubscribeToFormChanges && (
              <FormSpy
                subscription={{ values: true }}
                onChange={(props) => onFormStateChange(props)}
              />
            )}
            {!modifiedSinceLastSubmit && submitError && (
              <FormHelperText sx={{ py: 0, height: '16px' }} error>
                Failed to save comment
              </FormHelperText>
            )}
            <LoadingButton
              onClick={(e) => void handleSubmit(e)}
              loading={submitting}
              variant="contained"
              data-testid="post-comment-button"
            >
              Send
            </LoadingButton>
          </Stack>
        </form>
      )}
    ></Form>
  );
};
