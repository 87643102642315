import { ActivityIcon } from 'design-system';
import { AddEngagementToTimelineDocument } from '@flashpack/graphql';
import { mapFormValuesToCreateActivity } from 'src/itinerary/activity/common/activityFormMapper';
import { ItineraryDrawer } from 'src/itinerary/common/ItineraryDrawer';
import { useToast } from 'src/shared/toast/useToast';
import { useRouting } from 'src/shared/useRouting';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useTimelineContext } from '@src/shared/timeline/TimelineContext';
import { TimelineDocument } from '@flashpack/graphql';
import { useMutation } from '@apollo/client';
import {
  CreateActivityFormValues,
  ViewEditActivityForm,
} from '../view-activity/ViewEditActivityForm';

export const AddActivityPage = () => {
  const [addEngagement, { error }] = useMutation(AddEngagementToTimelineDocument);
  const { success, error: errorToast } = useToast();
  const { navigate } = useRouting();
  const { timelineId } = useTimelineContext();
  const { safeMutation } = useSafeMutation();

  const navigateToTimeline = () => navigate('../');

  const onSubmit = async (values: CreateActivityFormValues) => {
    await safeMutation(
      addEngagement({
        variables: {
          timelineId,
          engagement: mapFormValuesToCreateActivity(values),
        },
        refetchQueries: [TimelineDocument],
      }),
      {
        onSuccess: () => {
          success('Activity successfully created');
          navigateToTimeline();
        },
        onUnexpectedError: () => {
          errorToast('There was an issue while saving this activity - try again later');
        },
        onServerValidationError: (error) => {
          errorToast(error.message);
        },
      },
    );
  };

  return (
    <ItineraryDrawer
      title="Activity"
      icon={<ActivityIcon />}
      warnForChangesNotSaved={true}
    >
      <ViewEditActivityForm<CreateActivityFormValues>
        onSubmit={onSubmit}
        onCancel={navigateToTimeline}
        error={error}
        locked={false}
      />
    </ItineraryDrawer>
  );
};
