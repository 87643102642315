import { Menu, MenuItem, Typography } from '@mui/material';
import { Dispatch, FC, SetStateAction } from 'react';
import { TableData, TableRowContextOption } from './Table';

interface PropTypes {
  options: TableRowContextOption[];
  isOpen: boolean;
  anchorEl: HTMLElement | null;
  handleClose: () => void;
  handleAction: (
    action: (
      targetRowIndex: number,
      table: TableData,
      setFormValues: Dispatch<SetStateAction<TableData>>,
    ) => void,
  ) => void;
}

export const TableRowContextMenu: FC<PropTypes> = ({
  options,
  isOpen,
  anchorEl,
  handleClose,
  handleAction,
}) => {
  return (
    <Menu id="long-menu" anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
      {options.map((option) => (
        <MenuItem
          key={option.label}
          onClick={() => {
            handleClose();
            handleAction(option.onClick);
          }}
        >
          {option.icon}
          <Typography sx={{ marginLeft: 1 }}>{option.label}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
};
