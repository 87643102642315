import { Button, Stack, Typography } from '@mui/material';
import { TextButton } from 'design-system';
import { ConfirmationDialog } from '@src/design-system/dialogs/ConfirmationDialog';
import { FC } from 'react';

interface PropTypes {
  open: boolean;
  onClose: () => void;
  numberOfDepartures: number;
  targetItineraryDescription: string;
  deselectAll: () => void;
  handleMoveSelectedDepartures: (copyTargetTimeline: boolean) => Promise<void>;
}

export const MoveDeparturesDialog: FC<PropTypes> = ({
  open,
  onClose,
  numberOfDepartures,
  targetItineraryDescription,
  deselectAll,
  handleMoveSelectedDepartures,
  ...props
}) => {
  const title = `Update ${numberOfDepartures} departure${
    numberOfDepartures > 1 ? 's' : ''
  } with new itinerary?`;

  const dialogBodyText = `By selecting "Update Itinerary" departures will inherit the itinerary from ${targetItineraryDescription}`;

  const updateItinerary = (copyTargetTimeline: boolean) => {
    void handleMoveSelectedDepartures(copyTargetTimeline);
    onClose();
  };

  return (
    <ConfirmationDialog
      {...props}
      open={open}
      onCancel={onClose}
      title={title}
      hasCustomActions={true}
      maxWidth={'sm'}
    >
      <Typography variant="bodyPara">{dialogBodyText}</Typography>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <TextButton onClick={() => onClose()}>Cancel</TextButton>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Button onClick={() => updateItinerary(false)} variant="outlined">
            Keep Existing
          </Button>
          <Button onClick={() => updateItinerary(true)} variant="contained">
            Update Itinerary
          </Button>
        </Stack>
      </Stack>
    </ConfirmationDialog>
  );
};
