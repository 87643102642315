import { Challenge } from '@flashpack/graphql';
import { ReactElement } from 'react';
import {
  DepartureBoard,
  DirectionsBike,
  Hiking,
  Kayaking,
  Landscape,
  NightsStay,
  Pets,
  Pool,
  Snowmobile,
  Thunderstorm,
} from '@mui/icons-material';

type ChallengeMapItem = {
  label: string;
  icon: ReactElement;
};

export const challengeMap: Record<Challenge, ChallengeMapItem> = {
  [Challenge.ChangeableWeather]: {
    label: 'Changeable weather',
    icon: <Thunderstorm />,
  },
  [Challenge.Cycling]: {
    label: 'Cycling',
    icon: <DirectionsBike />,
  },
  [Challenge.ExtendedTravelTime]: {
    label: 'Extended travel time',
    icon: <DepartureBoard />,
  },
  [Challenge.Heights]: {
    label: 'Heights',
    icon: <Landscape />,
  },
  [Challenge.Overnight]: {
    label: 'Overnight',
    icon: <NightsStay />,
  },
  [Challenge.SpecializedTransport]: {
    label: 'Specialised transport',
    icon: <Snowmobile />,
  },
  [Challenge.StrenuousActivity]: {
    label: 'Strenuous activity',
    icon: <Hiking />,
  },
  [Challenge.Swimming]: {
    label: 'Swimming',
    icon: <Pool />,
  },
  [Challenge.WildAnimals]: {
    label: 'Wild animals',
    icon: <Pets />,
  },
  [Challenge.Paddlesports]: {
    label: 'Paddlesports',
    icon: <Kayaking />,
  },
};
