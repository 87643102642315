import {
  AccommodationType,
  EditAccommodationsDocument,
  UserRole,
  HotelDetailsDocument,
  Maybe,
  RoomGroup,
  AdventureWithItineraryAndDepartureTimelinesDocument,
  AccommodationFieldsForComparisonFragment,
} from '@flashpack/graphql';
import { Box, Stack, Button, Typography, CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { ItineraryDrawer } from '@src/itinerary/common/ItineraryDrawer';
import {
  DraftPropertyMulti,
  SelectPropertyTab,
} from '@src/itinerary/view-accommodation/tabs/SelectPropertyTab';
import { AccommodationFormState } from '@src/shared/context/SharedTabsContext';
import { AccommodationIcon, FreeTextMuiRff, GenericError } from 'design-system';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { useMutation, useQuery } from '@apollo/client';
import { useToast } from '@src/shared/toast/useToast';
import { hasServerValidationErrors } from '@src/shared/errorUtils';
import { HotelCard } from '@src/hotels/hotels-overview/HotelCard';
import { Protected } from '@src/authentication/Protected';
import { RatehawkAccommodationFields } from '@src/itinerary/add-accommodation/add-ratehawk-accommodation/AddRatehawkAccommodation';
import { ManualAccommodationFields } from '@src/itinerary/add-accommodation/add-manual-accommodation/AddManualAccommodation';
import { ServerValidationErrors } from '@src/design-system/forms/ServerValidationErrors';
import { DeleteAccommodations } from '@src/itinerary/edit-accommodation/DeleteAccommodations';
import { FormState } from 'final-form';
import isEqual from 'lodash.isequal';
import {
  extractInfoFromFormValues,
  extractInfoFromRateHawkProperty,
} from '@src/shared/accommodation/utils';
import {
  MULTIPLE_SINGLE_ROOM_TYPES,
  MULTIPLE_TWIN_ROOM_TYPES,
  getFormValuesFromAccommodations,
  getPayloadForEditAccommodations,
} from './utils';

type PropTypes = {
  accommodations: AccommodationFieldsForComparisonFragment[];
  setAccommodations: Dispatch<SetStateAction<AccommodationFieldsForComparisonFragment[]>>;
};

export const BulkEditAccommodationDrawer: FC<PropTypes> = ({
  accommodations,
  setAccommodations,
}) => {
  const { safeMutation } = useSafeMutation();
  const [editAccommodations, { error }] = useMutation(EditAccommodationsDocument);
  const { success: successToast, error: errorToast } = useToast();

  const [formValues, setFormValues] = useState(
    getFormValuesFromAccommodations(accommodations),
  );
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const [isChangingProperty, setIsChangingProperty] = useState(false);
  const [propertyIdForSearch, setPropertyIdForSearch] = useState(formValues.hotelId);

  useEffect(() => {
    setFormValues(getFormValuesFromAccommodations(accommodations));
    setHasPendingChanges(false);
  }, [accommodations]);

  useEffect(() => {
    setPropertyIdForSearch(formValues.hotelId);
  }, [formValues.hotelId]);

  const hotelDetails = useQuery(HotelDetailsDocument, {
    variables: {
      id: propertyIdForSearch ?? '',
    },
    skip: !propertyIdForSearch,
  });

  const onFormStateChange = (
    formState: FormState<AccommodationFormState, Partial<AccommodationFormState>>,
  ) => {
    // We avoid calls to the contextual setState if the data is unchanged
    if (isEqual(formState.values, formValues)) {
      return;
    }
    setHasPendingChanges(true);
    setFormValues(formState.values);
  };

  const handleSubmit = useCallback(
    async (formData: AccommodationFormState) => {
      const accommodationPayload = getPayloadForEditAccommodations(
        accommodations.map((a) => a.id),
        formData,
      );
      await safeMutation(
        editAccommodations({
          variables: {
            input: accommodationPayload,
          },
          refetchQueries: [AdventureWithItineraryAndDepartureTimelinesDocument],
        }),
        {
          onSuccess: () => {
            setHasPendingChanges(false);
            successToast(`${accommodations.length} accommodations successfully updated`);
          },
          onUnexpectedError: () => {
            errorToast(
              `There was an issue while saving ${accommodations.length > 1 ? 'these accommodations' : 'this accommodation'} - try again later`,
            );
          },
          onServerValidationError: (error) => {
            errorToast(error.message);
          },
        },
      );
    },
    [accommodations, editAccommodations, errorToast, safeMutation, successToast],
  );

  const handlePropertySelection = (selectedProperty: DraftPropertyMulti) => {
    setIsChangingProperty(false);
    setFormValues({
      ...formValues,
      hotelId: selectedProperty.ratehawkProperty?.id ?? null,
      description: selectedProperty.manualProperty?.name ?? null,
      roomDescriptorSingle: '',
      roomDescriptorTwin: '',
      type: selectedProperty.ratehawkProperty
        ? AccommodationType.RateHawk
        : AccommodationType.Manual,
    });
  };

  const drawerTitle = `${accommodations.length} Accommodation${accommodations.length > 1 ? 's' : ''} Selected`;

  if (hotelDetails.loading) {
    return (
      <ItineraryDrawer
        title={drawerTitle}
        icon={<AccommodationIcon sx={{ width: 18, height: 18 }} />}
        handleClose={() => {
          setAccommodations([]);
        }}
      >
        <Box mt={5} textAlign="center">
          <CircularProgress />
        </Box>
      </ItineraryDrawer>
    );
  }

  if (hotelDetails.error) {
    return (
      <ItineraryDrawer
        title={drawerTitle}
        icon={<AccommodationIcon sx={{ width: 18, height: 18 }} />}
        handleClose={() => {
          setAccommodations([]);
        }}
      >
        <GenericError error={hotelDetails.error} />
      </ItineraryDrawer>
    );
  }

  const hotel = hotelDetails?.data?.hotel;

  const hotelInfo = hotel
    ? extractInfoFromRateHawkProperty(hotel)
    : extractInfoFromFormValues(formValues);

  return (
    <ItineraryDrawer
      title={drawerTitle}
      icon={<AccommodationIcon sx={{ width: 18, height: 18 }} />}
      handleClose={() => {
        setAccommodations([]);
      }}
      warnForChangesNotSaved={hasPendingChanges}
    >
      {isChangingProperty && (
        <SelectPropertyTab
          cancelPropertySelect={() => setIsChangingProperty(false)}
          onPropertySelect={handlePropertySelection}
        />
      )}
      {!isChangingProperty && (
        <Form
          initialValues={formValues}
          onSubmit={handleSubmit}
          validate={({ allocationSingleRoom, allocationTwinRoom }) => {
            const errors: {
              allocationSingleRoom?: string;
              allocationTwinRoom?: string;
            } = {};
            if (!allocationSingleRoom && !allocationTwinRoom) {
              errors.allocationSingleRoom = 'required';
              errors.allocationTwinRoom = 'required';
            }
            return errors;
          }}
          render={({ handleSubmit, submitting, dirtySinceLastSubmit, valid, form }) => {
            if (error && !hasServerValidationErrors(error)) {
              return <GenericError error={error} />;
            }
            return (
              <form onSubmit={(form) => void handleSubmit(form)}>
                <HotelCard hotelInfo={hotelInfo} view="full" />
                <Protected roles={[UserRole.Flashpack, UserRole.Dmc]}>
                  <Stack direction="row" justifyContent="flex-end">
                    <Button
                      onClick={() => {
                        setIsChangingProperty(true);
                      }}
                      sx={{ px: 0 }}
                    >
                      <Typography variant="bodySingle">Change Property</Typography>
                    </Button>
                  </Stack>
                </Protected>
                {/* TODO: use context in the future to have all the bulk-update-related values available to all the components that need them without needing to pass them down the chain of components */}
                {hotel ? (
                  <RatehawkAccommodationFields
                    rooms={(hotel?.room_groups ?? []).filter(
                      (g: Maybe<RoomGroup>): g is RoomGroup => !!g,
                    )}
                    isBulk
                    isRoomDescriptorTwinMixed={
                      formValues.roomDescriptorTwin === MULTIPLE_TWIN_ROOM_TYPES
                    }
                    isRoomDescriptorSingleMixed={
                      formValues.roomDescriptorSingle === MULTIPLE_SINGLE_ROOM_TYPES
                    }
                    isCheckInDayMixed={formValues.checkIn.day === 'Multiple'}
                    isCheckOutDayMixed={formValues.checkOut.day === 'Multiple'}
                  />
                ) : (
                  <ManualAccommodationFields
                    skipName
                    isBulk
                    areAllAccomodationsManual={
                      formValues.type === AccommodationType.Manual
                    }
                    isCheckInDayMixed={formValues.checkIn.day === 'Multiple'}
                    isCheckOutDayMixed={formValues.checkOut.day === 'Multiple'}
                  />
                )}
                <Protected roles={[UserRole.Flashpack]}>
                  <Typography sx={{ color: 'principal.grey70', my: 2 }}>Notes</Typography>
                  <FreeTextMuiRff
                    name="notes"
                    rows={4}
                    disabled
                    multiline
                    variant="outlined"
                    inputProps={{ 'data-testid': 'notes' }}
                    helperText="Notes are only visible to FlashPack employees"
                  />
                </Protected>
                {!dirtySinceLastSubmit && <ServerValidationErrors error={error} />}
                <Protected roles={[UserRole.Flashpack, UserRole.Dmc]}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={5}
                    mb={7}
                  >
                    <DeleteAccommodations
                      accommodationIds={accommodations.map(({ id }) => id)}
                    />
                    <Stack direction="row" gap={1} justifyContent="end">
                      <Button
                        type="reset"
                        onClick={() => {
                          setAccommodations([]);
                        }}
                        data-testid="view-edit-accommodation-cancel-button"
                      >
                        Cancel
                      </Button>
                      <LoadingButton
                        loading={submitting}
                        disabled={!hasPendingChanges || !valid || submitting}
                        type="button" // this is a button to avoid accidental form submission on enter
                        onClick={() => {
                          void form.submit();
                        }}
                        variant="contained"
                        color="success"
                        data-testid="view-edit-accommodation-save-button"
                      >
                        Save Changes
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Protected>

                <FormSpy
                  onChange={(
                    props: FormState<
                      AccommodationFormState,
                      Partial<AccommodationFormState>
                    >,
                  ) => onFormStateChange(props)}
                />
              </form>
            );
          }}
        />
      )}
    </ItineraryDrawer>
  );
};
