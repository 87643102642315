import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { CheckIcon2 } from '../../icons';
import { Stack } from '../../containers';

export type DropdownOptionItem = {
  label: string;
  secondaryLabel?: string;
  value: string;
};

type DropdownOptionProps = React.HTMLAttributes<HTMLLIElement> & {
  option: DropdownOptionItem;
  selected: boolean;
};

export const DropdownOption: React.FC<DropdownOptionProps> = ({
  option,
  selected,
  ...restProps
}) => {
  return (
    <StyledOption {...restProps}>
      <Stack gap={0.5} flexGrow={1}>
        <Typography variant="Body M">{option.label}</Typography>
        {option.secondaryLabel && (
          <Typography variant="Additional">{option.secondaryLabel}</Typography>
        )}
      </Stack>
      {selected && <CheckIcon2 sx={{ ml: 1, width: 14 }} />}
    </StyledOption>
  );
};

const StyledOption = styled('li')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  borderLeft: '4px solid transparent',
  backgroundColor: 'transparent',
  padding: '8px 16px 8px 12px',

  // selected
  '&&[aria-selected="true"]': {
    backgroundColor: theme.palette.system.green10,
    borderLeftColor: theme.palette.brand.jungle,
    color: theme.palette.brand.jungle,
  },
  // focused, or hovered
  '&&.Mui-focused': {
    backgroundColor: theme.palette.principal.grey30,
    borderLeftColor: theme.palette.principal.grey70,
  },
  '&&.Mui-focused[aria-selected="true"]': {
    backgroundColor: theme.palette.system.green10,
    borderLeftColor: theme.palette.brand.jungle,
  },
}));

export default DropdownOption;
