import {
  CreateEngagement,
  EditEngagementsInput,
  EngagementFieldsFragment,
  TransferMode,
} from '@flashpack/graphql';
import { CreateTransferFormValues, EditTransferFormValues } from './TransferForm';
import {
  getFormValuesFromTransfers,
  getPayloadForEditTransfers,
} from '@src/shared/timeline/bulk-timeline-items/utils';

// TODO: refactor to remove this function and use getPayloadForEditTransfers directly
const toEditTransferInput = (values: EditTransferFormValues): EditEngagementsInput => {
  return getPayloadForEditTransfers(values);
};

const toCreateTransferInput = (values: CreateTransferFormValues): CreateEngagement => {
  return {
    title: values.title,
    start: {
      location: values.start.location,
      time: {
        day: values.start.day as number,
        time: values.start.time,
      },
    },
    end: {
      location: values.end.location,
      time: {
        day: values.end.day as number,
        time: values.end.time,
      },
    },
    transfer: {
      mode: values.mode as TransferMode,
      description: values.description,
    },
  };
};

// TODO: refactor to remove this function and use getFormValuesFromTransfers directly
const toEditTransferFormValues = (
  transferEngagement: EngagementFieldsFragment,
): EditTransferFormValues => {
  if (!transferEngagement?.transfer) {
    throw Error("Can't map transfer to form values, transfer fields are missing!");
  }
  return getFormValuesFromTransfers([transferEngagement]);
};

export const TransferMapper = {
  toEditTransferFormValues,
  toCreateTransferInput,
  toEditTransferInput,
};
