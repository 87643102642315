import { useMutation } from '@apollo/client';
import { Stack } from '@mui/system';
import { EngagementFieldsFragment, FlagEngagementDocument } from '@flashpack/graphql';
import { CommentsSection } from '@src/itinerary/common/comments/CommentsSection';
import { FlagSwitch } from '@src/itinerary/common/FlagSwitch';
import { FC, useCallback } from 'react';

type PropTypes = {
  engagement: EngagementFieldsFragment;
};

export const EngagementCommentsTab: FC<PropTypes> = ({ engagement }) => {
  const [flagEngagementMutation] = useMutation(FlagEngagementDocument);
  const { flagged } = engagement;
  const onChange = useCallback(
    async ({ flagged }: { flagged: boolean }) => {
      if (!engagement.id) {
        return;
      }

      return flagEngagementMutation({
        variables: { engagement: { id: engagement.id, flagged } },
      });
    },
    [engagement?.id, flagEngagementMutation],
  );
  const onChangeFlagged = (flagged: boolean) => onChange({ flagged });

  return (
    <Stack sx={{ marginY: 5 }} gap={2}>
      <CommentsSection threadId={engagement.commentThread.id} />
      <FlagSwitch flagged={flagged} onChange={onChangeFlagged} />
    </Stack>
  );
};
