
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Accommodation": [
      "ManualAccommodation",
      "RateHawkAccommodation"
    ],
    "AuthorizedUser": [
      "AdventureManagerUser",
      "MobileAppUser"
    ],
    "RoomInterface": [
      "ManualRoom",
      "RoomGroup"
    ],
    "UserActivityReference": [
      "Adventure",
      "Comment",
      "Departure",
      "Engagement",
      "Itinerary",
      "ManualAccommodation",
      "Message",
      "MessageThread",
      "MissingUserActivityReference",
      "PackLeader",
      "RateHawkAccommodation",
      "User"
    ]
  }
};
      export default result;
    