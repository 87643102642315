import { FC, useCallback, useContext, useState } from 'react';
import { GenericError, FreeTextMuiRff } from 'design-system';
import {
  AccommodationFieldsFragment,
  EditAccommodationsInput,
  Maybe,
  RoomGroup,
  UserRole,
  TimelineDocument,
  HotelDetailsDocument,
  EditAccommodationsDocument,
  DepartureHeaderQueryDocument,
} from '@flashpack/graphql';
import { useRequiredParams } from '@src/shared/useRequiredParams';
import { CircularProgress, Stack, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Form, FormSpy } from 'react-final-form';
import {
  extractInfoFromManualProperty,
  extractInfoFromRateHawkProperty,
  isRateHawkAccommodation,
  mergeAccommodationWithDraftProperty,
} from '@src/shared/accommodation/utils';
import { ServerValidationErrors } from '@src/design-system/forms/ServerValidationErrors';
import { hasServerValidationErrors } from '@src/shared/errorUtils';
import { useRouting } from '@src/shared/useRouting';
import { useSafeMutation } from '@src/shared/useSafeMutation';
import { RatehawkAccommodationFields } from '@src/itinerary/add-accommodation/add-ratehawk-accommodation/AddRatehawkAccommodation';
import { ManualAccommodationFields } from '@src/itinerary/add-accommodation/add-manual-accommodation/AddManualAccommodation';
import { DeleteAccommodations } from '@src/itinerary/edit-accommodation/DeleteAccommodations';
import {
  AccommodationFormState,
  SharedTabsContext,
} from '@src/shared/context/SharedTabsContext';
import { FormState } from 'final-form';
import isEqual from 'lodash.isequal';
import { Protected } from '@src/authentication/Protected';
import { HotelCard } from '@src/hotels/hotels-overview/HotelCard';
import { DraftPropertyMulti, SelectPropertyTab } from './SelectPropertyTab';
import { WarningMessage } from '@src/design-system/warning-message/WarningMessage';
import { TimelineContext } from '@src/shared/timeline/TimelineContext';
import { useMutation, useQuery } from '@apollo/client';
import { useToast } from '@src/shared/toast/useToast';

type PropTypes = {
  accommodation: AccommodationFieldsFragment;
  isTimelineLocked?: boolean;
  isEditable: boolean;
};

const lockedTimelineMessage =
  'This itinerary has departures running and cannot be edited.';

export const AccommodationInfoTab: FC<PropTypes> = ({
  accommodation: defaultAccommodation,
  isTimelineLocked,
  isEditable,
}) => {
  const { adventureId, itineraryId } = useRequiredParams(['adventureId', 'itineraryId']);

  const { success: successToast, error: errorToast } = useToast();

  const { accommodationForm } = useContext(SharedTabsContext);
  const { routeLocation } = useContext(TimelineContext);
  const shouldSubscribeToFormChanges = !!accommodationForm;

  const [isChangingProperty, setIsChangingProperty] = useState(false);

  const accommodation = accommodationForm?.draftProperty
    ? mergeAccommodationWithDraftProperty(
        defaultAccommodation,
        accommodationForm.draftProperty,
      )
    : defaultAccommodation;

  const onFormStateChange = (
    formState: FormState<AccommodationFormState, Partial<AccommodationFormState>>,
  ) => {
    if (!shouldSubscribeToFormChanges) {
      return;
    }
    // We avoid calls to the contextual setState if the data is unchanged
    if (isEqual(formState.values, accommodationForm.state)) {
      return;
    }
    accommodationForm.setHasPendingChanges(true);
    accommodationForm.setState({
      ...formState.values,
      notes: formState.values.notes ?? '',
    });
  };

  const handleEnableChangeProperty = () => {
    setIsChangingProperty(true);
  };

  const handlePropertySelection = (selectedProperty: DraftPropertyMulti) => {
    setIsChangingProperty(false);
    if (accommodationForm) {
      accommodationForm.setDraftProperty(selectedProperty);
    }

    if (shouldSubscribeToFormChanges) {
      accommodationForm.setState((state) => {
        return {
          ...state,
          type: selectedProperty.type,
          hotelId: selectedProperty.ratehawkProperty?.id ?? null,
          description: selectedProperty.manualProperty?.name ?? null,
          roomDescriptorSingle: '',
          roomDescriptorTwin: '',
        };
      });
    }
  };

  const { navigate } = useRouting();
  const { safeMutation } = useSafeMutation();

  const propertyIdForSearch: string | undefined = isRateHawkAccommodation(accommodation)
    ? accommodation.hotel?.id
    : '';
  const hotelDetails = useQuery(HotelDetailsDocument, {
    variables: {
      id: propertyIdForSearch ?? '',
    },
    skip: !propertyIdForSearch,
  });
  const [editAccommodations, { error }] = useMutation(EditAccommodationsDocument);
  const handleClose = useCallback(() => {
    if (!accommodation?.id || !itineraryId || !adventureId) {
      return;
    }
    navigate(routeLocation);
  }, [accommodation?.id, itineraryId, adventureId, navigate, routeLocation]);

  const handleSubmit = useCallback(
    async (formData: AccommodationFormState) => {
      const accommodationPayload: EditAccommodationsInput = {
        ids: [defaultAccommodation.id],
        allocationTwinRoom: formData.allocationTwinRoom as number,
        allocationSingleRoom: formData.allocationSingleRoom as number,
        checkIn: {
          day: formData.checkIn.day as number,
          time: formData.checkIn.time,
        },
        checkOut: {
          day: formData.checkOut.day as number,
          time: formData.checkOut.time,
        },
        notes: formData.notes,
        description: formData.description,
        hotelId: formData.hotelId,
        type: formData.type,
        roomDescriptorSingle: formData.roomDescriptorSingle,
        roomDescriptorTwin: formData.roomDescriptorTwin,
      };

      await safeMutation(
        editAccommodations({
          variables: {
            input: accommodationPayload,
          },
          refetchQueries: [TimelineDocument, DepartureHeaderQueryDocument],
        }),
        {
          onSuccess: () => {
            successToast('Accommodation successfully edited');
            accommodationForm?.setHasPendingChanges(false);
          },
          onError: () => {
            errorToast(
              'There was an issue while saving this accommodation - try again later',
            );
          },
        },
      );
    },
    [
      accommodationForm,
      defaultAccommodation.id,
      editAccommodations,
      safeMutation,
      successToast,
      errorToast,
    ],
  );

  if (hotelDetails.error) {
    return <GenericError error={hotelDetails.error} />;
  }

  if (hotelDetails.loading) {
    return <CircularProgress />;
  }

  const hotel = hotelDetails?.data?.hotel;

  const hotelInfo = hotel
    ? extractInfoFromRateHawkProperty(hotel)
    : extractInfoFromManualProperty(accommodation);

  if (isChangingProperty) {
    return (
      <SelectPropertyTab
        cancelPropertySelect={() => setIsChangingProperty(false)}
        onPropertySelect={handlePropertySelection}
      />
    );
  }

  return (
    <Form
      initialValues={accommodationForm?.state}
      onSubmit={handleSubmit}
      validate={({ checkOut, checkIn, allocationSingleRoom, allocationTwinRoom }) => {
        const errors: {
          allocationSingleRoom?: string;
          allocationTwinRoom?: string;
          checkOut?: { day: string };
          checkIn?: { day: string };
        } = {};
        if (!allocationSingleRoom && !allocationTwinRoom) {
          errors.allocationSingleRoom = 'required';
          errors.allocationTwinRoom = 'required';
        }
        if (checkIn?.day >= checkOut?.day) {
          errors.checkOut = { day: 'Must be after check in' };
          errors.checkIn = { day: 'Must be before check out' };
        }
        return errors;
      }}
      render={({ handleSubmit, submitting, dirtySinceLastSubmit, valid, form }) => {
        if (error && !hasServerValidationErrors(error)) {
          return <GenericError error={error} />;
        }
        return (
          <form onSubmit={(form) => void handleSubmit(form)}>
            <HotelCard hotelInfo={hotelInfo} view="full" />
            {isTimelineLocked ? null : (
              <Protected roles={[UserRole.Flashpack, UserRole.Dmc]}>
                <Stack direction="row" justifyContent="flex-end">
                  <Button onClick={handleEnableChangeProperty} sx={{ px: 0 }}>
                    <Typography variant="bodySingle">Change Property</Typography>
                  </Button>
                </Stack>
              </Protected>
            )}
            {isTimelineLocked && (
              <WarningMessage message={lockedTimelineMessage} iconType="LOCKED" />
            )}
            {isRateHawkAccommodation(accommodation) ? (
              <RatehawkAccommodationFields
                rooms={(hotel?.room_groups ?? []).filter(
                  (g: Maybe<RoomGroup>): g is RoomGroup => !!g,
                )}
                readonly={isTimelineLocked || !isEditable}
              />
            ) : (
              <ManualAccommodationFields
                readonly={isTimelineLocked || !isEditable}
                skipName
              />
            )}
            <Protected roles={[UserRole.Flashpack]}>
              <Typography sx={{ color: 'principal.grey70', my: 2 }}>Notes</Typography>
              <FreeTextMuiRff
                label="Notes"
                name="notes"
                rows={4}
                multiline
                variant="outlined"
                inputProps={{ 'data-testid': 'notes' }}
                helperText="Notes are only visible to FlashPack employees"
              />
            </Protected>
            {!dirtySinceLastSubmit && <ServerValidationErrors error={error} />}
            <Protected roles={[UserRole.Flashpack, UserRole.Dmc]}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mt={5}
                mb={7}
              >
                {!isTimelineLocked ? (
                  <DeleteAccommodations
                    accommodationIds={[accommodation.id]}
                    actionOnDelete={() => navigate('../')}
                  />
                ) : (
                  // This is a hack to maintain the flexbox layout when the delete
                  // button is hidden
                  <div></div>
                )}
                <Stack direction="row" gap={1} justifyContent="end">
                  <Button
                    type="reset"
                    onClick={handleClose}
                    data-testid="view-edit-accommodation-cancel-button"
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={submitting}
                    disabled={
                      (valid && !accommodationForm?.hasPendingChanges) || submitting
                    }
                    type="button" // this is a button to avoid accidental form submission on enter
                    onClick={() => {
                      void form.submit();
                    }}
                    variant="contained"
                    color="success"
                    data-testid="view-edit-accommodation-save-button"
                  >
                    Save Changes
                  </LoadingButton>
                </Stack>
              </Stack>
            </Protected>
            {shouldSubscribeToFormChanges && (
              <FormSpy
                onChange={(
                  props: FormState<
                    AccommodationFormState,
                    Partial<AccommodationFormState>
                  >,
                ) => onFormStateChange(props)}
              />
            )}
          </form>
        );
      }}
    />
  );
};
