import React, { FC } from 'react';
import { ToastContainerProps, useToastContainer } from 'react-toastify';
import {
  Alert,
  AlertColor,
  Slide,
  SlideProps,
  Snackbar as MuiSnackbar,
} from '@mui/material';
import { ExclamationIcon, SuccessIcon, FailureIcon } from 'design-system';

export const Snackbar: FC<ToastContainerProps> = (props) => {
  const { getToastToRender, containerRef, isToastActive } = useToastContainer(props);

  return (
    <div ref={containerRef}>
      {getToastToRender((position, toastList) => {
        return (
          <div key={`container-${position}`}>
            {toastList.map(({ content, props: toastProps }) => {
              const closeToast = toastProps.closeToast;

              return (
                <MuiSnackbar
                  open={isToastActive(toastProps.toastId)}
                  key={`toast-${toastProps.key}`}
                  onClose={closeToast}
                  autoHideDuration={4000}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  TransitionProps={{
                    onExited: toastProps.deleteToast,
                  }}
                  TransitionComponent={SlideDown}
                  sx={{ maxWidth: 560 }}
                >
                  <Alert
                    onClose={closeToast}
                    severity={toastProps.type as AlertColor}
                    variant="filled"
                    sx={{ width: '100%' }}
                    icon={getIcon(toastProps.type)}
                  >
                    <>{content}</>
                  </Alert>
                </MuiSnackbar>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export function getIcon(type: string) {
  switch (type) {
    case 'success':
      return <SuccessIcon />;
    case 'warning':
      return <ExclamationIcon />;
    case 'error':
      return <FailureIcon />;
    default:
      return <></>;
  }
}

Snackbar.displayName = 'Snackbar';

function SlideDown(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}
