import {
  AccommodationFieldsForComparisonFragment,
  AccommodationType,
  AdventureWithItineraryAndDepartureTimelinesQuery,
  Departure,
  EditAccommodationsInput,
  EditEngagementsInput,
  EngagementFieldsForComparisonFragment,
} from '@flashpack/graphql';
import { EditTransferFormValues } from '@src/itinerary/transfer/common/TransferForm';
import { EditActivityFormValues } from '@src/itinerary/activity/view-activity/ViewEditActivityForm';
import {
  getDescription,
  getHotelId,
  getRoomDescriptorSingle,
  getRoomDescriptorTwin,
  isRateHawkAccommodation,
} from '@src/shared/accommodation/utils';
import { AccommodationFormState } from '@src/shared/context/SharedTabsContext';
import { format, parseISO, add, compareAsc } from 'date-fns';
import { isActivityChallengesDifferent } from '@src/departures/adjustments/utils';

export const MULTIPLE_TWIN_ROOM_TYPES = 'Multiple twin room types';
export const MULTIPLE_SINGLE_ROOM_TYPES = 'Multiple single room types';

export type DayTime = {
  day: number | 'Multiple';
  time: string;
};

export const calculateDateFromDepartureAndDay = (
  departureDate: Departure['date'],
  day: number,
) => {
  if (day < 0) {
    throw new Error('Day must be a positive number');
  }
  const formattedDepartureDate = parseISO(departureDate);
  return format(add(formattedDepartureDate, { days: day - 1 }), 'dd MMM');
};

export type AccommodationFieldsFragmentWithDepartureDate = {
  departureDate: Departure['date'];
  accommodation: AccommodationFieldsForComparisonFragment;
};

export type EngagementFieldsFragmentWithDeparture = {
  departureDate: Departure['date'];
  engagement: EngagementFieldsForComparisonFragment;
};

export const getEventsFromAdventureDepartures = (
  adventure?: AdventureWithItineraryAndDepartureTimelinesQuery['adventure'],
  itinearyId?: string,
) => {
  const accommodations: AccommodationFieldsFragmentWithDepartureDate[] = [];
  const transfers: EngagementFieldsFragmentWithDeparture[] = [];
  const activities: EngagementFieldsFragmentWithDeparture[] = [];

  if (!adventure) {
    return { accommodations, transfers, activities };
  }

  adventure.itineraries.forEach((itinerary) => {
    if (itinearyId && itinerary.id !== itinearyId) {
      return;
    }
    itinerary.departures.forEach((departure) => {
      departure.timeline.accommodations.forEach((accommodation) => {
        accommodations.push({
          departureDate: departure.date,
          accommodation,
        });
      });
      departure.timeline.engagements.forEach((engagement) => {
        if (engagement.activity) {
          activities.push({
            departureDate: departure.date,
            engagement,
          });
        }
        if (engagement.transfer) {
          transfers.push({
            departureDate: departure.date,
            engagement,
          });
        }
      });
    });
  });

  accommodations.sort((a, b) =>
    compareAsc(parseISO(a.departureDate), parseISO(b.departureDate)),
  );
  transfers.sort((a, b) =>
    compareAsc(parseISO(a.departureDate), parseISO(b.departureDate)),
  );
  activities.sort((a, b) =>
    compareAsc(parseISO(a.departureDate), parseISO(b.departureDate)),
  );

  return { accommodations, transfers, activities };
};

// TODO: at some point, when start and end objects match between activies and transfers, refactor out the common engagement parts into a separate function
export const getFormValuesFromActivities = (
  engagements: EngagementFieldsForComparisonFragment[],
): EditActivityFormValues => {
  return {
    ids: engagements.map((e) => e.id),
    title: engagements.every((e) => e.title === engagements[0].title)
      ? engagements[0].title
      : 'Multiple',
    start: {
      time: {
        day: engagements.every(
          (e) => e.start?.time.day === engagements[0].start?.time.day,
        )
          ? engagements[0].start?.time.day
          : 'Multiple',
        time: engagements.every(
          (e) => e.start?.time.time === engagements[0].start?.time.time,
        )
          ? engagements[0].start?.time.time
          : 'Multiple',
      },
      location: engagements.every(
        (e) => e.start?.location === engagements[0].start?.location,
      )
        ? engagements[0].start?.location
        : 'Multiple',
    },
    end: {
      time: {
        day: engagements.every((e) => e.end?.time.day === engagements[0].end?.time.day)
          ? engagements[0].end?.time.day
          : 'Multiple',
        time: engagements.every((e) => e.end?.time.time === engagements[0].end?.time.time)
          ? engagements[0].end?.time.time
          : 'Multiple',
      },
      location: engagements.every((e) => e.end?.location === engagements[0].end?.location)
        ? engagements[0].end?.location
        : 'Multiple',
    },
    activity: {
      description: engagements.every(
        (e) => e.activity?.description === engagements[0].activity?.description,
      )
        ? engagements[0].activity?.description
        : 'Multiple',
      foodAndDrink: engagements.every(
        (e) => e.activity?.foodAndDrink === engagements[0].activity?.foodAndDrink,
      )
        ? engagements[0].activity?.foodAndDrink
        : 'Multiple',
      challenges: engagements.some((e) =>
        isActivityChallengesDifferent(
          e.activity?.challenges,
          engagements[0].activity?.challenges,
        ),
      )
        ? 'Multiple'
        : engagements[0].activity?.challenges,
      mealIncluded: engagements.every(
        (e) => e.activity?.mealIncluded === engagements[0].activity?.mealIncluded,
      )
        ? engagements[0].activity?.mealIncluded || false
        : 'Multiple',
    },
  };
};

export const getFormValuesFromAccommodations = (
  accommodations: AccommodationFieldsForComparisonFragment[],
): AccommodationFormState => {
  return {
    description:
      accommodations.every(
        (a) => getDescription(a) === getDescription(accommodations[0]),
      ) && accommodations.every((a) => getHotelId(a) === getHotelId(accommodations[0]))
        ? getDescription(accommodations[0])
        : 'Multiple properties',
    hotelId: accommodations.every((a) => getHotelId(a) === getHotelId(accommodations[0]))
      ? getHotelId(accommodations[0])
      : null,
    type: accommodations.every((a) => isRateHawkAccommodation(a))
      ? AccommodationType.RateHawk
      : accommodations.some((a) => isRateHawkAccommodation(a))
        ? AccommodationType.Mixed
        : AccommodationType.Manual,
    checkIn: {
      day: accommodations.every((a) => a.checkIn?.day === accommodations[0].checkIn?.day)
        ? accommodations[0].checkIn?.day ?? 0
        : 'Multiple',
      time: accommodations.every(
        (a) => a.checkIn?.time === accommodations[0].checkIn?.time,
      )
        ? accommodations[0].checkIn?.time ?? ''
        : 'Multiple',
    },
    checkOut: {
      day: accommodations.every(
        (a) => a.checkOut?.day === accommodations[0].checkOut?.day,
      )
        ? accommodations[0].checkOut?.day ?? 0
        : 'Multiple',
      time: accommodations.every(
        (a) => a.checkOut?.time === accommodations[0].checkOut?.time,
      )
        ? accommodations[0].checkOut?.time ?? ''
        : 'Multiple',
    },
    roomDescriptorTwin: accommodations.every(
      (a) => getRoomDescriptorTwin(a) === getRoomDescriptorTwin(accommodations[0]),
    )
      ? getRoomDescriptorTwin(accommodations[0])
      : MULTIPLE_TWIN_ROOM_TYPES,
    roomDescriptorSingle: accommodations.every(
      (a) => getRoomDescriptorSingle(a) === getRoomDescriptorSingle(accommodations[0]),
    )
      ? getRoomDescriptorSingle(accommodations[0])
      : MULTIPLE_SINGLE_ROOM_TYPES,
    allocationTwinRoom: accommodations.every(
      (a) => a.allocationTwinRoom === accommodations[0].allocationTwinRoom,
    )
      ? accommodations[0].allocationTwinRoom
      : 'Multiple',
    allocationSingleRoom: accommodations.every(
      (a) => a.allocationSingleRoom === accommodations[0].allocationSingleRoom,
    )
      ? accommodations[0].allocationSingleRoom
      : 'Multiple',
    notes: accommodations.every((a) => a.notes === accommodations[0].notes)
      ? accommodations[0].notes
      : 'Multiple notes',
  };
};

export const getPayloadForEditAccommodations = (
  accommodationIds: string[],
  formData: AccommodationFormState,
): EditAccommodationsInput => {
  return {
    ids: accommodationIds,
    hotelId:
      formData.description === 'Multiple properties' ? undefined : formData.hotelId,
    description:
      formData.description === 'Multiple properties' ? undefined : formData.description,
    roomDescriptorSingle:
      formData.roomDescriptorSingle === MULTIPLE_SINGLE_ROOM_TYPES
        ? undefined
        : formData.roomDescriptorSingle,
    roomDescriptorTwin:
      formData.roomDescriptorTwin === MULTIPLE_TWIN_ROOM_TYPES
        ? undefined
        : formData.roomDescriptorTwin,
    allocationTwinRoom:
      formData.allocationTwinRoom === 'Multiple'
        ? undefined
        : (formData.allocationTwinRoom as number),
    allocationSingleRoom:
      formData.allocationSingleRoom === 'Multiple'
        ? undefined
        : (formData.allocationSingleRoom as number),
    checkIn: {
      day: formData.checkIn.day === 'Multiple' ? undefined : formData.checkIn.day,
      time: formData.checkIn.time === 'Multiple' ? undefined : formData.checkIn.time,
    },
    checkOut: {
      day: formData.checkOut.day === 'Multiple' ? undefined : formData.checkOut.day,
      time: formData.checkOut.time === 'Multiple' ? undefined : formData.checkOut.time,
    },
    type: formData.type === AccommodationType.Mixed ? undefined : formData.type,
  };
};

export const getFormValuesFromTransfers = (
  transfers: EngagementFieldsForComparisonFragment[],
): EditTransferFormValues => {
  return {
    ids: transfers.map((t) => t.id),
    title: transfers.every((t) => t.title === transfers[0].title)
      ? transfers[0].title
      : 'Multiple',
    mode: transfers.every((t) => t.transfer!.mode === transfers[0].transfer!.mode)
      ? transfers[0].transfer!.mode
      : 'Multiple',
    description: transfers.every(
      (t) => t.transfer!.description === transfers[0].transfer!.description,
    )
      ? transfers[0].transfer!.description ?? ''
      : 'Multiple',
    start: {
      day: transfers.every((t) => t.start.time.day === transfers[0].start.time.day)
        ? transfers[0].start.time.day
        : 'Multiple',
      time: transfers.every((t) => t.start.time.time === transfers[0].start.time.time)
        ? transfers[0].start.time.time
        : 'Multiple',
      location: transfers.every((t) => t.start.location === transfers[0].start.location)
        ? transfers[0].start.location
        : 'Multiple',
    },
    end: {
      day: transfers.every((t) => t.end.time.day === transfers[0].end.time.day)
        ? transfers[0].end.time.day
        : 'Multiple',
      time: transfers.every((t) => t.end.time.time === transfers[0].end.time.time)
        ? transfers[0].end.time.time
        : 'Multiple',
      location: transfers.every((t) => t.end.location === transfers[0].end.location)
        ? transfers[0].end.location
        : 'Multiple',
    },
  };
};

export const getPayloadForEditTransfers = (
  formData: EditTransferFormValues,
): EditEngagementsInput => {
  return {
    ids: formData.ids,
    title: formData.title === 'Multiple' ? undefined : formData.title,
    start: {
      location:
        formData.start.location === 'Multiple' ? undefined : formData.start.location,
      time: {
        day: formData.start.day === 'Multiple' ? undefined : formData.start.day,
        time: formData.start.time === 'Multiple' ? undefined : formData.start.time,
      },
    },
    end: {
      location: formData.end.location === 'Multiple' ? undefined : formData.end.location,
      time: {
        day: formData.end.day === 'Multiple' ? undefined : formData.end.day,
        time: formData.end.time === 'Multiple' ? undefined : formData.end.time,
      },
    },
    transfer: {
      mode: formData.mode === 'Multiple' ? undefined : formData.mode,
      description: formData.description === 'Multiple' ? undefined : formData.description,
    },
  };
};

export const getPayloadForEditActivities = (
  formData: EditActivityFormValues,
): EditEngagementsInput => {
  const endLocation =
    formData.end.location === 'Multiple'
      ? undefined
      : formData.end.location ??
        (formData.start.location === 'Multiple' ? undefined : formData.start.location);

  return {
    ids: formData.ids,
    activity: {
      description:
        formData.activity?.description === 'Multiple'
          ? undefined
          : formData.activity?.description,
      foodAndDrink:
        formData.activity?.foodAndDrink === 'Multiple'
          ? undefined
          : formData.activity?.foodAndDrink,
      challenges:
        formData.activity?.challenges === 'Multiple'
          ? undefined
          : formData.activity?.challenges,
      mealIncluded:
        formData.activity?.mealIncluded === 'Multiple'
          ? undefined
          : formData.activity?.mealIncluded,
    },
    end: {
      location: endLocation,
      time: {
        day: formData.end.time.day === 'Multiple' ? undefined : formData.end.time.day,
        time: formData.end.time.time === 'Multiple' ? undefined : formData.end.time.time,
      },
    },
    start: {
      location:
        formData.start.location === 'Multiple' ? undefined : formData.start.location,
      time: {
        day: formData.start.time.day === 'Multiple' ? undefined : formData.start.time.day,
        time:
          formData.start.time.time === 'Multiple' ? undefined : formData.start.time.time,
      },
    },
    title: formData.title === 'Multiple' ? undefined : formData.title,
  };
};
