import { FC } from 'react';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Box, Typography, Container, Stack } from '@mui/material';
import { ApolloError } from '@apollo/client';
import { BackButton } from '../buttons/BackButton';

interface PropTypes {
  error: ApolloError | string;
  homePageLink?: string;
}

export const GenericError: FC<PropTypes> = ({ error, homePageLink }) => {
  const title = 'Error occurred';
  const message = error instanceof ApolloError ? error.message : error;

  return (
    <Box sx={{ mt: 5, display: 'flex', alignItems: 'center' }}>
      <Container maxWidth="sm">
        <Stack gap={2} direction="column" alignItems="center">
          <ErrorOutlineIcon
            sx={{ color: 'red' }}
            fontSize="large"
            data-testid="error-icon"
          />
          <Typography variant="bodySingle" data-testid="error-message-title">
            {title}
          </Typography>
          {message && (
            <Typography
              align="center"
              variant="bodyPara"
              data-testid="error-message-description"
            >
              {message}
            </Typography>
          )}
          {homePageLink && (
            <BackButton href={homePageLink} data-testid="error-home-page-link">
              Navigate to the Home page
            </BackButton>
          )}
        </Stack>
      </Container>
    </Box>
  );
};
