import { Typography } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import { ChainedText } from 'design-system';
import { FC } from 'react';
import { ActivityFeedIcon } from './ActivityFeedIcon';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { generateActivityFeedItemUrl } from './utils';
import { UserActivityAction, UserActivityFieldsFragment } from '@flashpack/graphql';
import { ActivityFeedExpression } from './ActivityFeedExpression';
import { Circle } from '@mui/icons-material';

export type ActivityFeedItemProps = UserActivityFieldsFragment & {
  closeUserActivityDrawer: () => void;
};

export const ActivityFeedItem: FC<ActivityFeedItemProps> = ({
  createdAt,
  action,
  user,
  subject,
  target,
  departure,
  adventure,
  itinerary,
  timeline,
  read,
  closeUserActivityDrawer,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const activityFeedItemUrl = generateActivityFeedItemUrl({
    action,
    departure,
    adventure,
    itinerary,
    timeline,
    subject,
    target,
  });
  const flagged = action === UserActivityAction.FlaggedTimelineComponent;

  const userActivityMetaInformation: string[] = [];
  if (adventure && adventure.bookingEngineTourCode) {
    userActivityMetaInformation.push(adventure.bookingEngineTourCode);
  }
  if (itinerary && itinerary.description) {
    userActivityMetaInformation.push(itinerary.description);
  }
  if (departure) {
    userActivityMetaInformation.push(format(parseISO(departure.date), 'dd MMM yyyy'));
  }

  const createdAtDate = new Date(createdAt);
  const timeSinceEvent = formatDistanceToNow(createdAtDate);

  return (
    <ActivityFeedItemContainer
      flagged={flagged}
      onClick={() => {
        if (!activityFeedItemUrl) {
          return;
        }
        closeUserActivityDrawer();
        navigate(activityFeedItemUrl);
      }}
      data-testid="activity-feed-item"
    >
      <ActivityFeedIcon action={action} flagged={flagged} sx={{ gridArea: 'icon' }} />
      <ActivityFeedExpression
        action={action}
        user={user}
        subject={subject}
        target={target}
      />
      <Typography
        variant="bodyPara"
        color={theme.palette.principal.grey70}
        sx={{
          whiteSpace: 'nowrap',
          gridArea: 'timestamp',
          textAlign: 'right',
        }}
        className="chromatic-ignore"
      >
        {timeSinceEvent} ago
      </Typography>
      <Typography
        variant="bodyPara"
        color={theme.palette.principal.grey70}
        sx={{ gridArea: 'meta' }}
      >
        <ChainedText parts={userActivityMetaInformation} marginX={1} />
      </Typography>
      {!read && (
        <Box
          sx={{ position: 'absolute', top: -8, right: -8, fontSize: 16 }}
          data-testid="indicator-icon"
        >
          <Circle htmlColor={'red'} fontSize="inherit" />
        </Box>
      )}
    </ActivityFeedItemContainer>
  );
};

interface ActivityFeedItemContainerProps {
  flagged: boolean;
}

const ActivityFeedItemContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'flagged',
})<ActivityFeedItemContainerProps>(({ flagged, theme }) => ({
  textAlign: 'left',
  cursor: 'pointer',
  backgroundColor: flagged ? theme.palette.system.red10 : theme.palette.principal.white,
  padding: theme.spacing(2),
  borderColor: flagged ? theme.palette.system.red10 : theme.palette.principal.grey50,
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 0.5,
  marginBottom: theme.spacing(1),
  transition: 'box-shadow 0.2s ease-in-out',
  '&:hover': {
    boxShadow: '0px 10px 20px -5px #20202033',
  },
  display: 'grid',
  gridTemplateColumns: '32px auto 1fr',
  columnGap: theme.spacing(1),
  gridTemplateAreas: `"icon title timestamp"
  "icon meta meta"`,
  position: 'relative',
}));
