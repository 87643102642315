import { Form } from 'react-final-form';
import { ConfirmationDialog } from '@src/design-system/dialogs/ConfirmationDialog';
import { ApolloError } from '@apollo/client';
import { ServerValidationErrors } from '@src/design-system/forms/ServerValidationErrors';
import { Validator } from '@src/design-system/forms/validators';
import { TextField } from 'mui-rff';
import { Stack } from '@mui/system';
import { Button } from '@mui/material';

import { SourceItinerary } from './AdventureItinerariesTab';
import { FC } from 'react';

export type DuplicateItineraryForm = {
  newDescription: string;
};

type DuplicateItineraryDialogProps = {
  sourceItinerary: SourceItinerary;
  open: boolean;
  onClose: () => void;
  onSubmit: (values: DuplicateItineraryForm) => Promise<unknown>;
};

export const DuplicateItineraryDialog: FC<DuplicateItineraryDialogProps> = ({
  sourceItinerary,
  open,
  onClose,
  onSubmit,
}) => {
  return (
    <ConfirmationDialog
      title={`Duplicate ${sourceItinerary.description}?`}
      open={open}
      onCancel={onClose}
      hasCustomActions
    >
      <Form<DuplicateItineraryForm>
        onSubmit={onSubmit}
        render={({ handleSubmit, dirtySinceLastSubmit, submitError }) => (
          <>
            <form id="duplicateForm" onSubmit={(event) => void handleSubmit(event)}>
              <TextField
                label="New Version Name"
                name="newDescription"
                fieldProps={{ validate: Validator.required }}
              />
            </form>
            {!dirtySinceLastSubmit && (
              <ServerValidationErrors error={submitError as ApolloError} />
            )}
            <Stack direction="row" gap={3} justifyContent="flex-end">
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit" form="duplicateForm" variant="contained">
                Duplicate
              </Button>
            </Stack>
          </>
        )}
      />
    </ConfirmationDialog>
  );
};
