import { LoadingButton } from '@mui/lab';
import { Menu, MenuItem } from '@mui/material';
import { Itinerary } from '@flashpack/graphql';
import { FC, useState } from 'react';
import { OpenMenu } from '../departure-status/icons/DepartureStatusIcon';

interface PropTypes {
  alternativeItineraries: Pick<Itinerary, 'id' | 'description'>[];
  onChange: (itinerary: Pick<Itinerary, 'id' | 'description'>) => void;
  loading: boolean;
}

export const MoveDepartureMenu: FC<PropTypes> = ({
  alternativeItineraries,
  onChange,
  loading,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnChange = (itinerary: Pick<Itinerary, 'id' | 'description'>) => {
    handleClose();
    onChange(itinerary);
  };

  if (alternativeItineraries.length === 0) {
    return null;
  }

  return (
    <>
      <Menu
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {alternativeItineraries.map((itinerary) => {
          return (
            <MenuItem onClick={() => handleOnChange(itinerary)} key={itinerary.id}>
              {itinerary.description}
            </MenuItem>
          );
        })}
      </Menu>
      <LoadingButton
        loading={loading}
        variant="contained"
        onClick={handleClick}
        sx={{
          color: 'white',
          border: '1px solid white',
          paddingX: 3,
          '& .MuiLoadingButton-loadingIndicator': {
            color: 'white',
          },
        }}
        endIcon={<OpenMenu fill="white" />}
      >
        Move to Itinerary
      </LoadingButton>
    </>
  );
};
