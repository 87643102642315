import { useQuery } from '@apollo/client';
import { MenuItem, Select, Stack } from '@mui/material';
import { flashPackTheme } from '@src/design-system/theme';
import {
  Adventure,
  User,
  UserActivityCategory,
  UserActivityFilterScope,
  UserActivityFiltersDocument,
} from '@flashpack/graphql';
import { Dispatch, FC, SetStateAction } from 'react';

export const favoriteAdventureFilterString = 'FAVORITES';

export type FilterStates = {
  adventure: {
    selectedAdventure: string;
    setSelectedAdventure: Dispatch<SetStateAction<string>>;
  };
  user: {
    selectedUser: string;
    setSelectedUser: Dispatch<SetStateAction<string>>;
  };
  type: {
    selectedType: UserActivityCategory | '';
    setSelectedType: Dispatch<SetStateAction<UserActivityCategory | ''>>;
  };
};

export const ActivityFeedFilters: FC<FilterStates> = ({ adventure, user, type }) => {
  const { data } = useQuery(UserActivityFiltersDocument);

  return (
    <Stack direction="row" spacing={1}>
      <AdventuresFilter
        adventures={data?.adventures}
        selectedAdventure={adventure.selectedAdventure}
        setSelectedAdventure={adventure.setSelectedAdventure}
      />
      <UsersFilter
        users={data?.adventureManagerUsers}
        selectedUser={user.selectedUser}
        setSelectedUser={user.setSelectedUser}
      />
      <TypesFilter
        selectedType={type.selectedType}
        setSelectedType={type.setSelectedType}
      />
    </Stack>
  );
};

const AdventuresFilter = ({
  adventures,
  selectedAdventure,
  setSelectedAdventure,
}: {
  adventures: Pick<Adventure, 'id' | 'bookingEngineTourCode'>[] | undefined;
  selectedAdventure: string;
  setSelectedAdventure: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <Select
      value={selectedAdventure}
      onChange={(event) => {
        setSelectedAdventure(event.target.value);
      }}
      displayEmpty
      sx={{
        width: '160px',
        backgroundColor: flashPackTheme.palette.principal.grey30,
        color: flashPackTheme.palette.principal.grey70,
        '&:hover': {
          backgroundColor: flashPackTheme.palette.principal.grey50,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      }}
    >
      <MenuItem value={''}>Adventures</MenuItem>
      <MenuItem value={favoriteAdventureFilterString} onClick={() => {}}>
        My Adventures
      </MenuItem>
      {adventures?.map((adventure) => (
        <MenuItem key={adventure.id} value={adventure.id} onClick={() => {}}>
          {adventure.bookingEngineTourCode}
        </MenuItem>
      ))}
    </Select>
  );
};

const UsersFilter = ({
  users,
  selectedUser,
  setSelectedUser,
}: {
  users: Pick<User, 'id' | 'email'>[] | undefined;
  selectedUser: string;
  setSelectedUser: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <Select
      value={selectedUser}
      onChange={(event) => {
        setSelectedUser(event.target.value);
      }}
      displayEmpty
      sx={{
        width: '195px',
        backgroundColor: flashPackTheme.palette.principal.grey30,
        color: flashPackTheme.palette.principal.grey70,
        '&:hover': {
          backgroundColor: flashPackTheme.palette.principal.grey50,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      }}
    >
      <MenuItem value={''}>Users</MenuItem>
      <MenuItem value={UserActivityFilterScope.ExternalOnly}>All DMCs</MenuItem>
      {users?.map((user) => (
        <MenuItem key={user.id} value={user.id} onClick={() => {}}>
          {user.email}
        </MenuItem>
      ))}
    </Select>
  );
};

const TypesFilter = ({
  selectedType,
  setSelectedType,
}: {
  selectedType: UserActivityCategory | '';
  setSelectedType: Dispatch<SetStateAction<UserActivityCategory | ''>>;
}) => {
  return (
    <Select
      value={selectedType}
      onChange={(event) => {
        setSelectedType(event.target.value as UserActivityCategory);
      }}
      displayEmpty
      sx={{
        width: '225px',
        backgroundColor: flashPackTheme.palette.principal.grey30,
        color: flashPackTheme.palette.principal.grey70,
        '&:hover': {
          backgroundColor: flashPackTheme.palette.principal.grey50,
        },
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
      }}
    >
      <MenuItem value={''}>Types</MenuItem>
      <MenuItem value={UserActivityCategory.Accommodation}>Accommodation</MenuItem>
      <MenuItem value={UserActivityCategory.Activity}>Activity</MenuItem>
      <MenuItem value={UserActivityCategory.Adventure}>Adventure</MenuItem>
      <MenuItem value={UserActivityCategory.Comment}>Comment</MenuItem>
      <MenuItem value={UserActivityCategory.Cost}>Cost</MenuItem>
      <MenuItem value={UserActivityCategory.DepartureCancellation}>
        Departure Cancellation
      </MenuItem>
      <MenuItem value={UserActivityCategory.DepartureProposal}>
        Departure Proposal
      </MenuItem>
      <MenuItem value={UserActivityCategory.DepartureSecured}>Departure Secured</MenuItem>
      <MenuItem value={UserActivityCategory.DepartureVisibility}>
        Departure Visibility
      </MenuItem>
      <MenuItem value={UserActivityCategory.DepartureSettings}>
        Departure Settings
      </MenuItem>
      <MenuItem value={UserActivityCategory.DepartureSignOffs}>
        Departure Sign Offs
      </MenuItem>
      <MenuItem value={UserActivityCategory.EarlyBirdDiscount}>
        Early Bird Discount
      </MenuItem>
      <MenuItem value={UserActivityCategory.PromotionalDiscount}>
        Promotional Discount
      </MenuItem>
      <MenuItem value={UserActivityCategory.Itinerary}>Itinerary</MenuItem>
      <MenuItem value={UserActivityCategory.PackLeader}>Pack Leader</MenuItem>
      <MenuItem value={UserActivityCategory.Price}>Price</MenuItem>
      <MenuItem value={UserActivityCategory.Thread}>Thread</MenuItem>
      <MenuItem value={UserActivityCategory.TimelineFlagging}>Timeline Flagging</MenuItem>
      <MenuItem value={UserActivityCategory.Transfer}>Transfer</MenuItem>
      <MenuItem value={UserActivityCategory.User}>User</MenuItem>
    </Select>
  );
};
