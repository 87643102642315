import {
  LinearProgress,
  LinearProgressProps,
  Theme,
  Typography,
  linearProgressClasses,
  styled,
  useTheme,
} from '@mui/material';
import { Box } from '@mui/system';
import { FC } from 'react';

type ProgressBarProps = Omit<LinearProgressProps, 'color'> & {
  value: number;
  showValue?: boolean;
  barColor?: string;
};

const StyledLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'barColor',
})<{ barColor: string }>(({ theme, barColor }) => ({
  height: '16px',
  borderRadius: '100px',
  backgroundColor: theme.palette.principal.grey30,

  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '100px',
    backgroundColor: barColor,
  },
}));

export const ProgressBar: FC<ProgressBarProps> = ({ showValue = true, ...props }) => {
  const theme = useTheme();
  const { value, barColor } = props;
  const color = barColor ? barColor : getColorFromValue(value, theme);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%' }}>
        <StyledLinearProgress variant="determinate" barColor={color} {...props} />
      </Box>
      {showValue && (
        <Box sx={{ minWidth: 35 }}>
          <Typography
            data-testid="progress-bar-value"
            variant="captionSingle"
            color={barColor}
          >
            {value}%
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const getColorFromValue = (value: number, theme: Theme) => {
  if (value === 0) {
    return theme.palette.principal.grey70;
  }
  if (value < 100) {
    return theme.palette.brand.sunset;
  } else {
    return theme.palette.system.green100;
  }
};
